import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonButtons, IonCheckbox, IonContent } from '@ionic/react'
import { IonFooter, IonInput, IonItem, IonLabel, IonModal } from '@ionic/react'
import { IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { CreateConsentEventInput } from '../../../API'
import { ConsentEventService } from '../../../components/services/ConsentEventService'
import { CurrentDoctorService } from '../../../components/services/CurrentDoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure } from '../../../components/util/Toast'

interface OwnProps {
  email?: string | null
  phone?: string | null
  givenName: string | null
  familyName?: string | null
  patientID?: string | null
  userID?: string | null
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
interface ConsentFormManualModalProps extends OwnProps {}

const ConsentFormManualModal: FC<ConsentFormManualModalProps> = ({
  givenName,
  familyName,
  patientID,
  userID,
  phone,
  email,
  isOpen,
  onDidDismiss,
}) => {
  const [model, setModel] = useState<CreateConsentEventInput>({
    givenName,
    familyName,
    userID: userID || '0',
    patientID: patientID || '0',
    phone,
    email,
  } as CreateConsentEventInput)
  const modalRef = useRef<any>()
  const [saving, setSaving] = useState(false)
  const [presentToast] = useIonToast()

  const store = async () => {
    setSaving(true)
    const res: TbnResponse = await ConsentEventService.Instance.createConsentEvent({
      ...model,
      consenter: `${model.givenName} ${model.familyName}`,
      consentContent: '{}',
      manual: true,
    })
    if (res.data) modalRef?.current?.dismiss(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setSaving(false)
  }

  const isInvalid = () => {
    return !model.familyName
  }

  useEffect(() => {
    try {
      CurrentDoctorService.Instance.currentDoctor().catch((ignore) => {})
    } catch (ignore) {}
  }, [])

  return (
    <IonModal id='consent-form-manual-modal' isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef}>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel className='ion-margin-bottom required' position='stacked'>
            Given name
          </IonLabel>
          <IonInput value={model.givenName} readonly={true} disabled={true} />
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom required' position='stacked'>
            Family name
          </IonLabel>
          <IonInput
            value={model.familyName}
            placeholder='Type family name...'
            onIonChange={(e) => setModel({ ...model, familyName: e.detail.value } as CreateConsentEventInput)}
          />
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom required' position='stacked'>
            Phone
          </IonLabel>
          <IonInput value={model.phone} readonly={true} disabled={true} />
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom'>Agree to share clinical outcomes for research purposes</IonLabel>
          <IonCheckbox
            slot='end'
            value={model.researchAllowed}
            onIonChange={(e) => setModel({ ...model, researchAllowed: e.detail.checked } as CreateConsentEventInput)}
          />
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => modalRef.current.dismiss()}>Cancel</IonButton>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ConsentFormManualModal
