import { TgaStatus } from '../../API'
import { warn } from '../util/Log'

const tgaStatusLookup = (text: string) => {
  if (!!text) {
    switch (text) {
      case 'TGA Approved':
        return TgaStatus.TGA_APPROVED
      case 'Under TGA Review':
        return TgaStatus.TGA_UNDER_REVIEW
    }
  }
  warn('NO TGA STATUS FOUND for', text)
  return null
}

export default tgaStatusLookup
