import { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Doctor } from '../../../API'
import { connect } from '../../../data'
import DoctorContainer from '../DoctorContainer'
import PatientSearch from './PatientSearch'

interface OwnProps extends RouteComponentProps {}
interface PatientSearchPageProps extends OwnProps {}
const PatientSearchPage: FC<PatientSearchPageProps> = ({ history }) => {
  const [doctor, setDoctor] = useState<Doctor>()

  return (
    <DoctorContainer
      title={`Patients - Dr ${!!doctor?.drName ? doctor?.drName : '...'}`}
      id='search-patient-page'
      doctorLoaded={setDoctor}
    >
      {!!doctor?.id && <PatientSearch doctorID={doctor?.id} history={history} />}
    </DoctorContainer>
  )
}

export default connect<OwnProps, {}, {}>({
  component: PatientSearchPage,
})
