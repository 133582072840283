import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { CreateConsentEventInput } from '../../API'
import { createConsentEvent } from '../../graphql/mutations'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ConsentEventService {
  private static consentEventService: ConsentEventService | undefined

  public async buildConsentEventService(): Promise<ConsentEventService> {
    try {
      let consentEventService: ConsentEventService = new ConsentEventService()
      return Promise.resolve(consentEventService)
    } catch (err) {
      warn('Init ConsentEventService failure:', err)
      return this.buildConsentEventService()
    }
  }

  public async createConsentEvent(input: CreateConsentEventInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createConsentEvent,
        variables: {
          input: {
            ...input,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create consentEvent res', res)
      return { data: res.data.createConsentEvent, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('Error creating consentEvent! :', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ConsentEventService {
    if (!this.consentEventService) {
      this.consentEventService = new ConsentEventService()
      this.consentEventService.buildConsentEventService().then((service: ConsentEventService) => {
        this.consentEventService = service
      })
    }
    return this.consentEventService
  }
}
