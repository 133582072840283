import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { FC, useEffect, useState } from 'react'
import { isPlatform } from '@ionic/react'
import { IonButton, IonCheckbox, IonInput, IonItem, IonLabel } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { Patient } from '../../../API'
import { isBooking, isPatient } from '../../../components/models/Model'
import { lookupOtherText } from '../../../components/util/LookupOtherText'
import { connect } from '../../../data'
import { setBooking, setPatientHealth } from '../../../data/user/user.actions'
import Booking from '../../booking/Booking.model'
import './QuestionMultiCheck.scss'

interface OwnProps {
  swiper?: SwiperCore
  model: Booking | Patient
  label: string
  alter?: string
  id: string
  options: string[]
  required?: boolean
  readonly?: boolean
  paramElse?: string | null
}
interface DispatchProps {
  setBooking: typeof setBooking
  setPatientHealth: typeof setPatientHealth
}
interface QuestionMultiCheckProps extends OwnProps, DispatchProps {}
const QuestionMultiCheck: FC<QuestionMultiCheckProps> = ({
  swiper,
  model,
  label,
  alter,
  id,
  options,
  required = false,
  readonly = false,
  setBooking,
  setPatientHealth,
  paramElse,
}) => {
  const [chks, setChks] = useState<string>((model as any)?.[id] || '')
  const isKeyboardOpen = useDetectKeyboardOpen()
  const [hideLabel, setHideLabel] = useState(false)

  const isChecked = (chk: string) => {
    return !!chks && chks.indexOf(chk) !== -1
  }

  const setChecked = (chk: string, checked: boolean) => {
    if (checked) {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) === -1) {
          setChks(chks + `${chk},`)
        }
      } else {
        setChks(chks + `${chk}<>,`)
      }
    } else {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) !== -1) {
          setChks(chks?.replace(`${chk},`, ''))
        }
      } else {
        setChks(chks.replace(/Other<.*>,/, ''))
      }
    }
  }

  const lookupOther = () => {
    return lookupOtherText(chks)
  }

  const setOther = (othr?: string) => {
    setChks(chks.replace(/Other<.*>,/, `Other<${othr}>,`))
  }

  const setModel = (id: string, value: string) => {
    if (!readonly) {
      if (isBooking(model)) {
        setBooking({ ...model, [id]: value })
      } else if (isPatient(model)) {
        setPatientHealth({ ...model, [id]: value })
      }
    }
  }

  const resolveLabel = () => {
    if (!paramElse) {
      return label.split(' ').map((str) => {
        if (str.startsWith('{')) {
          return (model as any)?.[str.replace(/[{}]/g, '')]
        } else {
          return str + ' '
        }
      })
    } else {
      return alter?.split(' ').map((str) => {
        if (str.startsWith('{')) {
          return (model as any)?.[str.replace(/[{}]/g, '')]
        } else {
          return str + ' '
        }
      })
    }
  }

  useEffect(() => {
    if (isKeyboardOpen && isPlatform('android')) {
      setHideLabel(true)
    } else {
      setHideLabel(false)
    }
  }, [isKeyboardOpen]) // eslint-disable-line

  useEffect(() => {
    //log(chks)
    setModel(id, chks)
  }, [chks]) // eslint-disable-line

  return (
    <>
      {!hideLabel && (
        <>
          <span></span>
          <IonLabel className={`ion-text-wrap ion-padding-bottom ${required ? 'required' : ''}`}>
            {resolveLabel()}
          </IonLabel>
        </>
      )}
      {options.map((opt) => (
        <div key={opt}>
          {!hideLabel && (
            <IonItem key={opt} className='question-multi-check'>
              <IonLabel className='ion-text-wrap'>{opt}</IonLabel>
              <IonCheckbox
                color='primary'
                slot='start'
                checked={isChecked(opt)}
                onIonChange={(e) => setChecked(opt, e.detail.checked)}
                disabled={readonly}
              />
            </IonItem>
          )}
          {opt === 'Other' && isChecked('Other') && (
            <IonItem key={'other-custom'}>
              <IonInput
                value={lookupOther()}
                onIonChange={(e: any) => setOther(e.detail.value)}
                placeholder={'Type your answer here...'}
                onIonFocus={() => {
                  if (isPlatform('android')) {
                    setHideLabel(true)
                  }
                }}
                onIonBlur={() => setHideLabel(false)}
                readonly={readonly}
              />
            </IonItem>
          )}
        </div>
      ))}

      {!hideLabel && !!swiper && (
        <IonButton color='primary' onClick={() => swiper?.slideNext()} className='ion-margin-top'>
          Continue
        </IonButton>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
    setPatientHealth,
  },
  component: QuestionMultiCheck,
})
