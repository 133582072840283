import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonButtons, IonContent } from '@ionic/react'
import { IonFooter, IonInput, IonItem, IonLabel, IonModal } from '@ionic/react'
import { IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { Doctor, ScriptReceiver } from '../../../API'
import { CurrentDoctorService } from '../../../components/services/CurrentDoctorService'
import { ScriptReceiverService } from '../../../components/services/ScriptReceiverService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure } from '../../../components/util/Toast'

interface OwnProps {
  scriptReceiver?: ScriptReceiver
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
interface ScriptReceiverModalFormProps extends OwnProps {}
const ScriptReceiverModalForm: FC<ScriptReceiverModalFormProps> = ({ isOpen, onDidDismiss, scriptReceiver }) => {
  const [model, setModel] = useState<ScriptReceiver>(!!scriptReceiver ? scriptReceiver : ({} as ScriptReceiver))
  const modalRef = useRef<any>()
  const [saving, setSaving] = useState(false)
  const [presentToast] = useIonToast()

  const store = async () => {
    setSaving(true)
    if (!model?.doctorID) {
      try {
        const doctor: Doctor = await CurrentDoctorService.Instance.currentDoctor()
        model.doctorID = doctor?.id
      } catch (ignore) {}
    }
    const res: TbnResponse = await ScriptReceiverService.Instance.store(model)
    if (res.data) modalRef?.current?.dismiss(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setSaving(false)
  }

  const isInvalid = () => {
    return !model?.name || !model?.email
  }

  useEffect(() => {
    try {
      CurrentDoctorService.Instance.currentDoctor().catch((ignore) => {})
    } catch (ignore) {}
  }, [])

  return (
    <IonModal id='script-receiver-modal-form' isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef}>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel className='ion-margin-bottom' position='stacked'>
            Name
          </IonLabel>
          <IonInput
            value={model.name}
            onIonChange={(e) => setModel({ ...model, name: e.detail.value } as ScriptReceiver)}
          />
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom' position='stacked'>
            Email
          </IonLabel>
          <IonInput
            value={model.email}
            onIonChange={(e) => setModel({ ...model, email: e.detail.value } as ScriptReceiver)}
          />
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => modalRef.current.dismiss()}>Cancel</IonButton>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ScriptReceiverModalForm
