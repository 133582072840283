import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonButtons, IonCol, IonContent, IonFooter } from '@ionic/react'
import { IonGrid, IonHeader, IonIcon, IonItem, IonRow, IonToggle } from '@ionic/react'
import { IonLabel, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/react'
import { IonModal, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { call, close, trashBin } from 'ionicons/icons'
import { AppointmentDefinition, AppointmentSubject, AppointmentType, Clinic, Doctor } from '../../../API'
import appointmentSubjectLabel from '../../../components/models/AppointmentSubjectLabel'
import ClinicStatusComp from '../../../components/models/ClinicStatus'
import AppointmentSubjectSelect from '../../../components/select/AppointmentSubjectSelect'
import CalendlyAppointmentSelect from '../../../components/select/CalendlyAppointmentSelect'
import ClinicSelect from '../../../components/select/ClinicSelect'
import { AppointmentDefinitionService } from '../../../components/services/AppointmentDefinitionService'
import { success } from '../../../components/util/Toast'
import { connect } from '../../../data'
import './AppointmentDefinitionModalForm.scss'

declare var crypto: any

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  doctor: Doctor
  onSuccess: Function
}
interface StateProps {}
interface DispatchProps {}
interface AppointmentDefinitionModalFormProps extends OwnProps, StateProps, DispatchProps {}

const AppointmentDefinitionModalForm: FC<AppointmentDefinitionModalFormProps> = ({
  onSuccess,
  showModal,
  setShowModal,
  doctor,
}) => {
  const [present] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)
  const [apptDef, setApptDef] = useState<AppointmentDefinition>({
    active: true,
    appointmentType: AppointmentType.TELEHEALTH,
  } as AppointmentDefinition)
  const [saving, setSaving] = useState(false)
  const [items, setItems] = useState<AppointmentDefinition[]>([])
  const [clinicPublicId, setClinicPublicId] = useState<string>()
  const [appointmentSubject, setAppointmentSubject] = useState<AppointmentSubject>()

  const store = async () => {
    if (items.length > 0) {
      await storeAll()
      return
    }
    setSaving(true)
    if (!!apptDef) {
      await AppointmentDefinitionService.Instance.store(apptDef)
      success('Success adding new appointment def', present)
      onSuccess()
    }
    setSaving(false)
  }

  const storeAll = async () => {
    setSaving(true)
    for (let itm of items) {
      const copy = JSON.parse(JSON.stringify(itm))
      delete (copy as any)?.['id']
      await AppointmentDefinitionService.Instance.store(copy)
    }
    setItems([])
    success('Success adding new appointment defs', present)
    onSuccess()
    setSaving(false)
  }

  const isInvalid = () => {
    return (
      !apptDef.appointmentType ||
      !apptDef.calendlyAppointmentID ||
      (apptDef.appointmentType === AppointmentType.IN_PERSON && !apptDef.clinicID)
    )
  }

  const addToItems = () => {
    const copy = JSON.parse(JSON.stringify(apptDef))
    copy.id = crypto.randomUUID({ disableEntropyCache: true })
    setItems([...items, copy])
  }

  const removeFromItems = (ad: AppointmentDefinition) => {
    setItems(items.filter((itm: AppointmentDefinition) => itm.id !== ad.id))
  }

  useEffect(() => {
    setApptDef({ ...apptDef, doctor, doctorID: doctor?.id, active: true } as AppointmentDefinition)
  }, [doctor?.id]) // eslint-disable-line

  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='calendly-appointment-modal'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>New Appointment Type</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonRadioGroup
          value={apptDef?.appointmentType}
          onIonChange={(e) => setApptDef({ ...apptDef, appointmentType: e.detail.value } as AppointmentDefinition)}
        >
          <IonItem>
            <IonLabel>Telehealth</IonLabel>
            <IonRadio slot='end' value={AppointmentType.TELEHEALTH}></IonRadio>
          </IonItem>
          <IonItem>
            <IonLabel>In-Person</IonLabel>
            <IonRadio slot='end' value={AppointmentType.IN_PERSON}></IonRadio>
          </IonItem>
        </IonRadioGroup>
        <IonItem>
          <IonLabel>Active</IonLabel>
          <IonToggle
            checked={apptDef?.active || undefined}
            onIonChange={(e) => setApptDef({ ...apptDef, active: e.detail.checked })}
          />
        </IonItem>
        <div className='ion-padding-start'>
          <IonLabel color='medium'>Clinic</IonLabel>
          <ClinicSelect
            defaultValue={apptDef?.clinicID}
            onChange={(clinic: Clinic) => {
              setApptDef({ ...apptDef, clinic, clinicID: clinic.id } as AppointmentDefinition)
              setClinicPublicId(clinic.publicId)
            }}
          />
        </div>
        <div className='ion-padding-start'>
          <IonLabel color='medium'>Appointment subject</IonLabel>
          <AppointmentSubjectSelect
            onChange={(itm: any) => {
              setApptDef({ ...apptDef, appointmentSubject: itm.value })
              setAppointmentSubject(itm.value)
            }}
          />
        </div>
        <div className='ion-padding-start'>
          <IonLabel color='medium'>Calendly Appointment</IonLabel>
          <CalendlyAppointmentSelect
            defaultValue={apptDef?.calendlyAppointmentID}
            clinicPublicId={clinicPublicId}
            doctorPublicId={doctor?.drId}
            appointmentSubject={appointmentSubject}
            onChange={(itm: any) => {
              setApptDef({ ...apptDef, calendlyAppointmentID: itm.value } as AppointmentDefinition)
            }}
          />
        </div>
        <IonGrid>
          <IonRow style={{ marginBottom: '60px' }}>
            <IonCol className='ion-text-end' color='primary'>
              <IonButton fill='clear' disabled={saving || isInvalid()} onClick={addToItems}>
                Add
              </IonButton>
            </IonCol>
          </IonRow>
          {items.map((itm: AppointmentDefinition) => (
            <IonRow key={itm.id}>
              <IonCol>
                <IonItem>
                  <IonLabel color={!itm?.active ? 'medium' : ''}>
                    <span>{appointmentSubjectLabel(itm?.appointmentSubject)}</span>
                    <p>
                      <span>{itm?.calendlyAppointmentID}</span>
                    </p>
                  </IonLabel>
                  {itm.appointmentType === AppointmentType.TELEHEALTH && (
                    <IonIcon
                      className='ion-margin-start'
                      icon={call}
                      slot='start'
                      color={!itm?.active ? 'medium' : ''}
                    />
                  )}
                  {itm.appointmentType === AppointmentType.IN_PERSON && (
                    <ClinicStatusComp clinic={itm?.clinic} activeOverride={!itm?.active} />
                  )}
                  <IonButton fill='clear' color='danger' onClick={() => removeFromItems(itm)} slot='end'>
                    <IonIcon icon={trashBin} />
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save {items.length > 0 && 'All'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  component: AppointmentDefinitionModalForm,
})
