import { IonSpinner } from '@ionic/react'

const TbnSpinner = () => {
  return (
    <div className='ion-text-center'>
      <IonSpinner />
    </div>
  )
}

export default TbnSpinner
