import { FC, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonChip, IonCol, IonContent } from '@ionic/react'
import { IonGrid, IonHeader, IonItem, IonLabel, IonToggle } from '@ionic/react'
import { IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { checkmark, clipboard, close } from 'ionicons/icons'
import { Quiz } from '../../../API'
import { quizName } from '../../../components/models/QuizType'
import { utcToLocaleDate } from '../../../components/util/Date'
import { QuizQuestion } from '../helpers/QuizQuestion.model'

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  model?: Quiz | undefined
  showNumbersDefault?: boolean
  doctorRole?: boolean
}

interface Dass21AnswerModalProps extends OwnProps {}

const Dass21AnswerModal: FC<Dass21AnswerModalProps> = ({
  showModal,
  setShowModal,
  model,
  showNumbersDefault = false,
  doctorRole = false,
}) => {
  const [present] = useIonToast()
  const [item, setItem] = useState(model)
  const modal = useRef<HTMLIonModalElement>(null)
  const [showNumbers, setShowNumbers] = useState(showNumbersDefault)

  const resolveAnswer = (question: any) => {
    switch (question?.answer) {
      case 'N':
        return 'Never'
      case 'S':
        return 'Sometimes'
      case 'O':
        return 'Often'
      case 'AA':
        return 'Almost Always'
    }
  }

  const resolveAnswersText = () => {
    let result = ''
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      result += `${q.label}   ${resolveAnswer(q)} \n`
      return q
    })
    return result
  }

  const resolveAnswerAsNumber = (answer: 'N' | 'S' | 'O' | 'AA') => {
    return answer === 'N' ? 0 : answer === 'S' ? 1 : answer === 'O' ? 2 : answer === 'AA' ? 3 : 0
  }

  const totalDepression = () => {
    let total = 0
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      if (resolve_D_A_S(idx + 1) === 'D') {
        total += resolveAnswerAsNumber(q.answer)
      }
      return q
    })
    return total
  }

  const totalAnxiety = () => {
    let total = 0
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      if (resolve_D_A_S(idx + 1) === 'A') {
        total += resolveAnswerAsNumber(q.answer)
      }
      return q
    })
    return total
  }

  const totalStress = () => {
    let total = 0
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      if (resolve_D_A_S(idx + 1) === 'S') {
        total += resolveAnswerAsNumber(q.answer)
      }
      return q
    })
    return total
  }

  const hasWarning = (row: number, q: QuizQuestion) => {
    return doctorRole && row === 21 && (q.answer === 'O' || q.answer === 'AA')
  }

  const resolve_D_A_S = (row: number) => {
    switch (row) {
      case 3:
      case 5:
      case 10:
      case 16:
      case 17:
      case 21:
        return 'D'
      case 2:
      case 4:
      case 7:
      case 9:
      case 13:
      case 15:
      case 19:
      case 20:
        return 'A'
      case 1:
      case 6:
      case 8:
      case 11:
      case 12:
      case 14:
      case 18:
        return 'S'
    }
  }

  useEffect(() => {
    const parsed: any = { ...item, qus: JSON.parse(item?.questions || '[]') }
    setItem(parsed)
  }, []) // eslint-disable-line

  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <span title={item?.id}>
                {quizName(item?.quizType)} - {utcToLocaleDate(item?.createdAt)}
              </span>
            </CopyToClipboard>
          </IonTitle>
          <IonButtons slot='end'>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <IonButton color='primary'>
                <IonIcon icon={clipboard} slot='icon-only'></IonIcon>
              </IonButton>
            </CopyToClipboard>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow className='tr-header'>
            <IonCol></IonCol>
            <IonCol size='8'></IonCol>
            <IonCol>N</IonCol>
            <IonCol>S</IonCol>
            <IonCol>O</IonCol>
            <IonCol>AA</IonCol>
            {showNumbers && <IonCol></IonCol>}
          </IonRow>
          {(item as any)?.qus?.map((q: QuizQuestion, idx: number) => (
            <IonRow key={q.id} className={hasWarning(idx + 1, q) ? 'tr-warning' : ''}>
              <IonCol>{idx + 1}</IonCol>
              <IonCol size='8'>{q.label}</IonCol>
              <IonCol>{q.answer === 'N' && (showNumbers ? '0' : <IonIcon icon={checkmark} />)}</IonCol>
              <IonCol>{q.answer === 'S' && (showNumbers ? '1' : <IonIcon icon={checkmark} />)}</IonCol>
              <IonCol>{q.answer === 'O' && (showNumbers ? '2' : <IonIcon icon={checkmark} />)}</IonCol>
              <IonCol>{q.answer === 'AA' && (showNumbers ? '3' : <IonIcon icon={checkmark} />)}</IonCol>
              {showNumbers && <IonCol>{resolve_D_A_S(idx + 1)}</IonCol>}
            </IonRow>
          ))}
        </IonGrid>
        <IonItem>
          <IonLabel>Show the numbers</IonLabel>
          <IonToggle checked={showNumbers} onIonChange={(e) => setShowNumbers(e.detail.checked)}></IonToggle>
        </IonItem>
        {showNumbers && (
          <IonChip style={{ minHeight: '100px' }} color='tertiary' outline={true}>
            The information in this document is not intended as a substitute for professional medical advice, diagnosis
            or treatment. Your results will be interpreted by your health practitioner and help them assess and monitor
            your condition.
          </IonChip>
        )}
        {showNumbers && (
          <IonGrid>
            <IonRow className='tr-header'>
              <IonCol></IonCol>
              <IonCol>
                <b>D</b>epression
              </IonCol>
              <IonCol>
                <b>A</b>nxiety
              </IonCol>
              <IonCol>
                <b>S</b>tress
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>TOTALS</IonCol>
              <IonCol>{totalDepression()}</IonCol>
              <IonCol>{totalAnxiety()}</IonCol>
              <IonCol>{totalStress()}</IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonModal>
  )
}

export default Dass21AnswerModal
