import { FC } from 'react'
import { Redirect } from 'react-router'
import { connect } from '../data'

interface DispatchProps {}
interface RootProps extends DispatchProps {}
const Root: FC<RootProps> = () => {
  const search = window.location.search

  return <Redirect to={`/landing${search}`} />
}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {},
  component: Root,
})
