import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonText, useIonToast } from '@ionic/react'
import { mailOpenOutline, mailOutline, refresh } from 'ionicons/icons'
import { Clinic, Referred } from '../../../API'
import { ReferredService } from '../../../components/services/ReferredService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setClinicReferralCount } from '../../../data/user/user.actions'
import ReceptionContainer from '../../reception/ReceptionContainer'
import Referral from '../Referral.model'
import ReferralAnswerModal from '../ReferralAnswerModal'

interface DispatchProps {
  setClinicReferralCount: typeof setClinicReferralCount
}
interface ClinicReferralListPageProps extends DispatchProps {}
const ClinicReferralListPage: FC<ClinicReferralListPageProps> = ({ setClinicReferralCount }) => {
  const [clinic, setClinic] = useState<Clinic>()
  const [item, setItem] = useState<Referred>()
  const [items, setItems] = useState<Referred[]>([])
  const [presentToast] = useIonToast()
  const [intializing, setInitalizing] = useState(true)
  const [marking, setMarking] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const fetchItems = async () => {
    if (clinic?.publicId) {
      const res: TbnResponse = await ReferredService.Instance.findByClinicPublicId(clinic.publicId)
      if (res.data) setItems(res.data)
      else if (res.errorMessage) failure(res.errorMessage, presentToast)
    }
  }

  const markReadAsReverse = async (item: Referred) => {
    setMarking(true)
    const res: TbnResponse = await ReferredService.Instance.changeClinicReadStatus(item.id, !item.readByClinic)
    if (res.data) {
      await fetchItems()
      if (clinic?.publicId) {
        const count: TbnResponse = await ReferredService.Instance.countUnreadByClinicPublicId(clinic.publicId)
        await setClinicReferralCount(count.data)
      }
    } else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setMarking(false)
  }

  useEffect(() => {
    if (!!clinic) fetchItems()
  }, [clinic]) // eslint-disable-line

  return (
    <ReceptionContainer
      id='clinic-referral-list-page'
      title='Clinic Referral list'
      onClinicLoaded={(clinic: Clinic) => {
        setClinic(clinic)
        setInitalizing(false)
      }}
      actionButtonsEnd={
        <IonButton onClick={fetchItems}>
          <IonIcon icon={refresh} />
        </IonButton>
      }
    >
      {intializing && <TbnSpinner />}
      {!intializing && items.length === 0 && <IonText>No record to display</IonText>}
      <IonList>
        {items.map((itm: Referred) => (
          <IonItem key={itm.id}>
            <IonLabel
              className='pointer'
              style={{ fontWeight: itm?.readByClinic ? 'normal' : 'bold' }}
              onClick={() => {
                setItem(itm)
                setShowModal(true)
              }}
            >
              {itm.givenName} {itm.surName}
              <p>
                {itm.practitionerFirstName
                  ? `Referred by ${itm.practitionerFirstName} ${itm.practitionerLastName}`
                  : ''}
              </p>
            </IonLabel>
            <IonButtons>
              {!itm.readByClinic && (
                <IonButton onClick={() => markReadAsReverse(itm)} disabled={marking}>
                  <IonIcon icon={mailOutline}></IonIcon>
                </IonButton>
              )}
              {!!itm.readByClinic && (
                <IonButton onClick={() => markReadAsReverse(itm)} disabled={marking}>
                  <IonIcon icon={mailOpenOutline}></IonIcon>
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        ))}
      </IonList>
      {!!item && (
        <ReferralAnswerModal
          referral={item as Referral}
          isOpen={showModal}
          onDidDismiss={() => {
            setShowModal(false)
            setTimeout(() => {
              setItem(undefined)
            }, 0)
          }}
        />
      )}
    </ReceptionContainer>
  )
}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {
    setClinicReferralCount,
  },
  component: ClinicReferralListPage,
})
