import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonCheckbox, IonChip } from '@ionic/react'
import { IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonTextarea } from '@ionic/react'
import { IonLabel, IonList, IonText, useIonAlert, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { add, refresh, trashBin, calculator } from 'ionicons/icons'
import { AppointmentMedication, CreateAppointmentMedicationInput, Doctor, ProductMap } from '../../API'
import { Patient, TypeFormEvent } from '../../API'
import dosageMeasurementLabel from '../../components/models/DosageMeasurementLabel'
import dosageMeasurementLookup from '../../components/models/DosageMeasurementLookup'
import ProductCategoryBadge from '../../components/models/ProductCategoryBadge'
import productCategoryToNumber from '../../components/models/ProductCategoryToNumber'
import ProductMeasurementFromPresentation from '../../components/models/ProductMeasurementFromPresentation'
import ProductPresentationIcon from '../../components/models/ProductPresentationIcon'
import MapNumberSelect from '../../components/select/MapNumberSelect'
import { AppointmentMedicationService } from '../../components/services/AppointmentMedicationService'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { isDoctor } from '../../components/util/Auth'
import { convertISOtoDateFormat1, isBeforeToday } from '../../components/util/Date'
import { warn } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { isMobile } from '../../components/util/isMobile'
import DosageCalculatorModal from '../tools/dose-calc/DosageCalculatorModal'

interface AppointmentMedProps {
  appointment: TypeFormEvent
  patient?: Patient | null
}
const AppointmentMed: FC<AppointmentMedProps> = ({ appointment, patient }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [mapSelected, setMapSelected] = useState<ProductMap>()
  const [items, setItems] = useState([])
  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [dailyDose, setDailyDose] = useState<string>()
  const [quantity, setQuantity] = useState<string>()
  const [repeats, setRepeats] = useState<string>()
  const [repeatInterval, setRepeatInterval] = useState<string>()
  const [frequency, setFrequency] = useState<string>()
  const [prn, setPrn] = useState<boolean>(false)
  const [instruction, setInstruction] = useState<string>()
  const [hide, setHide] = useState(false)
  const [showCalcModal, setShowCalcModal] = useState(false)

  const fetchItems = async () => {
    const res: TbnResponse = await AppointmentMedicationService.Instance.findByAppointmentId(appointment.id)
    if (!!res.data) setItems(res.data)
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
  }

  const addToAppointment = async () => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setAdding(true)
    const doctorID = mapSelected?.doctorID
    if (!!mapSelected && !!doctorID && !!patient?.id) {
      const cmd: CreateAppointmentMedicationInput = {
        mapID: mapSelected?.mapID,
        productID: mapSelected?.productID,
        appointmentID: appointment.id,
        doctorID,
        patientID: patient?.id,
        phone: appointment.phone,
        givenName: appointment.firstName,
        familyName: patient?.surName,
        dailyDose,
        measurement: dosageMeasurementLookup(mapSelected?.product?.presentation),
        quantity,
        repeats,
        repeatInterval,
        prn,
        frequency,
        instruction,
      }
      const res: TbnResponse = await AppointmentMedicationService.Instance.add(cmd)
      if (!!res.data) {
        fetchItems()
        setDailyDose('')
        setQuantity('')
        setRepeats('')
        setRepeatInterval('')
        setPrn(false)
        setFrequency('')
        setMapSelected(undefined)
        setTimeout(() => {
          setInstruction(undefined)
        }, 1000)
      } else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    }
    setAdding(false)
  }

  const removeFromAppointment = async (itm: AppointmentMedication) => {
    const isAllowed = await isActualDoctor()
    if (!isAllowed) {
      failure('This appointment belongs to another doctor', presentToast)
      return
    }
    setRemoving(true)
    const res: TbnResponse = await AppointmentMedicationService.Instance.remove(itm)
    if (!!res.data) fetchItems()
    else if (!!res.errorMessage) failure(res.errorMessage, presentToast)
    setRemoving(false)
  }

  const isActualDoctor = async () => {
    if (!isDoctor(user)) {
      return false
    }
    try {
      const currentDoctor: Doctor | undefined = await CurrentDoctorService.Instance.currentDoctor()
      if (!!currentDoctor && currentDoctor?.drId === appointment.doctorPublicId) {
        return true
      }
    } catch (err) {
      warn(err)
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      const res: any = await isActualDoctor()
      return res
    }
    return false
  }

  const count = (str?: string) => {
    return str?.split('\n')?.length
  }

  const initInstruction = () => {
    const line1 = !!mapSelected
      ? `${mapSelected?.product?.name?.toUpperCase()} (${mapSelected?.product?.ingredients})`
      : ''
    const dose = mapSelected
      ? `${dailyDose} ${
          mapSelected?.product?.presentation
            ? dosageMeasurementLabel(undefined, mapSelected?.product?.presentation)
            : ''
        }`
      : ''
    const line2 = `${!!dose ? dose : ''}${!!frequency ? `, ${frequency}` : ''}${prn ? `, PRN` : ''}`
    const line3 = `QUANTITY: ${!!quantity ? quantity : 'NIL'}   REPEATS: ${!!repeats ? repeats : 'NIL'} ${
      !!repeatInterval ? `   REPEAT INTERVAL: ${repeatInterval}` : ''
    }`
    const category = mapSelected?.map?.category ? `Category ${productCategoryToNumber(mapSelected?.map?.category)}` : ''
    const line4 = !!mapSelected
      ? `${category}, ${mapSelected?.map?.mapNumber}, ${convertISOtoDateFormat1(mapSelected?.map?.expiryDate)}`
      : ''
    setInstruction(`${line1}\n${line2}\n${line3}\n${line4}`)
  }

  const instructionProductChangeEvent = () => {
    const lines = instruction?.split('\n') || ['', '', '', '']
    const line1 = `${mapSelected?.product?.name?.toUpperCase()} (${mapSelected?.product?.ingredients})`
    lines[0] = line1
    const category = mapSelected?.map?.category ? `Category ${productCategoryToNumber(mapSelected?.map?.category)}` : ''
    const line4 = `${category}, ${mapSelected?.map?.mapNumber}, ${convertISOtoDateFormat1(
      mapSelected?.map?.expiryDate,
    )}`
    lines[3] = line4
    setInstruction(lines.join('\n'))
  }

  const instructionLine3ChangeEvent = () => {
    const lines = instruction?.split('\n') || ['', '', '', '']
    const line3 = `QUANTITY: ${!!quantity ? quantity : 'NIL'}   REPEATS: ${!!repeats ? repeats : 'NIL'} ${
      !!repeatInterval ? `   REPEAT INTERVAL: ${repeatInterval}` : ''
    }`
    lines[2] = line3
    setInstruction(lines.join('\n'))
  }

  const instructionLine2ChangeEvent = () => {
    const lines = instruction?.split('\n') || ['', '', '', '']
    const dose = `${dailyDose} ${
      mapSelected?.product?.presentation ? dosageMeasurementLabel(undefined, mapSelected?.product?.presentation) : ''
    }`
    const line2 = `${!!dose ? dose : ''}${!!frequency ? `, ${frequency}` : ''}${prn ? `, PRN` : ''}`
    lines[1] = line2
    setInstruction(lines.join('\n'))
  }

  useEffect(() => {
    instructionLine2ChangeEvent()
  }, [dailyDose, frequency, prn]) // eslint-disable-line

  useEffect(() => {
    instructionLine3ChangeEvent()
  }, [quantity, repeats, repeatInterval]) // eslint-disable-line

  useEffect(() => {
    if (!!mapSelected) {
      instructionProductChangeEvent()
    }
  }, [mapSelected]) // eslint-disable-line

  useEffect(() => {
    if (count(instruction) !== 4) initInstruction()
  }, [instruction]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <>
      {isDoctor(user) && (
        <div className='ion-padding-bottom'>
          <IonList>
            {items.map((itm: AppointmentMedication) => (
              <IonItem key={itm.id}>
                <IonText slot='start'>
                  <ProductPresentationIcon pp={itm?.product?.presentation} />
                </IonText>
                <IonLabel className='ion-no-margin'>
                  {itm?.product?.name} ({itm?.product?.ingredients})
                  <p>
                    <IonChip>
                      {itm?.dailyDose} {dosageMeasurementLabel(itm?.measurement, itm?.product?.presentation)}
                    </IonChip>{' '}
                    <IonChip>n = {itm?.quantity}</IonChip> <IonChip>{itm?.repeats} repeats</IonChip>{' '}
                    <IonChip>{itm?.repeatInterval}</IonChip>
                  </p>
                </IonLabel>
                <IonText color={isBeforeToday(itm?.map?.expiryDate || '1960-00-00') ? 'danger' : ''} slot='end'>
                  {convertISOtoDateFormat1(itm?.map?.expiryDate)}
                </IonText>
                <IonButtons slot='end'>
                  <ProductCategoryBadge item={itm?.product} />
                  <IonButton
                    fill='clear'
                    color='danger'
                    title='Delete'
                    disabled={removing}
                    onClick={() =>
                      presentAlert({
                        header: `Are you sure?`,
                        subHeader: '',
                        message: `${itm?.product?.name} (${itm?.product?.ingredients})`,
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {},
                          },
                          {
                            text: 'Delete',
                            role: 'destructive',
                            cssClass: 'alert-button-delete',
                            handler: () => {
                              removeFromAppointment(itm)
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => {},
                      })
                    }
                  >
                    <IonIcon icon={trashBin} slot='icon-only' />
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
          </IonList>
          {!!patient && (
            <IonGrid className='ion-no-padding'>
              <div className='flex-container flex-space-between align-items-center'>
                <div style={{ width: '90%' }}>
                  <MapNumberSelect
                    doctorID={CurrentDoctorService.Instance.doctor?.id}
                    patientID={patient?.id}
                    onChange={(itm: ProductMap | undefined) => setMapSelected(itm)}
                    reload={hide}
                  />
                </div>
                <IonButton
                  fill='clear'
                  size='small'
                  onClick={() => {
                    setHide(!hide)
                  }}
                >
                  <IonIcon icon={refresh} slot='icon-only' />
                </IonButton>
              </div>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonInput
                      inputMode='decimal'
                      placeholder='Daily dose'
                      value={dailyDose}
                      onIonChange={(e: any) => setDailyDose(e.detail.value)}
                    />
                    <ProductMeasurementFromPresentation pp={mapSelected?.product?.presentation} slot='end' />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Quantity'
                      value={quantity}
                      onIonChange={(e: any) => setQuantity(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Frequency/Directions'
                      value={frequency}
                      onIonChange={(e: any) => setFrequency(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel>PRN</IonLabel>
                    <IonCheckbox
                      checked={prn}
                      onIonChange={(e: any) => setPrn(e.detail.checked)}
                      style={{ margin: '15px 0px' }}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Repeats'
                      value={repeats}
                      onIonChange={(e: any) => setRepeats(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonInput
                      placeholder='Repeat Interval'
                      value={repeatInterval}
                      onIonChange={(e: any) => setRepeatInterval(e.detail.value)}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='12'>
                  <IonItem>
                    <IonTextarea
                      rows={4}
                      value={instruction}
                      onIonChange={(e: any) => setInstruction(e.detail.value)}
                      placeholder='Instruction label'
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='6' className='ion-text-start'>
                  <IonButton fill='clear' color='dark' onClick={() => setShowCalcModal(true)}>
                    <IonIcon icon={calculator} slot='icon-only' />
                  </IonButton>
                </IonCol>
                <IonCol size='6' className='ion-text-end'>
                  <IonButton
                    onClick={addToAppointment}
                    disabled={!mapSelected || adding}
                    className='ion-float-end'
                    size={isMobile() ? 'small' : 'default'}
                  >
                    <IonIcon icon={add} slot='icon-only' />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {!patient?.id && <IonText color='danger'>Patient record is not present.</IonText>}
        </div>
      )}
      {!isDoctor(user) && <IonText color='danger'>Doctor role is required.</IonText>}
      <DosageCalculatorModal
        isOpen={showCalcModal}
        onDidDismiss={(e: any) => {
          setShowCalcModal(false)
        }}
      />
    </>
  )
}

export default AppointmentMed
