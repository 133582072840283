import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react'
import { IonIcon, IonItem, IonLabel, IonSpinner } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { refresh, sync, trashBin } from 'ionicons/icons'
import { CalendlyAppointment, ModelSortDirection } from '../../../API'
import { CalendlyAppointmentService } from '../../../components/services/CalendlyAppointmentService'
import { RestApiService } from '../../../components/services/RestApiService'
import { failure } from '../../../components/util/Toast'
import { isDesktop } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import { listCalendlyAppointments } from '../../../graphql/queries'
import PageNavigate from '../../doctor/PageNavigate'
import AdminContainer from '../AdminContainer'
import CalendlyAppointmentModal from './CalendlyAppointmentModal'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface CalendlyAppointmentListProps extends OwnProps, StateProps, DispatchProps {}
const CalendlyAppointmentList: FC<CalendlyAppointmentListProps> = ({ history, setNet, net }) => {
  const [item, setItem] = useState<CalendlyAppointment>()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [present] = useIonToast()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const [sortDirection] = useState(ModelSortDirection.DESC)
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length
  const [showModal, setShowModal] = useState(false)

  const fetchItems = async () => {
    setLoading(true)
    const variables = {
      nextToken,
      sortDirection,
    }
    try {
      const res: any = await API.graphql({
        query: listCalendlyAppointments,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItems(res?.data?.listCalendlyAppointments?.items || [])
      setNextNextToken(res?.data?.listCalendlyAppointments?.nextToken)
      setLoading(false)
    } catch (err: any) {
      present({ message: err?.errors[0]?.message, color: 'danger', duration: 3000 })
      setLoading(false)
      setItems([])
    }
  }

  const syncWithCalendly = async () => {
    setNet(true)
    try {
      await reset()
      setItems([])
      await RestApiService.Instance.syncWithCalendlyAppointmentDefinitions()
      await fetchItems()
    } catch (err: any) {
      failure(`${err?.message}`, present)
    }
    setNet(false)
  }

  const deleteAll = async () => {
    setNet(true)
    try {
      await reset()
      setItems([])
      await CalendlyAppointmentService.Instance.removeAll()
      await fetchItems()
    } catch (err) {
      failure(JSON.stringify(err), present)
    }
    setNet(false)
  }

  const deleteItem = async (itm: CalendlyAppointment) => {
    setLoading(true)
    setItems([])
    await CalendlyAppointmentService.Instance.remove(itm)
    await fetchItems()
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  useEffect(() => {
    fetchItems()
  }, [nextToken]) // eslint-disable-line

  return (
    <AdminContainer
      id='calendly-appt-defs'
      title='Calendly appointment definitions'
      actionButtonsEnd={
        <>
          {isDesktop() && (
            <IonButton onClick={deleteAll} disabled={net} className='ion-margin-horizontal'>
              <IonIcon icon={trashBin} slot='start' />
              Delete all
            </IonButton>
          )}
          <IonButton onClick={syncWithCalendly} disabled={net}>
            <IonIcon icon={sync} slot='start' />
            Sync
          </IonButton>
          <IonButton fill='clear' onClick={fetchItems} disabled={loading}>
            <IonIcon icon={refresh} slot='start' />
            Refresh
          </IonButton>
        </>
      }
    >
      <IonGrid>
        {items.map((itm: CalendlyAppointment) => (
          <IonItemSliding key={itm.id}>
            <IonItem style={{ borderBottom: `2px solid ${itm.color}` }}>
              <IonLabel
                className='pinter'
                onClick={() => {
                  setItem(itm)
                  setShowModal(true)
                }}
                color={itm.active ? '' : 'medium'}
              >
                <span>{itm?.name}</span>
                <p>{itm?.slug}</p>
              </IonLabel>
              <IonBadge color='light' slot='end'>
                {itm?.scheduling_url?.substring(21, 21 + itm?.scheduling_url?.substring(21)?.indexOf('/'))}
              </IonBadge>
            </IonItem>
            <IonItemOptions>
              <IonItemOption color='danger' onClick={() => deleteItem(itm)}>
                <IonIcon icon={trashBin} slot='icon-only' />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
        {loading && (
          <div className='ion-text-center'>
            <IonSpinner style={{ position: 'fixed' }} />
          </div>
        )}
        <IonRow>
          <IonCol>
            <PageNavigate {...{ hasNext, hasPrev, prev, next, loading }} />
          </IonCol>
        </IonRow>
      </IonGrid>
      {!!item && <CalendlyAppointmentModal item={item} showModal={showModal} setShowModal={setShowModal} />}
    </AdminContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: withRouter(CalendlyAppointmentList),
})
