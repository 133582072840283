import { FC } from 'react'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { isAccountant } from '../../components/util/Auth'
import PageContainer from '../PageContainer'

interface OwnProps {
  id: string
  title?: string
  padding?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
}
interface AccountantContainerProps extends OwnProps {}

const AccountantContainer: FC<AccountantContainerProps> = ({
  id,
  title,
  children,
  padding = true,
  actionButtonsEnd,
  actionButtonsStart,
}) => {
  const { user } = useAuthenticator((context) => [context.user])

  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      padding={padding}
      actionButtonsEnd={actionButtonsEnd}
      actionButtonsStart={actionButtonsStart}
    >
      {isAccountant(user) && <>{children}</>}
      {!isAccountant(user) && <IonText color='danger'>Access Denied (Accountant role is required)</IonText>}
    </PageContainer>
  )
}

export default AccountantContainer
