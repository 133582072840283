export const findTypeFormEventsByClinicPublicId = /* GraphQL */ `
  query FindTypeFormEventsByClinicPublicId(
    $clinicID: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsByClinicPublicId(
      clinicPublicId: $clinicID
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctor {
          drId
          drName
        }
        definitionSubject
        startTime
      }
      nextToken
    }
  }
`
