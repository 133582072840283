import { FC, useEffect, useState } from 'react'
import { IonAvatar } from '@ionic/react'
import { Storage } from 'aws-amplify'

interface TbnAvatarProps {
  avatar?: string | null
  slot?: string
  width?: number | string | undefined
}
const TbnAvatar: FC<TbnAvatarProps> = ({ avatar, slot = 'start', width }) => {
  const [avatarUrl, setAvatarUrl] = useState<string>()

  const fetchAvatarUrl = async () => {
    if (!!avatar) {
      const url = await Storage.get(avatar, { level: 'public' })
      setAvatarUrl(url)
    }
  }

  useEffect(() => {
    fetchAvatarUrl()
  }, [avatar]) // eslint-disable-line

  return (
    <IonAvatar slot={slot} style={{ width, height: width }}>
      <img src={avatarUrl} alt='' />
    </IonAvatar>
  )
}

export default TbnAvatar
