import { FC, useEffect, useState } from 'react'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import { IonButton, IonSpinner, IonText, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Swiper as SwiperCore } from 'swiper'
import { AppointmentDefinition, TypeFormEvent, Who } from '../../API'
import { AppointmentDefinitionService } from '../../components/services/AppointmentDefinitionService'
import { BookingService } from '../../components/services/BookingService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { AuthStatus } from '../../components/util/AuthStatus'
import { error, log } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import { setBooking } from '../../data/user/user.actions'
import Booking from './Booking.model'

interface OwnProps {
  booking: Booking
  appointmentDefinitionID: string
  swiper?: SwiperCore
  paramElse?: string | null
  bookFast?: boolean
}
interface StateProps {}
interface DispatchProps {
  setBooking: typeof setBooking
}
interface BookingCalendlyProps extends OwnProps, StateProps, DispatchProps {}

const BookingCalendly: FC<BookingCalendlyProps> = ({
  booking,
  setBooking,
  appointmentDefinitionID,
  swiper,
  paramElse,
  bookFast,
}) => {
  const [present] = useIonToast()
  const [inprogress, setInprogress] = useState(false)
  const [success, setSuccess] = useState(false)
  const [schedulingUrl, setschedulingUrl] = useState<string | null>()
  const { user } = useAuthenticator((context) => [context.user])
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [entity, setEntity] = useState<TypeFormEvent>() // eslint-disable-line

  useCalendlyEventListener({
    onProfilePageViewed: () => log('onProfilePageViewed'),
    onDateAndTimeSelected: () => log('onDateAndTimeSelected'),
    onEventTypeViewed: () => log('onEventTypeViewed'),
    onEventScheduled: async (e) => {
      log('onEventScheduled', e.data)
      const updatedBooking = {
        ...booking,
        bookingUrl: !!e?.data?.payload?.invitee?.uri ? e?.data?.payload?.invitee?.uri : e?.data?.payload?.event?.uri,
        appointmentDefinitionID,
      }
      await setBooking(updatedBooking)
      await submit(updatedBooking)
    },
  })

  const submit = async (booking: Booking) => {
    try {
      setInprogress(true)
      const cmd = { ...booking, bookerID: user?.attributes?.sub, who: !!paramElse ? Who.SOELSE : Who.MYSELF } as Booking
      const res: TbnResponse = await BookingService.Instance.storeBooking(cmd, authStatus as AuthStatus)
      if (!!res?.data) {
        setSuccess(true)
        setEntity(res?.data)
        await setBooking({ ...booking, persisted: true })
      } else {
        setSuccess(false)
      }
      setInprogress(false)
    } catch (err) {
      error('Error on persist booking event', err)
      setSuccess(false)
      setInprogress(false)
      failure('Something went wrong, please submit again.', present)
    }
  }

  const fetchAppointmentDefinition = async () => {
    setInprogress(true)
    try {
      const res: TbnResponse = await AppointmentDefinitionService.Instance.publicFindById(
        appointmentDefinitionID,
        authStatus as AuthStatus,
      )
      const appointmentDefinition: AppointmentDefinition = res?.data
      if (!!appointmentDefinition) {
        setschedulingUrl(appointmentDefinition?.calendlyAppointment?.scheduling_url)
      } else if (res.errorMessage) {
        failure(res.errorMessage, present)
      }
    } catch (err) {
      error('Fetch appointment definition failure', err)
      failure(JSON.stringify(err), present)
    }
    setInprogress(false)
  }

  useEffect(() => {
    fetchAppointmentDefinition()
  }, [appointmentDefinitionID]) // eslint-disable-line

  return (
    <>
      {!!booking && !booking?.bookingUrl && !!schedulingUrl && booking?.phone?.length === 12 && (
        <div>
          <InlineWidget
            url={`${schedulingUrl}?hide_gdpr_banner=1&hide_event_type_details=1`}
            prefill={{
              name: booking.firstName,
              firstName: booking.firstName,
              email: user?.attributes?.email,
              customAnswers: { a1: booking.phone },
            }}
            pageSettings={{
              hideGdprBanner: true,
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: '1a7088',
              backgroundColor: 'transparent',
            }}
            styles={{
              height: '80vh',
              minWidth: '320px',
            }}
          />
        </div>
      )}
      {!!booking?.bookingUrl && success && (
        <IonText className='ion-margin ion-padding-left'>
          {!paramElse && (
            <span>Your appointment was succesfully made, please signin with {booking.phone} for more info.</span>
          )}
          {!!paramElse && <span>Appointment was succesfully made for {booking.firstName}.</span>}
        </IonText>
      )}
      {!!booking?.bookingUrl && success && !bookFast && (
        <>
          <IonButton
            disabled={inprogress}
            className='ion-margin-start'
            color='primary'
            routerLink={`/forms/consent?phone=${booking.phone}`}
          >
            {!paramElse && <span>Submit Consent Form</span>}
            {!!paramElse && <span>Submit Consent for {booking.firstName}</span>}
          </IonButton>
          <IonButton disabled={inprogress} className='ion-margin-start' color='primary' routerLink='/profile'>
            Back to your profile
          </IonButton>
        </>
      )}
      {!!booking?.bookingUrl && success && bookFast && (
        <>
          <IonButton
            disabled={inprogress}
            className='ion-margin-start'
            color='primary'
            routerLink={`/r/appointment/${entity?.id}`}
          >
            Appointment detail
          </IonButton>
        </>
      )}
      {!!booking?.bookingUrl && !success && (
        <IonButton disabled={inprogress} color='primary' onClick={() => submit(booking)} className='ion-margin-start'>
          Submit
        </IonButton>
      )}
      {inprogress && (
        <div className='ion-text-center'>
          <IonText>Please wait...</IonText>
          <IonSpinner color='primary' className='ion-margin-start' />
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
  },
  component: BookingCalendly,
})
