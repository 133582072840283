import { FC } from 'react'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { isClinicAdminOrAdmin } from '../../components/util/Auth'
import PageContainer from '../PageContainer'

interface OwnProps {
  id: string
  title?: string
  padding?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
}
interface AdminContainerProps extends OwnProps {}

const ClinicAdminContainer: FC<AdminContainerProps> = ({
  id,
  title,
  children,
  padding = true,
  actionButtonsEnd,
  actionButtonsStart,
}) => {
  const { user } = useAuthenticator((context) => [context.user])

  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      padding={padding}
      actionButtonsEnd={actionButtonsEnd}
      actionButtonsStart={actionButtonsStart}
    >
      {isClinicAdminOrAdmin(user) && <>{children}</>}
      {!isClinicAdminOrAdmin(user) && <IonText color='danger'>Access Denied (ClinicAdmin role is required)</IonText>}
    </PageContainer>
  )
}

export default ClinicAdminContainer
