/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTypeFormEvent = /* GraphQL */ `
  query GetTypeFormEvent($id: ID!) {
    getTypeFormEvent(id: $id) {
      id
      doctorPublicId
      clinicPublicId
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      bookerID
      definitionID
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      definitionDoctorName
      definitionClinicName
      definitionClinicLogo
      who
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      paymentSuccess
      paymentAmount
      questions
      answers
      hidden
      calendlyStatus
      rescheduled
      canceled
      cancelReason
      landedAt
      submittedAt
      startTime
      endTime
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const listTypeFormEvents = /* GraphQL */ `
  query ListTypeFormEvents(
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTypeFormEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findTypeFormEventsByDoctorPublicId = /* GraphQL */ `
  query FindTypeFormEventsByDoctorPublicId(
    $doctorPublicId: String!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsByDoctorPublicId(
      doctorPublicId: $doctorPublicId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findTypeFormEventsByClinicPublicId = /* GraphQL */ `
  query FindTypeFormEventsByClinicPublicId(
    $clinicPublicId: String!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsByClinicPublicId(
      clinicPublicId: $clinicPublicId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findTypeFormEventsBySorter = /* GraphQL */ `
  query FindTypeFormEventsBySorter(
    $sorter: String!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsBySorter(
      sorter: $sorter
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sub
      username
      email
      phoneNumber
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findUserBySub = /* GraphQL */ `
  query FindUserBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findUserBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findUserByUsername = /* GraphQL */ `
  query FindUserByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findUserByEmail = /* GraphQL */ `
  query FindUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findUserByPhoneNumber = /* GraphQL */ `
  query FindUserByPhoneNumber(
    $phoneNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findUserByPhoneNumber(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findUserByGivenName = /* GraphQL */ `
  query FindUserByGivenName(
    $givenName: String!
    $phoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findUserByGivenName(
      givenName: $givenName
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      givenName
      surName
      medicareNumber
      addressOneLine
      dateOfBirth
      contactNumber
      nextOfKin
      nextOfKinRelation
      nextOfKinContact
      powerOfAttorney
      powerOfAttorneyRelation
      powerOfAttorneyContact
      carer
      carerRelation
      carerContact
      emergency
      emergencyRelation
      emergencyContact
      primaryDiagnosis
      otherTreatments
      otherMedications
      selfMedicated
      kidneyLiver
      cardiovascular
      substanceAbuseDisorder
      allergies
      smoke
      drinkAlcohol
      otherConditions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findPatientByEmail = /* GraphQL */ `
  query FindPatientByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findPatientByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findPatientByPhone = /* GraphQL */ `
  query FindPatientByPhone(
    $phone: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findPatientByPhone(
      phone: $phone
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findPatientByUserId = /* GraphQL */ `
  query FindPatientByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findPatientByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReferred = /* GraphQL */ `
  query GetReferred($id: ID!) {
    getReferred(id: $id) {
      id
      givenName
      surName
      addressOneLine
      medicareNumber
      dateOfBirth
      phone
      ihiNo
      nextOfKin
      nextOfKinRelation
      nextOfKinContact
      powerOfAttorney
      powerOfAttorneyRelation
      powerOfAttorneyContact
      carer
      carerRelation
      carerContact
      emergency
      emergencyRelation
      emergencyContact
      symptoms
      otherTreatments
      primaryDiagnosis
      otherMedications
      selfMedicated
      kidneyLiver
      cardiovascular
      substanceAbuseDisorder
      allergies
      smoke
      drinkAlcohol
      otherConditions
      cvConditions
      practitionerConcerns
      recentReports
      clinicPublicId
      doctorPublicId
      readByClinic
      readByDoctor
      practitionerFirstName
      practitionerLastName
      practitionerAddress
      practitionerProviderNumber
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReferreds = /* GraphQL */ `
  query ListReferreds(
    $filter: ModelReferredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferreds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        givenName
        surName
        addressOneLine
        medicareNumber
        dateOfBirth
        phone
        ihiNo
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        symptoms
        otherTreatments
        primaryDiagnosis
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        cvConditions
        practitionerConcerns
        recentReports
        clinicPublicId
        doctorPublicId
        readByClinic
        readByDoctor
        practitionerFirstName
        practitionerLastName
        practitionerAddress
        practitionerProviderNumber
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findReferredsByClinicPublicId = /* GraphQL */ `
  query FindReferredsByClinicPublicId(
    $clinicPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findReferredsByClinicPublicId(
      clinicPublicId: $clinicPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        givenName
        surName
        addressOneLine
        medicareNumber
        dateOfBirth
        phone
        ihiNo
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        symptoms
        otherTreatments
        primaryDiagnosis
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        cvConditions
        practitionerConcerns
        recentReports
        clinicPublicId
        doctorPublicId
        readByClinic
        readByDoctor
        practitionerFirstName
        practitionerLastName
        practitionerAddress
        practitionerProviderNumber
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findReferredsByDoctorPublicId = /* GraphQL */ `
  query FindReferredsByDoctorPublicId(
    $doctorPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findReferredsByDoctorPublicId(
      doctorPublicId: $doctorPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        givenName
        surName
        addressOneLine
        medicareNumber
        dateOfBirth
        phone
        ihiNo
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        symptoms
        otherTreatments
        primaryDiagnosis
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        cvConditions
        practitionerConcerns
        recentReports
        clinicPublicId
        doctorPublicId
        readByClinic
        readByDoctor
        practitionerFirstName
        practitionerLastName
        practitionerAddress
        practitionerProviderNumber
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getConsentEvent = /* GraphQL */ `
  query GetConsentEvent($id: ID!) {
    getConsentEvent(id: $id) {
      id
      email
      phone
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      givenName
      familyName
      consenter
      consentContent
      researchAllowed
      manual
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listConsentEvents = /* GraphQL */ `
  query ListConsentEvents(
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsentEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        givenName
        familyName
        consenter
        consentContent
        researchAllowed
        manual
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findConsentEventByEmail = /* GraphQL */ `
  query FindConsentEventByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findConsentEventByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        givenName
        familyName
        consenter
        consentContent
        researchAllowed
        manual
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findConsentEventByPhone = /* GraphQL */ `
  query FindConsentEventByPhone(
    $phone: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findConsentEventByPhone(
      phone: $phone
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        givenName
        familyName
        consenter
        consentContent
        researchAllowed
        manual
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findConsentEventByUserId = /* GraphQL */ `
  query FindConsentEventByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findConsentEventByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        givenName
        familyName
        consenter
        consentContent
        researchAllowed
        manual
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findConsentEventByPatientId = /* GraphQL */ `
  query FindConsentEventByPatientId(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findConsentEventByPatientId(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        givenName
        familyName
        consenter
        consentContent
        researchAllowed
        manual
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      userID
      quizType
      questions
      givenName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        quizType
        questions
        givenName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findQuizByUserId = /* GraphQL */ `
  query FindQuizByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findQuizByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        quizType
        questions
        givenName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findQuizByOwner = /* GraphQL */ `
  query FindQuizByOwner(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findQuizByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        quizType
        questions
        givenName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCalendlyAppointment = /* GraphQL */ `
  query GetCalendlyAppointment($id: ID!) {
    getCalendlyAppointment(id: $id) {
      id
      slug
      name
      uri
      scheduling_url
      duration
      kind
      type
      color
      active
      booking_method
      description_plain
      description_html
      internal_note
      pooling_type
      secret
      profile
      custom_questions
      created_at
      updated_at
      deleted_at
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listCalendlyAppointments = /* GraphQL */ `
  query ListCalendlyAppointments(
    $filter: ModelCalendlyAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendlyAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCalendlyAppointmentBySlug = /* GraphQL */ `
  query FindCalendlyAppointmentBySlug(
    $slug: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendlyAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCalendlyAppointmentBySlug(
      slug: $slug
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCalendlyAppointmentByName = /* GraphQL */ `
  query FindCalendlyAppointmentByName(
    $name: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendlyAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCalendlyAppointmentByName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCalendlyAppointmentByOwner = /* GraphQL */ `
  query FindCalendlyAppointmentByOwner(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendlyAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCalendlyAppointmentByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      drId
      drName
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      avatar
      qualifications
      prescriberNumber
      clinics {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      appointmentDefinitions {
        items {
          id
          doctorID
          calendlyAppointmentID
          clinicID
          appointmentType
          appointmentSubject
          drPublicId
          drName
          drAvatar
          clinicPublicId
          clinicName
          clinicFormerlyKnownAs
          clinicStatus
          clinicLogoImage
          active
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findDoctorByDrId = /* GraphQL */ `
  query FindDoctorByDrId(
    $drId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findDoctorByDrId(
      drId: $drId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findDoctorByUserId = /* GraphQL */ `
  query FindDoctorByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findDoctorByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const findDoctorByStatus = /* GraphQL */ `
  query FindDoctorByStatus(
    $status: DoctorStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findDoctorByStatus(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAppointmentDefinition = /* GraphQL */ `
  query GetAppointmentDefinition($id: ID!) {
    getAppointmentDefinition(id: $id) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      calendlyAppointmentID
      calendlyAppointment {
        id
        slug
        name
        uri
        scheduling_url
        duration
        kind
        type
        color
        active
        booking_method
        description_plain
        description_html
        internal_note
        pooling_type
        secret
        profile
        custom_questions
        created_at
        updated_at
        deleted_at
        createdAt
        owner
        updatedAt
      }
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      appointmentType
      appointmentSubject
      drPublicId
      drName
      drAvatar
      clinicPublicId
      clinicName
      clinicFormerlyKnownAs
      clinicStatus
      clinicLogoImage
      active
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listAppointmentDefinitions = /* GraphQL */ `
  query ListAppointmentDefinitions(
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentDefinitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentDefinitionByDoctorId = /* GraphQL */ `
  query FindAppointmentDefinitionByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentDefinitionByClinicId = /* GraphQL */ `
  query FindAppointmentDefinitionByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentDefinitionByDrPublicId = /* GraphQL */ `
  query FindAppointmentDefinitionByDrPublicId(
    $drPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByDrPublicId(
      drPublicId: $drPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentDefinitionByClinicPublicId = /* GraphQL */ `
  query FindAppointmentDefinitionByClinicPublicId(
    $clinicPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByClinicPublicId(
      clinicPublicId: $clinicPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          secret
          profile
          custom_questions
          created_at
          updated_at
          deleted_at
          createdAt
          owner
          updatedAt
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        appointmentType
        appointmentSubject
        drPublicId
        drName
        drAvatar
        clinicPublicId
        clinicName
        clinicFormerlyKnownAs
        clinicStatus
        clinicLogoImage
        active
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointmentNote = /* GraphQL */ `
  query GetAppointmentNote($id: ID!) {
    getAppointmentNote(id: $id) {
      id
      note
      appointmentID
      appointment {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listAppointmentNotes = /* GraphQL */ `
  query ListAppointmentNotes(
    $filter: ModelAppointmentNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        note
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentNoteByAppointmentId = /* GraphQL */ `
  query FindAppointmentNoteByAppointmentId(
    $appointmentID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentNoteByAppointmentId(
      appointmentID: $appointmentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        note
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentNoteByDoctorId = /* GraphQL */ `
  query FindAppointmentNoteByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentNoteByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        note
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentNoteByPatientId = /* GraphQL */ `
  query FindAppointmentNoteByPatientId(
    $patientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentNoteByPatientId(
      patientID: $patientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        note
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointmentMedication = /* GraphQL */ `
  query GetAppointmentMedication($id: ID!) {
    getAppointmentMedication(id: $id) {
      id
      mapID
      map {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      appointmentID
      appointment {
        id
        doctorPublicId
        clinicPublicId
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        bookerID
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        paymentSuccess
        paymentAmount
        questions
        answers
        hidden
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        landedAt
        submittedAt
        startTime
        endTime
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      phone
      givenName
      familyName
      dailyDose
      measurement
      quantity
      repeats
      repeatInterval
      frequency
      prn
      instruction
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listAppointmentMedications = /* GraphQL */ `
  query ListAppointmentMedications(
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentMedications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentMedicationByMapId = /* GraphQL */ `
  query FindAppointmentMedicationByMapId(
    $mapID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByMapId(
      mapID: $mapID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentMedicationByProductId = /* GraphQL */ `
  query FindAppointmentMedicationByProductId(
    $productID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByProductId(
      productID: $productID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentMedicationByAppointmentId = /* GraphQL */ `
  query FindAppointmentMedicationByAppointmentId(
    $appointmentID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByAppointmentId(
      appointmentID: $appointmentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentMedicationByDoctorId = /* GraphQL */ `
  query FindAppointmentMedicationByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAppointmentMedicationByPatientId = /* GraphQL */ `
  query FindAppointmentMedicationByPatientId(
    $patientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByPatientId(
      patientID: $patientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        appointmentID
        appointment {
          id
          doctorPublicId
          clinicPublicId
          patientID
          bookerID
          definitionID
          definitionTitle
          definitionSlug
          definitionType
          definitionSubject
          definitionDoctorName
          definitionClinicName
          definitionClinicLogo
          who
          email
          phone
          firstName
          bookingUrl
          oldBookingUrl
          paymentSuccess
          paymentAmount
          questions
          answers
          hidden
          calendlyStatus
          rescheduled
          canceled
          cancelReason
          landedAt
          submittedAt
          startTime
          endTime
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        phone
        givenName
        familyName
        dailyDose
        measurement
        quantity
        repeats
        repeatInterval
        frequency
        prn
        instruction
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAdminByUserId = /* GraphQL */ `
  query FindAdminByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAdminByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReception = /* GraphQL */ `
  query GetReception($id: ID!) {
    getReception(id: $id) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listReceptions = /* GraphQL */ `
  query ListReceptions(
    $filter: ModelReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findReceptionByUserId = /* GraphQL */ `
  query FindReceptionByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findReceptionByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountant = /* GraphQL */ `
  query GetAccountant($id: ID!) {
    getAccountant(id: $id) {
      id
      userID
      user {
        id
        sub
        username
        email
        phoneNumber
        givenName
        familyName
        createdAt
        owner
        updatedAt
      }
      clinics {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      name
      avatar
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listAccountants = /* GraphQL */ `
  query ListAccountants(
    $filter: ModelAccountantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findAccountantByUserId = /* GraphQL */ `
  query FindAccountantByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAccountantByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      publicId
      name
      formerlyKnownAs
      status
      receptions {
        items {
          id
          clinicID
          receptionID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      accountants {
        items {
          id
          clinicID
          accountantID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      doctors {
        items {
          id
          clinicID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      admins {
        items {
          id
          clinicID
          adminID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      address
      phone
      logoImage
      location {
        lat
        lon
      }
      openingHours
      bankName
      bankStateBranch
      bankAccountName
      bankAccountNumber
      createdAt
      updatedAt
      sorter
      owner
    }
  }
`;
export const listClinics = /* GraphQL */ `
  query ListClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      nextToken
    }
  }
`;
export const findClinicByPublicId = /* GraphQL */ `
  query FindClinicByPublicId(
    $publicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicByPublicId(
      publicId: $publicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      nextToken
    }
  }
`;
export const findClinicByName = /* GraphQL */ `
  query FindClinicByName(
    $name: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicByName(
      name: $name
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      nextToken
    }
  }
`;
export const findClinicByStatus = /* GraphQL */ `
  query FindClinicByStatus(
    $status: ClinicStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicByStatus(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      nextToken
    }
  }
`;
export const findClinicBySorter = /* GraphQL */ `
  query FindClinicBySorter(
    $sorter: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicBySorter(
      sorter: $sorter
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      nextToken
    }
  }
`;
export const getClinicAdmin = /* GraphQL */ `
  query GetClinicAdmin($id: ID!) {
    getClinicAdmin(id: $id) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      adminID
      admin {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listClinicAdmins = /* GraphQL */ `
  query ListClinicAdmins(
    $filter: ModelClinicAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        adminID
        admin {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicAdminByClinicId = /* GraphQL */ `
  query FindClinicAdminByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicAdminByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        adminID
        admin {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicAdminByAdminId = /* GraphQL */ `
  query FindClinicAdminByAdminId(
    $adminID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicAdminByAdminId(
      adminID: $adminID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        adminID
        admin {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClinicReception = /* GraphQL */ `
  query GetClinicReception($id: ID!) {
    getClinicReception(id: $id) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      receptionID
      reception {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listClinicReceptions = /* GraphQL */ `
  query ListClinicReceptions(
    $filter: ModelClinicReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicReceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        receptionID
        reception {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicReceptionByClinicId = /* GraphQL */ `
  query FindClinicReceptionByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicReceptionByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        receptionID
        reception {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicReceptionByReceptionId = /* GraphQL */ `
  query FindClinicReceptionByReceptionId(
    $receptionID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicReceptionByReceptionId(
      receptionID: $receptionID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        receptionID
        reception {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClinicAccountant = /* GraphQL */ `
  query GetClinicAccountant($id: ID!) {
    getClinicAccountant(id: $id) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      accountantID
      accountant {
        id
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        clinics {
          nextToken
        }
        name
        avatar
        createdAt
        owner
        updatedAt
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listClinicAccountants = /* GraphQL */ `
  query ListClinicAccountants(
    $filter: ModelClinicAccountantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicAccountants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        accountantID
        accountant {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicAccountantByClinicId = /* GraphQL */ `
  query FindClinicAccountantByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicAccountantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicAccountantByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        accountantID
        accountant {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicAccountantByAccountantId = /* GraphQL */ `
  query FindClinicAccountantByAccountantId(
    $accountantID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicAccountantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicAccountantByAccountantId(
      accountantID: $accountantID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        accountantID
        accountant {
          id
          userID
          name
          avatar
          createdAt
          owner
          updatedAt
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClinicDoctor = /* GraphQL */ `
  query GetClinicDoctor($id: ID!) {
    getClinicDoctor(id: $id) {
      id
      clinicID
      clinic {
        id
        publicId
        name
        formerlyKnownAs
        status
        receptions {
          nextToken
        }
        accountants {
          nextToken
        }
        doctors {
          nextToken
        }
        admins {
          nextToken
        }
        address
        phone
        logoImage
        location {
          lat
          lon
        }
        openingHours
        bankName
        bankStateBranch
        bankAccountName
        bankAccountNumber
        createdAt
        updatedAt
        sorter
        owner
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listClinicDoctors = /* GraphQL */ `
  query ListClinicDoctors(
    $filter: ModelClinicDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicDoctorByClinicId = /* GraphQL */ `
  query FindClinicDoctorByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicDoctorByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findClinicDoctorByDoctorId = /* GraphQL */ `
  query FindClinicDoctorByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findClinicDoctorByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          bankName
          bankStateBranch
          bankAccountName
          bankAccountNumber
          createdAt
          updatedAt
          sorter
          owner
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCannabisProduct = /* GraphQL */ `
  query GetCannabisProduct($id: ID!) {
    getCannabisProduct(id: $id) {
      id
      idxOrder
      name
      ingredients
      qtyPerDosageUnit
      category
      presentation
      presentationText
      strength
      volume
      sponsor
      archived
      maps {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const listCannabisProducts = /* GraphQL */ `
  query ListCannabisProducts(
    $filter: ModelCannabisProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCannabisProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCannabisProductByName = /* GraphQL */ `
  query FindCannabisProductByName(
    $name: String!
    $idxOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCannabisProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCannabisProductByName(
      name: $name
      idxOrder: $idxOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCannabisProductBySorter = /* GraphQL */ `
  query FindCannabisProductBySorter(
    $sorter: String!
    $idxOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCannabisProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCannabisProductBySorter(
      sorter: $sorter
      idxOrder: $idxOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicalAuthorisedPrescriber = /* GraphQL */ `
  query GetMedicalAuthorisedPrescriber($id: ID!) {
    getMedicalAuthorisedPrescriber(id: $id) {
      id
      mapNumber
      category
      idxOrder
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      status
      presentation
      presentationText
      createdDate
      decisionDate
      expiryDate
      approvalDate
      isSAS
      archived
      products {
        items {
          id
          productID
          mapID
          doctorID
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      createdAt
      sorter
      owner
      updatedAt
    }
  }
`;
export const listMedicalAuthorisedPrescribers = /* GraphQL */ `
  query ListMedicalAuthorisedPrescribers(
    $filter: ModelMedicalAuthorisedPrescriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalAuthorisedPrescribers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findMedicalAuthorisedPrescriberByNumber = /* GraphQL */ `
  query FindMedicalAuthorisedPrescriberByNumber(
    $mapNumber: String!
    $idxOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalAuthorisedPrescriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findMedicalAuthorisedPrescriberByNumber(
      mapNumber: $mapNumber
      idxOrder: $idxOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findMedicalAuthorisedPrescriberByDoctorId = /* GraphQL */ `
  query FindMedicalAuthorisedPrescriberByDoctorId(
    $doctorID: ID!
    $idxOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalAuthorisedPrescriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findMedicalAuthorisedPrescriberByDoctorId(
      doctorID: $doctorID
      idxOrder: $idxOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findMedicalAuthorisedPrescriberBySorter = /* GraphQL */ `
  query FindMedicalAuthorisedPrescriberBySorter(
    $sorter: String!
    $idxOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalAuthorisedPrescriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findMedicalAuthorisedPrescriberBySorter(
      sorter: $sorter
      idxOrder: $idxOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScriptReceiver = /* GraphQL */ `
  query GetScriptReceiver($id: ID!) {
    getScriptReceiver(id: $id) {
      id
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      name
      email
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listScriptReceivers = /* GraphQL */ `
  query ListScriptReceivers(
    $filter: ModelScriptReceiverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScriptReceivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        name
        email
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findScriptReceiverByDoctorId = /* GraphQL */ `
  query FindScriptReceiverByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScriptReceiverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findScriptReceiverByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        name
        email
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductMap = /* GraphQL */ `
  query GetProductMap($id: ID!) {
    getProductMap(id: $id) {
      id
      productID
      product {
        id
        idxOrder
        name
        ingredients
        qtyPerDosageUnit
        category
        presentation
        presentationText
        strength
        volume
        sponsor
        archived
        maps {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      mapID
      map {
        id
        mapNumber
        category
        idxOrder
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        status
        presentation
        presentationText
        createdDate
        decisionDate
        expiryDate
        approvalDate
        isSAS
        archived
        products {
          nextToken
        }
        createdAt
        sorter
        owner
        updatedAt
      }
      doctorID
      doctor {
        id
        drId
        drName
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        avatar
        qualifications
        prescriberNumber
        clinics {
          nextToken
        }
        appointmentDefinitions {
          nextToken
        }
        status
        createdAt
        updatedAt
        owner
      }
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listProductMaps = /* GraphQL */ `
  query ListProductMaps(
    $filter: ModelProductMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findProductMapByProductId = /* GraphQL */ `
  query FindProductMapByProductId(
    $productID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findProductMapByProductId(
      productID: $productID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findProductMapByMapId = /* GraphQL */ `
  query FindProductMapByMapId(
    $mapID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findProductMapByMapId(
      mapID: $mapID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const findProductMapByDoctorId = /* GraphQL */ `
  query FindProductMapByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findProductMapByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        product {
          id
          idxOrder
          name
          ingredients
          qtyPerDosageUnit
          category
          presentation
          presentationText
          strength
          volume
          sponsor
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        mapID
        map {
          id
          mapNumber
          category
          idxOrder
          doctorID
          patientID
          status
          presentation
          presentationText
          createdDate
          decisionDate
          expiryDate
          approvalDate
          isSAS
          archived
          createdAt
          sorter
          owner
          updatedAt
        }
        doctorID
        doctor {
          id
          drId
          drName
          userID
          avatar
          qualifications
          prescriberNumber
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatientLink = /* GraphQL */ `
  query GetPatientLink($doctorID: ID!, $patientID: ID!) {
    getPatientLink(doctorID: $doctorID, patientID: $patientID) {
      doctorID
      patientID
      patient {
        id
        email
        phone
        userID
        user {
          id
          sub
          username
          email
          phoneNumber
          givenName
          familyName
          createdAt
          owner
          updatedAt
        }
        givenName
        surName
        medicareNumber
        addressOneLine
        dateOfBirth
        contactNumber
        nextOfKin
        nextOfKinRelation
        nextOfKinContact
        powerOfAttorney
        powerOfAttorneyRelation
        powerOfAttorneyContact
        carer
        carerRelation
        carerContact
        emergency
        emergencyRelation
        emergencyContact
        primaryDiagnosis
        otherTreatments
        otherMedications
        selfMedicated
        kidneyLiver
        cardiovascular
        substanceAbuseDisorder
        allergies
        smoke
        drinkAlcohol
        otherConditions
        createdAt
        updatedAt
        owner
      }
      clinicID
      createdAt
      updatedAt
    }
  }
`;
export const listPatientLinks = /* GraphQL */ `
  query ListPatientLinks(
    $doctorID: ID
    $patientID: ModelIDKeyConditionInput
    $filter: ModelPatientLinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPatientLinks(
      doctorID: $doctorID
      patientID: $patientID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        doctorID
        patientID
        patient {
          id
          email
          phone
          userID
          givenName
          surName
          medicareNumber
          addressOneLine
          dateOfBirth
          contactNumber
          nextOfKin
          nextOfKinRelation
          nextOfKinContact
          powerOfAttorney
          powerOfAttorneyRelation
          powerOfAttorneyContact
          carer
          carerRelation
          carerContact
          emergency
          emergencyRelation
          emergencyContact
          primaryDiagnosis
          otherTreatments
          otherMedications
          selfMedicated
          kidneyLiver
          cardiovascular
          substanceAbuseDisorder
          allergies
          smoke
          drinkAlcohol
          otherConditions
          createdAt
          updatedAt
          owner
        }
        clinicID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
