import { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonRow } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import CheckboxWithOtherSupport from '../../../components/form/CheckboxWithOtherSupport'
import { lookupOtherText } from '../../../components/util/LookupOtherText'
import { isDesktop } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import { Quiz } from './Quiz.model'
import { QuizQuestion } from './QuizQuestion.model'

interface OwnProps {
  swiper?: SwiperCore
  quizQuestion: QuizQuestion
  disabled: boolean
}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface QuizQuestionMultiCheckTwoColumnsProps extends OwnProps, StateProps, DispatchProps {}
const QuizQuestionMultiCheckTwoColumns: FC<QuizQuestionMultiCheckTwoColumnsProps> = ({
  swiper,
  quizQuestion,
  quiz,
  setQuiz,
  disabled,
}) => {
  const [chks, setChks] = useState<string>(quizQuestion.answer || '')

  const isChecked = (chk: string) => {
    return !!chks && chks.indexOf(chk) !== -1
  }

  const setChecked = (chk: string, checked: boolean) => {
    if (checked) {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) === -1) {
          setChks(chks + `${chk},`)
        }
      } else {
        setChks(chks + `${chk}<>,`)
      }
    } else {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) !== -1) {
          setChks(chks?.replace(`${chk},`, ''))
        }
      } else {
        setChks(chks.replace(/Other<.*>,/, ''))
      }
    }
  }

  const lookupOther = () => {
    return lookupOtherText(chks)
  }

  const setOther = (othr?: string) => {
    setChks(chks.replace(/Other<.*>,/, `Other<${othr}>,`))
  }

  const setModel = (answer: any) => {
    const q = quiz?.questions?.find((q: QuizQuestion) => q.id === quizQuestion.id)
    if (!!q) {
      q.answer = answer
      const idx = quiz?.questions?.findIndex((x) => x.id === quizQuestion.id)
      quiz.questions[idx] = q
      setQuiz(quiz)
    }
  }

  const options =
    quizQuestion?.options?.reduce((acc: any[], el) => {
      if (!acc.length || acc[acc.length - 1].length === 2) {
        acc.push([])
      }
      acc[acc.length - 1].push(el)
      return acc
    }, []) || []

  useEffect(() => {
    setModel(chks)
  }, [chks]) // eslint-disable-line

  return (
    <>
      <h1 className='ion-text-center'>{quizQuestion.label}</h1>
      {options.map((col: any[], idx: number) => (
        <IonRow key={col[0].id + col[1].id}>
          {!!col[0] && (
            <IonCol className='ion-align-self-end'>
              <CheckboxWithOtherSupport
                id={col[0].id}
                label={col[0].label}
                isChecked={isChecked}
                setChecked={setChecked}
                setOther={setOther}
                lookupOther={lookupOther}
                disabled={disabled}
              />
            </IonCol>
          )}
          {!!col[1] && (
            <IonCol className='ion-align-self-end'>
              <CheckboxWithOtherSupport
                id={col[1].id}
                label={col[1].label}
                isChecked={isChecked}
                setChecked={setChecked}
                setOther={setOther}
                lookupOther={lookupOther}
                disabled={disabled}
              />
            </IonCol>
          )}
        </IonRow>
      ))}

      {!!swiper && (
        <div className={`ion-text-center ${isDesktop() ? 'ion-margin-vertical' : ''}`}>
          {!swiper?.isBeginning && (
            <IonButton
              fill='outline'
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              className='ion-padding-end'
              onClick={() => swiper?.slidePrev()}
            >
              Previous
            </IonButton>
          )}
          {!swiper?.isEnd && (
            <IonButton
              disabled={quizQuestion.required && !chks}
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              onClick={() => swiper?.slideNext()}
            >
              Next
            </IonButton>
          )}
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: QuizQuestionMultiCheckTwoColumns,
})
