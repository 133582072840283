import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { API } from 'aws-amplify'

export class RestApiService {
  private static restApiService: RestApiService | undefined
  private apiInstance: AxiosInstance
  constructor(apiInstance: AxiosInstance) {
    this.apiInstance = apiInstance
  }

  public syncWithCalendlyAppointmentDefinitions = async (config?: AxiosRequestConfig) => {
    return await API.get('CalendlyApiV1', '/calendly/sync', {})
  }

  public findCalendlyBooking = async (bookingUuid?: string) => {
    return await API.get('CalendlyApiV1', '/calendly/find-booking', {
      queryStringParameters: {
        bookingUuid,
      },
    })
  }

  public static get Instance(): RestApiService {
    if (!this.restApiService) {
      const api = axios.create({
        baseURL: process?.env?.REACT_APP_REST_API_URL,
        headers: {
          Accept: 'application/json',
        },
      })
      this.restApiService = new RestApiService(api)
    }
    return this.restApiService
  }
}
