import { FC, useEffect } from 'react'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Doctor } from '../../API'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { isDoctor } from '../../components/util/Auth'
import PageContainer from '../PageContainer'

interface OwnProps {
  id: string
  title?: string
  padding?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
  doctorLoaded?: (doctor: Doctor) => void
}
interface DoctorContainerProps extends OwnProps {}

const DoctorContainer: FC<DoctorContainerProps> = ({
  id,
  title,
  children,
  padding = true,
  actionButtonsEnd,
  actionButtonsStart,
  doctorLoaded,
}) => {
  const { user } = useAuthenticator((context) => [context.user])

  const loadDoctorLoop = async (): Promise<Doctor> => {
    try {
      return await CurrentDoctorService.Instance?.currentDoctor()
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return loadDoctorLoop()
    }
  }

  const loadDoctor = async () => {
    const doctor: Doctor = await loadDoctorLoop()
    if (!!doctorLoaded) doctorLoaded(doctor)
  }

  useEffect(() => {
    loadDoctor()
  }, []) // eslint-disable-line

  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      padding={padding}
      actionButtonsEnd={actionButtonsEnd}
      actionButtonsStart={actionButtonsStart}
    >
      {isDoctor(user) && <>{children}</>}
      {!isDoctor(user) && <IonText color='danger'>Access Denied (Doctor role is required)</IonText>}
    </PageContainer>
  )
}

export default DoctorContainer
