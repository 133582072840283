import { ClinicStatus } from '../../API'

const statusColor = (status?: ClinicStatus | null) => {
  switch (status) {
    case ClinicStatus.ACTIVE:
      return 'success'
    case ClinicStatus.INACTIVE:
      return 'warning'
    case ClinicStatus.ARCHIVED:
      return 'medium'
    case ClinicStatus.DELETED:
      return 'danger'
    default:
      return 'dark'
  }
}

export default statusColor
