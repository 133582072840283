import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Patient } from '../../API'
import { PatientService } from '../services/PatientService'
import './select.scss'

interface PatientSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const PatientSelect: FC<PatientSelectProps> = ({ defaultValue, onChange }) => {
  const [options, setOptions] = useState([])
  const [items, setItems] = useState<Patient[]>([])
  const [selectedOption, setSelectedOption] = useState<any>()

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(items.find((i: Patient) => i.id === itm.value))
  }

  const fetchOptions = async () => {
    const res: any = await PatientService.Instance.list()
    if (res.data) {
      setItems(res.data || [])
      setOptions(
        res.data?.flatMap((itm: Patient) => {
          return {
            value: itm.id,
            label: `${itm.givenName} ${itm.surName} ${!!itm.dateOfBirth ? `(${itm.dateOfBirth})` : ''}`,
          }
        }),
      )
    }
  }

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!!options && !!defaultValue)
      setSelectedOption({
        value: defaultValue,
        label: options?.find((itm: any) => itm.value === defaultValue)?.['label'],
      })
  }, [options, defaultValue]) // eslint-disable-line

  return (
    <Select
      defaultValue={selectedOption}
      value={selectedOption}
      options={options}
      onChange={handleChange}
      className='tbn-select'
      placeholder='Patient'
    />
  )
}

export default PatientSelect
