import { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Clinic } from '../../../API'
import { connect } from '../../../data'
import ReceptionContainer from '../ReceptionContainer'
import PatientSearch from './PatientSearch'

interface OwnProps extends RouteComponentProps {}
interface PatientSearchPageProps extends OwnProps {}
const PatientSearchPage: FC<PatientSearchPageProps> = ({ history }) => {
  const [clinic, setClinic] = useState<Clinic>()

  return (
    <ReceptionContainer
      title={`Patients - ${clinic?.name ? clinic?.name : '...'}`}
      id='search-patient-page'
      onClinicLoaded={setClinic}
    >
      {!!clinic?.id && <PatientSearch clinicID={clinic?.id} />}
    </ReceptionContainer>
  )
}

export default connect<OwnProps, {}, {}>({
  component: PatientSearchPage,
})
