import { FC, useEffect, useState } from 'react'
import { IonButton, IonText } from '@ionic/react'
import { Auth } from 'aws-amplify'
import { Alert, useAuthenticator } from '@aws-amplify/ui-react'
import { ConsentEvent } from '../../../API'
import { CurrentConsentService } from '../../../components/services/CurrentConsentService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { utcToLocaleDate } from '../../../components/util/Date'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { connect } from '../../../data'
import PageContainer from '../../PageContainer'
import PatientDeclaration from '../PatientDeclaration'
import PatientDetails from '../PatientDetails'
import PatientHealth from '../PatientHealth'

interface StateProps {}
interface DispatchProps {}
interface ConsentFormProps extends StateProps, DispatchProps {}

const ConsentForm: FC<ConsentFormProps> = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const search = window.location.search
  const params = new URLSearchParams(search)
  const paramPhone = params.get('phone')
  const [checkInprogress, setCheckInprogress] = useState(false)
  const [current, setCurrent] = useState<ConsentEvent>()

  const isNotSamePhone = () => {
    if (!!paramPhone) {
      const paramPhoneSpaceLess = paramPhone.split(' ').join('')?.trim()
      return +paramPhoneSpaceLess !== +(user?.attributes?.phone_number || 0)
    } else {
      return false
    }
  }

  const checkIfConsentSubmittedAlread = async () => {
    setCheckInprogress(true)
    try {
      const exist: ConsentEvent = await CurrentConsentService.Instance.currentConsent()
      setCurrent(exist)
    } catch (ignore) {
      const user = await Auth.currentUserInfo()
      const exist: TbnResponse = await CurrentConsentService.Instance.listConsentPublicDTO(
        user?.attributes?.sub,
        user?.attributes?.given_name,
        user?.attributes?.family_name,
      )
      setCurrent(exist?.data?.[0])
    }
    setCheckInprogress(false)
  }

  useEffect(() => {
    checkIfConsentSubmittedAlread()
  }, []) // eslint-disable-line

  return (
    <PageContainer id='consent-form-page' isPrivate={true} padding={false} hideFooter={true} title='Consent Form'>
      {isNotSamePhone() && (
        <Alert variation={'warning'}>
          You are supposed to submit this form with{' '}
          <IonText color='dark' className='text-bold'>
            {paramPhone}
          </IonText>
          , but you are currently logged in with{' '}
          <IonText color='danger' className='text-bold'>
            {user?.attributes?.phone_number || user?.attributes?.email}
          </IonText>
          . <IonButton onClick={signOut}>Sign out</IonButton>
        </Alert>
      )}
      {!checkInprogress && !current && (
        <>
          <PatientDetails />
          <PatientHealth />
          <PatientDeclaration reCheck={checkIfConsentSubmittedAlread} />
        </>
      )}
      {checkInprogress && <TbnSpinner />}
      {!checkInprogress && !!current && (
        <div className='ion-padding'>
          Acknowledged at <b>{utcToLocaleDate(current?.createdAt)}</b>
        </div>
      )}
    </PageContainer>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  component: ConsentForm,
})
