import { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonContent, IonGrid } from '@ionic/react'
import { IonItem, IonLabel, IonPopover } from '@ionic/react'
import { IonRow, IonText } from '@ionic/react'
import { AppointmentMedication } from '../../../API'
import productCategoryLabel from '../../../components/models/ProductCategoryLabel'
import groupByProp from '../../../components/util/GroupByProp'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { connect } from '../../../data'

interface OwnProps {
  items: AppointmentMedication[]
}
interface StateProps {
  net: boolean
}
interface DispatchProps {}
interface Tga6StatProps extends OwnProps, StateProps, DispatchProps {}
const Tga6Stat: FC<Tga6StatProps> = ({ items, net }) => {
  const [stat, setStat] = useState<any>([])
  const [render, setRender] = useState(false)
  let mapNums = groupByProp(items, (itm: AppointmentMedication) => itm.map?.mapNumber)

  useEffect(() => {
    Array.from(mapNums).map((group: any, idx: number) => {
      stat[idx] = Array.from(groupByProp(group[1], (itm: AppointmentMedication) => itm?.patientID))
      return stat
    })
    setStat(stat)
    setRender(true)
  }, [items]) // eslint-disable-line

  useEffect(() => {
    if (net) setRender(false)
  }, [net]) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow className='tr-header'>
        <IonCol>Authorised Prescriber</IonCol>
        <IonCol>Product Profile</IonCol>
        <IonCol>Product Presentation</IonCol>
        <IonCol>Number of new patients</IonCol>
        <IonCol>Number of total patients</IonCol>
      </IonRow>
      {items.length === 0 && !net && <IonText>No record found for given dates</IonText>}
      {items.length === 0 && net && <TbnSpinner />}
      {render &&
        Array.from(mapNums).map((group: any, idx: number) => (
          <IonRow key={group[0]}>
            <IonCol>{group?.[1]?.[0]?.map?.mapNumber}</IonCol>
            <IonCol>
              {group?.[1]?.[0]?.map?.category || group?.[1]?.[0]?.product?.category
                ? `${productCategoryLabel(group?.[1]?.[0]?.map?.category || group?.[1]?.[0]?.product?.category)}`
                : '-'}
            </IonCol>
            <IonCol>{group?.[1]?.[0]?.product?.presentationText}</IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === group?.[1]?.[0]?.patientID)?.[1]?.[0]?.id}
              >
                {stat[idx]?.length}
              </IonButton>
              <IonPopover
                trigger={stat[idx]?.find((arr: any[]) => arr?.[0] === group?.[1]?.[0]?.patientID)?.[1]?.[0]?.id}
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding'>
                  {stat[idx]?.map((arr: any) => (
                    <IonItem key={arr[1]?.[0].id} title={arr[1]?.[0].phone || ''}>
                      <IonLabel className='ion-text-wrap'>{`${arr[1]?.[0].givenName} ${arr[1]?.[0].familyName}`}</IonLabel>
                    </IonItem>
                  ))}
                </IonContent>
              </IonPopover>
            </IonCol>
            <IonCol>
              <IonButton
                fill='clear'
                id={stat[idx]?.find((arr: any[]) => arr?.[0] === group?.[1]?.[0]?.patientID)?.[1]?.[0]?.id + 'id2'}
              >
                {stat[idx]?.length}
              </IonButton>
              <IonPopover
                trigger={stat[idx]?.find((arr: any[]) => arr?.[0] === group?.[1]?.[0]?.patientID)?.[1]?.[0]?.id + 'id2'}
                triggerAction='click'
                size='auto'
              >
                <IonContent class='ion-padding' style={{ minWidth: '200px' }}>
                  {stat[idx]?.map((arr: any) => (
                    <IonItem key={arr[1]?.[0].id} title={arr[1]?.[0].phone || ''}>
                      <IonLabel className='ion-text-wrap'>{`${arr[1]?.[0].givenName} ${arr[1]?.[0].familyName}`}</IonLabel>
                    </IonItem>
                  ))}
                </IonContent>
              </IonPopover>
            </IonCol>
          </IonRow>
        ))}
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  component: Tga6Stat,
})
