import { Measurement, ProductPresentation } from '../../API'

const dosageMeasurementLookup = (presentation?: ProductPresentation | null) => {
  if (!!presentation) {
    switch (presentation) {
      case ProductPresentation.CAPSULE:
        return Measurement.MILLIGRAM
      case ProductPresentation.ORAL_LIQUID:
        return Measurement.MILLILITER
      case ProductPresentation.DRIED_HERB:
        return Measurement.GRAM
      case ProductPresentation.SPRAY_SOLUTION:
        return Measurement.MILLILITER
    }
  }
  return null
}

export default dosageMeasurementLookup
