import { FC, useEffect, useState } from 'react'
import { IonBadge, IonChip, IonItem, IonLabel, IonList, IonText, useIonToast } from '@ionic/react'
import { AppointmentMedication } from '../../API'
import ProductPresentationIcon from '../../components/models/ProductPresentationIcon'
import { AppointmentMedicationService } from '../../components/services/AppointmentMedicationService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { utcToLocaleDate } from '../../components/util/Date'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'

interface OwnProps {
  patientID: string
  doctorPublicId: string
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AppointmentMedHistoryProps extends OwnProps, DispatchProps {}

const AppointmentMedHistory: FC<AppointmentMedHistoryProps> = ({ patientID, doctorPublicId, setNet }) => {
  const [items, setItems] = useState<AppointmentMedication[]>([])
  const [presentToast] = useIonToast()

  const fetchItems = async () => {
    setNet(true)
    const res: TbnResponse = await AppointmentMedicationService.Instance.findByPatientId(patientID)
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonList>
      {items.map((itm: AppointmentMedication) => (
        <IonItem key={itm.id}>
          <IonText slot='start'>
            <ProductPresentationIcon pp={itm?.product?.presentation} />
          </IonText>
          <IonLabel className='ion-no-margin ion-text-wrap'>
            {itm?.product?.name} ({itm?.product?.ingredients})
            <p>
              <IonChip>{itm?.dailyDose}</IonChip> <IonChip>n = {itm?.quantity}</IonChip>{' '}
              <IonChip>{itm?.repeats} repeats</IonChip> <IonChip>{itm?.repeatInterval}</IonChip>
            </p>
          </IonLabel>
          <IonText slot='end'>{utcToLocaleDate(itm?.createdAt)}</IonText>
          {doctorPublicId !== itm?.doctor?.drId && (
            <IonBadge slot='end' color={'medium'}>
              {itm?.doctor?.drId}
            </IonBadge>
          )}
        </IonItem>
      ))}
    </IonList>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setNet,
  },
  component: AppointmentMedHistory,
})
