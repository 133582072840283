import { FC, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonCol, IonContent, IonIcon, IonRadio, IonRadioGroup, useIonToast } from '@ionic/react'
import { IonFooter, IonGrid, IonInput, IonItem, IonLabel } from '@ionic/react'
import { IonModal, IonRow, IonTextarea, IonToolbar } from '@ionic/react'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { clipboard } from 'ionicons/icons'
import { info } from '../../components/util/Toast'
import Referral from './Referral.model'

interface ReferralAnswerModalProps {
  referral: Referral
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
const ReferralAnswerModal: FC<ReferralAnswerModalProps> = ({ isOpen, onDidDismiss, referral }) => {
  const modalRef = useRef<any>()
  const [referType] = useState<number | undefined>(!!referral.practitionerFirstName ? 0 : 1) // 0=ELSE 1=SELF
  const [presentToast] = useIonToast()

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef} id='referral-answer-modal'>
      <IonContent className='ion-padding'>
        <IonGrid>
          <Tabs currentIndex={referType} isDisabled={true}>
            <TabItem title='Referred by a health practitioner'></TabItem>
            <TabItem title='Self referred by a patient'></TabItem>
          </Tabs>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Given Name
            </IonLabel>
            <IonInput value={referral.givenName} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.givenName && (
                <CopyToClipboard text={referral.givenName} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Surname
            </IonLabel>
            <IonInput value={referral.surName} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.surName && (
                <CopyToClipboard text={referral.surName} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{!!referType ? "Patient's " : ''} Address</IonLabel>
            <IonTextarea autoGrow={true} value={referral.addressOneLine} readonly={true}></IonTextarea>
            <IonButtons slot='end'>
              {!!referral.addressOneLine && (
                <CopyToClipboard text={referral.addressOneLine} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 ? "Patient's " : ''} Medicare, DVA or private health number
            </IonLabel>
            <IonInput value={referral.medicareNumber} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.medicareNumber && (
                <CopyToClipboard text={referral.medicareNumber} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 ? "Patient's " : ''} IHI No.
            </IonLabel>
            <IonInput value={referral.ihiNo} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.ihiNo && (
                <CopyToClipboard text={referral.ihiNo} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{referType === 0 ? "Patient's " : ''} Date of Birth</IonLabel>
            <IonInput value={referral.dateOfBirth} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.dateOfBirth && (
                <CopyToClipboard text={referral.dateOfBirth} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Mobile
            </IonLabel>
            <IonInput value={referral.phone} readonly={true}></IonInput>
            <IonButtons slot='end'>
              {!!referral.phone && (
                <CopyToClipboard text={referral.phone} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          {referType === 1 && (
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Next of Kin</IonLabel>
                  <IonInput value={referral.nextOfKin} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.nextOfKin && (
                      <CopyToClipboard text={referral.nextOfKin} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput value={referral.nextOfKinRelation} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.nextOfKinRelation && (
                      <CopyToClipboard text={referral.nextOfKinRelation} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput value={referral.nextOfKinContact} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.nextOfKinContact && (
                      <CopyToClipboard text={referral.nextOfKinContact} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {referType === 1 && (
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Power of Attorney</IonLabel>
                  <IonInput value={referral.powerOfAttorney} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.powerOfAttorney && (
                      <CopyToClipboard text={referral.powerOfAttorney} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput value={referral.powerOfAttorneyRelation} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.powerOfAttorneyRelation && (
                      <CopyToClipboard
                        text={referral.powerOfAttorneyRelation}
                        onCopy={() => info('Copied', presentToast)}
                      >
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput value={referral.powerOfAttorneyContact} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.powerOfAttorneyContact && (
                      <CopyToClipboard
                        text={referral.powerOfAttorneyContact}
                        onCopy={() => info('Copied', presentToast)}
                      >
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Parent/guardian/carer</IonLabel>
                <IonInput value={referral.carer} readonly={true}></IonInput>
                <IonButtons slot='end'>
                  {!!referral.carer && (
                    <CopyToClipboard text={referral.carer} onCopy={() => info('Copied', presentToast)}>
                      <IonButton>
                        <IonIcon icon={clipboard}></IonIcon>
                      </IonButton>
                    </CopyToClipboard>
                  )}
                </IonButtons>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput value={referral.carerRelation} readonly={true}></IonInput>
                <IonButtons slot='end'>
                  {!!referral.carerRelation && (
                    <CopyToClipboard text={referral.carerRelation} onCopy={() => info('Copied', presentToast)}>
                      <IonButton>
                        <IonIcon icon={clipboard}></IonIcon>
                      </IonButton>
                    </CopyToClipboard>
                  )}
                </IonButtons>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput value={referral.carerContact} readonly={true}></IonInput>
                <IonButtons slot='end'>
                  {!!referral.carerContact && (
                    <CopyToClipboard text={referral.carerContact} onCopy={() => info('Copied', presentToast)}>
                      <IonButton>
                        <IonIcon icon={clipboard}></IonIcon>
                      </IonButton>
                    </CopyToClipboard>
                  )}
                </IonButtons>
              </IonItem>
            </IonCol>
          </IonRow>
          {referType === 1 && (
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Emergency Contact</IonLabel>
                  <IonInput value={referral.emergency} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.emergency && (
                      <CopyToClipboard text={referral.emergency} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput value={referral.emergencyRelation} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.emergencyRelation && (
                      <CopyToClipboard text={referral.emergencyRelation} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput value={referral.emergencyContact} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.emergencyContact && (
                      <CopyToClipboard text={referral.emergencyContact} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Presenting Symptoms (e.g. Pain/Sleep Issues/Spasms)
              </IonLabel>
              <IonTextarea autoGrow={true} value={referral.symptoms} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.symptoms && (
                  <CopyToClipboard text={referral.symptoms} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Stated Conditions/Diagnoses (e.g. Arthritis/Insomnia/MS)</span>}
              {referType === 1 && (
                <span>
                  What is the primary diagnosis or medical condition for which you seek treatment with medicinal
                  cannabis? Was this diagnosed by a doctor?
                </span>
              )}
            </IonLabel>
            <IonTextarea autoGrow={true} value={referral.primaryDiagnosis} readonly={true} />
            <IonButtons slot='end'>
              {!!referral.primaryDiagnosis && (
                <CopyToClipboard text={referral.primaryDiagnosis} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Treatments/Medications Used and Reason to Cease</span>}
              {referType === 1 && <span>What other treatments have you tried for this condition?</span>}
            </IonLabel>
            <IonTextarea autoGrow={true} value={referral.otherTreatments} readonly={true} />
            <IonButtons slot='end'>
              {!!referral.otherTreatments && (
                <CopyToClipboard text={referral.otherTreatments} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Current Medications</span>}
              {referType === 1 && (
                <span>
                  What medications have you previously used? List any side effects and your reason for stopping.
                </span>
              )}
            </IonLabel>
            <IonTextarea autoGrow={true} value={referral.otherMedications} readonly={true} />
            <IonButtons slot='end'>
              {!!referral.otherMedications && (
                <CopyToClipboard text={referral.otherMedications} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          {referType === 1 && (
            <>
              <br />
              <IonLabel className='ion-margin-start required'>
                Have you ever self-medicated or are you currently self-medicating with cannabis?
              </IonLabel>
              <IonRadioGroup value={referral.selfMedicated}>
                <IonItem>
                  <IonLabel>Yes</IonLabel>
                  <IonRadio value='yes' slot='start' disabled={true} />
                </IonItem>
                <IonItem>
                  <IonLabel>No</IonLabel>
                  <IonRadio value='no' slot='start' disabled={true} />
                </IonItem>
              </IonRadioGroup>
            </>
          )}
          {referType === 1 && (
            <>
              <br />
              <IonLabel className='ion-margin-start required'>Do you have any kidney or liver diseases?</IonLabel>
              <IonRadioGroup value={referral.kidneyLiver}>
                <IonItem>
                  <IonLabel>Yes</IonLabel>
                  <IonRadio value='yes' slot='start' disabled={true} />
                </IonItem>
                <IonItem>
                  <IonLabel>No</IonLabel>
                  <IonRadio value='no' slot='start' disabled={true} />
                </IonItem>
              </IonRadioGroup>
            </>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>
                Do you have any cardiovascular (heart and blood vessels) conditions?
              </IonLabel>
              <IonInput value={referral.cardiovascular} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.cardiovascular && (
                  <CopyToClipboard text={referral.cardiovascular} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you have a current or previous substance abuse disorder?</IonLabel>
              <IonInput value={referral.substanceAbuseDisorder} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.substanceAbuseDisorder && (
                  <CopyToClipboard text={referral.substanceAbuseDisorder} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you have any allergies?</IonLabel>
              <IonInput value={referral.allergies} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.allergies && (
                  <CopyToClipboard text={referral.allergies} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you smoke? If yes how many/day?</IonLabel>
              <IonInput value={referral.smoke} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.smoke && (
                  <CopyToClipboard text={referral.smoke} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>
                Do you drink alcohol? If yes how many a day and how many days per week?
              </IonLabel>
              <IonInput value={referral.drinkAlcohol} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.drinkAlcohol && (
                  <CopyToClipboard text={referral.drinkAlcohol} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Other medical conditions</span>}
              {referType === 1 && <span>Please list any other medical conditions and current medications below:</span>}
            </IonLabel>
            <IonTextarea autoGrow={true} value={referral.otherConditions} readonly={true} />
            <IonButtons slot='end'>
              {!!referral.otherConditions && (
                <CopyToClipboard text={referral.otherConditions} onCopy={() => info('Copied', presentToast)}>
                  <IonButton>
                    <IonIcon icon={clipboard}></IonIcon>
                  </IonButton>
                </CopyToClipboard>
              )}
            </IonButtons>
          </IonItem>
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Please include any CV, Psychiatric, Hepatic and Nephrotic conditions
              </IonLabel>
              <IonTextarea autoGrow={true} value={referral.cvConditions} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.cvConditions && (
                  <CopyToClipboard text={referral.cvConditions} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Please include if as the treating practitioner you have any concerns
              </IonLabel>
              <IonTextarea autoGrow={true} value={referral.practitionerConcerns} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.practitionerConcerns && (
                  <CopyToClipboard text={referral.practitionerConcerns} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Where possible, please include related recent investigation and consultation reports
              </IonLabel>
              <IonTextarea autoGrow={true} value={referral.recentReports} readonly={true} />
              <IonButtons slot='end'>
                {!!referral.recentReports && (
                  <CopyToClipboard text={referral.recentReports} onCopy={() => info('Copied', presentToast)}>
                    <IonButton>
                      <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                  </CopyToClipboard>
                )}
              </IonButtons>
            </IonItem>
          )}
          {referType === 0 && (
            <IonRow style={{ border: '1px solid gray' }}>
              <IonCol size='12'>Referring Practitioner:</IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Given name</IonLabel>
                  <IonInput value={referral?.practitionerFirstName} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.practitionerFirstName && (
                      <CopyToClipboard
                        text={referral.practitionerFirstName}
                        onCopy={() => info('Copied', presentToast)}
                      >
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Surname</IonLabel>
                  <IonInput value={referral?.practitionerLastName} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.practitionerLastName && (
                      <CopyToClipboard text={referral.practitionerLastName} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Provider Number</IonLabel>
                  <IonInput value={referral?.practitionerProviderNumber} readonly={true}></IonInput>
                  <IonButtons slot='end'>
                    {!!referral.practitionerProviderNumber && (
                      <CopyToClipboard
                        text={referral.practitionerProviderNumber}
                        onCopy={() => info('Copied', presentToast)}
                      >
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
              <IonCol size='12'>
                <IonItem>
                  <IonLabel position='floating'>Address</IonLabel>
                  <IonTextarea autoGrow={true} value={referral?.practitionerAddress} readonly={true}></IonTextarea>
                  <IonButtons slot='end'>
                    {!!referral.practitionerAddress && (
                      <CopyToClipboard text={referral.practitionerAddress} onCopy={() => info('Copied', presentToast)}>
                        <IonButton>
                          <IonIcon icon={clipboard}></IonIcon>
                        </IonButton>
                      </CopyToClipboard>
                    )}
                  </IonButtons>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => modalRef.current.dismiss(null)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ReferralAnswerModal
