import { ProductCategory } from '../../API'

const productCategoryLookup = (catNum: number) => {
  if (!!catNum) {
    switch (+catNum) {
      case 1:
        return ProductCategory.CATEGORY_1
      case 2:
        return ProductCategory.CATEGORY_2
      case 3:
        return ProductCategory.CATEGORY_3
      case 4:
        return ProductCategory.CATEGORY_4
      case 5:
        return ProductCategory.CATEGORY_5
    }
  }
  return null
}

export default productCategoryLookup
