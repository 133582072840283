import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { AppointmentDefinition, DeleteAppointmentDefinitionInput } from '../../API'
import { ModelSortDirection, UpdateAppointmentDefinitionInput } from '../../API'
import { findAppointmentDefinitionByDrPublicId, getAppointmentDefinition } from '../../graphql-custom/public/queries'
import { findAppointmentDefinitionByClinicPublicId } from '../../graphql-custom/public/queries'
import { createAppointmentDefinition } from '../../graphql/mutations'
import { deleteAppointmentDefinition, updateAppointmentDefinition } from '../../graphql/mutations'
import { findAppointmentDefinitionByDoctorId } from '../../graphql/queries'
import { AuthStatus } from '../util/AuthStatus'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class AppointmentDefinitionService {
  private static service: AppointmentDefinitionService | undefined

  public async findByDoctor(doctorID: string): Promise<TbnResponse> {
    const variables = {
      doctorID,
      sortDirection: ModelSortDirection.ASC,
    }
    try {
      const res: any = await API.graphql({
        query: findAppointmentDefinitionByDoctorId,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findAppointmentDefinitionByDoctorId, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(appointmentDefinition: AppointmentDefinition) {
    try {
      appointmentDefinition.drPublicId = appointmentDefinition?.doctor?.drId
      appointmentDefinition.drName = appointmentDefinition?.doctor?.drName
      appointmentDefinition.drAvatar = appointmentDefinition?.doctor?.avatar
      appointmentDefinition.clinicPublicId = appointmentDefinition?.clinic?.publicId
      appointmentDefinition.clinicName = appointmentDefinition?.clinic?.name
      appointmentDefinition.clinicFormerlyKnownAs = appointmentDefinition?.clinic?.formerlyKnownAs
      appointmentDefinition.clinicStatus = appointmentDefinition?.clinic?.status
      appointmentDefinition.clinicLogoImage = appointmentDefinition?.clinic?.logoImage
      delete appointmentDefinition?.doctor
      delete appointmentDefinition?.clinic
      const res: any = await API.graphql({
        query: createAppointmentDefinition,
        variables: { input: appointmentDefinition },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.createAppointmentDefinition
    } catch (err) {
      log('error on persist appointment definition: ', err)
      return err
    }
  }

  public async update(appointmentDefinition: AppointmentDefinition): Promise<TbnResponse> {
    const next: UpdateAppointmentDefinitionInput = {
      id: appointmentDefinition.id,
      drPublicId: appointmentDefinition?.doctor?.drId,
      drName: appointmentDefinition?.doctor?.drName,
      drAvatar: appointmentDefinition?.doctor?.avatar,
      clinicPublicId: appointmentDefinition?.clinic?.publicId,
      clinicName: appointmentDefinition?.clinic?.name,
      clinicFormerlyKnownAs: appointmentDefinition?.clinic?.formerlyKnownAs,
      clinicStatus: appointmentDefinition?.clinic?.status,
      clinicLogoImage: appointmentDefinition?.clinic?.logoImage,
      active: appointmentDefinition.active,
    }
    try {
      const res: any = await API.graphql({
        query: updateAppointmentDefinition,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateAppointmentDefinition, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(appointmentDefinition: AppointmentDefinition) {
    try {
      const input: DeleteAppointmentDefinitionInput = {
        id: appointmentDefinition.id,
      }
      const res: any = await API.graphql({
        query: deleteAppointmentDefinition,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.deleteAppointmentDefinition
    } catch (err) {
      log('error on remove appointment definition: ', err)
      return err
    }
  }

  public async removeAll(doctorID: string): Promise<TbnResponse> {
    const list = await this.findByDoctor(doctorID)
    for (let itm of list?.data?.items) {
      await this.remove(itm)
    }
    return { data: 'ok' }
  }

  public async publicFindById(id: string, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getAppointmentDefinition,
        variables: {
          id,
        },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getAppointmentDefinition, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching appt def: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async publicFindByDoctor(drPublicId: string, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAppointmentDefinitionByDrPublicId,
        variables: {
          drPublicId,
          sortDirection: ModelSortDirection.ASC,
        },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findAppointmentDefinitionByDrPublicId?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching appt def: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async publicFindByClinic(clinicPublicId: string, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAppointmentDefinitionByClinicPublicId,
        variables: {
          clinicPublicId,
        },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findAppointmentDefinitionByClinicPublicId?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error fetching appt def: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): AppointmentDefinitionService {
    if (!this.service) {
      this.service = new AppointmentDefinitionService()
    }
    return this.service
  }
}
