import { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonItem, IonLabel, IonSegment, IonSegmentButton, IonText } from '@ionic/react'
import { IonCard, IonCardContent } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { connect } from '../../data'
import PageContainer from '../PageContainer'
import ConsentList from '../forms/consent/ConsentList'
import Email from './Email'
import FamilyName from './FamilyName'
import GivenName from './GivenName'

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface ProfileProps extends OwnProps, StateProps {}
const Profile: FC<ProfileProps> = ({ history }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [section, setSection] = useState('account')

  return (
    <PageContainer id='profile' isPrivate={true} title={'Profile'}>
      <IonSegment value={section} onIonChange={(e: any) => setSection(e.detail.value)}>
        <IonSegmentButton value='account'>Account</IonSegmentButton>
        <IonSegmentButton value='consent'>Consent</IonSegmentButton>
      </IonSegment>
      {section === 'consent' && <ConsentList history={history} selectProfile={() => setSection('account')} />}
      {section === 'account' && (
        <IonCard>
          <IonCardContent>
            <GivenName />
            <FamilyName />
            <IonItem disabled={true}>
              <IonLabel position='stacked'>Phone</IonLabel>
              <IonText>{user?.attributes?.phone_number}</IonText>
            </IonItem>
            <Email />
          </IonCardContent>
        </IonCard>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: () => ({}),
  component: Profile,
})
