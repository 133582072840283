import { FC } from 'react'
import { Swiper as SwiperCore } from 'swiper'
import { isDesktop, isMobile } from '../../../components/util/isMobile'
import { QuizQuestion } from './QuizQuestion.model'
import QuizQuestionBodyPart from './QuizQuestionBodyPart'
import QuizQuestionMultiCheck from './QuizQuestionMultiCheck'
import QuizQuestionMultiCheckTwoColumns from './QuizQuestionMultiCheckTwoColumns'
import QuizQuestionPainInterval from './QuizQuestionPainInterval'
import QuizQuestionPainLevel from './QuizQuestionPainLevel'
import QuizQuestionRadio from './QuizQuestionRadio'

interface OwnProps {
  quizQuestion: QuizQuestion
  swiper?: SwiperCore
  disabled?: boolean
}
interface QuizQuestionTypeProps extends OwnProps {}
const QuizQuestionType: FC<QuizQuestionTypeProps> = ({ quizQuestion, swiper, disabled = false }) => {
  return (
    <>
      {quizQuestion.type === 'radio' && (
        <QuizQuestionRadio quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'body-part-selector' && (
        <QuizQuestionBodyPart quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'pain-level-selector' && (
        <QuizQuestionPainLevel quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'interval-selector' && (
        <QuizQuestionPainInterval quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'multi-check' && (
        <QuizQuestionMultiCheck quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'multi-check-two-columns' && isDesktop() && (
        <QuizQuestionMultiCheckTwoColumns quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
      {quizQuestion.type === 'multi-check-two-columns' && isMobile() && (
        <QuizQuestionMultiCheck quizQuestion={quizQuestion} swiper={swiper} disabled={disabled} />
      )}
    </>
  )
}

export default QuizQuestionType
