const isValidEmail = (email?: string) => {
  if (!!email) return /\S+@\S+\.\S+/.test(email)
  return false
}

const validateEmail = (email?: string) => {
  if (!!email)
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    )
  return false
}

export { isValidEmail, validateEmail }
