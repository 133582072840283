import awsExpors from '../../aws-exports'

const isMainProd = () => {
  return awsExpors.aws_user_files_s3_bucket.endsWith('-prod')
}

const isStageQa = () => {
  return awsExpors.aws_user_files_s3_bucket.endsWith('-stage')
}

const isDevTest = () => {
  return awsExpors.aws_user_files_s3_bucket.endsWith('-test')
}

export { isMainProd, isStageQa, isDevTest }
