import { useEffect } from 'react'
import { IonApp, IonSplitPane } from '@ionic/react'
import { setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
// import 'swiper/css/pagination'
import '@aws-amplify/ui-react/styles.css'

/* Import Swiper styles */
import 'swiper/swiper-bundle.css'
import './App.scss'
import Routes from './Routes'
import './assets/css/flex.scss'
import ErrorBoundary from './components/util/ErrorBoundary'
import { AppContextProvider, connect, loadUserData } from './data'
import { telebinoidTheme } from './theme/amplify-override'

/* Theme variables */
import './theme/variables.scss'

setupIonicReact()

const App: React.FC = () => (
  <ErrorBoundary>
    <AppContextProvider>
      <Authenticator.Provider>
        <IonicAppConnected />
      </Authenticator.Provider>
    </AppContextProvider>
  </ErrorBoundary>
)

interface OwnProps {}
interface StateProps {
  darkMode: boolean
}
interface DispatchProps {
  loadUserData: typeof loadUserData
}
interface IonicAppProps extends OwnProps, StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, loadUserData }) => {
  useEffect(() => {
    loadUserData()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (darkMode) {
      document.body.setAttribute('class', 'dark')
    } else {
      document.body.setAttribute('class', '')
    }
  }, [darkMode])

  return (
    <ThemeProvider theme={telebinoidTheme} colorMode={darkMode ? 'dark' : 'light'}>
      <IonApp className={darkMode ? 'dark-theme' : ''}>
        <IonReactRouter>
          <IonSplitPane contentId='main'>
            <Routes />
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </ThemeProvider>
  )
}

export default App

const IonicAppConnected = connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    loadUserData,
  },
  component: IonicApp,
})
