import { Storage } from '@capacitor/storage'
import Booking from '../pages/booking/Booking.model'
import Referral from '../pages/referring/Referral.model'

const DARK_MODE = 'darkMode'
const LOGIN_MODE = 'loginMode'
const CONSENT_DATA = 'consentData'
const BOOKING_DATA = 'bookingData'
const EMAIL_SENT = 'emailSent'
const DOCTOR_NOTE = 'doctorNote'
const REFERRAL_DATA = 'referralData'

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: DARK_MODE }),
    Storage.get({ key: CONSENT_DATA }),
    Storage.get({ key: BOOKING_DATA }),
    Storage.get({ key: LOGIN_MODE }),
    Storage.get({ key: EMAIL_SENT }),
    Storage.get({ key: DOCTOR_NOTE }),
    Storage.get({ key: REFERRAL_DATA }),
  ])
  const darkMode = (await response[0].value) === 'true'
  const consent = (await response[1].value) || '{ "__typename": "ConsentModel" }'
  const booking = (await response[2].value) || `{ "__typename": "Booking", "formId": "11001100" }`
  const loginMode = (await response[3].value) || 'phone_number'
  const emailSent = (await response[4].value) === 'true'
  const doctorNote = (await response[5].value) || ''
  const referral = (await response[6].value) || '{}'
  return {
    darkMode,
    consent: JSON.parse(consent),
    booking: JSON.parse(booking),
    loginMode,
    emailSent,
    doctorNote,
    referral: JSON.parse(referral),
  }
}

export const setDarkModeData = async (darkMode: boolean) => {
  await Storage.set({ key: DARK_MODE, value: JSON.stringify(darkMode) })
}

export const setEmailSentData = async (emailSent: boolean) => {
  await Storage.set({ key: EMAIL_SENT, value: JSON.stringify(emailSent) })
}

export const setLoginModeData = async (loginMode: 'phone_number' | 'email') => {
  await Storage.set({ key: LOGIN_MODE, value: loginMode })
}

export const setConsentData = async (consent: object) => {
  await Storage.set({ key: CONSENT_DATA, value: JSON.stringify(consent) })
}

export const setBookingData = async (booking?: Booking) => {
  if (!!booking) {
    await Storage.set({ key: BOOKING_DATA, value: JSON.stringify(booking) })
  } else {
    await Storage.remove({ key: BOOKING_DATA })
  }
}

export const setDoctorNoteData = async (doctorNote?: string) => {
  if (!!doctorNote) {
    await Storage.set({ key: DOCTOR_NOTE, value: doctorNote })
  } else {
    await Storage.remove({ key: DOCTOR_NOTE })
  }
}

export const setReferralData = async (referral?: Referral) => {
  if (!!referral) {
    await Storage.set({ key: REFERRAL_DATA, value: JSON.stringify(referral) })
  } else {
    await Storage.remove({ key: REFERRAL_DATA })
  }
}
