import { FC, useEffect, useState } from 'react'
import { IonButton, IonCheckbox, IonItem, IonLabel, IonListHeader, IonText } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { connect } from '../../data'
import { setBooking } from '../../data/user/user.actions'
import '../forms/helpers/QuestionMultiCheck.scss'
import Booking from './Booking.model'

interface OwnProps {
  swiper?: SwiperCore
  booking: Booking
  paramElse?: string | null
}
interface StateProps {}
interface DispatchProps {
  setBooking: typeof setBooking
}
interface BookingSlideOneProps extends OwnProps, StateProps, DispatchProps {}
const BookingSlideOne: FC<BookingSlideOneProps> = ({ swiper, booking, setBooking, paramElse }) => {
  const [psychosisHistory, setPsychosisHistory] = useState(booking.psychosisHistory)
  const [bipolarDisorder, setBipolarDisorder] = useState(booking.bipolarDisorder)
  const [anxietyDisorder, setAnxietyDisorder] = useState(booking.anxietyDisorder)
  const [severeCardio, setSevereCardio] = useState(booking.severeCardio)
  const [pregnant, setPregnant] = useState(booking.pregnant)
  const [noneOfAbove, setNoneOfAbove] = useState(false)

  const isNotSuitable = () => {
    return booking?.['severeCardio'] || booking?.['pregnant']
  }

  useEffect(() => {
    setBooking({ ...booking, psychosisHistory, bipolarDisorder, anxietyDisorder, severeCardio, pregnant })
    if (psychosisHistory || bipolarDisorder || anxietyDisorder || severeCardio || pregnant) setNoneOfAbove(false)
  }, [psychosisHistory, bipolarDisorder, anxietyDisorder, severeCardio, pregnant]) // eslint-disable-line

  useEffect(() => {
    if (noneOfAbove) {
      setPsychosisHistory(false)
      setBipolarDisorder(false)
      setAnxietyDisorder(false)
      setSevereCardio(false)
      setPregnant(false)
    }
  }, [noneOfAbove]) // esline-disable-line

  return (
    <div style={{ overflowY: 'auto' }}>
      <span className='first-top-element'></span>
      <IonListHeader>
        {!paramElse && <b>Please choose if you have</b>}
        {!!paramElse && <b>Please choose if the patient has any of the following</b>}
      </IonListHeader>
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>History of or current active psychosis</IonLabel>
        <IonCheckbox
          color='primary'
          slot='start'
          checked={psychosisHistory}
          onIonChange={(e) => setPsychosisHistory(e.detail.checked)}
        />
      </IonItem>
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>History of or current unstable bipolar disorder</IonLabel>
        <IonCheckbox
          color='primary'
          slot='start'
          checked={bipolarDisorder}
          onIonChange={(e) => setBipolarDisorder(e.detail.checked)}
        />
      </IonItem>
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>An active unstable mood or anxiety disorder</IonLabel>
        <IonCheckbox
          color='primary'
          slot='start'
          checked={anxietyDisorder}
          onIonChange={(e) => setAnxietyDisorder(e.detail.checked)}
        />
      </IonItem>
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>Severe and unstable cardio-pulmonary disease</IonLabel>
        <IonCheckbox
          color='danger'
          slot='start'
          checked={severeCardio}
          onIonChange={(e) => setSevereCardio(e.detail.checked)}
        />
      </IonItem>
      {!!booking?.['severeCardio'] && (
        <IonText color='danger'>
          Some of the treatments offered might not be suitable for you due to your current situation. Please email us on{' '}
          <a href='mailto:support@telebinoid.com'>support@telebinoid.com</a> if you would like to discuss this further.
        </IonText>
      )}
      <IonListHeader>
        {!paramElse && <b>or you are</b>}
        {!!paramElse && <b>or if they are</b>}
      </IonListHeader>
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>Pregnant, planning to conceive or breastfeeding</IonLabel>
        <IonCheckbox
          color='danger'
          slot='start'
          checked={pregnant}
          onIonChange={(e) => setPregnant(e.detail.checked)}
        />
      </IonItem>
      {!!booking?.['pregnant'] && (
        <IonText color='danger'>
          Some of the treatments offered might not be suitable for you due to your current situation. Please email us on{' '}
          <a href='mailto:support@telebinoid.com'>support@telebinoid.com</a> if you would like to discuss this further.
        </IonText>
      )}
      <IonItem className='question-multi-check'>
        <IonLabel className='ion-text-wrap'>None of above</IonLabel>
        <IonCheckbox
          color='primary'
          slot='start'
          checked={noneOfAbove}
          onIonChange={(e) => setNoneOfAbove(e.detail.checked)}
        />
      </IonItem>

      {!!swiper && (
        <IonButton
          disabled={isNotSuitable()}
          color='primary'
          onClick={() => swiper?.slideNext()}
          className='ion-margin-top'
          expand='block'
        >
          Continue
        </IonButton>
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
  },
  component: BookingSlideOne,
})
