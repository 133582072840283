import { FC } from 'react'
import { IonChip } from '@ionic/react'
import { ProductPresentation } from '../../API'

interface ProductMeasurementFromPresentationProps {
  pp?: ProductPresentation | null
  slot?: 'start' | 'end'
}
const ProductMeasurementFromPresentation: FC<ProductMeasurementFromPresentationProps> = ({ pp, slot }) => {
  const resolveIcon = () => {
    switch (pp) {
      case ProductPresentation.CAPSULE:
        return <IonChip slot={!!slot ? slot : undefined}>mg</IonChip>
      case ProductPresentation.DRIED_HERB:
        return <IonChip slot={!!slot ? slot : undefined}>gr</IonChip>
      case ProductPresentation.ORAL_LIQUID:
        return <IonChip slot={!!slot ? slot : undefined}>ml</IonChip>
      case ProductPresentation.SPRAY_SOLUTION:
        return <IonChip slot={!!slot ? slot : undefined}>ml</IonChip>
    }
    return <IonChip></IonChip>
  }

  return <>{resolveIcon()}</>
}

export default ProductMeasurementFromPresentation
