import { FC, useEffect, useState } from 'react'
import { IonButton, IonText } from '@ionic/react'
import Swiper from 'swiper'
import PainLevelSelector from '../../../components/helpers/quiz/PainLevelSelector'
import { isDesktop, isMobile } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import { Quiz } from './Quiz.model'
import { QuizQuestion } from './QuizQuestion.model'

interface OwnProps {
  quizQuestion: QuizQuestion
  swiper?: Swiper
  disabled: boolean
}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface QuizQuestionPainLevelProps extends OwnProps, StateProps, DispatchProps {}
const QuizQuestionPainLevel: FC<QuizQuestionPainLevelProps> = ({ quizQuestion, swiper, quiz, setQuiz, disabled }) => {
  const [selection, setSelection] = useState<number>(quizQuestion.answer)

  const width = () => {
    if (window.innerWidth > 400) {
      return '300px'
    } else {
      return `${window.innerWidth - 50}px`
    }
  }

  const updateQuiz = (answer: any) => {
    const q = quiz?.questions?.find((q: QuizQuestion) => q.id === quizQuestion.id)
    if (!!q) {
      q.answer = answer
      const idx = quiz?.questions?.findIndex((x) => x.id === quizQuestion.id)
      quiz.questions[idx] = q
      setQuiz(quiz)
    }
  }

  useEffect(() => {
    updateQuiz(selection)
  }, [selection]) // eslint-disable-line

  return (
    <>
      <h1 className='ion-text-center' style={{ marginBottom: isMobile() ? '0' : 'inherit' }}>
        {quizQuestion.label}
      </h1>
      {!!quizQuestion.hint && (
        <div className='ion-text-center'>
          <IonText className='quiz-hint'>{quizQuestion.hint}</IonText>
        </div>
      )}
      <div className='ion-text-center' style={{ marginTop: isDesktop() ? '20px' : '20px' }}>
        <div style={{ width: width(), margin: 'auto' }}>
          <PainLevelSelector
            defaultSelection={selection}
            onChangeSelection={(selection: any) => setSelection(selection)}
            disabled={disabled}
          />
        </div>
        {/*<span style={{fontSize: 'initial', position: 'absolute', maxWidth: '120px', left: 0}}>{JSON.stringify(selection)}</span>*/}
      </div>
      {!!swiper && (
        <div className={`ion-text-center ion-margin-vertical`}>
          {!swiper?.isBeginning && (
            <IonButton
              fill='outline'
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              className='ion-padding-end'
              onClick={() => swiper?.slidePrev()}
            >
              Previous
            </IonButton>
          )}
          {!swiper?.isEnd && (
            <IonButton
              disabled={!selection}
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              onClick={() => swiper?.slideNext()}
            >
              Next
            </IonButton>
          )}
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: QuizQuestionPainLevel,
})
