import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react'
import { IonListHeader, IonSpinner, IonText, useIonToast, useIonViewDidEnter } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Swiper as SwiperCore } from 'swiper'
import { Keyboard, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { QuizType, Quiz } from '../../../API'
import { QuizService } from '../../../components/services/QuizService'
import { log } from '../../../components/util/Log'
import { failure, success } from '../../../components/util/Toast'
import { isDesktop, isMobile } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import PageContainer from '../../PageContainer'
import '../Quiz.scss'
import { Quiz as QuizModel } from '../helpers/Quiz.model'
import { QuizQuestion } from '../helpers/QuizQuestion.model'
import quizJson from './Dass21.json'
import Dass21AnswerModal from './Dass21AnswerModal'
import Dass21Question from './Dass21Question'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  quiz: QuizModel
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface Dass21Props extends OwnProps, StateProps, DispatchProps {}
const Dass21: FC<Dass21Props> = ({ quiz, setQuiz, history }) => {
  let [swiper, setSwiper] = useState<SwiperCore>()
  const questions = quizJson.questions
  const [slide, setSlide] = useState(0)
  const [inprogress, setInprogress] = useState(true)
  const [succeed, setSucceed] = useState(false)
  const [persistedQuiz, setPersistedQuiz] = useState<Quiz>()
  const [showModal, setShowModal] = useState(false)
  const [preset] = useIonToast()
  const { user } = useAuthenticator((context) => [context.user])

  const submitQuiz = async () => {
    setInprogress(true)
    try {
      await QuizService.Instance.buildQuizService()
      const res: Quiz = await QuizService.Instance.createQuiz({
        quizType: QuizType.DASS_21,
        userID: user?.attributes?.sub || '0',
        questions: JSON.stringify(quiz?.questions),
      })
      setPersistedQuiz(res)
      success('Quiz sent successfully', preset)
      setSucceed(true)
    } catch (err: any) {
      failure(`Error: ${err?.errors?.[0]?.message}`, preset)
    }
    setInprogress(false)
  }

  const width = () => {
    if (window.innerWidth > 450) {
      return '450px'
    } else {
      return `${window.innerWidth - 25}px`
    }
  }

  const handleSlideChangeStart = () => {
    setSlide(swiper?.realIndex || 0)
  }

  const goBack = () => {
    if (!!history.goBack) {
      history.goBack()
    } else {
      history.push('/quiz')
    }
  }

  useIonViewDidEnter(() => {
    log('init quiz data')
    setQuiz({
      __typename: 'Quiz',
      type: 'DASS_21',
      questions,
    })
  })

  useEffect(() => {
    log('innerWith', window.innerWidth)
    log('innerHeight', window.innerHeight)
  }, []) // eslint-disable-line

  useEffect(() => {
    log('slide', slide)
  }, [slide])

  useEffect(() => {
    if (swiper?.isEnd) {
      log('Quiz ended!')
      submitQuiz()
    }
  }, [swiper?.isEnd]) // eslint-disable-line

  return (
    <PageContainer
      id='quize-dass-21'
      isPrivate={true}
      hideBackButton={false}
      hideMenu={true}
      hideFooter={true}
      padding={false}
      fullscreen={true}
      title='DASS-21'
    >
      <Swiper
        onSwiper={setSwiper}
        onSlideChangeTransitionStart={handleSlideChangeStart}
        pagination={{
          type: 'progressbar',
          clickable: false,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Pagination, Keyboard]}
        shortSwipes={false}
      >
        <SwiperSlide id='start'>
          <div
            style={{
              width: width(),
              margin: 'auto',
              marginTop: isMobile() ? '0' : 'auto',
              overflowY: 'auto',
              fontSize: '0.9em',
            }}
          >
            <p style={{ marginBottom: 0 }}>
              Please read each statement and then choose the option that indicates how much the statement applies to
              you, <b>over the past week</b>.
              <span>There are no right or wrong answers. Do not spend too much time on any statement.</span>
            </p>
            <IonList className='ion-no-padding'>
              <IonListHeader>
                <IonLabel>Options:</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  Did not apply to me at all - <b>NEVER</b>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  Applied to me to some degree, or some of the time - <b>SOMETIMES</b>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  Applied to me to a considerable degree, or a good part of time - <b>OFTEN</b>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  Applied to me very much, or most of the time - <b>ALMOST ALWAYS</b>
                </IonLabel>
              </IonItem>
            </IonList>
            <div className={`ion-text-center ${isDesktop() ? 'ion-margin-vertical' : ''}`}>
              <IonButton
                size={isDesktop() ? 'large' : 'default'}
                style={{ minWidth: '100px' }}
                onClick={() => swiper?.slideNext()}
              >
                Start
              </IonButton>
            </div>
          </div>
        </SwiperSlide>
        {questions.map((q: any) => (
          <SwiperSlide id={q.id} key={q.id}>
            <div style={{ width: width(), margin: 'auto', marginTop: isMobile() ? '0' : 'auto', overflowY: 'auto' }}>
              <Dass21Question quizQuestion={q as QuizQuestion} swiper={swiper} disabled={false} />
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide id='end'>
          <div className='ion-text-center'>
            <h1>Thank you!</h1>
            <hr />
            {inprogress && <IonSpinner />}
            {!inprogress && succeed && (
              <>
                <IonText color='success'>You have completed the assessment.</IonText>
                <br />
                <div className='flex-container' style={{ maxWidth: '300px', margin: 'auto' }}>
                  <IonText className='align-items-center' style={{ paddingTop: '5px' }}>
                    You can see your results
                  </IonText>
                  <IonButton className='align-items-center' fill='clear' onClick={() => setShowModal(true)}>
                    here
                  </IonButton>
                </div>
                <br />
                <IonButton onClick={goBack}>Back to home</IonButton>
              </>
            )}
            {!inprogress && !succeed && (
              <>
                <IonText color='danger'>Sorry, something went wrong, please submit again</IonText>
                <br />
                <IonButton onClick={submitQuiz} disabled={inprogress}>
                  Submit
                </IonButton>
              </>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
      {!!persistedQuiz && <Dass21AnswerModal model={persistedQuiz} showModal={showModal} setShowModal={setShowModal} />}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: withRouter(Dass21),
})
