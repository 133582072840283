import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { IonButton, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react'
import { IonRadio, IonRadioGroup, IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { AppointmentDefinition, Clinic, ClinicStatus, Doctor } from '../../API'
import TbnAvatar from '../../components/models/TbnAvatar'
import TbnThumbnail from '../../components/models/TbnThumbnail'
import { AppointmentDefinitionService } from '../../components/services/AppointmentDefinitionService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { AuthStatus } from '../../components/util/AuthStatus'
import TelSpinner from '../../components/util/TelSpinner'
import { isMobile } from '../../components/util/isMobile'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import PageContainer from '../PageContainer'
import AvailableBookingsComponent from './AvailableBookingsComponent'
import './ClinicPublicPage.scss'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ClinicPublicPageProps extends OwnProps, StateProps, DispatchProps {}
const ClinicPublicPage: FC<ClinicPublicPageProps> = ({ net, setNet, history }) => {
  const { clinicPublicId, drPublicId } = useParams<any>()
  const [clinic, setClinic] = useState<Clinic>()
  const [apptDefs, setApptDefs] = useState<AppointmentDefinition[]>([])
  const [error, setError] = useState<string>() // eslint-disable-line
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [doctorPublicId, setDoctorPublicId] = useState(drPublicId)
  const [doctors, setDoctors] = useState<Doctor[]>([])

  const loadApptDefs = async (clinicPublicId: string) => {
    if (apptDefs.length > 0) {
      return
    }
    setNet(true)
    const res: TbnResponse = await AppointmentDefinitionService.Instance.publicFindByClinic(
      clinicPublicId,
      authStatus as AuthStatus,
    )
    if (res.data) {
      setApptDefs(res.data?.filter((itm: AppointmentDefinition) => itm.active))
    }
    setNet(false)
  }

  useEffect(() => {
    const res: any[] = []
    apptDefs.flatMap((itm: AppointmentDefinition) => {
      if (!res.find((d: Doctor) => d.drId === itm.drPublicId)) {
        if (!!drPublicId) {
          if (drPublicId === itm.drPublicId) {
            res.push({
              drId: itm.drPublicId,
              drName: itm?.drName,
              avatar: itm?.drAvatar,
            } as Doctor)
          }
        } else {
          res.push({
            drId: itm.drPublicId,
            drName: itm?.drName,
            avatar: itm?.drAvatar,
          } as Doctor)
        }
      }
      setClinic({
        publicId: itm?.clinicPublicId,
        name: itm?.clinicName,
        formerlyKnownAs: itm?.clinicFormerlyKnownAs,
        status: itm?.clinicStatus,
        logoImage: itm.clinicLogoImage,
      } as Clinic)
      return itm
    })
    if (!doctorPublicId) {
      setDoctorPublicId(res?.[0]?.drId)
    }
    setDoctors(res)
  }, [apptDefs]) // eslint-disable-line

  useEffect(() => {
    if (!!clinicPublicId) loadApptDefs(clinicPublicId)
  }, [clinicPublicId, authStatus]) // eslint-disable-line

  return (
    <PageContainer
      id='doctor-public-page'
      isPrivate={false}
      padding={true}
      title={
        <>
          {isMobile() ? 'Appt' : 'Appointment'} at <IonText color='tertiary'>{clinic?.name}</IonText> clinic
        </>
      }
      actionButtonsEnd={<>{!!clinic?.logoImage && <TbnThumbnail path={clinic?.logoImage} />}</>}
    >
      {net && <TelSpinner />}
      {!!error && (
        <>
          <h6>Something went wrong, We apologize for any inconvenience.</h6>
          <IonButton className='ion-padding' onClick={() => window.location.reload()}>
            {'Refresh page'}
          </IonButton>
          <br />
          <br />
          <details style={{ whiteSpace: 'pre-wrap' }}>{error}</details>
        </>
      )}
      {!net && !error && clinic?.status !== ClinicStatus.ACTIVE && (
        <div className='ion-text-center'>
          <IonText color='tertiary'>{clinic?.name}</IonText> clinic
        </div>
      )}
      {!net && !error && clinic?.status === ClinicStatus.ACTIVE && (
        <>
          {doctors?.length > 1 && (
            <IonList>
              <IonListHeader>Doctors:</IonListHeader>
              <IonRadioGroup value={doctorPublicId} onIonChange={(e) => setDoctorPublicId(e.detail.value)}>
                {doctors.map((d: Doctor) => (
                  <IonItem key={d.drId} mode='ios'>
                    <TbnAvatar avatar={d.avatar} />
                    <IonLabel>Dr {d.drName}</IonLabel>
                    <IonRadio slot='end' value={d.drId}></IonRadio>
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )}
          <AvailableBookingsComponent
            apptDefs={apptDefs}
            clinicPublicId={clinicPublicId}
            drPublicId={doctorPublicId}
            history={history}
          />
        </>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: ClinicPublicPage,
})
