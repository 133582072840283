import { FC, useEffect, useState } from 'react'
import { IonButton, IonLabel, IonList, IonListHeader, IonRadioGroup } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import RadioWithOtherSupport from '../../../components/form/RadioWithOtherSupport'
import { lookupOtherText } from '../../../components/util/LookupOtherText'
import { isDesktop } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import { Quiz } from './Quiz.model'
import { QuizQuestion } from './QuizQuestion.model'

interface OwnProps {
  quizQuestion: QuizQuestion
  swiper?: SwiperCore
  disabled: boolean
}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface QuizQuestionRadioPrps extends OwnProps, StateProps, DispatchProps {}
const QuizQuestionRadio: FC<QuizQuestionRadioPrps> = ({ quizQuestion, swiper, quiz, setQuiz, disabled }) => {
  const [value, setValue] = useState(quizQuestion?.answer?.includes('Other<') ? 'other' : quizQuestion.answer)
  const [otherValue, setOtherValue] = useState(lookupOtherText(quizQuestion?.answer))

  const isChecked = (idParam: string) => {
    return idParam === value
  }

  const setModel = (answer: any) => {
    const q = quiz?.questions?.find((q: QuizQuestion) => q.id === quizQuestion.id)
    if (!!q) {
      q.answer = answer
      const idx = quiz?.questions?.findIndex((x) => x.id === quizQuestion.id)
      quiz.questions[idx] = q
      setQuiz(quiz)
    }
  }

  const isValidValue = (valueParam: string) => {
    return quizQuestion.options?.find((opt) => opt.id === valueParam)
  }

  const isBeginning = () => {
    return quizQuestion.id === 'how-long-suffering'
  }

  useEffect(() => {
    setModel(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    setModel(`Other<${otherValue}>,`)
  }, [otherValue]) // eslint-disable-line

  return (
    <>
      <h1>{quizQuestion.label}</h1>
      <IonList>
        <IonRadioGroup
          value={value}
          onIonChange={(e) => {
            if (isValidValue(e.detail.value)) {
              setValue(e.detail.value!)
            }
          }}
        >
          <IonListHeader>
            <IonLabel>Select one</IonLabel>
          </IonListHeader>
          {quizQuestion?.options?.map((option) => (
            <RadioWithOtherSupport
              key={option.id}
              id={option.id}
              label={option.label}
              disabled={disabled}
              isChecked={isChecked}
              defaultOtherValue={otherValue}
              onOtherValueChange={setOtherValue}
            />
          ))}
        </IonRadioGroup>
      </IonList>
      {!!swiper && (
        <div className={`ion-text-center ${isDesktop() ? 'ion-margin-vertical' : ''}`}>
          {!isBeginning() && (
            <IonButton
              fill='outline'
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              className='ion-padding-end'
              onClick={() => swiper?.slidePrev()}
            >
              Previous
            </IonButton>
          )}
          {!swiper?.isEnd && (
            <IonButton
              disabled={quizQuestion.required && !value}
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              onClick={() => swiper?.slideNext()}
            >
              Next
            </IonButton>
          )}
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: QuizQuestionRadio,
})
