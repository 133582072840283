import { FC } from 'react'
import PageContainer from '../../PageContainer'
import DosageCalculator from './DosageCalculator'

interface DosageCalculatorPageProps {}
const DosageCalculatorPage: FC<DosageCalculatorPageProps> = () => {
  return (
    <PageContainer id='dosage-calculator-page' isPrivate={false}>
      <DosageCalculator />
    </PageContainer>
  )
}

export default DosageCalculatorPage
