import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Accountant } from '../../API'
import { AccountantService } from '../services/AccountantService'
import './select.scss'

interface AccountantSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const AccountantSelect: FC<AccountantSelectProps> = ({ defaultValue, onChange }) => {
  const [options, setOptions] = useState()
  const [items, setItems] = useState<Accountant[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (value: any) => {
    setSelectedOption(value)
    onChange(items.find((itm: Accountant) => itm.id === value.value))
  }

  const fetchOptions = async () => {
    const res: any = await AccountantService.Instance.list()
    if (res.data) {
      setItems(res.data)
      setOptions(
        res.data?.flatMap((itm: Accountant) => {
          return { value: itm.id, label: `${itm.name}` }
        }),
      )
    }
  }

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  return <Select value={selectedOption} options={options} onChange={handleChange} className='tbn-select' />
}

export default AccountantSelect
