import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Clinic, ClinicStatus, DeleteClinicInput, ModelSortDirection, UpdateClinicInput } from '../../API'
import { findClinicByPublicId, findClinicByStatus } from '../../graphql-custom/public/queries'
import { createClinic, deleteClinic, updateClinic } from '../../graphql/mutations'
import { findClinicBySorter, getClinic } from '../../graphql/queries'
import { AuthStatus } from '../util/AuthStatus'
import { log, warn } from '../util/Log'
import SORT_CHAR from '../util/Sorter'
import { TbnResponse } from './TbnResponse'

export class ClinicService {
  private static service: ClinicService | undefined

  public async load(id: string) {
    const variables = {
      id,
    }
    const res: any = await API.graphql({
      query: getClinic,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })
    return res?.data?.getClinic
  }

  public async getAll(): Promise<TbnResponse> {
    try {
      const variables = {
        sorter: SORT_CHAR,
        sortDirection: ModelSortDirection.DESC,
      }
      const res: any = await API.graphql({
        query: findClinicBySorter,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicBySorter, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn(err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(clinic: Clinic) {
    try {
      if (!clinic.id) {
        const res: any = await API.graphql({
          query: createClinic,
          variables: { input: { ...clinic, sorter: SORT_CHAR } },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return res
      } else {
        const next: UpdateClinicInput = {
          id: clinic.id,
          //publicId: clinic.publicId?.toLowerCase(),
          name: clinic.name,
          formerlyKnownAs: clinic.formerlyKnownAs,
          address: clinic.address,
          location: clinic.location,
          status: clinic.status,
          logoImage: clinic.logoImage,
          bankName: clinic.bankName,
          bankStateBranch: clinic.bankStateBranch,
          bankAccountName: clinic.bankAccountName,
          bankAccountNumber: clinic.bankAccountNumber,
          sorter: SORT_CHAR, // sorter must be same for all clinics, https://stackoverflow.com/a/63894340/2179502
        }
        if (!!clinic.phone) {
          next.phone = clinic.phone
        }
        const res: any = await API.graphql({
          query: updateClinic,
          variables: { input: next },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('Clinic updated to', res?.data?.updateClinic)
        return res
      }
    } catch (err) {
      log('error on persist clinic: ', err)
      return err
    }
  }

  public async remove(clinic: Clinic) {
    try {
      const input: DeleteClinicInput = {
        id: clinic.id,
      }
      const res: any = await API.graphql({
        query: deleteClinic,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return res?.data?.deleteClinic
    } catch (err) {
      log('error on remove clinic: ', err)
      return err
    }
  }

  public async publicList(status: ClinicStatus, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const variables = {
        status,
        sortDirection: ModelSortDirection.DESC,
      }
      const res: any = await API.graphql({
        query: findClinicByStatus,
        variables,
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicByStatus?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn(err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async publicFind(publicId: string, authStatus: AuthStatus): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findClinicByPublicId,
        variables: {
          publicId: publicId?.toLowerCase(),
        },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicByPublicId?.items?.[0], errorMessage: res.errors?.[0]?.message }
    } catch (err) {
      warn('error find', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ClinicService {
    if (!this.service) {
      this.service = new ClinicService()
    }
    return this.service
  }
}
