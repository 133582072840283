import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { CreateReferredInput, ModelReferredFilterInput, ModelSortDirection, UpdateReferredInput } from '../../API'
import { countReferredsByClinicPublicId, countReferredsByDoctorPublicId } from '../../graphql-custom/optimized/queries'
import { createReferred, updateReferred } from '../../graphql/mutations'
import { findReferredsByClinicPublicId, findReferredsByDoctorPublicId } from '../../graphql/queries'
import Referral from '../../pages/referring/Referral.model'
import { AuthStatus } from '../util/AuthStatus'
import { log } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ReferredService {
  private static ReferredService: ReferredService | undefined

  public storeReferralForm = async (referral: Referral, authStatus: AuthStatus): Promise<TbnResponse> => {
    try {
      log('Referral is going to be persisted', referral)

      const refferred: CreateReferredInput = {
        ...referral,
        givenName: referral.givenName || '',
      }
      const res: any = await API.graphql({
        query: createReferred,
        variables: {
          input: refferred,
        },
        authMode:
          authStatus !== AuthStatus.AUTHENTICATED
            ? GRAPHQL_AUTH_MODE.AWS_IAM
            : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createReferred, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByDoctorPublicId(doctorPublicId: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findReferredsByDoctorPublicId,
        variables: {
          doctorPublicId,
          sortDirection: ModelSortDirection.DESC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findReferredsByDoctorPublicId?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByClinicPublicId(clinicPublicId: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findReferredsByClinicPublicId,
        variables: {
          clinicPublicId,
          sortDirection: ModelSortDirection.DESC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findReferredsByClinicPublicId?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async countUnreadByDoctorPublicId(doctorPublicId: string): Promise<TbnResponse> {
    try {
      const filter: ModelReferredFilterInput = {
        readByDoctor: { ne: true },
      }
      const res: any = await API.graphql({
        query: countReferredsByDoctorPublicId,
        variables: {
          doctorPublicId,
          sortDirection: ModelSortDirection.DESC,
          filter,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findReferredsByDoctorPublicId?.items?.length, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async countUnreadByClinicPublicId(clinicPublicId: string): Promise<TbnResponse> {
    try {
      const filter: ModelReferredFilterInput = {
        readByClinic: { ne: true },
      }
      const res: any = await API.graphql({
        query: countReferredsByClinicPublicId,
        variables: {
          clinicPublicId,
          sortDirection: ModelSortDirection.DESC,
          filter,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findReferredsByClinicPublicId?.items?.length, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async changeDoctorReadStatus(id: string, readByDoctor: boolean): Promise<TbnResponse> {
    try {
      const input: UpdateReferredInput = {
        id,
        readByDoctor,
      }
      const res: any = await API.graphql({
        query: updateReferred,
        variables: {
          input,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateReferred, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async changeClinicReadStatus(id: string, readByClinic: boolean): Promise<TbnResponse> {
    try {
      const input: UpdateReferredInput = {
        id,
        readByClinic,
      }
      const res: any = await API.graphql({
        query: updateReferred,
        variables: {
          input,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateReferred, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ReferredService {
    if (!this.ReferredService) {
      this.ReferredService = new ReferredService()
    }
    return this.ReferredService
  }
}
