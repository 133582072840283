import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonTextarea, isPlatform } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { checkmark } from 'ionicons/icons'
import { Patient } from '../../../API'
import { isBooking, isPatient } from '../../../components/models/Model'
import { isMobile } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setBooking, setPatientHealth } from '../../../data/user/user.actions'
import Booking from '../../booking/Booking.model'

interface DispatchProps {
  setBooking: typeof setBooking
  setPatientHealth: typeof setPatientHealth
}
interface OwnProps {
  id: string
  label: string
  alter?: string
  model: Booking | Patient
  rows?: number
  required?: boolean
  readonly?: boolean
  swiper?: SwiperCore
  paramElse?: string | null
}
interface QuestionTextProps extends OwnProps, DispatchProps {}
const QuestionText: FC<QuestionTextProps> = ({
  id,
  label,
  alter,
  model,
  required = false,
  readonly = false,
  setBooking,
  setPatientHealth,
  swiper,
  rows = 1,
  paramElse,
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen()
  const [hideLabel, setHideLabel] = useState(isKeyboardOpen)
  const inputRef = useRef<HTMLIonInputElement | any>()

  useEffect(() => {
    if (isKeyboardOpen && isPlatform('android')) {
      setHideLabel(true)
    } else {
      setHideLabel(false)
    }
  }, [isKeyboardOpen]) // eslint-disable-line

  const onKeyUpEvent = (e: any) => {
    if (e?.key === 'Enter' && isMobile()) {
      e?.target?.blur()
    }
  }

  const setModel = (id: string, value: string) => {
    if (!readonly) {
      if (isBooking(model)) {
        setBooking({ ...model, [id]: value })
      } else if (isPatient(model)) {
        setPatientHealth({ ...model, [id]: value })
      }
    }
  }

  return (
    <>
      <IonItem key={label + id}>
        {!hideLabel && (
          <IonLabel position='floating' className={`ion-text-wrap ${required ? ' required' : ''}`}>
            {!paramElse &&
              label.split(' ').map((str) => {
                if (str.startsWith('{')) {
                  return (model as any)?.[str.replace(/[{}]/g, '')]
                } else {
                  return str + ' '
                }
              })}
            {!!paramElse &&
              alter?.split(' ').map((str) => {
                if (str.startsWith('{')) {
                  return (model as any)?.[str.replace(/[{}]/g, '')]
                } else {
                  return str + ' '
                }
              })}
          </IonLabel>
        )}
        <IonTextarea
          value={(model as any)?.[id]}
          onIonChange={(e: any) => setModel(id, e.detail.value)}
          placeholder={'Type your answer here...'}
          onIonFocus={() => {
            if (isPlatform('android')) {
              setHideLabel(true)
            }
          }}
          onIonBlur={() => {
            setHideLabel(false)
          }}
          onKeyUp={onKeyUpEvent}
          ref={inputRef}
          rows={rows}
          readonly={readonly}
          autoGrow={true}
          wrap={'soft'}
        ></IonTextarea>
      </IonItem>
      {!hideLabel && !!swiper && (
        <>
          <IonButton
            color='primary'
            disabled={required ? !(model as any)?.[id] : false}
            onClick={() => swiper?.slideNext()}
          >
            OK
            <IonIcon icon={checkmark} slot='end' />
          </IonButton>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
    setPatientHealth,
  },
  component: QuestionText,
})
