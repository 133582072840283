import { FC, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import MapNumberModalForm from './MapNumberModalForm'

interface OwnProps {
  text?: string
  onAddSuccess: (event: any) => void
}
interface MapNumberAddButtonProps extends OwnProps {}
const MapNumberAddButton: FC<MapNumberAddButtonProps> = ({ text, onAddSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonButton onClick={() => setShowModal(true)}>
        {text}
        <IonIcon icon={add} slot={!!text ? 'start' : 'icon-only'} />
      </IonButton>
      <MapNumberModalForm
        isOpen={showModal}
        onDidDismiss={(e) => {
          setShowModal(false)
          onAddSuccess(e?.detail?.data)
        }}
      />
    </>
  )
}

export default MapNumberAddButton
