import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ClinicDoctor, DeleteClinicDoctorInput, ModelClinicDoctorFilterInput } from '../../API'
import { createClinicDoctor, deleteClinicDoctor } from '../../graphql/mutations'
import { findClinicDoctorByDoctorId, listClinicDoctors } from '../../graphql/queries'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ClinicDoctorService {
  private static service: ClinicDoctorService | undefined

  public async list(filter: ModelClinicDoctorFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listClinicDoctors,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listClinicDoctors?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching ClinicDoctors: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(doctorID: string, clinicID: string): Promise<TbnResponse> {
    try {
      const filter: ModelClinicDoctorFilterInput = {
        clinicID: { eq: clinicID },
      }
      const res: any = await API.graphql({
        query: findClinicDoctorByDoctorId,
        variables: {
          doctorID,
          filter,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicDoctorByDoctorId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(clinicDoctor: ClinicDoctor): Promise<TbnResponse> {
    try {
      const existed: TbnResponse = await this.find(clinicDoctor.doctorID, clinicDoctor.clinicID)
      if (!!existed.data) return existed
      const res: any = await API.graphql({
        query: createClinicDoctor,
        variables: { input: clinicDoctor },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createClinicDoctor, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(clinicDoctor: ClinicDoctor): Promise<TbnResponse> {
    try {
      const input: DeleteClinicDoctorInput = {
        id: clinicDoctor.id,
      }
      const res: any = await API.graphql({
        query: deleteClinicDoctor,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteClinicDoctor, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on remove: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ClinicDoctorService {
    if (!this.service) {
      this.service = new ClinicDoctorService()
    }
    return this.service
  }
}
