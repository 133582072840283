import { CreateCannabisProductInput, CreateMedicalAuthorisedPrescriberInput } from '../../API'
import { CannabisProductService } from './CannabisProductService'
import { MedicalAuthorisedPrescriberService } from './MedicalAuthorisedPrescriberService'
import { ProductMapService } from './ProductMapService'
import { TbnResponse } from './TbnResponse'

export class MapImportService {
  private static service: MapImportService | undefined

  public async import(
    doctorID: string,
    mapCmd: CreateMedicalAuthorisedPrescriberInput,
    productCmd: CreateCannabisProductInput,
  ): Promise<TbnResponse> {
    try {
      const mapRes: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.import(mapCmd)
      const productRes: TbnResponse = await CannabisProductService.Instance.import(productCmd)
      //log(doctorID, productRes?.data?.name, productRes?.data?.id, mapRes?.data?.id)
      const productMapRes: TbnResponse = await ProductMapService.Instance.import({
        doctorID,
        productID: productRes?.data?.id,
        mapID: mapRes?.data?.id,
      })
      return { data: productMapRes?.data }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): MapImportService {
    if (!this.service) {
      this.service = new MapImportService()
    }
    return this.service
  }
}
