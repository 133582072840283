import { FC } from 'react'
import { FaLeaf, FaCapsules, FaFlask, FaSprayCan, FaUnlink } from 'react-icons/fa'
import { ProductPresentation } from '../../API'

interface ProductPresentationIconProps {
  pp?: ProductPresentation | null
}
const ProductPresentationIcon: FC<ProductPresentationIconProps> = ({ pp }) => {
  const resolveIcon = () => {
    switch (pp) {
      case ProductPresentation.CAPSULE:
        return <FaCapsules />
      case ProductPresentation.DRIED_HERB:
        return <FaLeaf />
      case ProductPresentation.ORAL_LIQUID:
        return <FaFlask />
      case ProductPresentation.SPRAY_SOLUTION:
        return <FaSprayCan />
    }
    return <FaUnlink />
  }

  return <>{resolveIcon()}</>
}

export default ProductPresentationIcon
