import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonInput, IonRouterLink } from '@ionic/react'
import { IonItem, IonLabel, IonRow, IonText } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { calendar, documentOutline, refresh, repeat } from 'ionicons/icons'
import { Clinic, ModelSortDirection, ModelTypeFormEventFilterInput, TypeFormEvent } from '../../API'
import appointmentSubjectLabel from '../../components/models/AppointmentSubjectLabel'
import AppointmentTypeIcon from '../../components/models/AppointmentTypeIcon'
import { defaultTimezone, utcToLocale } from '../../components/util/Date'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import { findTypeFormEventsByClinicPublicId } from '../../graphql/queries'
import AnswerModal from '../doctor/AnswerModal'
import PageNavigate from '../doctor/PageNavigate'
import ReceptionContainer from './ReceptionContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ReceptionBookingsProps extends OwnProps, StateProps, DispatchProps {}
const ReceptionBookings: FC<ReceptionBookingsProps> = ({ setNet, net, history }) => {
  const [items, setItems] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState<any>()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const limit = 20
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length
  const [clinic, setClinic] = useState<Clinic>()
  const [givenName, setGivenName] = useState<string | null>()
  const [phone, setPhone] = useState<string | null>()

  const fetchItems = async () => {
    setNet(true)
    const filter: ModelTypeFormEventFilterInput = {}
    if (!!givenName) {
      filter.firstName = { contains: givenName }
    }
    if (!!phone) {
      filter.phone = { contains: phone }
    }
    const variables = {
      nextToken,
      limit,
      clinicPublicId: clinic?.publicId,
      filter,
      sortDirection: ModelSortDirection.DESC,
    }
    try {
      const res: any = await API.graphql({
        query: findTypeFormEventsByClinicPublicId,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItems(res?.data?.findTypeFormEventsByClinicPublicId?.items || [])
      setNextNextToken(res?.data?.findTypeFormEventsByClinicPublicId?.nextToken)
    } catch (ignore) {}
    setNet(false)
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  const resolveDate = (itm: any) => {
    return !!itm.startTime ? `${utcToLocale(itm.startTime)} (${defaultTimezone()})` : 'null'
  }

  useEffect(() => {
    if (!!clinic?.id) fetchItems()
  }, [nextToken, clinic?.id, givenName, phone]) // eslint-disable-line

  return (
    <ReceptionContainer
      id='reception-bookings'
      title={'Bookings'}
      onClinicLoaded={setClinic}
      actionButtonsEnd={
        <IonButton
          onClick={() => {
            reset()
            if (!!clinic?.id) {
              fetchItems()
            }
          }}
          disabled={net}
        >
          <IonIcon icon={refresh} slot='icon-only' />
        </IonButton>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position='floating'>Given name</IonLabel>
              <IonInput value={givenName} onIonChange={(e) => setGivenName(e.detail.value)} debounce={1000} />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position='floating'>Phone</IonLabel>
              <IonInput value={phone} onIonChange={(e) => setPhone(e.detail.value)} debounce={1000} />
            </IonItem>
          </IonCol>
        </IonRow>
        {items.map((itm: TypeFormEvent) => (
          <IonItem key={itm.id} button>
            <AppointmentTypeIcon item={itm} />
            <IonRouterLink color={'dark'} routerLink={`/r/appointment/${itm.id}`}>
              <IonLabel>
                {itm.firstName} {itm?.patient?.surName}, {itm.phone} {itm.email} -{' '}
                {appointmentSubjectLabel(itm.definitionSubject)} with Dr {itm?.definitionDoctorName}
                <p>
                  <IonText color={itm.canceled ? 'danger' : ''}>{resolveDate(itm)}</IonText>
                  {itm.rescheduled && (
                    <IonText color='warning' className='ion-margin-horizontal'>
                      Rescheduled
                    </IonText>
                  )}
                  {itm.canceled && (
                    <IonText color='danger' className='ion-margin-horizontal'>
                      Canceled: {itm.cancelReason}
                    </IonText>
                  )}
                </p>
              </IonLabel>
            </IonRouterLink>
            <IonButtons slot='end'>
              {false && (
                <IonButton
                  fill='clear'
                  onClick={() => {
                    setShowModal(false)
                    setItem(null)
                    setTimeout(() => {
                      setItem(itm)
                      setShowModal(true)
                    }, 0)
                  }}
                >
                  <IonIcon icon={documentOutline} slot='icon-only'></IonIcon>
                </IonButton>
              )}
              <IonButton
                fill='clear'
                onClick={() =>
                  window.open(itm?.bookingUrl?.replace('https://api.calendly.com', 'https://calendly.com'), '_blank')
                }
              >
                <IonIcon icon={calendar} slot='icon-only'></IonIcon>
              </IonButton>
              <IonButton fill='clear' onClick={() => history.push(`/book/fast/${itm.id}`)}>
                <IonIcon icon={repeat} slot='icon-only'></IonIcon>
              </IonButton>
            </IonButtons>
          </IonItem>
        ))}
        <IonRow>
          <IonCol>
            <PageNavigate {...{ hasNext, hasPrev, prev, next, loading: net }} />
          </IonCol>
        </IonRow>
      </IonGrid>
      {!!item && <AnswerModal model={item} showModal={showModal} setShowModal={setShowModal} />}
    </ReceptionContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: ReceptionBookings,
})
