import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Clinic } from '../../API'
import { ClinicService } from '../services/ClinicService'
import './select.scss'

interface ClinicSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const ClinicSelect: FC<ClinicSelectProps> = ({ defaultValue, onChange }) => {
  const [options, setOptions] = useState()
  const [items, setItems] = useState<Clinic[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(items.find((e: Clinic) => e.id === itm.value))
  }

  const fetchOptions = async () => {
    const res: any = await ClinicService.Instance.getAll()
    if (res.data?.items) {
      setItems(res?.data?.items)
      setOptions(
        res.data?.items?.flatMap((itm: Clinic) => {
          return { value: itm.id, label: `${itm.name} (${itm.publicId})` }
        }),
      )
    }
  }

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  return <Select value={selectedOption} options={options} onChange={handleChange} />
}

export default ClinicSelect
