import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { IonText } from '@ionic/react'
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { refresh } from 'ionicons/icons'
import { AppointmentDefinition, Clinic, ClinicStatus, Doctor } from '../../API'
import ClinicStatusComp from '../../components/models/ClinicStatus'
import TbnAvatar from '../../components/models/TbnAvatar'
import { AppointmentDefinitionService } from '../../components/services/AppointmentDefinitionService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { AuthStatus } from '../../components/util/AuthStatus'
import TelSpinner from '../../components/util/TelSpinner'
import { isDesktop, isMobile } from '../../components/util/isMobile'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import PageContainer from '../PageContainer'
import AvailableBookingsComponent from './AvailableBookingsComponent'
import './DoctorPublicPage.scss'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface DoctorPublicPageProps extends OwnProps, StateProps, DispatchProps {}

const DoctorPublicPage: FC<DoctorPublicPageProps> = ({ net, setNet, history }) => {
  const { drPublicId } = useParams<any>()
  const [doctor, setDoctor] = useState<Doctor>()
  const [apptDefs, setApptDefs] = useState<AppointmentDefinition[]>([])
  const [error, setError] = useState<string>() // eslint-disable-line
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [clinicPublicId, setClinicPublicId] = useState<string>()
  const [clinics, setClinics] = useState<Clinic[]>([])

  const loadApptDefs = async (drPublicId: string) => {
    if (apptDefs.length > 0) {
      return
    }
    setNet(true)
    const res: TbnResponse = await AppointmentDefinitionService.Instance.publicFindByDoctor(
      drPublicId,
      authStatus as AuthStatus,
    )
    if (res.data) {
      setApptDefs(res.data?.filter((itm: any) => itm.active))
      let cls: Clinic[] = []
      for (let itm of res.data) {
        if (!cls.find((c: Clinic) => c.publicId === itm.clinicPublicId) && itm?.clinicStatus === ClinicStatus.ACTIVE) {
          cls.push({
            publicId: itm.clinicPublicId,
            name: itm.clinicName,
            formerlyKnownAs: itm.clinicFormerlyKnownAs,
            logoImage: itm.clinicLogoImage,
            status: itm.clinicStatus,
          } as Clinic)
        }
        setDoctor({
          drId: itm.drPublicId,
          drName: itm.drName,
          avatar: itm.drAvatar,
        } as any)
      }
      if (!clinicPublicId) {
        setClinicPublicId(cls?.[0]?.publicId)
      }
      setClinics(cls)
    }
    setNet(false)
  }

  useEffect(() => {
    if (!!drPublicId) loadApptDefs(drPublicId)
  }, [authStatus, drPublicId]) // eslint-disable-line

  return (
    <PageContainer
      id='doctor-public-page'
      isPrivate={false}
      padding={true}
      titlePadding={isDesktop()}
      title={
        <>
          {isMobile() ? 'Appt' : 'Appointment'} with <IonText color='tertiary'>Dr {doctor?.drName}</IonText>
        </>
      }
      actionButtonsEnd={
        <>
          {!doctor && (
            <IonButton onClick={() => loadApptDefs(drPublicId)} disabled={net} color={'primary'}>
              <IonIcon icon={refresh} />
            </IonButton>
          )}
          {!!doctor && <TbnAvatar avatar={doctor?.avatar} width={'50px'} />}
        </>
      }
    >
      {net && <TelSpinner />}
      {!!error && (
        <>
          <h6>Something went wrong, We apologize for any inconvenience.</h6>
          <IonButton className='ion-padding' onClick={() => window.location.reload()}>
            {'Refresh page'}
          </IonButton>
          <br />
          <br />
          <details style={{ whiteSpace: 'pre-wrap' }}>{error}</details>
        </>
      )}
      {!net && !error && clinics?.length > 0 && (
        <>
          <IonList>
            <IonRadioGroup value={clinicPublicId} onIonChange={(e: any) => setClinicPublicId(e.detail.value)}>
              {apptDefs.length > 0 &&
                clinics.map((c: Clinic) => (
                  <IonItem key={c.publicId} mode='ios'>
                    <ClinicStatusComp clinic={c} />
                    <IonLabel>{c.name}</IonLabel>
                    <IonRadio slot='end' value={c.publicId}></IonRadio>
                  </IonItem>
                ))}
            </IonRadioGroup>
          </IonList>
          <AvailableBookingsComponent
            apptDefs={apptDefs}
            clinicPublicId={clinicPublicId}
            drPublicId={doctor?.drId}
            history={history}
          />
        </>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: DoctorPublicPage,
})
