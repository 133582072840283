import { useState } from 'react'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { Doctor } from '../../../API'
import TbnSpinner from '../../../components/util/TbnSpinner'
import CannabisProductList from '../../admin/cannabisProducts/CannabisProductList'
import MapNumbersComponent from '../../admin/medicalAuthorisedPrescriber/MapNumbersComponent'
import DoctorContainer from '../../doctor/DoctorContainer'
import ScriptReceiverComponent from '../../reception/scriptreceiver/ScriptReceiverComponent'
import Tga6Report from './Tga6Report'

const TgaReportingTools = () => {
  const [doctor, setDoctor] = useState<Doctor>()

  return (
    <DoctorContainer
      id='tga-reporting-tools'
      title='Prescribing and TGA report tools'
      doctorLoaded={(doc: Doctor) => setDoctor(doc)}
    >
      {!doctor && <TbnSpinner />}
      {!!doctor && (
        <Tabs spacing='equal'>
          <TabItem title='Map Numpers'>
            <MapNumbersComponent doctor={doctor} />
          </TabItem>
          <TabItem title='Plant based products'>
            <CannabisProductList />
          </TabItem>
          <TabItem title='TGA 6 report'>
            <Tga6Report doctor={doctor} />
          </TabItem>
          <TabItem title='Script receivers'>
            <ScriptReceiverComponent doctor={doctor} />
          </TabItem>
        </Tabs>
      )}
    </DoctorContainer>
  )
}

export default TgaReportingTools
