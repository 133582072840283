import { FC, useState } from 'react'
import Select from 'react-select'
import { AppointmentSubject } from '../../API'
import './select.scss'

interface AppointmentSubjectSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const AppointmentSubjectSelect: FC<AppointmentSubjectSelectProps> = ({ defaultValue, onChange }) => {
  const options: any = [
    { value: AppointmentSubject.INITIAL_CONSULTATION.toString(), label: 'Initial Consultation' },
    { value: AppointmentSubject.FIRST_FOLLOW_UP.toString(), label: 'First follow up' },
    { value: AppointmentSubject.LONG_FOLLOW_UP.toString(), label: 'Long follow up' },
    { value: AppointmentSubject.USUAL_FOLLOW_UP.toString(), label: 'Usual follow up' },
  ]
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(itm)
  }

  return <Select value={selectedOption} options={options} onChange={handleChange} />
}

export default AppointmentSubjectSelect
