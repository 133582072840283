import { useState, useEffect, FC } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonSpinner, IonBackButton } from '@ionic/react'
import { IonItem, IonButtons, IonMenuButton } from '@ionic/react'
import { IonPopover, IonContent } from '@ionic/react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { isDoctor } from '../../components/util/Auth'
import { connect } from '../../data'

interface OwnProps {
  title?: string
  titlePadding?: boolean
  hideBackButton?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
}
interface StateProps {
  darkMode: boolean
  net: boolean
}
interface DispatchProps {}
interface HeaderProps extends OwnProps, StateProps, DispatchProps {}
const Header: FC<HeaderProps> = ({
  darkMode,
  title,
  titlePadding = true,
  net,
  hideBackButton = true,
  actionButtonsStart,
  actionButtonsEnd,
}) => {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<any | undefined>({})
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  const isInDoctorGroup = () => {
    return isDoctor(user)
  }

  return (
    <IonHeader>
      <IonToolbar color={darkMode ? '' : 'third'}>
        <IonButtons slot='start'>
          <IonMenuButton />
          {!hideBackButton && <IonBackButton />}
          {actionButtonsStart}
        </IonButtons>
        <IonButtons slot='end'>
          {net && <IonSpinner name='dots' className='ion-margin-end' />}
          {actionButtonsEnd}
        </IonButtons>
        <IonTitle className={titlePadding ? '' : 'ion-no-padding'}>{!!title ? title : 'Telebinoid'}</IonTitle>
      </IonToolbar>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        dismissOnSelect={true}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonContent>
          <IonItem button routerLink={`/dr/nas`}>
            Book an appointment
          </IonItem>
          <IonItem button routerLink='/profile'>
            {authState === AuthState.SignedIn ? 'Profile' : 'Sign in'}
          </IonItem>
          {isInDoctorGroup() && <IonItem routerLink='/appointments'>Appointments</IonItem>}
        </IonContent>
      </IonPopover>
    </IonHeader>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    net: state.user.net,
  }),
  component: Header,
})
