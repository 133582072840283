import { QuizType } from '../../API'

export const quizName = (quizType?: QuizType | null) => {
  if (!!quizType) {
    switch (quizType) {
      case QuizType.CHRONIC_PAIN:
        return 'Chronic pain'
      case QuizType.DASS_21:
        return 'DASS-21'
      case QuizType.MIDAS:
        return 'MIDAS'
    }
  }
}
