import { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonGrid, IonIcon, useIonAlert } from '@ionic/react'
import { IonRow, IonText, IonToggle } from '@ionic/react'
import { IonItem, IonLabel, IonSpinner } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { call, trashBin } from 'ionicons/icons'
import { AppointmentDefinition, AppointmentType } from '../../../API'
import appointmentSubjectLabel from '../../../components/models/AppointmentSubjectLabel'
import ClinicStatusComp from '../../../components/models/ClinicStatus'
import { AppointmentDefinitionService } from '../../../components/services/AppointmentDefinitionService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { isTechAdmin } from '../../../components/util/Auth'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import PageNavigate from '../../doctor/PageNavigate'

interface OwnProps {
  doctorID: string
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AppointmentDefinitionListProps extends OwnProps, StateProps, DispatchProps {}
const AppointmentDefinitionList: FC<AppointmentDefinitionListProps> = ({ setNet, net, doctorID }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [activating, setActivating] = useState(false)
  const [present] = useIonToast()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const [presentAlert] = useIonAlert()
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length
  const [toggleAll, setToggleAll] = useState(false)

  const fetchItems = async () => {
    setLoading(true)
    try {
      const res: TbnResponse = await AppointmentDefinitionService.Instance.findByDoctor(doctorID)
      setItems(res?.data?.items || [])
      setNextNextToken(res?.data?.nextToken)
      setLoading(false)
    } catch (err: any) {
      present({ message: err?.errors[0]?.message, color: 'danger', duration: 3000 })
      setLoading(false)
      setItems([])
    }
  }

  const deleteAD = async (ad: AppointmentDefinition) => {
    setLoading(true)
    setItems([])
    await AppointmentDefinitionService.Instance.remove(ad)
    await fetchItems()
  }

  const toggleActive = async (ad: AppointmentDefinition, active: boolean) => {
    if (active !== undefined) {
      ad.active = active
      setActivating(true)
      await AppointmentDefinitionService.Instance.update({ ...ad, active })
      setActivating(false)
    }
  }

  const toggleAllTrigger = async (toggle: boolean) => {
    if (toggleAll !== toggle) {
      setActivating(true)
      console.log('toggle all triggered', toggle)
      const persistOperations: any[] = []
      for (let itm of items) {
        const apt: AppointmentDefinition = { ...(itm as AppointmentDefinition), active: toggle }
        persistOperations.push(AppointmentDefinitionService.Instance.update(apt))
      }
      await Promise.allSettled(persistOperations)
      await fetchItems()
      setActivating(false)
    }
  }

  const deleteAll = async () => {
    setActivating(true)
    await AppointmentDefinitionService.Instance.removeAll(doctorID)
    await reset()
    await fetchItems()
    setActivating(false)
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  // eslint-disable-next-line
  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  useEffect(() => {
    fetchItems()
  }, [nextToken]) // eslint-disable-line

  return (
    <IonGrid>
      {items.map((itm: AppointmentDefinition) => (
        <IonItem key={itm.id}>
          <IonLabel color={!itm?.active ? 'medium' : ''}>
            <span>{appointmentSubjectLabel(itm?.appointmentSubject)}</span>
            <p>
              <span>
                {itm?.calendlyAppointment?.slug} ({itm?.calendlyAppointment?.duration}m)
              </span>
              {itm?.clinic?.name && (
                <IonText className='ion-padding-start' color='tertiary'>
                  {itm?.clinic?.name}
                </IonText>
              )}
            </p>
          </IonLabel>
          {itm.appointmentType === AppointmentType.TELEHEALTH && (
            <IonIcon className='ion-margin-start' icon={call} slot='start' color={!itm?.active ? 'medium' : ''} />
          )}
          {itm.appointmentType === AppointmentType.IN_PERSON && (
            <ClinicStatusComp clinic={itm?.clinic} activeOverride={!itm?.active} />
          )}
          <IonToggle
            defaultChecked={itm.active || undefined}
            checked={itm.active || undefined}
            slot='end'
            onIonChange={(e) => toggleActive(itm, e.detail.checked)}
            disabled={activating}
          />
          <IonButton
            fill='clear'
            color='danger'
            onClick={() => {
              presentAlert({
                header: `Are you sure?`,
                subHeader: 'After delete definitions are not retrievable.',
                message: appointmentSubjectLabel(itm?.appointmentSubject),
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {},
                  },
                  {
                    text: 'Delete',
                    role: 'destructive',
                    cssClass: 'alert-button-delete',
                    handler: () => {
                      deleteAD(itm)
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) => {},
              })
            }}
            slot='end'
          >
            <IonIcon icon={trashBin} slot='icon-only' />
          </IonButton>
        </IonItem>
      ))}
      {loading && (
        <div className='ion-text-center'>
          <IonSpinner style={{ position: 'fixed' }} />
        </div>
      )}
      {hasNext && (
        <IonRow>
          <IonCol>
            <PageNavigate {...{ hasNext, hasPrev, prev, next, loading }} />
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol className='ion-text-end'>
          {isTechAdmin(user) && items.length > 0 && (
            <IonButton color='danger' onClick={deleteAll} disabled={activating}>
              <IonIcon icon={trashBin} slot='start' />
              Delete all
            </IonButton>
          )}
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>Toggle all</IonLabel>
            <IonToggle
              defaultChecked={toggleAll}
              checked={toggleAll}
              slot='end'
              onIonChange={(e) => {
                setToggleAll(e.detail.checked)
                toggleAllTrigger(e.detail.checked)
              }}
              disabled={activating}
            />
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: AppointmentDefinitionList,
})
