import { FC, useEffect, useState } from 'react'
import { IonButton, IonChip, IonCol, IonGrid, IonInput, IonItem, IonSpinner, IonText } from '@ionic/react'
import { IonLabel, IonRadio, IonRadioGroup, IonRow, IonTextarea, useIonToast } from '@ionic/react'
import { TabItem, Tabs, useAuthenticator } from '@aws-amplify/ui-react'
import { Clinic, ClinicStatus, Doctor, DoctorStatus } from '../../API'
import DateOfBirth from '../../components/form/DateOfBirth'
import ClinicStatusComp from '../../components/models/ClinicStatus'
import TbnAvatar from '../../components/models/TbnAvatar'
import { ClinicService } from '../../components/services/ClinicService'
import { DoctorService } from '../../components/services/DoctorService'
import { ReferredService } from '../../components/services/ReferredService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { AuthStatus } from '../../components/util/AuthStatus'
import capitalizeFirstName from '../../components/util/CapitalizeFirstName'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import { setReferral } from '../../data/user/user.actions'
import Referral from './Referral.model'

interface OwnProps {
  defaultDoctorPublicId?: string
  defaultClinicPublicId?: string
}
interface StateProps {
  referral: Referral
}
interface DispatchProps {
  setReferral: typeof setReferral
}
interface ReferAPatientProps extends OwnProps, StateProps, DispatchProps {}

const ReferAPatient: FC<ReferAPatientProps> = ({
  defaultDoctorPublicId,
  defaultClinicPublicId,
  referral,
  setReferral,
}) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [referType, setReferType] = useState<number | undefined>(0) // 0=ELSE 1=SELF
  const [referToType, setReferToType] = useState<number | undefined>(referral.referToType) // 0=Clinic 1=Doctor
  const [clinicPublicId, setClinicPublicId] = useState(defaultClinicPublicId)
  const [doctorPublicId, setDoctorPublicId] = useState(defaultDoctorPublicId)
  const [border1, setBorder1] = useState(false)
  const [border2, setBorder2] = useState(false)
  const [border3, setBorder3] = useState(false)
  const [border4, setBorder4] = useState(false)
  const [border5, setBorder5] = useState(false)
  const [clinics, setClinics] = useState<Clinic[]>([])
  const [doctors, setDoctors] = useState<Doctor[]>([])
  const [doctor, setDoctor] = useState<Doctor>()
  const [clinic, setClinic] = useState<Clinic>()
  const [presentToast] = useIonToast()
  const [original, setOriginal] = useState<Referral>({})
  const [saving, setSaving] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)

  const persist = async () => {
    setSaving(true)
    const res: TbnResponse = await ReferredService.Instance.storeReferralForm(
      { ...referral, clinicPublicId, doctorPublicId },
      authStatus as AuthStatus,
    )
    if (res.data) {
      setSuccess(true)
      await setReferral({})
      await setOriginal({})
    } else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setSaving(false)
  }

  const setProp = async (prop: string, value: any) => {
    await setReferral({ ...original, ...referral, [prop]: value })
  }

  const fetchClinics = async () => {
    setLoading(true)
    const res: TbnResponse = await ClinicService.Instance.publicList(ClinicStatus.ACTIVE, authStatus as AuthStatus)
    if (res.data) setClinics(res.data)
    setLoading(false)
  }

  const fetchClinic = async (drId: string) => {
    setLoading(true)
    const res: TbnResponse = await ClinicService.Instance.publicFind(drId, authStatus as AuthStatus)
    if (res.data) setClinic(res.data)
    setLoading(false)
  }

  const fetchDoctors = async () => {
    setLoading(true)
    const res: TbnResponse = await DoctorService.Instance.publicList(DoctorStatus.ACTIVE, authStatus as AuthStatus)
    if (res.data) setDoctors(res.data)
    setLoading(false)
  }

  const fetchDoctor = async (drId: string) => {
    setLoading(true)
    const res: TbnResponse = await DoctorService.Instance.publicFind(drId, authStatus as AuthStatus)
    if (res.data) setDoctor(res.data)
    setLoading(false)
  }

  const isInValid = () => {
    return (
      !referral.givenName ||
      !referral.surName ||
      !referral.phone ||
      (!doctorPublicId && !clinicPublicId) ||
      (referType === 0 && !referral.practitionerFirstName) ||
      (referType === 1 && !referral.emergency) ||
      (referType === 1 && !referral.emergencyRelation) ||
      (referType === 1 && !referral.emergencyRelation) ||
      (referType === 1 && !referral.selfMedicated) ||
      (referType === 1 && !referral.kidneyLiver)
    )
  }

  useEffect(() => {
    if (!defaultDoctorPublicId) setDoctorPublicId(undefined)
    if (!defaultClinicPublicId) setClinicPublicId(undefined)
  }, [referToType]) // eslint-disable-line

  useEffect(() => {
    if (!!referral && Object.keys(original).length === 0) {
      setOriginal(referral)
    }
  }, [referral]) // eslint-disable-line

  useEffect(() => {
    if (Object.keys(original).length !== 0) setReferral(original)
  }, [original]) // eslint-disable-line

  useEffect(() => {
    if (!defaultClinicPublicId) fetchClinics()
    if (!defaultDoctorPublicId) fetchDoctors()
  }, [authStatus]) // eslint-disable-line

  useEffect(() => {
    if (!!defaultClinicPublicId) fetchClinic(defaultClinicPublicId)
  }, [defaultClinicPublicId, authStatus]) // eslint-disable-line

  useEffect(() => {
    if (!!defaultDoctorPublicId) fetchDoctor(defaultDoctorPublicId)
  }, [defaultDoctorPublicId, authStatus]) // eslint-disable-line

  return (
    <>
      {success && (
        <>
          <IonChip color='success'>Referral form submitted successfully.</IonChip>
          <br />
          <br />
          <IonButton color='light' onClick={() => setSuccess(false)}>
            Back to Referral form
          </IonButton>
        </>
      )}
      {!success && (
        <IonGrid>
          {loading && <IonSpinner />}
          {!defaultClinicPublicId && !defaultDoctorPublicId && (
            <IonRow>
              <IonCol>
                <Tabs currentIndex={referToType} onChange={(tabIndex: any) => setReferToType(+tabIndex)}>
                  <TabItem title='Choose a Clinic'>
                    <IonRadioGroup value={clinicPublicId} onIonChange={(e: any) => setClinicPublicId(e.detail.value)}>
                      {clinics.map((c: Clinic) => (
                        <IonItem key={c.publicId} mode='ios'>
                          <ClinicStatusComp clinic={c} />
                          <IonLabel>{c.name}</IonLabel>
                          <IonRadio slot='end' value={c.publicId}></IonRadio>
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </TabItem>
                  <TabItem title='or a Doctor'>
                    <IonRadioGroup value={doctorPublicId} onIonChange={(e: any) => setDoctorPublicId(e.detail.value)}>
                      {doctors.map((d: Doctor) => (
                        <IonItem key={d.drId} mode='ios'>
                          <TbnAvatar avatar={d.avatar} width={'50px'} slot='start' />
                          <IonLabel>{d.drName}</IonLabel>
                          <IonRadio slot='end' value={d.drId}></IonRadio>
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </TabItem>
                </Tabs>
              </IonCol>
            </IonRow>
          )}
          {!!defaultClinicPublicId && !!clinic && (
            <IonRadioGroup value={clinic.publicId}>
              <IonItem key={clinic.publicId} mode='ios'>
                <ClinicStatusComp clinic={clinic} />
                <IonLabel>{clinic.name}</IonLabel>
                <IonRadio slot='end' value={clinic.publicId}></IonRadio>
              </IonItem>
            </IonRadioGroup>
          )}
          {!!defaultDoctorPublicId && !!doctor && (
            <IonRadioGroup value={doctor.drId}>
              <IonItem key={doctor.drId} mode='ios'>
                <TbnAvatar avatar={doctor.avatar} width={'50px'} slot='start' />
                <IonLabel>{doctor.drName}</IonLabel>
                <IonRadio slot='end' value={doctor.drId}></IonRadio>
              </IonItem>
            </IonRadioGroup>
          )}
          <Tabs currentIndex={referType} onChange={(tabIndex: any) => setReferType(+tabIndex)}>
            <TabItem title='Refer a patient (for health practitioners)'></TabItem>
            <TabItem title='Self referral (for patients)'></TabItem>
          </Tabs>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Given Name
            </IonLabel>
            <IonInput
              value={referral.givenName}
              onIonChange={(e: any) => setProp('givenName', capitalizeFirstName(e.detail.value))}
              placeholder={'type here...'}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Surname
            </IonLabel>
            <IonInput
              value={referral.surName}
              onIonChange={(e: any) => setProp('surName', e.detail.value)}
              placeholder={'type here...'}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{referType === 0 ? "Patient's " : ''} Address</IonLabel>
            <IonTextarea
              autoGrow={true}
              value={referral.addressOneLine}
              onIonChange={(e: any) => setProp('addressOneLine', e.detail.value)}
              placeholder={'type here...'}
            ></IonTextarea>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 ? "Patient's " : ''} Medicare, DVA or private health number
            </IonLabel>
            <IonInput
              value={referral.medicareNumber}
              onIonChange={(e) => setProp('medicareNumber', e.detail.value)}
              placeholder={'type here...'}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 ? "Patient's " : ''} IHI No.
            </IonLabel>
            <IonInput
              value={referral.ihiNo}
              onIonChange={(e) => setProp('ihiNo', e.detail.value)}
              placeholder={'type here...'}
            ></IonInput>
          </IonItem>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonLabel position='floating' className='ion-padding-start'>
                {referType === 0 ? "Patient's " : ''} Date of Birth
              </IonLabel>
              <IonItem className='ion-no-padding' id='date-of-birth-container'>
                <DateOfBirth
                  defaultValue={referral.dateOfBirth}
                  onValueChange={(value: any) => setProp('dateOfBirth', value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem>
            <IonLabel position='floating' className='required'>
              {referType === 0 ? "Patient's " : ''} Mobile
            </IonLabel>
            <IonInput
              value={referral.phone}
              onIonChange={(e: any) => setProp('phone', e.detail.value)}
              placeholder='04xxxxxxxx'
            ></IonInput>
          </IonItem>
          {referType === 1 && (
            <IonRow style={{ border: border1 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Next of Kin</IonLabel>
                  <IonInput
                    value={referral?.nextOfKin}
                    onIonChange={(e) => setProp('nextOfKin', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput
                    value={referral?.nextOfKinRelation}
                    onIonChange={(e) => setProp('nextOfKinRelation', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput
                    value={referral?.nextOfKinContact}
                    onIonChange={(e) => setProp('nextOfKinContact', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {referType === 1 && (
            <IonRow style={{ border: border2 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Power of Attorney</IonLabel>
                  <IonInput
                    value={referral?.powerOfAttorney}
                    onIonChange={(e) => setProp('powerOfAttorney', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput
                    value={referral?.powerOfAttorneyRelation}
                    onIonChange={(e) => setProp('powerOfAttorneyRelation', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput
                    value={referral?.powerOfAttorneyContact}
                    onIonChange={(e) => setProp('powerOfAttorneyContact', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          <IonRow style={{ border: border3 ? '1px solid #5FD0E7' : '' }}>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Parent/guardian/carer</IonLabel>
                <IonInput
                  value={referral.carer}
                  onIonChange={(e: any) => setProp('carer', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput
                  value={referral.carerRelation}
                  onIonChange={(e: any) => setProp('carerRelation', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput
                  value={referral.carerContact}
                  onIonChange={(e: any) => setProp('carerContact', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          {referType === 1 && (
            <IonRow style={{ border: border4 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Emergency Contact
                  </IonLabel>
                  <IonInput
                    value={referral?.emergency}
                    onIonChange={(e) => setProp('emergency', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Relationship
                  </IonLabel>
                  <IonInput
                    value={referral?.emergencyRelation}
                    onIonChange={(e) => setProp('emergencyRelation', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Contact Number
                  </IonLabel>
                  <IonInput
                    value={referral?.emergencyContact}
                    onIonChange={(e) => setProp('emergencyContact', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Presenting Symptoms (e.g. Pain/Sleep Issues/Spasms)
              </IonLabel>
              <IonTextarea
                autoGrow={true}
                value={referral.symptoms}
                onIonChange={(e: any) => setProp('symptoms', e.detail.value)}
                placeholder='type here...'
              />
            </IonItem>
          )}
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Stated Conditions/Diagnoses (e.g. Arthritis/Insomnia/MS)</span>}
              {referType === 1 && (
                <span>
                  What is the primary diagnosis or medical condition for which you seek treatment with medicinal
                  cannabis? Was this diagnosed by a doctor?
                </span>
              )}
            </IonLabel>
            <IonTextarea
              autoGrow={true}
              value={referral.primaryDiagnosis}
              onIonChange={(e: any) => setProp('primaryDiagnosis', e.detail.value)}
              placeholder='type here...'
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Treatments/Medications Used and Reason to Cease</span>}
              {referType === 1 && <span>What other treatments have you tried for this condition?</span>}
            </IonLabel>
            <IonTextarea
              autoGrow={true}
              value={referral.otherTreatments}
              onIonChange={(e: any) => setProp('otherTreatments', e.detail.value)}
              placeholder='type here...'
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Current Medications</span>}
              {referType === 1 && (
                <span>
                  What medications have you previously used? List any side effects and your reason for stopping.
                </span>
              )}
            </IonLabel>
            <IonTextarea
              autoGrow={true}
              value={referral.otherMedications}
              onIonChange={(e: any) => setProp('otherMedications', e.detail.value)}
              placeholder='type here...'
            />
          </IonItem>
          {referType === 1 && (
            <>
              <br />
              <IonLabel className='ion-margin-start required'>
                Have you ever self-medicated or are you currently self-medicating with cannabis?
              </IonLabel>
              <IonRadioGroup
                value={referral.selfMedicated}
                onIonChange={(e: any) => setProp('selfMedicated', e.detail.value)}
              >
                <IonItem>
                  <IonLabel>Yes</IonLabel>
                  <IonRadio value='yes' slot='start' />
                </IonItem>
                <IonItem>
                  <IonLabel>No</IonLabel>
                  <IonRadio value='no' slot='start' />
                </IonItem>
              </IonRadioGroup>
            </>
          )}
          {referType === 1 && (
            <>
              <br />
              <IonLabel className='ion-margin-start required'>Do you have any kidney or liver diseases?</IonLabel>
              <IonRadioGroup
                value={referral.kidneyLiver}
                onIonChange={(e: any) => setProp('kidneyLiver', e.detail.value)}
              >
                <IonItem>
                  <IonLabel>Yes</IonLabel>
                  <IonRadio value='yes' slot='start' />
                </IonItem>
                <IonItem>
                  <IonLabel>No</IonLabel>
                  <IonRadio value='no' slot='start' />
                </IonItem>
              </IonRadioGroup>
            </>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>
                Do you have any cardiovascular (heart and blood vessels) conditions?
              </IonLabel>
              <IonInput
                value={referral.cardiovascular}
                onIonChange={(e: any) => setProp('cardiovascular', e.detail.value)}
              />
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you have a current or previous substance abuse disorder?</IonLabel>
              <IonInput
                value={referral.substanceAbuseDisorder}
                onIonChange={(e: any) => setProp('substanceAbuseDisorder', e.detail.value)}
              />
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you have any allergies?</IonLabel>
              <IonInput value={referral.allergies} onIonChange={(e: any) => setProp('allergies', e.detail.value)} />
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>Do you smoke? If yes how many/day?</IonLabel>
              <IonInput value={referral.smoke} onIonChange={(e: any) => setProp('smoke', e.detail.value)} />
            </IonItem>
          )}
          {referType === 1 && (
            <IonItem>
              <IonLabel position='floating'>
                Do you drink alcohol? If yes how many a day and how many days per week?
              </IonLabel>
              <IonInput
                value={referral.drinkAlcohol}
                onIonChange={(e: any) => setProp('drinkAlcohol', e.detail.value)}
              />
            </IonItem>
          )}
          <IonItem>
            <IonLabel position='floating' className='ion-text-wrap'>
              {referType === 0 && <span>Other medical conditions</span>}
              {referType === 1 && <span>Please list any other medical conditions and current medications below:</span>}
            </IonLabel>
            <IonTextarea
              autoGrow={true}
              value={referral.otherConditions}
              onIonChange={(e: any) => setProp('otherConditions', e.detail.value)}
              placeholder='type here...'
            />
          </IonItem>
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Please include any CV, Psychiatric, Hepatic and Nephrotic conditions
              </IonLabel>
              <IonTextarea
                autoGrow={true}
                value={referral.cvConditions}
                onIonChange={(e: any) => setProp('cvConditions', e.detail.value)}
                placeholder='type here...'
              />
            </IonItem>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Please include if as the treating practitioner you have any concerns
              </IonLabel>
              <IonTextarea
                autoGrow={true}
                value={referral.practitionerConcerns}
                onIonChange={(e: any) => setProp('practitionerConcerns', e.detail.value)}
                placeholder='type here...'
              />
            </IonItem>
          )}
          {referType === 0 && (
            <IonItem>
              <IonLabel position='floating' className='ion-text-wrap'>
                Where possible, please include related recent investigation and consultation reports
              </IonLabel>
              <IonTextarea
                autoGrow={true}
                value={referral.recentReports}
                onIonChange={(e: any) => setProp('recentReports', e.detail.value)}
                placeholder='type here...'
              />
            </IonItem>
          )}
          {referType === 0 && (
            <IonRow style={{ border: border5 ? '1px solid #5FD0E7' : '' }}>
              <IonCol size='12'>Referring Practitioner:</IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Given name</IonLabel>
                  <IonInput
                    value={referral?.practitionerFirstName}
                    onIonChange={(e) => setProp('practitionerFirstName', e.detail.value)}
                    onIonFocus={() => setBorder5(true)}
                    onIonBlur={() => setBorder5(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Surname</IonLabel>
                  <IonInput
                    value={referral?.practitionerLastName}
                    onIonChange={(e) => setProp('practitionerLastName', e.detail.value)}
                    onIonFocus={() => setBorder5(true)}
                    onIonBlur={() => setBorder5(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Provider Number</IonLabel>
                  <IonInput
                    value={referral?.practitionerProviderNumber}
                    onIonChange={(e) => setProp('practitionerProviderNumber', e.detail.value)}
                    onIonFocus={() => setBorder5(true)}
                    onIonBlur={() => setBorder5(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12'>
                <IonItem>
                  <IonLabel position='floating'>Address</IonLabel>
                  <IonTextarea
                    autoGrow={true}
                    value={referral?.practitionerAddress}
                    onIonChange={(e) => setProp('practitionerAddress', e.detail.value)}
                    onIonFocus={() => setBorder5(true)}
                    onIonBlur={() => setBorder5(false)}
                  ></IonTextarea>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <ul>
                {!clinicPublicId && !doctorPublicId && (
                  <li>
                    <IonText color='danger'>Please choose a Clinic or a Doctor</IonText>
                  </li>
                )}
                {!referral.givenName && (
                  <li>
                    <IonText color='danger'>Please enter {referType === 0 ? "Patient's " : ''} Given name</IonText>
                  </li>
                )}
                {!referral.surName && (
                  <li>
                    <IonText color='danger'>Please enter {referType === 0 ? "Patient's " : ''} Surname</IonText>
                  </li>
                )}
                {!referral.phone && (
                  <li>
                    <IonText color='danger'>Please enter {referType === 0 ? "Patient's " : ''} Mobile</IonText>
                  </li>
                )}
                {referType === 1 && !referral.emergency && (
                  <li>
                    <IonText color='danger'>Please enter Emergency Contact</IonText>
                  </li>
                )}
                {referType === 1 && !referral.emergencyRelation && (
                  <li>
                    <IonText color='danger'>Please enter Emergency Contact Relation</IonText>
                  </li>
                )}
                {referType === 1 && !referral.emergencyContact && (
                  <li>
                    <IonText color='danger'>Please enter Emergency Contact Number</IonText>
                  </li>
                )}
                {referType === 1 && !referral.selfMedicated && (
                  <li>
                    <IonText color='danger'>
                      Please choose Yes or No for Have you ever self-medicated or are you currently self-medicating with
                      cannabis?
                    </IonText>
                  </li>
                )}
                {referType === 1 && !referral.kidneyLiver && (
                  <li>
                    <IonText color='danger'>
                      Please choose Yes or No for Do you have any kidney or liver diseases?
                    </IonText>
                  </li>
                )}
                {referType === 0 && !referral.practitionerFirstName && (
                  <li>
                    <IonText color='danger'>Please enter Practitioner's given name</IonText>
                  </li>
                )}
                {referType === 0 && !referral.practitionerLastName && (
                  <li>
                    <IonText color='danger'>Please enter Practitioner's surname</IonText>
                  </li>
                )}
                {referType === 0 && !referral.practitionerAddress && (
                  <li>
                    <IonText color='danger'>Please enter Practitioner's address</IonText>
                  </li>
                )}
                {referType === 0 && !referral.practitionerProviderNumber && (
                  <li>
                    <IonText color='danger'>Please enter Practitioner's provider number</IonText>
                  </li>
                )}
              </ul>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol>
              <IonButton onClick={persist} expand='block' color='primary' disabled={isInValid() || saving}>
                {saving && <IonSpinner slot='start' />}
                Submit
              </IonButton>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    referral: state.user.referral,
  }),
  mapDispatchToProps: {
    setReferral,
  },
  component: ReferAPatient,
})
