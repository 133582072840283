const success = (message: string, present: Function) => {
  present({ message, color: 'success', duration: 3000 })
}

const failure = (message: string, present: Function) => {
  if (!!message) {
    present({ message, color: 'danger', duration: 3000 })
  } else {
    present({ message: 'Unexpected error', color: 'danger', duration: 3000 })
  }
}

const info = (message: string, present: Function) => {
  present({ message, color: 'tertiary', duration: 3000 })
}

export { success, failure, info }
