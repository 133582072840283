import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { IonSpinner } from '@ionic/react'
import { ScriptReceiver } from '../../API'
import { connect } from '../../data'
import { ScriptReceiverService } from '../services/ScriptReceiverService'
import { TbnResponse } from '../services/TbnResponse'
import './select.scss'

interface OwnProps {
  doctorID?: string | null
  defaultValue?: { label: string; value: string } | null
  onChange: (item: ScriptReceiver | undefined) => void
}
interface StateProps {
  darkMode: boolean
}
interface DispatchProps {}
interface ScriptReceiverSelectProps extends OwnProps, StateProps, DispatchProps {}
const ScriptReceiverSelect: FC<ScriptReceiverSelectProps> = ({ doctorID, defaultValue, onChange, darkMode }) => {
  const [options, setOptions] = useState([])
  const [items, setItems] = useState<ScriptReceiver[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const [loading, setLoading] = useState(false)

  const handleChange = (value: any) => {
    setSelectedOption(value)
    onChange(items?.find((itm: ScriptReceiver) => itm.id === value.value))
  }

  const fetchOptions = async () => {
    setLoading(true)
    if (!!doctorID) {
      const res: TbnResponse = await ScriptReceiverService.Instance.findByDoctorId(doctorID)
      if (!!res.data) {
        setItems(res.data)
        setOptions(
          res.data.flatMap((itm: ScriptReceiver) => {
            return {
              value: itm?.id,
              label: `${itm?.name}  - ${itm.email}`,
            }
          }),
        )
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchOptions()
  }, [doctorID]) // eslint-disable-line

  return (
    <>
      {!loading && options.length > 0 && (
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          className='tbn-select'
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: darkMode ? 'hotpink' : 'lightblue',
              primary: darkMode ? 'white' : 'black',
              neutral0: darkMode ? 'gray' : 'white',
            },
          })}
        />
      )}
      {loading && <IonSpinner />}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: ScriptReceiverSelect,
})
