/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "43028519a36e465a8deb2878bcc18981",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://s09qwihqvl.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "CalendlyApiV1",
            "endpoint": "https://be49ldoh3h.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "CalendlyWebooksV1",
            "endpoint": "https://icgz8iy696.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "SendEmail",
            "endpoint": "https://dkqqpzzzhf.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bbmr3cx3bbfn7d37mz6oty76eu.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ap-southeast-2:652af101-5c0a-4b87-ae1a-a90adc4d792c",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_O7udC4UOl",
    "aws_user_pools_web_client_id": "5nmdlakb5cd830f6set4pdejnv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "tbnweb71906-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
