import { FC } from 'react'
import { IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react'

interface CheckboxWithOtherSupportProps {
  id: string
  label: string
  disabled: boolean
  isChecked: Function
  setChecked: Function
  setOther: Function
  lookupOther: Function
}
const CheckboxWithOtherSupport: FC<CheckboxWithOtherSupportProps> = ({
  id,
  label,
  isChecked,
  setChecked,
  setOther,
  lookupOther,
  disabled = false,
}) => {
  const isOther = () => {
    return id.toLowerCase() === 'other' || label.toLowerCase() === 'other'
  }

  return (
    <>
      {isOther() && (
        <IonRow className='ion-no-padding' style={{ width: '100%' }}>
          <IonCol className='ion-no-padding'>
            <IonItem>
              <IonLabel className='ion-text-wrap'>{label}</IonLabel>
              <IonCheckbox
                color='primary'
                slot='end'
                checked={isChecked(id)}
                onIonChange={(e) => setChecked(id, e.detail.checked)}
                disabled={disabled}
              />
            </IonItem>
          </IonCol>
          {isChecked(id) && (
            <IonCol
              className='ion-no-padding'
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <IonItem key={'other-custom'}>
                <IonInput
                  value={lookupOther()}
                  onIonChange={(e: any) => setOther(e.detail.value)}
                  placeholder={'Type here...'}
                  disabled={disabled}
                />
              </IonItem>
            </IonCol>
          )}
        </IonRow>
      )}
      {!isOther() && (
        <IonItem>
          <IonLabel className='ion-text-wrap'>{label}</IonLabel>
          <IonCheckbox
            color='primary'
            slot='end'
            checked={isChecked(id)}
            onIonChange={(e) => setChecked(id, e.detail.checked)}
            disabled={disabled}
          />
        </IonItem>
      )}
    </>
  )
}

export default CheckboxWithOtherSupport
