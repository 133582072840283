import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonCol, IonGrid } from '@ionic/react'
import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { IonRow, useIonAlert, useIonToast } from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { Doctor, ScriptReceiver } from '../../../API'
import { ScriptReceiverService } from '../../../components/services/ScriptReceiverService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import ScriptReceiverModalForm from './ScriptReceiverModalForm'

interface ScriptReceiverListProps {
  doctor: Doctor
  reload?: boolean
}
const ScriptReceiverList: FC<ScriptReceiverListProps> = ({ doctor, reload }) => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [archiving, setArchiving] = useState(false)
  const [presentAlert] = useIonAlert()
  const [presentToast] = useIonToast()
  const [showEditModal, setShowEditModal] = useState(false)
  const [activeItem, setActiveItem] = useState<ScriptReceiver>()

  const fetchItems = async () => {
    setLoading(true)
    const res: TbnResponse = await ScriptReceiverService.Instance.findByDoctorId(doctor?.id)
    if (!!res.data) {
      setItems(res.data)
    }
    setLoading(false)
  }

  const archiveItem = async (cmd: ScriptReceiver) => {
    setArchiving(true)
    const res: TbnResponse = await ScriptReceiverService.Instance.delete(cmd)
    if (!!res?.data) {
      await fetchItems()
    } else if (!!res?.errorMessage) {
      failure(res.errorMessage, presentToast)
    }
    setArchiving(false)
  }

  useEffect(() => {
    if (!!doctor?.id) fetchItems()
  }, [doctor?.id, reload]) // eslint-disable-line

  return (
    <IonGrid>
      {loading && (
        <IonRow>
          <IonCol>
            <TbnSpinner />
          </IonCol>
        </IonRow>
      )}
      <IonList>
        {!loading &&
          items.map((itm: ScriptReceiver) => (
            <IonItem key={itm.id} button={true}>
              <IonLabel
                className='ion-text-wrap'
                onClick={() => {
                  setActiveItem(itm)
                  setShowEditModal(true)
                }}
              >
                {itm.name}
                <p>{itm.email}</p>
              </IonLabel>
              <IonButtons slot='end'>
                <IonButton
                  fill='clear'
                  color='danger'
                  disabled={archiving}
                  title='Delete'
                  onClick={() => {
                    presentAlert({
                      header: `Are you sure?`,
                      subHeader: 'After delete, receiver will not be available.',
                      message: `${itm.name}`,
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                          handler: () => {},
                        },
                        {
                          text: 'Delete',
                          role: 'destructive',
                          cssClass: 'alert-button-delete',
                          handler: () => {
                            archiveItem(itm)
                          },
                        },
                      ],
                      onDidDismiss: (e: CustomEvent) => {},
                    })
                  }}
                >
                  <IonIcon icon={trashBin} slot='icon-only' />
                </IonButton>
              </IonButtons>
            </IonItem>
          ))}
      </IonList>
      {!!activeItem && (
        <div>
          <ScriptReceiverModalForm
            scriptReceiver={activeItem}
            isOpen={showEditModal}
            onDidDismiss={(e: any) => {
              setShowEditModal(false)
              if (!!e?.detail?.data) {
                fetchItems()
              }
              setTimeout(() => {
                setActiveItem(undefined)
              }, 0)
            }}
          />
        </div>
      )}
    </IonGrid>
  )
}

export default ScriptReceiverList
