import { FC } from 'react'
import { IonPage, IonContent, IonFooter, useIonViewWillEnter, useIonViewWillLeave, IonToolbar } from '@ionic/react'
import AuthenticatorContainer from '../components/AuthenticatorContainer'
import { connect, setMenuEnabled } from '../data'
import Header from './common/Header'

interface OwnProps {
  id: string
  isPrivate?: boolean
  padding?: boolean
  hideMenu?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  hideBackButton?: boolean
  title?: any
  titlePadding?: boolean
  fullscreen?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
}
interface StateProps {}
interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
}
interface PageContainerProps extends OwnProps, StateProps, DispatchProps {}
const PageContainer: FC<PageContainerProps> = ({
  id,
  children,
  isPrivate = false,
  padding = false,
  hideMenu = false,
  hideHeader = false,
  hideFooter = true,
  hideBackButton = true,
  fullscreen = false,
  setMenuEnabled,
  title,
  titlePadding = true,
  actionButtonsEnd,
  actionButtonsStart,
}) => {
  useIonViewWillEnter(() => {
    setMenuEnabled(!hideMenu)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  return (
    <IonPage id={id}>
      {!hideHeader && (
        <Header
          title={title}
          titlePadding={titlePadding}
          hideBackButton={hideBackButton}
          actionButtonsEnd={actionButtonsEnd}
          actionButtonsStart={actionButtonsStart}
        />
      )}
      {isPrivate && (
        <IonContent className={padding ? 'ion-padding' : ''} fullscreen={fullscreen}>
          <AuthenticatorContainer>{children}</AuthenticatorContainer>
          <div style={{ marginBottom: '150px' }}></div>
          {/* TEL-56 fix */}
        </IonContent>
      )}
      {!isPrivate && (
        <IonContent className={padding ? 'ion-padding' : ''} fullscreen={fullscreen}>
          {children}
          <div style={{ marginBottom: '150px' }}></div>
          {/* TEL-56 fix */}
        </IonContent>
      )}
      {!hideFooter && (
        <IonFooter>
          <IonToolbar>
            <span className='ion-padding-start copyright'>
              &copy; 2022, Telebinoid.com, v{process?.env?.REACT_APP_VERSION}
            </span>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setMenuEnabled,
  },
  component: PageContainer,
})
