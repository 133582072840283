import { FC, useEffect, useState } from 'react'
import { IonCol, IonInput, IonItem, IonLabel, IonRadio, IonRow } from '@ionic/react'

interface RadioWithOtherSupportProps {
  id: string
  label: string
  isChecked: Function
  disabled: boolean
  defaultOtherValue?: string
  onOtherValueChange: Function
}
const RadioWithOtherSupport: FC<RadioWithOtherSupportProps> = ({
  id,
  label,
  isChecked,
  disabled = false,
  defaultOtherValue,
  onOtherValueChange,
}) => {
  const [text, setText] = useState(defaultOtherValue)
  const isOther = () => {
    return id.toLowerCase() === 'other' || label.toLowerCase() === 'other'
  }

  useEffect(() => {
    onOtherValueChange(text)
  }, [text]) // eslint-disable-line

  return (
    <>
      {isOther() && (
        <IonRow className='ion-no-padding' style={{ width: '100%' }}>
          <IonCol className='ion-no-padding'>
            <IonItem key={id}>
              <IonLabel className='ion-text-wrap'>{label}</IonLabel>
              <IonRadio value={id} disabled={disabled} />
            </IonItem>
          </IonCol>
          {isChecked(id) && (
            <IonCol
              className='ion-no-padding'
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <IonItem key={'other-custom'}>
                <IonInput
                  value={text}
                  onIonChange={(e: any) => setText(e.detail.value!)}
                  placeholder={'Type here...'}
                  disabled={disabled}
                />
              </IonItem>
            </IonCol>
          )}
        </IonRow>
      )}
      {!isOther() && (
        <IonItem key={id}>
          <IonLabel className='ion-text-wrap'>{label}</IonLabel>
          <IonRadio value={id} disabled={disabled} />
        </IonItem>
      )}
    </>
  )
}

export default RadioWithOtherSupport
