export const listTypeFormEvents = /* GraphQL */ `
  query ListTypeFormEvents($filter: ModelTypeFormEventFilterInput, $limit: Int, $nextToken: String) {
    listTypeFormEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctor {
          drId
          drName
          avatar
        }
        clinic {
          publicId
          name
          logoImage
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        phone
        firstName
        startTime
        bookingUrl
        rescheduled
        canceled
        cancelReason
      }
      nextToken
    }
  }
`

export const countReferredsByDoctorPublicId = /* GraphQL */ `
  query FindReferredsByDoctorPublicId(
    $doctorPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findReferredsByDoctorPublicId(
      doctorPublicId: $doctorPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`

export const countReferredsByClinicPublicId = /* GraphQL */ `
  query FindReferredsByClinicPublicId(
    $clinicPublicId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findReferredsByClinicPublicId(
      clinicPublicId: $clinicPublicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`
