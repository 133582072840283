import { ProductCategory } from '../../API'

const productCategoryProfile = (productCategory?: ProductCategory | null) => {
  switch (productCategory) {
    case ProductCategory.CATEGORY_1:
      return 'CBD medicinal cannabis product (CBD ≥ 98%)'
    case ProductCategory.CATEGORY_2:
      return 'CBD dominant medicinal cannabis product (CBD ≥ 60% and < 98%)'
    case ProductCategory.CATEGORY_3:
      return 'Balanced medicinal cannabis product (CBD <60% and ≥ 40%)'
    case ProductCategory.CATEGORY_4:
      return 'THC dominant medicinal cannabis product (THC 60% - 98%)'
    case ProductCategory.CATEGORY_5:
      return 'THC medicinal cannabis product (THC >98%)'
  }
  return ''
}

export default productCategoryProfile
