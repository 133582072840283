import { FC } from 'react'
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'
import { AppointmentDefinition } from '../../API'
import { connect } from '../../data'
import Booking from '../booking/Booking.model'
import BookingCalendly from '../booking/BookingCalendly'
import BookingSlideOne from '../booking/BookingSlideOne'
import BookingSlideThree from '../booking/BookingSlideThree'
import BookingSlideTwo from '../booking/BookingSlideTwo'
import questions from '../booking/questions.json'
import QuestionType from '../forms/helpers/QuestionType'

interface OwnProps {
  appointmentDefinition: AppointmentDefinition
  paramElse?: string | null
  booking: Booking
  bookFast?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface OnePageBookingProps extends OwnProps, StateProps {}
const OnePageBooking: FC<OnePageBookingProps> = ({ appointmentDefinition, booking, paramElse, bookFast }) => {
  const ques = questions.questions
  return (
    <IonGrid className='ion-no-padding'>
      <IonRow>
        <IonCol className='ion-text-center'>
          <h3>Booking form</h3>
        </IonCol>
      </IonRow>
      <IonCard>
        <IonCardContent>
          <BookingSlideOne booking={booking} paramElse={paramElse} />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardContent>
          <BookingSlideTwo model={booking} paramElse={paramElse} />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardContent>
          <BookingSlideThree model={booking} paramElse={paramElse} />
        </IonCardContent>
      </IonCard>
      {ques.map((q: any) => (
        <IonCard key={q.id}>
          <IonCardContent>
            <QuestionType question={q} model={booking} paramElse={paramElse} />
          </IonCardContent>
        </IonCard>
      ))}
      <IonCard>
        <IonCardContent>
          <BookingCalendly
            booking={booking}
            appointmentDefinitionID={appointmentDefinition?.id}
            paramElse={paramElse}
            bookFast={bookFast}
          />
        </IonCardContent>
      </IonCard>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  component: OnePageBooking,
})
