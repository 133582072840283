import { API } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { AppointmentDefinition, CreateTypeFormEventInput, ModelSortDirection, Patient, Who } from '../../API'
import { TypeFormEvent, UpdateTypeFormEventInput } from '../../API'
import { createTypeFormEvent } from '../../graphql-custom/public/queries'
import { getTypeFormEvent } from '../../graphql-custom/reception/queries'
import { findTypeFormEventsBySorter } from '../../graphql-custom/search/queries'
import { updateTypeFormEvent } from '../../graphql/mutations'
import Booking from '../../pages/booking/Booking.model'
import questions from '../../pages/booking/questions.json'
import { AuthStatus } from '../util/AuthStatus'
import capitalizeFirstName from '../util/CapitalizeFirstName'
import { log, warn } from '../util/Log'
import SORT_CHAR from '../util/Sorter'
import { AppointmentDefinitionService } from './AppointmentDefinitionService'
import { RestApiService } from './RestApiService'
import { TbnResponse } from './TbnResponse'

export class BookingService {
  private static bookingService: BookingService | undefined
  public cache: any = {}

  public storeBooking = async (booking: Booking, authStatus: AuthStatus): Promise<TbnResponse> => {
    log('Booking is going to be persisted', booking)
    const calendlyInfo: any = await this.fetchCalendlyInfo(booking?.bookingUrl)
    log('Calendly info', calendlyInfo)
    const appointmentDefinitionRes: any = await AppointmentDefinitionService.Instance.publicFindById(
      booking.appointmentDefinitionID,
      authStatus,
    )
    const appointmentDefinition: AppointmentDefinition = appointmentDefinitionRes?.data
    const typeFormEvent: CreateTypeFormEventInput = {
      doctorPublicId: appointmentDefinition?.drPublicId || 'oops',
      clinicPublicId: appointmentDefinition?.clinicPublicId,
      definitionID: booking.appointmentDefinitionID,
      definitionType: appointmentDefinition?.appointmentType,
      definitionSubject: appointmentDefinition?.appointmentSubject,
      definitionSlug: appointmentDefinition?.calendlyAppointment?.slug,
      definitionTitle: appointmentDefinition?.calendlyAppointment?.name,
      definitionDoctorName: appointmentDefinition?.drName,
      definitionClinicName: appointmentDefinition?.clinicName,
      definitionClinicLogo: appointmentDefinition?.clinicLogoImage,
      bookingUrl: booking.bookingUrl,
      bookerID: booking.bookerID,
      startTime: calendlyInfo?.start_time,
      endTime: calendlyInfo?.end_time,
      who: booking.who,
      phone: booking.phone,
      firstName: capitalizeFirstName(booking.firstName?.trim()) || '',
      submittedAt: new Date().toISOString(),
      // paymentSuccess: true, TODO FIXME
      // paymentAmount: 1,     TODO FIXME
      questions: JSON.stringify(this.resolveQuestions(booking)),
      sorter: SORT_CHAR,
    }
    if (booking.who === Who.MYSELF && !!booking.bookerID) {
      typeFormEvent.owner = booking.bookerID
    }
    const res: any = await API.graphql({
      query: createTypeFormEvent,
      variables: {
        input: typeFormEvent,
      },
      authMode:
        authStatus !== AuthStatus.AUTHENTICATED
          ? GRAPHQL_AUTH_MODE.AWS_IAM
          : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })
    log('persisted ', res)
    return { data: res?.data?.createTypeFormEvent, errorMessage: res?.errors?.[0]?.message }
  }

  public fetchCalendlyInfo = async (bookingUrl?: string) => {
    try {
      const bookingUuid = bookingUrl?.split('/')[4]
      const res: any = await RestApiService.Instance.findCalendlyBooking(bookingUuid)
      log('fetched calendly info', res)
      if (!!res?.data?.resource) {
        return res?.data?.resource
      } else {
        warn('Fetch calendly info failure', res)
        return res
      }
    } catch (err) {
      warn('Calendly fetch failure', err)
      return null
    }
  }

  public async lastAppointmentFor(patientID: string): Promise<TbnResponse> {
    if (!!this.cache.lastAppts?.patientID) {
      log('From cache', patientID)
      return this?.cache.lastAppts?.patientID
    }
    try {
      const res: GraphQLResult<any> = await API.graphql({
        query: findTypeFormEventsBySorter,
        variables: {
          sorter: SORT_CHAR,
          filter: { patientID: { eq: patientID } },
          sortDirection: ModelSortDirection.DESC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      if (!this.cache.lastAppts) {
        this.cache.lastAppts = { patientID: res?.data?.findTypeFormEventsBySorter?.items?.[0] }
      } else {
        this.cache.lastAppts[patientID] = res?.data?.findTypeFormEventsBySorter?.items?.[0]
      }
      return { data: res?.data?.findTypeFormEventsBySorter?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      console.log('y', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async connectPatientToAppointment(appointment: TypeFormEvent, patient: Patient): Promise<TbnResponse> {
    try {
      const input: UpdateTypeFormEventInput = {
        id: appointment.id,
        patientID: patient.id,
      }
      const res: any = await API.graphql({
        query: updateTypeFormEvent,
        variables: {
          input,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateTypeFormEvent, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findForReception(appointmentId: string): Promise<TbnResponse> {
    try {
      const res: GraphQLResult<any> = await API.graphql({
        query: getTypeFormEvent,
        variables: {
          id: appointmentId,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getTypeFormEvent, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  private resolveQuestions(booking: Booking) {
    const slideOne: any[] = []
    slideOne.push({ id: 'psychosisHistory', type: 'checkbox', label: 'A history of or currently active psychosis' })
    slideOne.push({
      id: 'bipolarDisorder',
      type: 'checkbox',
      label: 'A history of or currently active bipolar disorder',
    })
    slideOne.push({
      id: 'anxietyDisorder',
      type: 'checkbox',
      label: 'An active mood disorder or severe anxiety disorder',
    })
    slideOne.push({ id: 'severeCardio', type: 'checkbox', label: 'Severe and unstable cardio-pulmonary disease' })
    slideOne.push({
      id: 'pregnant',
      type: 'checkbox',
      label: 'Pregnant, planning to become pregnant or breastfeeding.',
    })
    const qus = slideOne.concat(questions.questions)
    qus.map((q: any) => {
      q.answer = (booking as any)?.[q.id]
      return q
    })
    return qus
  }

  public static get Instance(): BookingService {
    if (!this.bookingService) {
      this.bookingService = new BookingService()
    }
    return this.bookingService
  }
}
