export const findAppointmentMedicationByDoctorId = /* GraphQL */ `
  query FindAppointmentMedicationByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentMedicationByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        map {
          mapNumber
        }
        product {
          category
          presentationText
        }
        patientID
        phone
        givenName
        familyName
      }
      nextToken
    }
  }
`
