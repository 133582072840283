import { FC } from 'react'
import { IonButton, IonChip, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { IonListHeader, IonRadio, IonRadioGroup } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { checkmark, warningOutline } from 'ionicons/icons'
import { Patient } from '../../../API'
import { isBooking, isPatient } from '../../../components/models/Model'
import { isMobile } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setBooking, setPatientHealth } from '../../../data/user/user.actions'
import Booking from '../../booking/Booking.model'
import { Question } from './Question.model'

interface DispatchProps {
  setBooking: typeof setBooking
  setPatientHealth: typeof setPatientHealth
}
interface OwnProps {
  question: Question
  model: Booking | Patient
  readonly?: boolean
  swiper: SwiperCore
  labelOutside?: boolean
  paramElse?: string | null
}
interface QuestionYesNoProps extends OwnProps, DispatchProps {}
const QuestionYesNo: FC<QuestionYesNoProps> = ({
  question,
  model,
  readonly = false,
  swiper,
  labelOutside = true,
  setBooking,
  setPatientHealth,
  paramElse,
}) => {
  const setModel = (id: string, value: string) => {
    if (!readonly) {
      if (isBooking(model)) {
        setBooking({ ...model, [id]: value })
      } else if (isPatient(model)) {
        setPatientHealth({ ...model, [id]: value })
      }
    }
  }

  const goToNextSlide = () => {
    if (question.id === 'bloodThinning' && model.__typename === 'Booking' && model.bloodThinning === 'no') {
      swiper?.slideTo(swiper?.activeIndex + 2)
    } else {
      swiper?.slideNext()
    }
  }

  const label = () => {
    if (!paramElse) {
      return question?.label.split(' ').map((str) => {
        if (str.startsWith('{')) {
          return (model as any)?.[str.replace(/[{}]/g, '')]
        } else {
          return str + ' '
        }
      })
    } else {
      return question?.alter?.split(' ').map((str) => {
        if (str.startsWith('{')) {
          return (model as any)?.[str.replace(/[{}]/g, '')]
        } else {
          return str + ' '
        }
      })
    }
  }

  return (
    <>
      {labelOutside && (
        <IonLabel className={`ion-text-wrap ${question.required ? ' required' : ''}`}>{label()}</IonLabel>
      )}
      <IonRadioGroup value={(model as any)?.[question.id]} onIonChange={(e) => setModel(question.id, e.detail.value)}>
        {!labelOutside && (
          <IonListHeader>
            <IonLabel className={`ion-text-wrap ${question.required ? ' required' : ''}`}>{label()}</IonLabel>
          </IonListHeader>
        )}
        <IonItem>
          <IonLabel>Yes</IonLabel>
          <IonRadio value='yes' slot='start' disabled={readonly}></IonRadio>
        </IonItem>
        <IonItem>
          <IonLabel>No</IonLabel>
          <IonRadio value='no' slot='start' disabled={readonly}></IonRadio>
        </IonItem>
      </IonRadioGroup>
      {(model as any)?.[question.id] === 'yes' && !!question.warning && (
        <IonChip
          style={{ minHeight: isMobile() ? '100px' : 'auto' }}
          className='ion-text-wrap'
          color={'warning'}
          outline={true}
          onClick={() => {
            !!question.warningLink && window.open(question.warningLink, '_blank')
          }}
        >
          <IonIcon icon={warningOutline} color='warning' />
          <IonLabel
            className='ion-text-wrap'
            color='dark'
            dangerouslySetInnerHTML={{ __html: question.warning }}
          ></IonLabel>
        </IonChip>
      )}
      {!!swiper && (
        <IonButton
          color='primary'
          disabled={question.required ? !(model as any)?.[question.id] : false}
          onClick={() => goToNextSlide()}
        >
          NEXT
          {!!(model as any)?.[question.id] && <IonIcon icon={checkmark} slot='end' />}
        </IonButton>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
    setPatientHealth,
  },
  component: QuestionYesNo,
})
