import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Admin, ModelAdminFilterInput, UpdateAdminInput } from '../../API'
import { createAdmin, updateAdmin } from '../../graphql/mutations'
import { findAdminByUserId, listAdmins } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import hasNewChanges from '../util/hasNewChanges'
import { TbnResponse } from './TbnResponse'

export class AdminService {
  private static adminService: AdminService | undefined

  public async list(filter?: ModelAdminFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listAdmins,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return Promise.resolve({ data: res?.data?.listAdmins?.items, errorMessage: res?.errors?.[0]?.message })
    } catch (err) {
      warn('error fetching admin: ', err)
      return Promise.reject(err)
    }
  }

  public async findAdmin(userID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAdminByUserId,
        variables: { userID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForAdminDuplication(res?.data?.findAdminByUserId?.items)
      return { data: res?.data?.findAdminByUserId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error fetching admin: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async addOrEditAdmin(userID: string, admin: Admin): Promise<TbnResponse> {
    try {
      const res: TbnResponse = await this.findAdmin(userID)
      if (!!res.data) {
        return { data: this.editAdmin(res.data, admin) }
      } else {
        return { data: this.addAdmin(userID, admin) }
      }
    } catch (err) {
      log('error fetching admin: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async editAdmin(previous: Admin, admin: Admin) {
    const next: UpdateAdminInput = {
      id: previous.id,
      name: admin.name?.trim(),
      avatar: admin.avatar,
      owner: previous.owner,
    }
    log('Admin update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Admin update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updateAdmin,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Admin updated to', res?.data?.updateAdmin)
      return res?.data?.updateAdmin
    }
  }

  public async addAdmin(userID: string, admin: Admin) {
    try {
      const res: any = await API.graphql({
        query: createAdmin,
        variables: {
          input: {
            userID,
            name: admin.name?.trim(),
            avatar: admin.avatar,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create admin res', res)
      return res.data.createAdmin
    } catch (err) {
      log('Error creating admin! :', err)
      return err
    }
  }

  private checkForAdminDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      warn('FOUND DUPLICATED ADMIN!!!', items)
    }
  }

  private includesNoNewChanges(cmd: UpdateAdminInput, previous: Admin) {
    return hasNewChanges(cmd, previous)
  }

  private async buildAdminService(): Promise<AdminService> {
    try {
      let service = new AdminService()
      return Promise.resolve(service)
    } catch (err) {
      warn('Init AdminService failure:', err)
      return this.buildAdminService()
    }
  }

  public static get Instance(): AdminService {
    if (!this.adminService) {
      this.adminService = new AdminService()
      this.adminService.buildAdminService().then((service: AdminService) => {
        this.adminService = service
      })
    }
    return this.adminService
  }
}
