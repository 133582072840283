import { API, Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { User, ConsentEvent, ModelSortDirection } from '../../API'
import { CreateConsentEventInput, ModelConsentEventFilterInput } from '../../API'
import { findConsentEventByUserId } from '../../graphql-custom/queries'
import { createConsentEvent } from '../../graphql/mutations'
import { log, warn } from '../util/Log'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentConsentService {
  private static consentService: CurrentConsentService | undefined
  private consent?: ConsentEvent
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentConsent(): Promise<ConsentEvent> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentConsentService.Instance.consent) {
      return Promise.resolve(CurrentConsentService.Instance.consent)
    }
    await new Promise((r) => setTimeout(r, 5000)) // Delay 5 seconds
    if (!!CurrentConsentService.Instance.consent) {
      return Promise.resolve(CurrentConsentService.Instance.consent)
    }
    const doubleCheck: CurrentConsentService = await this.checkIfConsentExists(CurrentConsentService.Instance)
    if (!!doubleCheck.consent) {
      return Promise.resolve(doubleCheck.consent)
    } else {
      return Promise.reject('Current consent not found!')
    }
  }

  private async buildCurrentConsentService(): Promise<CurrentConsentService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let consentService: CurrentConsentService = new CurrentConsentService(user)
      consentService = await consentService.checkIfConsentExists(consentService)
      return Promise.resolve(consentService)
    } catch (err) {
      warn('Init CurrentConsentService failure:', err)
      return this.buildCurrentConsentService()
    }
  }

  private async checkIfConsentExists(consentService: CurrentConsentService) {
    try {
      const user = await Auth.currentUserInfo()
      const res: TbnResponse = await this.listConsentPublicDTO(
        user?.attributes?.sub,
        user?.attributes?.given_name,
        user?.attributes?.faimly_name,
      )
      if (!!res?.data?.[0]) {
        consentService.consent = res?.data?.[0]
        log('foundByUserId', res?.data?.[0])
        return consentService
      }
    } catch (err) {
      log('error fetching Consent: ', err)
    }
    return consentService
  }

  public async createConsent(input: CreateConsentEventInput) {
    try {
      const res: any = await API.graphql({
        query: createConsentEvent,
        variables: {
          input,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create consent res', res)
      CurrentConsentService.Instance.consent = res.data.createConsentEvent
    } catch (err) {
      log('Error creating consent! :', err)
      throw err
    }
    return CurrentConsentService.Instance.consent
  }

  public async listConsentPublicDTO(userID?: string, givenName?: string, familyName?: string): Promise<TbnResponse> {
    try {
      const filter: ModelConsentEventFilterInput = {
        givenName: { eq: givenName },
        familyName: { eq: familyName },
      }
      const res: any = await API.graphql({
        query: findConsentEventByUserId,
        variables: {
          userID,
          filter,
          sortDirection: ModelSortDirection.DESC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findConsentEventByUserId.items?.slice(0, 1), errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): CurrentConsentService {
    if (!this.consentService) {
      this.consentService = new CurrentConsentService()
      this.consentService.buildCurrentConsentService().then((service: CurrentConsentService) => {
        this.consentService = service
      })
    }
    return this.consentService
  }
}
