import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Admin, User, Clinic } from '../../API'
import { findAdminByUserId } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { ClinicAdminService } from './ClinicAdminService'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentAdminService {
  private static adminService: CurrentAdminService | undefined
  private admin?: Admin
  private clinic?: Clinic
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentAdmin(): Promise<Admin> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentAdminService.Instance.admin) {
      return Promise.resolve(CurrentAdminService.Instance.admin)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentAdminService.Instance.admin) {
      return Promise.resolve(CurrentAdminService.Instance.admin)
    } else {
      return Promise.reject('Current admin resolve timeout!')
    }
  }

  public async currentClinic(): Promise<Clinic> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentAdminService.Instance.clinic) {
      return CurrentAdminService.Instance.clinic
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentAdminService.Instance.clinic) {
      return CurrentAdminService.Instance.clinic
    } else {
      return Promise.reject('Current clinic resolve timeout!')
    }
  }

  private async checkIfAdminExists(service: CurrentAdminService) {
    try {
      const res: any = await API.graphql({
        query: findAdminByUserId,
        variables: { userID: service.user?.id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      const admin = res?.data?.findAdminByUserId?.items?.[0]
      if (!!admin) {
        service.admin = admin
        const res: TbnResponse = await ClinicAdminService.Instance.find(admin.id)
        if (!!res.data) service.clinic = res.data.clinic
        log('foundAdminByUserId', admin?.id, 'at', res.data?.clinicID)
        return service
      }
    } catch (err) {
      log('error fetching Admin: ', err)
    }
    return service
  }

  private async buildCurrentAdminService(): Promise<CurrentAdminService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let service = new CurrentAdminService(user)
      service = await service.checkIfAdminExists(service)
      return Promise.resolve(service)
    } catch (err) {
      warn('Init CurrentAdminService failure:', err)
      return this.buildCurrentAdminService()
    }
  }

  public static get Instance(): CurrentAdminService {
    if (!this.adminService) {
      this.adminService = new CurrentAdminService()
      this.adminService.buildCurrentAdminService().then((service: CurrentAdminService) => {
        this.adminService = service
      })
    }
    return this.adminService
  }
}
