import { FC, useEffect, useState } from 'react'
import { IonButton, IonCard, IonCardContent, IonCheckbox, IonSpinner, IonText } from '@ionic/react'
import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { refresh } from 'ionicons/icons'
import { AppointmentMedication, Doctor, Patient, ScriptReceiver, TypeFormEvent } from '../../API'
import SES from '../../components/models/SES'
import ScriptReceiverSelect from '../../components/select/ScriptReceiverSelect'
import { AppointmentMedicationService } from '../../components/services/AppointmentMedicationService'
import { CurrentDoctorService } from '../../components/services/CurrentDoctorService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { todayInDateFormat1 } from '../../components/util/Date'
import { error, log } from '../../components/util/Log'
import { failure, success } from '../../components/util/Toast'

interface OwnProps {
  appointment: TypeFormEvent
  patient: Patient
}
interface DoctorPrescriptionProps extends OwnProps {}
const DoctorPrescription: FC<DoctorPrescriptionProps> = ({ patient, appointment }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [doctor, setDoctor] = useState<Doctor>()
  const [items, setItems] = useState<AppointmentMedication[]>([])
  const [presentToast] = useIonToast()
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [scriptReceiver, setScriptReceiver] = useState<ScriptReceiver>()

  const loadDoctor = async () => {
    try {
      const doctor: Doctor = await CurrentDoctorService.Instance.currentDoctor()
      setDoctor(doctor)
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      const res: any = await loadDoctor()
      return res
    }
  }

  const fetchItems = async () => {
    setLoading(true)
    const res: TbnResponse = await AppointmentMedicationService.Instance.findByAppointmentId(appointment.id)
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setLoading(false)
  }

  const emailBodyText = () => {
    const arr = [
      `Prescriber: Dr ${doctor?.drName}`,
      `Prescriber qualifications: ${doctor?.qualifications}`,
      `Prescriber number: ${doctor?.prescriberNumber}`,
      `Clinic name: ${appointment?.definitionClinicName}`,
      `Clinic address: `,
      `Patient Medicare no: ${patient?.medicareNumber}`,
      `Patient full name: ${patient?.givenName} ${patient?.surName}`,
      `Patient address: ${patient?.addressOneLine}`,
      'Pharmacist/patient copy',
    ]
    let counter = 0
    for (let itm of items) {
      counter++
      arr.push(`${counter}. `)
      arr.push(itm?.instruction || '')
      arr.push('')
    }
    arr.push('')
    arr.push(`Doctor's signature: ${doctor?.drName} Date: ${todayInDateFormat1()}`)
    arr.push(`Sent through email ${todayInDateFormat1()}`)
    return arr.join('\n')
  }

  const emailBodyHtml = () => {
    const arr = [
      '<table>',
      `<tr><td>Prescriber</td><td>Dr ${doctor?.drName}</td></tr>`,
      `<tr><td>Prescriber qualifications</td><td>${doctor?.qualifications}</td></tr>`,
      `<tr><td>Prescriber number</td><td>${doctor?.prescriberNumber}</td></tr>`,
      `<tr><td>Clinic name</td><td>${appointment?.definitionClinicName}</td></tr>`,
      `<tr><td>Clinic address</td><td></td></tr>`,
      `<tr><td>Patient Medicare no</td><td>${patient?.medicareNumber}</td></tr>`,
      `<tr><td>Patient full name</td><td>${patient?.givenName} ${patient?.surName}</td></tr>`,
      `<tr><td>Patient address</td><td>${patient?.addressOneLine}</td></tr>`,
      '<tr><td>Pharmacist/patient copy</td>',
    ]
    arr.push('<td><table>')
    for (let itm of items) {
      arr.push(`<tr><td><pre>${itm?.instruction}</pre></td></tr>`)
    }
    arr.push('</table></td>')
    arr.push(`<tr><td>Doctor's signature:</td><td></td></tr>`)
    arr.push(`<tr><td>${doctor?.drName}</td><td>Date: ${todayInDateFormat1()}</td></tr>`)
    arr.push(`<tr><td>Sent through email</td></tr>`)
    arr.push(`<tr><td>${todayInDateFormat1()}</td></tr>`)
    arr.push('</table>')
    return arr.join('')
  }

  const sendPrescription = async () => {
    setSending(true)
    try {
      const cmd: SES.Types.SendEmailRequest = {
        Destination: {
          ToAddresses: [scriptReceiver?.email || ''],
        },
        Source: process?.env?.REACT_APP_MAIL_SOURCE || '',
        Message: {
          Subject: { Data: `[TBN] Prescription for ${scriptReceiver?.name}` },
          Body: {
            Text: { Data: emailBodyText() },
            Html: { Data: emailBodyHtml() },
          },
        },
      }
      const res = await API.post('SendEmail', '/email/send', { body: cmd })
      log('SendEmail result:', res)
      success('Success sending email.', presentToast)
    } catch (err) {
      error(err)
      failure(JSON.stringify(err), presentToast)
    }
    setSending(false)
  }

  useEffect(() => {
    loadDoctor()
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='4'>Prescriber</IonCol>
        <IonCol>Dr {doctor?.drName}</IonCol>
        <IonCol className='ion-text-end'>
          <IonButton fill='clear' disabled={loading} onClick={fetchItems}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Prescriber qualifications</IonCol>
        <IonCol>{doctor?.qualifications}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Clinic name</IonCol>
        <IonCol>{appointment?.definitionClinicName}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Clinic Address</IonCol>
        <IonCol>{appointment?.definitionClinicName}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Prescriber number</IonCol>
        <IonCol>{doctor?.prescriberNumber}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Patient's Medicare no.</IonCol>
        <IonCol>{patient.medicareNumber}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Patient's full name</IonCol>
        <IonCol>
          {patient.givenName} {patient.surName}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='4'>Patient's address</IonCol>
        <IonCol>{patient.addressOneLine}</IonCol>
      </IonRow>
      {false && (
        <>
          <IonRow>
            <IonCol size='6' title='PBS Safety Net entitlement cardholder'>
              <IonItem>
                <IonLabel>PBS Safety Net entitlement cardholder</IonLabel>
                <IonCheckbox />
              </IonItem>
            </IonCol>
            <IonCol
              size='6'
              title='Concessional or dependant, RPBS beneficiary or PBS Safety Net concession cardholder'
            >
              <IonItem>
                <IonLabel>Concessional or dependant, RPBS beneficiary or PBS Safety Net concession cardholder</IonLabel>
                <IonCheckbox />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>PBS</IonLabel>
                <IonCheckbox />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>RPBS</IonLabel>
                <IonCheckbox />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Brand substitution not permitted</IonLabel>
                <IonCheckbox />
              </IonItem>
            </IonCol>
          </IonRow>
        </>
      )}
      <IonRow>
        <IonCol size='4'>Pharmacist/patient copy</IonCol>
        <IonCol>
          <IonCard>
            <IonCardContent>
              {items.map((itm: AppointmentMedication) => (
                <pre key={itm.id}>{itm.instruction}</pre>
              ))}
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Doctor's signature: {doctor?.drName}</IonCol>
        <IonCol>Date {todayInDateFormat1()}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <ScriptReceiverSelect
            doctorID={doctor?.id}
            onChange={(e: ScriptReceiver | undefined) => setScriptReceiver(e)}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {!user?.attributes?.email && (
            <IonText color='danger'>Please enter your email at your profile and verify it.</IonText>
          )}
          {!user?.attributes?.email_verified && <IonText color='danger'>Please verify your email.</IonText>}
        </IonCol>
        <IonCol className={'ion-text-end'}>
          <IonButton onClick={sendPrescription} disabled={!scriptReceiver?.email || sending}>
            {sending && <IonSpinner slot='start' />}
            Email it to {scriptReceiver?.email}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default DoctorPrescription
