import { FC, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import CannabisProductModalForm from './CannabisProductModalForm'

interface OwnProps {
  text?: string
  onAddSuccess: (event: any) => void
}
interface CannabisProductAddButtonProps extends OwnProps {}
const CannabisProductAddButton: FC<CannabisProductAddButtonProps> = ({ text, onAddSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonButton onClick={() => setShowModal(true)} title='Add product'>
        {text}
        <IonIcon icon={add} slot={!!text ? 'start' : 'icon-only'} />
      </IonButton>
      <CannabisProductModalForm
        isOpen={showModal}
        onDidDismiss={(e) => {
          setShowModal(false)
          onAddSuccess(e?.detail?.data)
        }}
      />
    </>
  )
}

export default CannabisProductAddButton
