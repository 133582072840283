import { FC } from 'react'
import AdminContainer from '../AdminContainer'
import CannabisProductList from './CannabisProductList'

interface CannabisProductListPageProps {}
const CannabisProductListPage: FC<CannabisProductListPageProps> = () => {
  return (
    <AdminContainer id='cannabis-product-list' title='Cannabis Product List'>
      <CannabisProductList />
    </AdminContainer>
  )
}

export default CannabisProductListPage
