import { FC, useEffect, useRef, useState } from 'react'
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonSpinner,
  IonThumbnail,
  useIonToast,
  useIonViewDidLeave,
} from '@ionic/react'
import { Storage } from 'aws-amplify'
import { cloudUpload } from 'ionicons/icons'
import { error } from './Log'
import { failure } from './Toast'

declare const crypto: any

interface ImageUploadProps {
  defaultPath: string
  onTbnChange: Function
  defaultImage?: string | null
  contentType?: string
  level?: 'public' | 'protected' | 'private'
  label?: string
  avatar?: boolean
}
const ImageUpload: FC<ImageUploadProps> = ({
  defaultImage,
  defaultPath,
  level = 'public',
  contentType = 'image/png',
  onTbnChange,
  label = 'Image',
  avatar = false,
}) => {
  const [image, setImage] = useState<string | null | undefined>(defaultImage)
  const [imageUrl, setImageUrl] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const imageInput = useRef<any>()
  const [present] = useIonToast()

  const onOpenFileDialog = () => {
    imageInput.current.click()
  }

  const onProcessFile = async (e: any) => {
    setUploading(true)
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    try {
      reader.readAsDataURL(file)
      const newImage = defaultImage || `${defaultPath}/${crypto.randomUUID({ disableEntropyCache: true })}`
      await Storage.put(newImage, file, {
        level,
        contentType,
      })
      setImage(newImage)
    } catch (err) {
      error(err)
      failure(JSON.stringify(err), present)
    }
    setUploading(false)
  }

  const fetchLogo = async () => {
    if (!!image) {
      const imgUrl = await Storage.get(image, { level, expires: 84600 })
      setImageUrl(imgUrl)
    }
  }

  useIonViewDidLeave(() => {
    setImageUrl(undefined)
  })

  useEffect(() => {
    fetchLogo()
    onTbnChange(image)
  }, [image]) // eslint-disable-line

  return (
    <IonItem>
      {!avatar && (
        <IonThumbnail slot='start'>
          <img src={imageUrl} alt='' />
        </IonThumbnail>
      )}
      {avatar && (
        <IonAvatar slot='start'>
          <img src={imageUrl} alt='' />
        </IonAvatar>
      )}
      <IonLabel>{label}</IonLabel>
      <input type='file' accept='image/*' onChange={onProcessFile} ref={imageInput} hidden={true}></input>
      <IonButton fill='clear' onClick={onOpenFileDialog} slot='end' disabled={uploading}>
        {uploading && <IonSpinner slot='icon-only' />}
        {!uploading && <IonIcon icon={cloudUpload} slot='icon-only' />}
      </IonButton>
    </IonItem>
  )
}

export default ImageUpload
