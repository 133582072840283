import { IonSpinner } from '@ionic/react'

const Spinner = () => {
  return (
    <div className='ion-text-center ion-padding'>
      <IonSpinner color='primary' />
    </div>
  )
}

export default Spinner
