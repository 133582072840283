import { FC, useEffect } from 'react'
import { Analytics } from 'aws-amplify'
import { Widget } from '@typeform/embed-react'
import queryString from 'query-string'
import PageContainer from '../PageContainer'
import './InitialConsultationLegacy.scss'

declare var window: any

interface InitialConsultationProps {}

const InitialConsultationLegacy: FC<InitialConsultationProps> = () => {
  const params = queryString.parse(window?.location?.search)
  const firstname: any = params.firstname
  const email: any = params.email

  useEffect(() => {
    Analytics.record({ name: 'Initial consultation' })
  }, [])

  return (
    <PageContainer
      id='initial-consultation'
      isPrivate={false}
      hideMenu={true}
      hideHeader={true}
      padding={false}
      hideFooter={true}
    >
      <Widget
        id={process?.env?.REACT_APP_TYPEFORM_INITIAL_CONSULTATION_ID || 'afSvsb9A'}
        style={{ width: '100%', height: '100vh' }}
        hidden={{
          firstname: firstname,
          email: email,
        }}
      />
    </PageContainer>
  )
}

export default InitialConsultationLegacy
