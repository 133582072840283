import { FC, useEffect, useState } from 'react'
import { FaCertificate } from 'react-icons/fa'
import Select from 'react-select'
import { IonSpinner } from '@ionic/react'
import { ProductMap } from '../../API'
import { connect } from '../../data'
import productCategoryToNumber from '../models/ProductCategoryToNumber'
import { ProductMapService } from '../services/ProductMapService'
import { TbnResponse } from '../services/TbnResponse'
import './select.scss'

interface OwnProps {
  doctorID?: string | null
  patientID?: string | null
  defaultValue?: { label: string; value: string } | null
  onChange: (item: ProductMap | undefined) => void
  reload?: boolean
}
interface StateProps {
  darkMode: boolean
}
interface DispatchProps {}
interface MapNumberSelectProps extends OwnProps, StateProps, DispatchProps {}
const MapNumberSelect: FC<MapNumberSelectProps> = ({
  doctorID,
  defaultValue,
  onChange,
  patientID,
  darkMode,
  reload,
}) => {
  const [options, setOptions] = useState([])
  const [items, setItems] = useState<ProductMap[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const [loading, setLoading] = useState(false)

  const handleChange = (value: any) => {
    setSelectedOption(value)
    onChange(items?.find((itm: ProductMap) => itm.id === value.value))
  }

  const fetchOptions = async () => {
    setLoading(true)
    if (!!doctorID) {
      const res: TbnResponse = await ProductMapService.Instance.find(doctorID)
      if (!!res.data) {
        setItems(res.data)
        setOptions(
          res.data
            ?.filter((itm: ProductMap) => !itm?.map?.archived)
            ?.filter((itm: ProductMap) => !itm?.map?.isSAS || (itm?.map?.isSAS && itm?.map?.patientID === patientID))
            .flatMap((itm: ProductMap) => {
              return {
                value: itm?.id,
                label: `${itm?.product?.name} ${itm?.product?.ingredients ? `(${itm?.product?.ingredients})` : ''} - ${
                  itm?.product?.presentationText
                } - ${itm?.product?.category ? `cat ${productCategoryToNumber(itm?.product?.category)}` : ''}`,
                isSAS: itm?.map?.isSAS,
                patientID: itm?.map?.patientID,
              }
            }),
        )
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!!doctorID) fetchOptions()
  }, [doctorID, reload]) // eslint-disable-line

  return (
    <>
      {!loading && (
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          className='tbn-select'
          isOptionDisabled={(option: any) => option.isSAS && option.patientID !== patientID}
          formatOptionLabel={(option: any, { context }) => {
            return option?.isSAS ? (
              <>
                <FaCertificate /> {option.label}
              </>
            ) : (
              option.label
            )
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: darkMode ? 'hotpink' : 'lightblue',
              primary: darkMode ? 'white' : 'black',
              neutral0: darkMode ? 'gray' : 'white',
            },
          })}
        />
      )}
      {loading && <IonSpinner />}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: MapNumberSelect,
})
