import { AppointmentSubject } from '../../API'

const appointmentSubjectLabel = (appointmentSubject: AppointmentSubject | null | undefined) => {
  switch (appointmentSubject) {
    case AppointmentSubject.INITIAL_CONSULTATION:
      return 'Initial Consultation'
    case AppointmentSubject.FIRST_FOLLOW_UP:
      return 'First follow up'
    case AppointmentSubject.LONG_FOLLOW_UP:
      return 'Long follow up'
    case AppointmentSubject.USUAL_FOLLOW_UP:
      return 'Usual follow up'
    default:
      return 'Unknown'
  }
}

export default appointmentSubjectLabel
