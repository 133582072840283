import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { IonButton, IonButtons, IonCol, IonGrid } from '@ionic/react'
import { IonIcon, IonText, useIonViewWillLeave } from '@ionic/react'
import { IonRow, IonSpinner, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { calendar, card, documentOutline } from 'ionicons/icons'
import { ConsentEvent, Patient, TypeFormEvent } from '../../API'
import appointmentSubjectLabel from '../../components/models/AppointmentSubjectLabel'
import AppointmentTypeIcon from '../../components/models/AppointmentTypeIcon'
import { BookingService } from '../../components/services/BookingService'
import { PatientLinkService } from '../../components/services/PatientLinkService'
import { defaultTimezone, utcToLocale } from '../../components/util/Date'
import { isDesktop } from '../../components/util/isMobile'
import { getTypeFormEvent } from '../../graphql/queries'
import AnswerModal from '../doctor/AnswerModal'
import DoctorPatientDetails from '../doctor/DoctorPatientDetails'
import DoctorPatientHealth from '../doctor/DoctorPatientHealth'
import ConsentFormView from '../forms/consent/ConsentFormView'
import ReceptionContainer from './ReceptionContainer'

const ReceptionAppointmentDetail = () => {
  const { id } = useParams<any>()
  const [loading, setLoading] = useState(true)
  const [cLoading, setCloading] = useState(false)
  const [item, setItem] = useState<TypeFormEvent | null | undefined>()
  const [present] = useIonToast()
  const [showModal, setShowModal] = useState(false)
  const [doubleCheck, setDoubleCheck] = useState(true)
  const [patient, setPatient] = useState<Patient | null>()
  const [consentLoaded, setConsentLoaded] = useState<boolean>(false) // eslint-disable-line
  const mainTab = useRef<any>()

  const fetchItem = async () => {
    setLoading(true)
    try {
      const res: any = await API.graphql({
        query: getTypeFormEvent,
        variables: {
          id,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      setItem(res?.data?.getTypeFormEvent)
      if (!!res?.data?.getTypeFormEvent?.patient) setPatient(res.data.getTypeFormEvent.patient)
    } catch (err: any) {
      present({ message: JSON.stringify(err), color: 'danger', duration: 3000 })
    }
    setLoading(false)
  }

  const onConsentLoaded = async (consent: ConsentEvent) => {
    setCloading(true)
    setConsentLoaded(false)
    if (!!consent?.patient?.id && !!item) {
      if (!item?.patientID) {
        await BookingService.Instance.connectPatientToAppointment(item, consent.patient)
        if (!!consent?.patient) setPatient(consent?.patient)
      }
      await PatientLinkService.Instance.storeSimple(
        item?.doctorPublicId,
        item?.patientID || consent.patient.id,
        item?.clinicPublicId,
      )
    }
    setConsentLoaded(true)
    setCloading(false)
  }

  const onPatientLoaded = async (patient: Patient) => {
    if (!!patient?.id && !!item) {
      if (!item?.patientID) {
        await BookingService.Instance.connectPatientToAppointment(item, patient)
        if (!!patient) setPatient(patient)
      }
      await PatientLinkService.Instance.storeSimple(
        item?.doctorPublicId,
        item?.patientID || patient.id,
        item?.clinicPublicId,
      )
    }
  }

  const reset = () => {
    setItem(undefined)
    setPatient(undefined)
    setConsentLoaded(false)
  }

  useIonViewWillLeave(() => {
    reset()
    if (mainTab && mainTab.current && mainTab.current.currentIndex) {
      mainTab.current.currentIndex = 0
      mainTab.current.defaultIndex = 0
    }
  })

  useEffect(() => {
    if (!!id) {
      fetchItem()
    }
  }, [id]) // eslint-disable-line

  return (
    <ReceptionContainer id='reception-appointment-detail' padding={true} onClinicLoaded={() => {}}>
      {loading && (
        <div className='ion-text-center ion-padding'>
          <IonSpinner />
        </div>
      )}
      {!loading && !!item && (
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol>
              {doubleCheck ? (
                <b
                  className='pointer'
                  onClick={() => {
                    setPatient(undefined)
                    setDoubleCheck(false)
                  }}
                >
                  {item.firstName}
                </b>
              ) : (
                <span
                  onClick={() => {
                    setPatient(undefined)
                    setDoubleCheck(true)
                  }}
                  className='pointer'
                >
                  {item.firstName}
                </span>
              )}
              {!!patient?.surName ? ` ${patient?.surName}` : ''}, {item.email} {item.phone}
            </IonCol>
            <IonCol>
              {utcToLocale(item.endTime)} ({defaultTimezone()})
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <AppointmentTypeIcon item={item} />
              {appointmentSubjectLabel(item.definitionSubject)}
              <IonText color={'tertiary'} className='ion-padding-horizontal'>
                {item?.definitionClinicName}
              </IonText>
            </IonCol>
            <IonCol>
              {utcToLocale(item.startTime)} ({defaultTimezone()})
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {item.rescheduled && <IonText color='warning'>Rescheduled</IonText>}
              {item.canceled && <IonText color='danger'>Canceled: {item.cancelReason}</IonText>}
            </IonCol>
            <IonCol>
              <IonButtons>
                <IonButton
                  fill='clear'
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <IonIcon icon={documentOutline} slot='icon-only'></IonIcon>
                </IonButton>
                {item.paymentSuccess && (
                  <IonButton fill='clear' color='success' title={'A$ ' + item.paymentAmount}>
                    <IonIcon icon={card} slot='icon-only'></IonIcon>
                  </IonButton>
                )}
                <IonButton
                  fill='clear'
                  onClick={() =>
                    window.open(item?.bookingUrl?.replace('https://api.calendly.com', 'https://calendly.com'), '_blank')
                  }
                >
                  <IonIcon icon={calendar} slot='icon-only'></IonIcon>
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              {!!item && !loading && (
                <Tabs spacing='equal' ref={mainTab}>
                  <TabItem title={isDesktop() ? 'Consent Form' : 'CF'}>
                    <ConsentFormView
                      email={item.email}
                      phone={item.phone}
                      givenName={item.firstName}
                      familyName={patient?.surName}
                      patientID={patient?.id}
                      userID={patient?.userID}
                      doubleCheck={doubleCheck}
                      onLoad={onConsentLoaded}
                    />
                  </TabItem>
                  <TabItem title={isDesktop() ? 'Patient' : 'P'} isDisabled={loading}>
                    <Tabs spacing='equal' defaultIndex={0}>
                      <TabItem title='Details'>
                        {!loading && !cLoading && !!item.phone && (
                          <DoctorPatientDetails
                            givenName={item.firstName}
                            phone={item.phone}
                            email={item.email}
                            patientID={patient?.id}
                            onLoad={onPatientLoaded}
                          />
                        )}
                      </TabItem>
                      <TabItem title='Health'>
                        {!loading && !cLoading && !!item.phone && (
                          <DoctorPatientHealth
                            givenName={item.firstName}
                            phone={item.phone}
                            email={item.email}
                            patientID={patient?.id}
                            onLoad={onPatientLoaded}
                          />
                        )}
                      </TabItem>
                    </Tabs>
                  </TabItem>
                </Tabs>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {!!item && <AnswerModal model={item} showModal={showModal} setShowModal={setShowModal} />}
    </ReceptionContainer>
  )
}

export default ReceptionAppointmentDetail
