export const defaultShowParts = {
  head: true,
  face: true,
  neck: true,
  'shoulder-left': true,
  'shoulder-right': true,
  'arm-left': true,
  'forearm-left': true,
  'arm-right': true,
  'forearm-right': true,
  'chest-left': true,
  'chest-right': true,
  'belly-left': true,
  'ribs-left': true,
  'belly-right': true,
  belly: true,
  'ribs-right': true,
  'thigh-left': true,
  'innerthigh-left': true,
  'feet-left': true,
  'calf-left': true,
  'knee-left': true,
  'thigh-right': true,
  genitalia: true,
  'innerthigh-right': true,
  'right-feet': true,
  'calf-right': true,
  'knee-right': true,
  'elbow-right': true,
  'hand-right': true,
  'elbow-left': true,
  'hands-left': true,
  'armback-left': true,
  'leg-left': true,
  buttock: true,
  loin: true,
  column: true,
  'head-back': true,
  nape: true,
  'armback-right': true,
  'leg-right': true,
  'back-right': true,
  'clavicule-right': true,
  'back-left': true,
  'clavicule-left': true,
}
