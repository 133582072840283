import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Doctor } from '../../API'
import { DoctorService } from '../services/DoctorService'
import './select.scss'

interface DoctorSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const DoctorSelect: FC<DoctorSelectProps> = ({ defaultValue, onChange }) => {
  const [options, setOptions] = useState()
  const [items, setItems] = useState<Doctor[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(items.find((i: Doctor) => i.id === itm.value))
  }

  const fetchOptions = async () => {
    const res: any = await DoctorService.Instance.list()
    if (res.data) {
      setItems(res.data || [])
      setOptions(
        res.data?.flatMap((itm: Doctor) => {
          return { value: itm.id, label: `Dr ${itm.drName}` }
        }),
      )
    }
  }

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  return <Select value={selectedOption} options={options} onChange={handleChange} className='tbn-select' />
}

export default DoctorSelect
