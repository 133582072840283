import { FC, useState } from 'react'
import { IonButton, IonIcon, useIonToast } from '@ionic/react'
import { add } from 'ionicons/icons'
import { ClinicAdmin, Admin } from '../../../API'
import AdminSelect from '../../../components/select/AdminSelect'
import { ClinicAdminService } from '../../../components/services/ClinicAdminService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure, success } from '../../../components/util/Toast'
import { isMobile } from '../../../components/util/isMobile'

interface ClinicAdminAddButtonProps {
  clinicID: string
  onSuccess: Function
}
const ClinicAdminAddButton: FC<ClinicAdminAddButtonProps> = ({ clinicID, onSuccess }) => {
  const [selected, setSelected] = useState<Admin>()
  const [model] = useState<ClinicAdmin>({
    clinicID,
  } as ClinicAdmin)
  const [adding, setAdding] = useState(false)
  const [presentToast] = useIonToast()

  const addItem = async () => {
    setAdding(true)
    if (!!selected) {
      const res: TbnResponse = await ClinicAdminService.Instance.store({ ...model, adminID: selected.id })
      if (res.data) {
        success('Success adding new admin to the clinic', presentToast)
        onSuccess()
      } else if (res.errorMessage) {
        failure(res.errorMessage, presentToast)
      }
    }
    setAdding(false)
  }

  return (
    <div className='flex-container flex-space-between align-items-center'>
      <div style={{ width: '90%' }}>
        <AdminSelect onChange={(itm: Admin) => setSelected(itm)} />
      </div>
      <IonButton
        onClick={addItem}
        disabled={!selected || adding}
        className='ion-float-end'
        size={isMobile() ? 'small' : 'default'}
      >
        <IonIcon icon={add} slot='icon-only' />
      </IonButton>
    </div>
  )
}

export default ClinicAdminAddButton
