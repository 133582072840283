import { FC, useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import CheckboxWithOtherSupport from '../../../components/form/CheckboxWithOtherSupport'
import { lookupOtherText } from '../../../components/util/LookupOtherText'
import { isDesktop } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import { Quiz } from './Quiz.model'
import { QuizQuestion } from './QuizQuestion.model'

interface OwnProps {
  swiper?: SwiperCore
  quizQuestion: QuizQuestion
  disabled: boolean
}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface QuizQuestionMultiCheckProps extends OwnProps, StateProps, DispatchProps {}
const QuizQuestionMultiCheck: FC<QuizQuestionMultiCheckProps> = ({ swiper, quizQuestion, quiz, setQuiz, disabled }) => {
  const [chks, setChks] = useState<string>(quizQuestion.answer || '')

  const isChecked = (chk: string) => {
    return !!chks && chks.indexOf(chk) !== -1
  }

  const setChecked = (chk: string, checked: boolean) => {
    if (checked) {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) === -1) {
          setChks(chks + `${chk},`)
        }
      } else {
        setChks(chks + `${chk}<>,`)
      }
    } else {
      if (chk !== 'Other') {
        if (chks?.indexOf(chk) !== -1) {
          setChks(chks?.replace(`${chk},`, ''))
        }
      } else {
        setChks(chks.replace(/Other<.*>,/, ''))
      }
    }
  }

  const lookupOther = () => {
    return lookupOtherText(chks)
  }

  const setOther = (othr?: string) => {
    setChks(chks.replace(/Other<.*>,/, `Other<${othr}>,`))
  }

  const setModel = (answer: any) => {
    const q = quiz?.questions?.find((q: QuizQuestion) => q.id === quizQuestion.id)
    if (!!q) {
      q.answer = answer
      const idx = quiz?.questions?.findIndex((x) => x.id === quizQuestion.id)
      quiz.questions[idx] = q
      setQuiz(quiz)
    }
  }

  useEffect(() => {
    setModel(chks)
  }, [chks]) // eslint-disable-line

  return (
    <>
      <h1>{quizQuestion.label}</h1>
      {quizQuestion?.options?.map((opt) => (
        <CheckboxWithOtherSupport
          key={opt.id}
          id={opt.id}
          label={opt.label}
          isChecked={isChecked}
          setChecked={setChecked}
          setOther={setOther}
          lookupOther={lookupOther}
          disabled={disabled}
        />
      ))}

      {!!swiper && (
        <div className={`ion-text-center ${isDesktop() ? 'ion-margin-vertical' : ''}`}>
          {!swiper?.isBeginning && (
            <IonButton
              fill='outline'
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              className='ion-padding-end'
              onClick={() => swiper?.slidePrev()}
            >
              Previous
            </IonButton>
          )}
          {!swiper?.isEnd && (
            <IonButton
              disabled={quizQuestion.required && !chks}
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              onClick={() => swiper?.slideNext()}
            >
              Next
            </IonButton>
          )}
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: QuizQuestionMultiCheck,
})
