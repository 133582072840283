import { FC, useEffect, useRef, useState } from 'react'
import { FaCapsules, FaFlask, FaLeaf, FaSprayCan } from 'react-icons/fa'
import { IonButton, IonButtons, IonCard, IonCardContent, IonIcon } from '@ionic/react'
import { IonCardHeader, IonCardSubtitle, IonContent, IonDatetime, IonDatetimeButton } from '@ionic/react'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { IonFooter, IonInput, IonItem, IonLabel, IonModal } from '@ionic/react'
import { IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { add, close } from 'ionicons/icons'
import {
  Doctor,
  MedicalAuthorisedPrescriber,
  Patient,
  ProductCategory,
  ProductPresentation,
  TgaStatus,
} from '../../../API'
import PatientSelect from '../../../components/select/PatientSelect'
import { CurrentDoctorService } from '../../../components/services/CurrentDoctorService'
import { MedicalAuthorisedPrescriberService } from '../../../components/services/MedicalAuthorisedPrescriberService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { todayInUTC } from '../../../components/util/Date'
import { failure } from '../../../components/util/Toast'
import ProductMapList from './ProductMapList'

interface OwnProps {
  map?: MedicalAuthorisedPrescriber
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
interface MapNumberModalFormProps extends OwnProps {}
const MapNumberModalForm: FC<MapNumberModalFormProps> = ({ isOpen, onDidDismiss, map }) => {
  const [today] = useState(todayInUTC())
  const [model, setModel] = useState<MedicalAuthorisedPrescriber>(
    !!map
      ? map
      : ({
          idxOrder: 1,
          status: TgaStatus.TGA_APPROVED,
          createdDate: today,
          decisionDate: '1960-01-01',
          approvalDate: '1960-01-01',
          expiryDate: '1960-01-01',
        } as MedicalAuthorisedPrescriber),
  )
  const modalRef = useRef<any>()
  const [saving, setSaving] = useState(false)
  const [presentToast] = useIonToast()

  const store = async () => {
    setSaving(true)
    if (!model?.doctorID) {
      try {
        const doctor: Doctor = await CurrentDoctorService.Instance.currentDoctor()
        model.doctorID = doctor?.id
      } catch (ignore) {}
    }
    if (model.decisionDate?.startsWith('1960-01-01')) model.decisionDate = null
    if (model.approvalDate?.startsWith('1960-01-01')) model.approvalDate = null
    if (model.expiryDate?.startsWith('1960-01-01')) model.expiryDate = null
    const res: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.store(model)
    if (res.data) {
      if (!!model?.id) modalRef?.current?.dismiss(res.data)
      else {
        setModel(res.data)
      }
    } else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setSaving(false)
  }

  const isInvalid = () => {
    return !model?.mapNumber
  }

  const isApprovalDateEmpty = () => {
    return model?.approvalDate?.startsWith('1960-01-01') || !model?.approvalDate
  }

  const isDecisionDateEmpty = () => {
    return !model?.decisionDate || model?.decisionDate?.startsWith('1960-01-01')
  }

  const isExpireDateEmpty = () => {
    return !model?.expiryDate || model?.expiryDate?.startsWith('1960-01-01')
  }

  useEffect(() => {
    try {
      CurrentDoctorService.Instance.currentDoctor().catch((ignore) => {})
    } catch (ignore) {}
  }, [])

  return (
    <IonModal id='map-number-modal-form' isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef}>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonSegment
            value={model.isSAS ? 'true' : 'false'}
            onIonChange={(e: any) => setModel({ ...model, isSAS: e.detail.value === 'true' })}
          >
            <IonSegmentButton value={'false'}>MAP</IonSegmentButton>
            <IonSegmentButton value={'true'}>SAS</IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonInput
            placeholder={model.isSAS ? 'MPxx-xxxxxxx' : 'MAPxx-xxxxxxx'}
            value={model.mapNumber}
            onIonChange={(e: any) => setModel({ ...model, mapNumber: e.detail.value })}
          />
        </IonItem>
        {model.isSAS && (
          <div className='ion-padding'>
            <PatientSelect
              defaultValue={model?.patientID}
              onChange={(itm: Patient) => setModel({ ...model, patientID: itm.id })}
            />
          </div>
        )}
        <IonItem>
          <IonSegment value={model.category} onIonChange={(e: any) => setModel({ ...model, category: e.detail.value })}>
            <IonSegmentButton value={ProductCategory.CATEGORY_1}>Cat 1</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_2}>Cat 2</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_3}>Cat 3</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_4}>Cat 4</IonSegmentButton>
            <IonSegmentButton value={ProductCategory.CATEGORY_5}>Cat 5</IonSegmentButton>
            <IonSegmentButton value={undefined}>-</IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonSegment
            value={model.presentation}
            onIonChange={(e: any) => {
              setModel({
                ...model,
                presentation: e.detail.value,
              })
            }}
          >
            <IonSegmentButton value={ProductPresentation.CAPSULE} title='Capsule'>
              <FaCapsules />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.DRIED_HERB} title='Dried Herb'>
              <FaLeaf />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.ORAL_LIQUID} title='Oral Liquid'>
              <FaFlask />
            </IonSegmentButton>
            <IonSegmentButton value={ProductPresentation.SPRAY_SOLUTION} title='Spray Solution'>
              <FaSprayCan />
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
        <IonItem>
          <IonLabel>Presentation text</IonLabel>
          <IonInput
            placeholder='Presentation text'
            value={model.presentationText}
            onIonChange={(e: any) => setModel({ ...model, presentationText: e.detail.value })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Created Date</IonLabel>
          <IonDatetimeButton datetime='createdDate'></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>Created Date</IonLabel>
            <IonDatetime
              value={model?.createdDate || today}
              id='createdDate'
              showDefaultButtons={true}
              presentation={'date'}
              onIonChange={(e) => setModel({ ...model, createdDate: e.detail.value as string })}
              yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        {!model.isSAS && (
          <IonItem>
            <IonLabel>Decision Date</IonLabel>
            {!isDecisionDateEmpty() && <IonDatetimeButton datetime='decisionDate'></IonDatetimeButton>}
            <IonModal keepContentsMounted={true}>
              <IonLabel className='ion-text-center'>Decision Date</IonLabel>
              <IonDatetime
                value={model?.decisionDate || '1960-01-01'}
                id='decisionDate'
                showDefaultButtons={true}
                presentation={'date'}
                onIonChange={(e) => setModel({ ...model, decisionDate: e.detail.value as string })}
                yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
              ></IonDatetime>
            </IonModal>
            <IonButtons slot='end'>
              {!isDecisionDateEmpty() && (
                <IonButton onClick={() => setModel({ ...model, decisionDate: '1960-01-01' })}>
                  <IonIcon icon={close} />
                </IonButton>
              )}
              {isDecisionDateEmpty() && (
                <IonButton onClick={() => setModel({ ...model, decisionDate: today })}>
                  <IonIcon icon={add} />
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        )}
        {model.isSAS && (
          <IonItem>
            <IonLabel>{model.status === TgaStatus.SAS_DENIED ? 'Denied Date' : 'Approval Date'}</IonLabel>
            {!isApprovalDateEmpty() && <IonDatetimeButton datetime='approvalDate'></IonDatetimeButton>}
            <IonModal keepContentsMounted={true}>
              <IonLabel className='ion-text-center'>Approval Date</IonLabel>
              <IonDatetime
                value={model?.approvalDate || '1960-01-01'}
                id='approvalDate'
                showDefaultButtons={true}
                presentation={'date'}
                onIonChange={(e) => setModel({ ...model, approvalDate: e.detail.value as string })}
                yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
              ></IonDatetime>
            </IonModal>
            <IonButtons slot='end'>
              {!isApprovalDateEmpty() && (
                <IonButton onClick={() => setModel({ ...model, approvalDate: '1960-01-01' })}>
                  <IonIcon icon={close} />
                </IonButton>
              )}
              {isApprovalDateEmpty() && (
                <IonButton onClick={() => setModel({ ...model, approvalDate: today })}>
                  <IonIcon icon={add} />
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        )}
        <IonItem>
          <IonLabel>Expiry Date</IonLabel>
          {!isExpireDateEmpty() && <IonDatetimeButton datetime='expiryDate'></IonDatetimeButton>}
          <IonModal keepContentsMounted={true}>
            <IonLabel className='ion-text-center'>Expiry Date</IonLabel>
            <IonDatetime
              value={model?.expiryDate || '1960-01-01'}
              id='expiryDate'
              showDefaultButtons={true}
              presentation={'date'}
              onIonChange={(e) => setModel({ ...model, expiryDate: e.detail.value as string })}
              yearValues={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
            ></IonDatetime>
          </IonModal>
          <IonButtons slot='end'>
            {!isExpireDateEmpty() && (
              <IonButton onClick={() => setModel({ ...model, expiryDate: '1960-01-01' })}>
                <IonIcon icon={close} />
              </IonButton>
            )}
            {isExpireDateEmpty() && (
              <IonButton onClick={() => setModel({ ...model, expiryDate: today })}>
                <IonIcon icon={add} />
              </IonButton>
            )}
          </IonButtons>
        </IonItem>
        <IonItem>
          <IonLabel className='ion-margin-bottom' position='stacked'>
            Status
          </IonLabel>
          <IonSegment
            value={model.status}
            onIonChange={(e: any) => {
              setModel({ ...model, status: e.detail.value })
            }}
            mode='ios'
          >
            {!model.isSAS && <IonSegmentButton value={TgaStatus.TGA_APPROVED}>TGA approved</IonSegmentButton>}
            {!model.isSAS && <IonSegmentButton value={TgaStatus.TGA_UNDER_REVIEW}>Under TGA Review</IonSegmentButton>}
            {model.isSAS && <IonSegmentButton value={TgaStatus.SAS_APPLIED}>SAS applied</IonSegmentButton>}
            {model.isSAS && <IonSegmentButton value={TgaStatus.SAS_APPROVED}>SAS approved</IonSegmentButton>}
            {model.isSAS && <IonSegmentButton value={TgaStatus.SAS_DENIED}>SAS denied</IonSegmentButton>}
            {!model.isSAS && <IonSegmentButton value={undefined}>Other</IonSegmentButton>}
          </IonSegment>
        </IonItem>
        {!!model?.id && (
          <IonCard style={{ border: '1px solid green' }}>
            <IonCardHeader className='ion-no-padding ion-margin-top'>
              <IonCardSubtitle className='ion-padding-start' color={'primary'}>
                Products
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <ProductMapList mapID={model?.id} />
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDidDismiss(null)}>Cancel</IonButton>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default MapNumberModalForm
