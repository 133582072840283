import { useState, useEffect, FC } from 'react'
import { AlertInput, IonButton, IonButtons, IonIcon, IonInput } from '@ionic/react'
import { IonItem, IonLabel, IonNote, IonSpinner, useIonAlert, useIonToast } from '@ionic/react'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { shield, shieldCheckmark } from 'ionicons/icons'
import useDebounce from '../../components/hooks/useDebounce'
import { CurrentUserService } from '../../components/services/CurrentUserService'
import { validateEmail } from '../../components/util/Email'
import { log } from '../../components/util/Log'
import { failure, info, success } from '../../components/util/Toast'
import { connect } from '../../data'
import { setEmailSent, setNet } from '../../data/user/user.actions'

interface OwnProps {
  required?: boolean
  onTbnChange?: Function
}
interface StateProps {
  emailSent: boolean
}
interface DispatchProps {
  setNet: typeof setNet
  setEmailSent: typeof setEmailSent
}
interface EmailProps extends OwnProps, StateProps, DispatchProps {}

const Email: FC<EmailProps> = ({ required = false, onTbnChange, setNet, emailSent, setEmailSent }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [email, setEmail] = useState<string | undefined>(user?.attributes?.email)
  const debounced = useDebounce(email, 500)
  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()
  const [isTouched, setIsTouched] = useState(false)
  const [isValid, setIsValid] = useState<boolean>()
  const [verifying, setVerifying] = useState(false)

  const updateEmail = async (email?: string) => {
    setNet(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user?.attributes?.email !== email && validateEmail(email)) {
        const res = await Auth.updateUserAttributes(user, {
          email: email?.trim(),
        })
        if (res === 'SUCCESS') {
          try {
            CurrentUserService.Instance?.updateCurrentEmail(email?.trim())
          } catch (ignore) {}
        }
      }
    } catch (ignore) {}
    setNet(false)
  }

  const sendVerificationEmail = async (force: boolean) => {
    if (emailSent && !force) return true
    try {
      const res: any = await Auth.verifyCurrentUserAttribute('email')
      if ('success' === (res as string)?.toLowerCase()) {
        info('Verification email sent', presentToast)
        await setEmailSent(true)
      } else {
        failure(`${res} sending verfication code`, presentToast)
      }
      return true
    } catch (err: any) {
      log('err', err)
      failure(err?.message, presentToast)
      return false
    }
  }

  const checkEmailVerfication = async () => {
    setVerifying(true)
    const user = await Auth.currentAuthenticatedUser()
    if (user?.attributes?.email_verified) success('Email is verified', presentToast)
    else {
      const res: boolean = await sendVerificationEmail(false)
      if (res) {
        presentAlert({
          header: 'Email verification code sent',
          keyboardClose: true,
          buttons: [
            {
              text: 'Resend',
              role: 'resend',
              handler: async () => {
                log('Verification resend')
                await sendVerificationEmail(true)
              },
            },
            {
              text: 'Verify',
              role: 'confirm',
              handler: () => {
                log('Verification confirm')
              },
            },
          ],
          inputs: [
            {
              type: 'number',
              placeholder: 'Enter verification code',
              handler: (input: AlertInput) => {
                log(input)
              },
            },
          ],
          onDidDismiss: async (e: CustomEvent) => {
            if (e.detail?.role === 'confirm' && !!e.detail?.data?.values?.[0]) {
              try {
                const res: any = await Auth.verifyCurrentUserAttributeSubmit('email', e.detail.data.values[0])
                if ('success' === (res as string)?.toLowerCase()) {
                  success('Success verifying email', presentToast)
                  setTimeout(() => {
                    Auth.signOut()
                  }, 2000)
                } else {
                  failure(`${res} verifying email`, presentToast)
                }
              } catch (err: any) {
                failure(err?.message, presentToast)
              }
            }
            setVerifying(false)
          },
        })
      } else {
        setVerifying(false)
      }
    }
  }

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value
    setEmail(value)

    setIsValid(undefined)

    if (value === '') return

    validateEmail(value) !== null ? setIsValid(true) : setIsValid(false)
  }

  const markTouched = () => {
    setIsTouched(true)
  }

  useEffect(() => {
    updateEmail(debounced)
  }, [debounced]) // eslint-disable-line

  useEffect(() => {
    if (!!onTbnChange) {
      onTbnChange(email)
    }
  }, [email]) // eslint-disable-line

  useEffect(() => {
    try {
      CurrentUserService.Instance?.currentUser().catch(() => {})
    } catch (ignore) {}
    if (!!user?.attributes?.email && !email) {
      setEmail(user?.attributes?.email)
    }
  }, []) // eslint-disable-line

  return (
    <IonItem
      className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
    >
      <IonLabel position='stacked' className={required ? 'required' : ''}>
        Email
      </IonLabel>
      <IonInput
        type='email'
        value={email}
        defaultValue={user?.attributes?.email}
        placeholder={'Email'}
        onIonInput={(event) => validate(event)}
        onIonBlur={() => markTouched()}
      />
      {!email && <IonNote slot='helper'>Enter a valid email</IonNote>}
      <IonNote slot='error'>Invalid email</IonNote>
      <IonButtons slot='end'>
        {verifying && <IonSpinner name='dots' />}
        <IonButton
          fill='clear'
          onClick={() => checkEmailVerfication()}
          title={user?.attributes?.email_verified ? 'Verified' : 'Need verification'}
          disabled={verifying || !email}
        >
          {user?.attributes?.email_verified && <IonIcon icon={shieldCheckmark} color='tertiary' slot='icon-only' />}
          {!user?.attributes?.email_verified && <IonIcon icon={shield} color='warning' slot='icon-only' />}
        </IonButton>
      </IonButtons>
    </IonItem>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    emailSent: state.user.emailSent,
  }),
  mapDispatchToProps: {
    setNet,
    setEmailSent,
  },
  component: Email,
})
