import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { CreateAnimation, IonButton, IonIcon, IonLabel } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import { connect } from '../../data'
import PageContainer from '../PageContainer'
import './DrLanding.scss'
import drImg from './images/me.jpg'

interface DispatchProps {}
interface DrLandingProps extends DispatchProps {}
const DrLanding: FC<DrLandingProps> = () => {
  const [section, setSection] = useState('home')

  return (
    <PageContainer
      id='dr-landing'
      isPrivate={false}
      hideMenu={true}
      hideHeader={true}
      padding={false}
      hideFooter={true}
    >
      <div id='dr-landing-body'>
        <div id='wrapper'>
          <nav id='nav'>
            <a
              onClick={() => setSection('home')}
              className={`icon solid fa-home ${section === 'home' ? 'active' : ''}`}
            >
              <span>&nbsp;</span>
            </a>
            <Link to='dr/nas' className='icon solid fa-calendar'>
              <span>&nbsp;</span>
            </Link>
            <Link to='/profile' className='icon solid fa-user'>
              <span>&nbsp;</span>
            </Link>
          </nav>
          <div id='main'>
            {section === 'home' && (
              <CreateAnimation
                duration={1000}
                fromTo={{
                  property: 'opacity',
                  fromValue: '0',
                  toValue: '1',
                }}
                play={true}
              >
                <article id='home' className='panel intro'>
                  <header>
                    <h1>Dr Nastaran Rafieian</h1>
                    <p>MD, FRACGP</p>
                  </header>
                  <a onClick={() => setSection('work')} className='jumplink pic' title='Book appointment with Dr Nas'>
                    <span className='arrow icon solid fa-chevron-right pointer'>
                      <span>Book appointment with Dr Nas</span>
                    </span>
                    <img src={drImg} alt='' />
                  </a>
                </article>
              </CreateAnimation>
            )}

            {section === 'work' && (
              <CreateAnimation
                duration={1000}
                fromTo={{
                  property: 'opacity',
                  fromValue: '0',
                  toValue: '1',
                }}
                play={true}
              >
                <article id='work' className='panel' style={{ width: '88%' }}>
                  <header>
                    <h2>Book your appointment with Dr Nas</h2>
                  </header>
                  <p>
                    Nas has been practising medicine since 2011. She graduated medicine in Iran and proceeded to work in
                    different areas of medicine: rural family medicine, sports medicine, mental health and harm
                    reduction. She received her fellowship of the Royal Australian College of General Practitioners in
                    2019 and further educated in areas of mental health and medicinal cannabis in the same year.
                  </p>
                  <p>
                    Through her roles she has had the precious experience of working for ethnic, linguistic and cultural
                    minorities.{' '}
                  </p>
                  <p>
                    Nastaran has had further experience and education in areas of mental health of adults and children,
                    medicinal cannabis, and women’s health.
                  </p>
                  <div className='ion-text-center'>
                    <IonButton expand='block' routerLink={`/dr/nas`} color={'primary'}>
                      <IonLabel>Book appointment with Dr Nas</IonLabel>
                      <IonIcon icon={chevronForward} slot='end'></IonIcon>
                    </IonButton>
                  </div>
                </article>
              </CreateAnimation>
            )}

            {section === 'contact' && (
              <CreateAnimation
                duration={1000}
                fromTo={{
                  property: 'opacity',
                  fromValue: '0',
                  toValue: '1',
                }}
                play={true}
              >
                <article id='contact' className='panel' style={{ width: '88%' }}>
                  <header>
                    <h2>Contact Us</h2>
                  </header>
                  <form action='#' method='post'>
                    <div>
                      <div className='row'>
                        <div className='col-6 col-12-medium'>
                          <input type='text' name='name' placeholder='Name' disabled={true} />
                        </div>
                        <div className='col-6 col-12-medium'>
                          <input type='text' name='email' placeholder='Email' disabled={true} />
                        </div>
                        <div className='col-12'>
                          <input type='text' name='subject' placeholder='Subject' disabled={true} />
                        </div>
                        <div className='col-12'>
                          <textarea name='message' placeholder='Message' rows={6} disabled={true}></textarea>
                        </div>
                        <div className='col-12'>
                          <input type='submit' value='Send Message' disabled={true} />
                        </div>
                      </div>
                    </div>
                  </form>
                </article>
              </CreateAnimation>
            )}
          </div>

          <div id='footer'>
            <ul className='copyright'>
              <li>&copy; 2022, Telebinoid.com, v{process?.env?.REACT_APP_VERSION}</li>
            </ul>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {},
  component: DrLanding,
})
