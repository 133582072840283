const utcToLocale = (utcString: string | null | undefined) => {
  if (!!utcString)
    return new Date(utcString).toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
}

const utcToLocaleDate = (utcString: string | null | undefined) => {
  if (!!utcString)
    return new Date(utcString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
}

const defaultTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * @param input e.g. 20/02/2022
 * result: 2022-02-20
 */
const convertDateFormat1ToISO = (input: string) => {
  if (!input || !input.includes('/')) return input
  const parts = input.split('/')
  return `${parts[2]}-${parts[1]}-${parts[0]}`
}

/**
 * @param input e.g. 2022-02-20
 * result: 20/02/2022
 */
const convertISOtoDateFormat1 = (input?: string | null) => {
  if (!input || !input.includes('-')) return input
  const parts = input.split('-')
  return `${parts[2]}/${parts[1]}/${parts[0]}`
}

/**
 *
 * @param date in utc format yyyy-mm-dd
 * @returns true if given date is before today
 */
const isBeforeToday = (date?: string | null) => {
  if (!!date) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return new Date(date) < today
  }
  return false
}

const todayInDateFormat1 = () => {
  const d = new Date()
  return `${d.getUTCDate()}/${d.getUTCMonth()}/${d.getUTCFullYear()}`
}

const todayInUTC = () => {
  const d = new Date()
  return `${d.getUTCFullYear()}-${d.getUTCMonth() < 10 ? `0${d.getUTCMonth()}` : d.getUTCMonth()}-${
    d.getUTCDate() < 10 ? `0${d.getUTCDate()}` : d.getUTCDate()
  }`
}

export {
  utcToLocale,
  defaultTimezone,
  utcToLocaleDate,
  convertDateFormat1ToISO,
  convertISOtoDateFormat1,
  isBeforeToday,
  todayInDateFormat1,
  todayInUTC,
}
