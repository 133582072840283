import { FC } from 'react'
import { IonButton } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'

interface QuestionStatementProps {
  statement: string
  okayButtonText?: string
  swiper?: SwiperCore
}
const QuestionStatement: FC<QuestionStatementProps> = ({ statement, okayButtonText = 'OK', swiper }) => {
  return (
    <>
      <span>{`${statement}`}</span>
      <span>
        <IonButton onClick={() => swiper?.slideNext()} color='primary'>
          {okayButtonText}
        </IonButton>
      </span>
    </>
  )
}

export default QuestionStatement
