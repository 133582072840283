import { FC, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, useIonToast, useIonViewWillEnter } from '@ionic/react'
import { chevronForward, document } from 'ionicons/icons'
import { Quiz, QuizType } from '../../../API'
import { QuizService } from '../../../components/services/QuizService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { utcToLocale } from '../../../components/util/Date'
import { failure } from '../../../components/util/Toast'
import Dass21AnswerModal from './Dass21AnswerModal'

interface OwnProps extends RouteComponentProps {}
interface Dass21ListProps extends OwnProps {}
const Dass21List: FC<Dass21ListProps> = ({ history }) => {
  const [inprogress, setInprogress] = useState(false)
  const [items, setItems] = useState([])
  const [present] = useIonToast()
  const [quizModel, setQuizModel] = useState<Quiz>()
  const [showModal, setShowModal] = useState(false)

  const fetchItems = async () => {
    setInprogress(true)
    await QuizService.Instance.buildQuizService()
    const res: TbnResponse = await QuizService.Instance.findQuizByOwner(QuizType.DASS_21)
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, present)
    setInprogress(false)
  }

  useIonViewWillEnter(() => {
    fetchItems()
  })

  return (
    <>
      <IonButton onClick={() => history.push('/quiz/dass-21')}>
        DASS-21
        <IonIcon icon={chevronForward} slot='end'></IonIcon>
      </IonButton>
      <p className='ion-no-margin ion-padding-start' style={{ fontSize: '90%' }}>
        <b>D</b>epression, <b>A</b>nxiety and <b>S</b>tress <b>S</b>cale - <b>21</b> items
      </p>
      <IonGrid>
        <IonRow className='tr-header'>
          <IonCol size='10'>Assessment date</IonCol>
          <IonCol></IonCol>
        </IonRow>
        {!inprogress &&
          items.slice(0, 3).map((qz: Quiz) => (
            <IonRow key={qz.id}>
              <IonCol size='10' className='ion-no-padding flex-container flex-column flex-center'>
                {utcToLocale(qz.createdAt)}
              </IonCol>
              <IonCol className='ion-no-padding'>
                <IonButton
                  fill='clear'
                  className='ion-no-padding ion-no-margin'
                  color={'primary'}
                  onClick={() => {
                    setShowModal(false)
                    setQuizModel(undefined)
                    setTimeout(() => {
                      setQuizModel(qz)
                      setShowModal(true)
                    }, 0)
                  }}
                >
                  <IonIcon icon={document} slot='icon-only'></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          ))}
        <IonRow>
          <>
            {!inprogress && items.length === 0 && <IonCol>No record to display</IonCol>}
            {inprogress && (
              <IonCol>
                <IonSpinner />
              </IonCol>
            )}
          </>
        </IonRow>
      </IonGrid>
      {!!quizModel && <Dass21AnswerModal model={quizModel} showModal={showModal} setShowModal={setShowModal} />}
    </>
  )
}

export default withRouter(Dass21List)
