import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import {
  ClinicAccountant,
  DeleteClinicAccountantInput,
  ModelClinicAccountantFilterInput,
  ModelSortDirection,
} from '../../API'
import { createClinicAccountant, deleteClinicAccountant } from '../../graphql/mutations'
import { findClinicAccountantByAccountantId, listClinicAccountants } from '../../graphql/queries'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ClinicAccountantService {
  private static service: ClinicAccountantService | undefined

  public async list(filter: ModelClinicAccountantFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listClinicAccountants,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listClinicAccountants?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching clinic accountant: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(accountantID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findClinicAccountantByAccountantId,
        variables: { accountantID, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findClinicAccountantByAccountantId?.items?.[0],
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(clinicAccountant: ClinicAccountant): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createClinicAccountant,
        variables: { input: clinicAccountant },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createClinicAccountant, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(clinicAccountant: ClinicAccountant): Promise<TbnResponse> {
    try {
      const input: DeleteClinicAccountantInput = {
        id: clinicAccountant.id,
      }
      const res: any = await API.graphql({
        query: deleteClinicAccountant,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteClinicAccountant, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on remove: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ClinicAccountantService {
    if (!this.service) {
      this.service = new ClinicAccountantService()
    }
    return this.service
  }
}
