export const getTypeFormEvent = /* GraphQL */ `
  query GetTypeFormEvent($id: ID!) {
    getTypeFormEvent(id: $id) {
      id
      definitionID
      definitionTitle
      definitionSlug
      definitionType
      definitionSubject
      email
      phone
      firstName
      bookingUrl
      oldBookingUrl
      paymentSuccess
      paymentAmount
      questions
      answers
      hidden
      calendlyStatus
      rescheduled
      canceled
      cancelReason
      landedAt
      submittedAt
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`
