import { FC, useEffect, useState } from 'react'
import { IonBadge } from '@ionic/react'
import { Clinic } from '../../../API'
import { CurrentReceptionService } from '../../../components/services/CurrentReceptionService'
import { ReferredService } from '../../../components/services/ReferredService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { connect } from '../../../data'
import { setClinicReferralCount } from '../../../data/user/user.actions'

interface OwnProps {}
interface StateProps {
  clinicReferralCount: number
}
interface DispatchProps {
  setClinicReferralCount: typeof setClinicReferralCount
}
interface ClinicReferralCountBadgeProps extends OwnProps, StateProps, DispatchProps {}
const ClinicReferralCountBadge: FC<ClinicReferralCountBadgeProps> = ({
  clinicReferralCount,
  setClinicReferralCount,
}) => {
  const [clinic, setClinic] = useState<Clinic>()

  const fetchCount = async () => {
    if (clinic?.publicId) {
      const res: TbnResponse = await ReferredService.Instance.countUnreadByClinicPublicId(clinic.publicId)
      if (res.data) await setClinicReferralCount(res.data)
    }
  }

  const resolveReceptionClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentReceptionService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveReceptionClinic()
    }
  }

  const resolveClinic = async () => {
    const clinic: Clinic = await resolveReceptionClinic()
    if (!!clinic?.id) setClinic(clinic)
  }

  useEffect(() => {
    if (!!clinic) fetchCount()
  }, [clinic]) // eslint-disable-line

  useEffect(() => {
    resolveClinic()
  }, []) // eslint-disable-line

  return (
    <>
      {!!clinicReferralCount && (
        <IonBadge color='danger' slot='end'>
          {clinicReferralCount}
        </IonBadge>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    clinicReferralCount: state.user.clinicReferralCount,
  }),
  mapDispatchToProps: {
    setClinicReferralCount,
  },
  component: ClinicReferralCountBadge,
})
