import { FC, useEffect, useState } from 'react'
import { IonButton, IonGrid, IonIcon, IonItem, IonListHeader, useIonAlert } from '@ionic/react'
import { IonLabel, IonList, useIonToast } from '@ionic/react'
import { personRemove } from 'ionicons/icons'
import { ClinicDoctor } from '../../../API'
import { ClinicDoctorService } from '../../../components/services/ClinicDoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { utcToLocaleDate } from '../../../components/util/Date'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import ClinicDoctorAddButton from './ClinicDoctorAddButton'
import ClinicDoctorModalForm from './ClinicDoctorModalForm'

interface OwnProps {
  clinicID: string
  history: any
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ClinicDoctorListProps extends OwnProps, StateProps, DispatchProps {}

const ClinicDoctorList: FC<ClinicDoctorListProps> = ({ clinicID, net, setNet, history }) => {
  const [items, setItems] = useState<ClinicDoctor[]>([])
  const [present] = useIonToast()
  const [showModal, setShowModal] = useState(false)
  const [presentAlert] = useIonAlert()

  const fetchItems = async () => {
    setNet(true)
    const res: TbnResponse = await ClinicDoctorService.Instance.list({ clinicID: { eq: clinicID } })
    if (res.data) {
      setItems(res.data)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setNet(false)
  }

  const deleteItem = async (itm: ClinicDoctor) => {
    setNet(true)
    setItems([])
    await ClinicDoctorService.Instance.remove(itm)
    await fetchItems()
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
  }, [clinicID]) // eslint-disable-line

  return (
    <IonGrid>
      <IonListHeader mode='ios'>Doctors</IonListHeader>
      <ClinicDoctorAddButton clinicID={clinicID} onSuccess={fetchItems} />
      <IonList>
        {items.map((itm: ClinicDoctor) => (
          <IonItem key={itm.id} button={true}>
            <IonLabel onClick={() => history.push(`/admin/doctors/${itm.doctor?.userID}`)}>
              Dr {itm?.doctor.drName}
              <p>{utcToLocaleDate(itm?.createdAt)}</p>
            </IonLabel>
            <IonButton
              fill='clear'
              color='danger'
              onClick={() => {
                presentAlert({
                  header: `Are you sure?`,
                  message: `Dr ${itm?.doctor?.drName}`,
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {},
                    },
                    {
                      text: 'Revoke access',
                      role: 'destructive',
                      cssClass: 'alert-button-delete',
                      handler: () => {
                        deleteItem(itm)
                      },
                    },
                  ],
                  onDidDismiss: (e: CustomEvent) => {},
                })
              }}
            >
              <IonIcon icon={personRemove} slot='icon-only' />
            </IonButton>
          </IonItem>
        ))}
      </IonList>
      {clinicID && (
        <ClinicDoctorModalForm
          clinicID={clinicID}
          showModal={showModal}
          setShowModal={setShowModal}
          onSuccess={() => {
            setShowModal(false)
            fetchItems()
          }}
        />
      )}
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: ClinicDoctorList,
})
