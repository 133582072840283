import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ProductMap, DeleteProductMapInput } from '../../API'
import { ModelProductMapFilterInput, ModelSortDirection, CreateProductMapInput } from '../../API'
import { createProductMap, deleteProductMap } from '../../graphql/mutations'
import { findProductMapByDoctorId, listProductMaps } from '../../graphql/queries'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ProductMapService {
  private static service: ProductMapService | undefined

  public async list(filter: ModelProductMapFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listProductMaps,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listProductMaps?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching ProductMaps: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(doctorID: string, filter?: ModelProductMapFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findProductMapByDoctorId,
        variables: {
          doctorID,
          sortDirection: ModelSortDirection.DESC,
          filter,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findProductMapByDoctorId?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(productMap: ProductMap | CreateProductMapInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createProductMap,
        variables: { input: productMap },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createProductMap, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async import(productMap: CreateProductMapInput): Promise<TbnResponse> {
    try {
      const existed = await this.find(productMap.doctorID, {
        productID: { eq: productMap.productID },
        mapID: { eq: productMap.mapID },
      })
      if (!!existed?.data?.length) {
        return { data: existed?.data?.[0] }
      } else {
        const res: any = await API.graphql({
          query: createProductMap,
          variables: { input: productMap },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return { data: res?.data?.createProductMap, errorMessage: res?.errors?.[0]?.message }
      }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(productMap: ProductMap): Promise<TbnResponse> {
    try {
      const input: DeleteProductMapInput = {
        id: productMap.id,
      }
      const res: any = await API.graphql({
        query: deleteProductMap,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteProductMap, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on remove: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ProductMapService {
    if (!this.service) {
      this.service = new ProductMapService()
    }
    return this.service
  }
}
