import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router'
import { IonButton, IonButtons, useIonViewDidLeave } from '@ionic/react'
import { IonIcon, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { Analytics } from 'aws-amplify'
import { Keyboard, Pagination } from 'swiper'
import { Swiper as SwiperCore } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { chevronDown, chevronUp, close } from 'ionicons/icons'
import { isValidEmail } from '../../components/util/Email'
import { log } from '../../components/util/Log'
import { connect } from '../../data'
import { setBooking } from '../../data/user/user.actions'
import PageContainer from '../PageContainer'
import { Question } from '../forms/helpers/Question.model'
import QuestionType from '../forms/helpers/QuestionType'
import Booking from './Booking.model'
import BookingCalendly from './BookingCalendly'
import './BookingPage.scss'
import BookingSlideOne from './BookingSlideOne'
import BookingSlideThree from './BookingSlideThree'
import BookingSlideTwo from './BookingSlideTwo'
import BookingSlideZero from './BookingSlideZero'
import questions from './questions.json'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  booking: Booking
  darkMode: boolean
}
interface DispatchProps {
  setBooking: typeof setBooking
}
interface BookingPageProps extends OwnProps, StateProps, DispatchProps {}
const BookingPage: FC<BookingPageProps> = ({ booking, setBooking, history, darkMode }) => {
  const { appointmentDefinitionID } = useParams<any>()
  const [slide, setSlide] = useState(0)
  let [swiper, setSwiper] = useState<SwiperCore>()
  const [pageActive, setPageActive] = useState(true)
  const slides = questions.questions
  const search = window.location.search
  const params = new URLSearchParams(search)
  const paramElse = params.get('else')
  const questionsOffset = 4

  const handleSlideChangeStart = () => {
    setSlide(swiper?.activeIndex || 0)
  }

  const isEnabled = (idx: number) => {
    if (!pageActive) {
      return true
    }
    let result = true
    if (idx === 0 || idx === slides.length + 2) {
      return true
    }
    if (idx >= 1 && (booking?.['pregnant'] || booking?.['severeCardio'])) {
      result = false
    }
    if (idx === 3 && booking?.phone?.length !== 12) {
      result = false
    }
    const s: Question | any = slides[idx - questionsOffset]
    if (!!s) {
      if (s.type === 'short_text' && s.required) {
        result = s.email ? isValidEmail((booking as any)?.[s.id]) : !!(booking as any)?.[s.id]
      } else if (s.type === 'yes_no' && s.required) {
        result = !!(booking as any)?.[s.id]
      }
    }
    log('isEnabled', result, idx)
    return result
  }

  const goToNextSlide = () => {
    const question: Question | any = slides[(swiper?.realIndex || 0) - 2]
    if (question?.id === 'bloodThinning' && booking?.bloodThinning === 'no') {
      swiper?.slideTo(swiper?.activeIndex + 2)
    } else {
      swiper?.slideNext()
    }
  }

  const goToPrevSlide = () => {
    const question: Question | any = slides[(swiper?.realIndex || 0) - 2]
    if (question?.id === 'angina' && booking?.bloodThinning === 'no') {
      swiper?.slideTo(swiper?.activeIndex - 2)
    } else {
      swiper?.slidePrev()
    }
  }

  const goBack = () => {
    if (!!history.goBack) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  useIonViewWillEnter(() => {
    setPageActive(true)
    setBooking({
      ...booking,
      __typename: 'Booking',
      appointmentDefinitionID,
      bookingUrl: undefined,
    })
  })

  useIonViewWillLeave(() => {
    setPageActive(false)
    setBooking({
      ...booking,
      __typename: 'Booking',
      appointmentDefinitionID,
      bookingUrl: undefined,
    })
  })

  useIonViewDidLeave(() => {
    setPageActive(false)
    setBooking({
      ...booking,
      __typename: 'Booking',
      appointmentDefinitionID,
      bookingUrl: undefined,
    })
  })

  useEffect(() => {
    Analytics.record({ name: 'Booking page loaded' })
    window.onkeyup = function (event) {
      if (event.keyCode === 27) {
        goBack()
      }
    }
  }, []) // eslint-disable-line

  return (
    <PageContainer
      id='booking-page'
      isPrivate={false}
      padding={false}
      hideHeader={true}
      hideMenu={true}
      hideFooter={true}
      fullscreen={true}
    >
      <button
        style={{ position: 'absolute', top: '0px', left: '0px', backgroundColor: 'transparent', zIndex: 999 }}
        onClick={goBack}
      >
        <img alt='' src={`../../assets/img/logo-text${darkMode ? '-white' : ''}.svg`} width={'150'} height='auto' />
      </button>
      <span style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 2 }}>
        <IonButtons>
          <IonButton color='medium' size='small' onClick={goBack} fill='clear'>
            <IonIcon slot='icon-only' icon={close}></IonIcon>
          </IonButton>
        </IonButtons>
      </span>
      <span className='navigation-button-container'>
        <IonButtons>
          <IonButton color='primary' size='small' disabled={swiper?.isBeginning} onClick={goToPrevSlide} fill='clear'>
            <IonIcon slot='icon-only' icon={chevronUp}></IonIcon>
          </IonButton>
          <IonButton color='primary' size='small' onClick={goToNextSlide} disabled={!isEnabled(slide)} fill='clear'>
            <IonIcon slot='icon-only' icon={chevronDown}></IonIcon>
          </IonButton>
        </IonButtons>
      </span>
      {pageActive && (
        <Swiper
          onSwiper={setSwiper}
          allowSlideNext={isEnabled(swiper?.activeIndex || 0)}
          onSlideChangeTransitionStart={handleSlideChangeStart}
          direction={'vertical'}
          pagination={{
            type: 'progressbar',
            clickable: false,
          }}
          keyboard={{
            enabled: true,
          }}
          modules={[Pagination, Keyboard]}
          onKeyPress={(swiper: SwiperCore, key: any) => {
            if (key === 13) {
              swiper.slideNext()
            }
          }}
          noSwiping={false}
          preventClicks={true}
          shortSwipes={false}
        >
          <SwiperSlide id='booking-slide-zero'>
            <BookingSlideZero swiper={swiper} />
          </SwiperSlide>
          <SwiperSlide id='booking-slide-one'>
            <BookingSlideOne swiper={swiper} booking={booking} paramElse={paramElse} />
          </SwiperSlide>
          <SwiperSlide id='booking-slide-two'>
            <BookingSlideTwo swiper={swiper} model={booking} paramElse={paramElse} />
          </SwiperSlide>
          <SwiperSlide id='booking-slide-three'>
            <BookingSlideThree swiper={swiper} model={booking} paramElse={paramElse} />
          </SwiperSlide>
          {slides.map((s: any, idx: number) => (
            <SwiperSlide key={s.id} id={`booking-slide-${s.id}`}>
              <QuestionType question={s} model={booking} swiper={swiper} paramElse={paramElse} />
            </SwiperSlide>
          ))}
          <SwiperSlide key={'calendly'} id='booking-slide-calendly' style={{ height: '95vh' }}>
            <BookingCalendly
              booking={booking}
              appointmentDefinitionID={appointmentDefinitionID}
              swiper={swiper}
              paramElse={paramElse}
            />
          </SwiperSlide>
        </Swiper>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    booking: state.user.booking,
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setBooking,
  },
  component: withRouter(BookingPage),
})
