import { FC, useEffect, useRef, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon } from '@ionic/react'
import { IonLabel, IonSpinner } from '@ionic/react'
import { IonModal, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { close } from 'ionicons/icons'
import { ClinicDoctor } from '../../../API'
import DoctorSelect from '../../../components/select/DoctorSelect'
import { ClinicDoctorService } from '../../../components/services/ClinicDoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure, success } from '../../../components/util/Toast'
import { connect } from '../../../data'

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  clinicID: string
  onSuccess: Function
}
interface StateProps {}
interface DispatchProps {}
interface ClinicDoctorModalFormProps extends OwnProps, StateProps, DispatchProps {}

const ClinicDoctorModalForm: FC<ClinicDoctorModalFormProps> = ({ onSuccess, showModal, setShowModal, clinicID }) => {
  const [present] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)
  const [model, setModel] = useState<ClinicDoctor>({
    clinicID,
  } as ClinicDoctor)
  const [saving, setSaving] = useState(false)

  const store = async () => {
    setSaving(true)
    const res: TbnResponse = await ClinicDoctorService.Instance.store(model)
    if (res.data) {
      success('Success adding new doctor to the clinic', present)
      onSuccess()
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setSaving(false)
  }

  const isInvalid = () => {
    return !model.clinicID || !model.doctorID
  }

  useEffect(() => {
    setModel({ ...model, clinicID } as ClinicDoctor)
  }, [clinicID]) // eslint-disable-line

  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='clinic-doctor-modal'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>Add Doctor to Clinic</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonLabel>Doctor</IonLabel>
        <DoctorSelect
          defaultValue={model?.doctorID}
          onChange={(itm: any) => setModel({ ...model, doctorID: itm.value })}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton color='primary' disabled={saving || isInvalid()} onClick={store}>
              {saving && <IonSpinner slot='start' />}
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  component: ClinicDoctorModalForm,
})
