import { FC, useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactJson from 'react-json-view'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon } from '@ionic/react'
import { IonModal, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { clipboard, close } from 'ionicons/icons'
import { CalendlyAppointment } from '../../../API'
import { connect } from '../../../data'

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  item?: CalendlyAppointment | null
}
interface StateProps {
  darkMode: boolean
}
interface DispatchProps {}
interface CalendlyAppointmentModalProps extends OwnProps, StateProps, DispatchProps {}
const CalendlyAppointmentModal: FC<CalendlyAppointmentModalProps> = ({ showModal, setShowModal, item, darkMode }) => {
  const [present] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)
  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='calendly-appointment-modal'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <span>
              {item?.slug} ({item?.name})
            </span>
          </IonTitle>
          <IonButtons slot='end'>
            <CopyToClipboard
              text={JSON.stringify(item)}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <IonButton color='primary'>
                <IonIcon icon={clipboard} slot='icon-only'></IonIcon>
              </IonButton>
            </CopyToClipboard>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <ReactJson src={item as object} theme={darkMode ? 'monokai' : 'rjv-default'} />
      </IonContent>
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: CalendlyAppointmentModal,
})
