import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ModelPatientFilterInput, ModelSortDirection, Patient, UpdatePatientInput } from '../../API'
import { createPatient, updatePatient } from '../../graphql/mutations'
import { findPatientByPhone, findPatientByUserId, getPatient, listPatients } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'
import { UserService } from './UserService'

export class PatientService {
  private static patientService: PatientService | undefined

  private async buildPatientService(givenName?: string): Promise<PatientService> {
    try {
      let patientService = new PatientService()
      return Promise.resolve(patientService)
    } catch (err) {
      warn('Init PaitentService failure:', err)
      return this.buildPatientService(givenName)
    }
  }

  public async list(filter?: ModelPatientFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listPatients,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listPatients?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByPhoneOrEmail(
    givenName?: string,
    phone?: string | null,
    email?: string | null,
  ): Promise<TbnResponse> {
    try {
      const filter: ModelPatientFilterInput = {}
      if (!!givenName) {
        filter.givenName = { eq: givenName }
      }
      const user: TbnResponse | undefined = await UserService.Instance?.findByPhoneOrEmail(phone, email)
      const res0: any = await API.graphql({
        query: findPatientByUserId,
        variables: { userID: user?.data?.id, filter, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForPatientDuplication(res0?.data?.findPatientByUserId?.items)
      return { data: res0?.data?.findPatientByUserId?.items?.[0], errorMessage: res0?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async get(patientID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getPatient,
        variables: { id: patientID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getPatient, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(patient?: Patient): Promise<TbnResponse> {
    try {
      if (!!patient?.id) {
        const input: UpdatePatientInput = {
          id: patient.id,
          givenName: patient.givenName,
          surName: patient.surName,
          medicareNumber: patient.medicareNumber,
          addressOneLine: patient.addressOneLine,
          dateOfBirth: !!patient.dateOfBirth ? patient.dateOfBirth : null,
          contactNumber: patient.contactNumber,
          nextOfKin: patient.nextOfKin,
          nextOfKinRelation: patient.nextOfKinRelation,
          nextOfKinContact: patient.nextOfKinContact,
          powerOfAttorney: patient.powerOfAttorney,
          powerOfAttorneyRelation: patient.powerOfAttorneyRelation,
          powerOfAttorneyContact: patient.powerOfAttorneyContact,
          carer: patient.carer,
          carerRelation: patient.carerRelation,
          carerContact: patient.carerContact,
          emergency: patient.emergency,
          emergencyRelation: patient.emergencyRelation,
          emergencyContact: patient.emergencyContact,

          primaryDiagnosis: patient.primaryDiagnosis,
          otherTreatments: patient.otherTreatments,
          otherMedications: patient.otherMedications,
          selfMedicated: patient.selfMedicated,
          kidneyLiver: patient.kidneyLiver,
          cardiovascular: patient.cardiovascular,
          substanceAbuseDisorder: patient.substanceAbuseDisorder,
          allergies: patient.allergies,
          smoke: patient.smoke,
          drinkAlcohol: patient.drinkAlcohol,
          otherConditions: patient.otherConditions,
        }
        const res: any = await API.graphql({
          query: updatePatient,
          variables: { input },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return { data: res?.data?.updatePatient, errorMessage: res?.errors?.[0]?.message }
      } else if (!!patient) {
        const res: any = await API.graphql({
          query: createPatient,
          variables: { input: patient },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        return { data: res?.data?.createPatient, errorMessage: res?.errors?.[0]?.message }
      } else {
        return { errorMessage: 'Patient was null' }
      }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByPhoneAndGivenName(phone: string, givenName: string): Promise<TbnResponse> {
    try {
      const filter: ModelPatientFilterInput = {
        givenName: { eq: givenName },
      }
      const res: any = await API.graphql({
        query: findPatientByPhone,
        variables: { phone, filter, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForPatientDuplication(res?.data?.findPatientByPhone?.items)
      return { data: res?.data?.findPatientByPhone?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  private checkForPatientDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      log('FOUND DUPLICATED PATIENT!!!', items?.length)
    }
  }

  public static get Instance(): PatientService {
    if (!this.patientService) {
      this.patientService = new PatientService()
      this.patientService.buildPatientService().then((service: PatientService) => {
        this.patientService = service
      })
    }
    return this.patientService
  }
}
