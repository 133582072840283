import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ClinicAdmin, DeleteClinicAdminInput, ModelClinicAdminFilterInput, ModelSortDirection } from '../../API'
import { createClinicAdmin, deleteClinicAdmin } from '../../graphql/mutations'
import { findClinicAdminByAdminId, listClinicAdmins } from '../../graphql/queries'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ClinicAdminService {
  private static service: ClinicAdminService | undefined

  public async list(filter: ModelClinicAdminFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listClinicAdmins,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listClinicAdmins?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching clinic admin: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(adminID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findClinicAdminByAdminId,
        variables: { adminID, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findClinicAdminByAdminId?.items?.[0],
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(clinicAdmin: ClinicAdmin): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createClinicAdmin,
        variables: { input: clinicAdmin },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createClinicAdmin, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(clinicAdmin: ClinicAdmin): Promise<TbnResponse> {
    try {
      const input: DeleteClinicAdminInput = {
        id: clinicAdmin.id,
      }
      const res: any = await API.graphql({
        query: deleteClinicAdmin,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteClinicAdmin, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on remove: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ClinicAdminService {
    if (!this.service) {
      this.service = new ClinicAdminService()
    }
    return this.service
  }
}
