import { FC, useEffect, useState } from 'react'
import { IonButton, IonChip, IonCol, IonGrid, IonIcon, IonRow, IonText, useIonToast } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { checkboxOutline, refresh, stopOutline } from 'ionicons/icons'
import { ConsentEvent, ModelConsentEventFilterInput, ModelSortDirection } from '../../../API'
import Spinner from '../../../components/helpers/Spinner'
import { utcToLocale } from '../../../components/util/Date'
import { error } from '../../../components/util/Log'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import { findConsentEventByPhone } from '../../../graphql/queries'
import PatientDetailsView from '../PatientDetailsView'
import PatientHealthView from '../PatientHealthView'
import ConsentFormManualModal from './ConsentFormManualModal'

interface OwnProps {
  doubleCheck: boolean
  email?: string | null
  phone?: string | null
  givenName: string | null
  familyName?: string | null
  patientID?: string | null
  userID?: string | null
  onLoad: Function
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ConsentFormViewProps extends OwnProps, DispatchProps {}
const ConsentFormView: FC<ConsentFormViewProps> = ({
  email,
  phone,
  givenName,
  familyName,
  patientID,
  userID,
  doubleCheck,
  onLoad,
  setNet,
}) => {
  const [loading, setLoading] = useState(true)
  const [consent, setConsent] = useState<ConsentEvent>()
  const [section, setSection] = useState('details')
  const [showModal, setShowModal] = useState(false)
  const [present] = useIonToast()

  const findConsent = async () => {
    setNet(true)
    setLoading(true)
    try {
      const filter: ModelConsentEventFilterInput = {}
      if (doubleCheck) {
        filter.givenName = { eq: givenName }
      }
      if (!!familyName) {
        filter.familyName = { eq: familyName }
      }
      const res: any = await API.graphql({
        query: findConsentEventByPhone,
        variables: { phone, sortDirection: ModelSortDirection.DESC, filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      if (!!res.data.findConsentEventByPhone.items?.[0]) {
        setConsent(res.data.findConsentEventByPhone.items[0])
      }
    } catch (err: any) {
      error(err)
      failure(`Error: ${err?.errors?.[0]?.message}`, present)
    }
    setLoading(false)
    setNet(false)
  }

  useEffect(() => {
    if (!!consent) onLoad(consent)
  }, [consent]) // eslint-disable-line

  useEffect(() => {
    if (!!phone) {
      findConsent()
    }
  }, [email, phone, doubleCheck]) // eslint-disable-line

  return (
    <>
      {loading && <Spinner />}
      {!loading && !!consent && (
        <IonGrid>
          <IonRow>
            <IonCol>Acknowledged by</IonCol>
            <IonCol>{consent?.consenter}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Acknowledged at</IonCol>
            <IonCol>{utcToLocale(consent?.createdAt)}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Research allowed</IonCol>
            <IonCol>
              {consent?.researchAllowed && <IonIcon icon={checkboxOutline} />}
              {!consent?.researchAllowed && <IonIcon icon={stopOutline} />}
            </IonCol>
          </IonRow>
          {consent?.manual && (
            <IonRow>
              <IonCol>Manually submitted</IonCol>
              <IonCol>
                <IonIcon icon={checkboxOutline} />
              </IonCol>
            </IonRow>
          )}
          {!consent?.manual && (
            <IonRow>
              <IonCol size='12'>
                <IonChip color={`${section === 'details' ? 'dark' : 'medium'}`} onClick={() => setSection('details')}>
                  Patient Details
                </IonChip>
                <IonChip color={`${section === 'healths' ? 'dark' : 'medium'}`} onClick={() => setSection('healths')}>
                  Patient Health
                </IonChip>
                {section === 'details' && (
                  <PatientDetailsView
                    patient={JSON.parse(consent?.consentContent).patientDetail}
                    givenName={consent?.givenName}
                    doubleCheck={doubleCheck}
                  />
                )}
                {section === 'healths' && (
                  <PatientHealthView
                    patient={JSON.parse(consent?.consentContent).patientHealth}
                    givenName={consent?.givenName}
                    doubleCheck={doubleCheck}
                  />
                )}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
      {!loading && !consent && (
        <div className='ion-padding ion-margin ion-text-start'>
          <IonText color='danger'>Consent Form not found!</IonText>
          <IonButton fill='clear' className='ion-margin-start' onClick={findConsent} disabled={loading}>
            <IonIcon icon={refresh} slot='icon-only' />
          </IonButton>
          <br />
          <br />
          <IonButton onClick={() => setShowModal(true)}>Mark consent as manually received</IonButton>
          <ConsentFormManualModal
            givenName={givenName}
            familyName={familyName}
            patientID={patientID}
            userID={userID}
            phone={phone}
            isOpen={showModal}
            onDidDismiss={() => {
              setShowModal(false)
              findConsent()
            }}
          />
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setNet,
  },
  component: ConsentFormView,
})
