export const findTypeFormEventsByEmail = /* GraphQL */ `
  query FindTypeFormEventsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventType
        formId
        token
        email
        firstName
        paymentSuccess
        paymentAmount
        bookingUrl
        landedAt
        submittedAt
        definitionId
        definitionTitle
        questions
        answers
        hidden
        startTime
        endTime
      }
      nextToken
    }
  }
`

export const listTypeFormEvents = /* GraphQL */ `
  query ListTypeFormEvents($filter: ModelTypeFormEventFilterInput, $limit: Int, $nextToken: String) {
    listTypeFormEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctor {
          drId
          drName
          avatar
        }
        clinic {
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          sorter
        }
        definitionID
        bookerID
        booker {
          id
        }
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        phone
        firstName
        startTime
        bookingUrl
      }
      nextToken
    }
  }
`

export const findConsentEventByUserId = /* GraphQL */ `
  query FindConsentEventByUserId(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findConsentEventByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
        }
        createdAt
      }
      nextToken
    }
  }
`

export const findAppointmentDefinitionByDoctorId = /* GraphQL */ `
  query FindAppointmentDefinitionByDoctorId(
    $doctorID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByDoctorId(
      doctorID: $doctorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appointmentType
        appointmentSubject
        doctorID
        doctor {
          id
          drId
          drName
          avatar
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          created_at
          updated_at
          deleted_at
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          sorter
        }
        active
      }
      nextToken
    }
  }
`

export const findAppointmentDefinitionByClinicId = /* GraphQL */ `
  query FindAppointmentDefinitionByClinicId(
    $clinicID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentDefinitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppointmentDefinitionByClinicId(
      clinicID: $clinicID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appointmentType
        appointmentSubject
        doctorID
        doctor {
          id
          drId
          drName
          avatar
        }
        calendlyAppointmentID
        calendlyAppointment {
          id
          slug
          name
          uri
          scheduling_url
          duration
          kind
          type
          color
          active
          booking_method
          description_plain
          description_html
          internal_note
          pooling_type
          custom_questions
          created_at
          updated_at
          deleted_at
        }
        clinicID
        clinic {
          id
          publicId
          name
          formerlyKnownAs
          status
          address
          phone
          logoImage
          openingHours
          sorter
        }
        active
      }
      nextToken
    }
  }
`
