/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteTypeFormEventInput = {
  id: string,
};

export type ModelTypeFormEventConditionInput = {
  doctorPublicId?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  bookerID?: ModelIDInput | null,
  definitionID?: ModelIDInput | null,
  definitionTitle?: ModelStringInput | null,
  definitionSlug?: ModelStringInput | null,
  definitionType?: ModelAppointmentTypeInput | null,
  definitionSubject?: ModelAppointmentSubjectInput | null,
  definitionDoctorName?: ModelStringInput | null,
  definitionClinicName?: ModelStringInput | null,
  definitionClinicLogo?: ModelStringInput | null,
  who?: ModelWhoInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  bookingUrl?: ModelStringInput | null,
  oldBookingUrl?: ModelStringInput | null,
  paymentSuccess?: ModelBooleanInput | null,
  paymentAmount?: ModelStringInput | null,
  questions?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  hidden?: ModelStringInput | null,
  calendlyStatus?: ModelStringInput | null,
  rescheduled?: ModelBooleanInput | null,
  canceled?: ModelBooleanInput | null,
  cancelReason?: ModelStringInput | null,
  landedAt?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTypeFormEventConditionInput | null > | null,
  or?: Array< ModelTypeFormEventConditionInput | null > | null,
  not?: ModelTypeFormEventConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAppointmentTypeInput = {
  eq?: AppointmentType | null,
  ne?: AppointmentType | null,
};

export enum AppointmentType {
  TELEHEALTH = "TELEHEALTH",
  IN_PERSON = "IN_PERSON",
}


export type ModelAppointmentSubjectInput = {
  eq?: AppointmentSubject | null,
  ne?: AppointmentSubject | null,
};

export enum AppointmentSubject {
  INITIAL_CONSULTATION = "INITIAL_CONSULTATION",
  FIRST_FOLLOW_UP = "FIRST_FOLLOW_UP",
  USUAL_FOLLOW_UP = "USUAL_FOLLOW_UP",
  LONG_FOLLOW_UP = "LONG_FOLLOW_UP",
}


export type ModelWhoInput = {
  eq?: Who | null,
  ne?: Who | null,
};

export enum Who {
  MYSELF = "MYSELF",
  SOELSE = "SOELSE",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type TypeFormEvent = {
  __typename: "TypeFormEvent",
  id: string,
  doctorPublicId: string,
  clinicPublicId?: string | null,
  patientID?: string | null,
  patient?: Patient | null,
  bookerID?: string | null,
  definitionID?: string | null,
  definitionTitle?: string | null,
  definitionSlug?: string | null,
  definitionType?: AppointmentType | null,
  definitionSubject?: AppointmentSubject | null,
  definitionDoctorName?: string | null,
  definitionClinicName?: string | null,
  definitionClinicLogo?: string | null,
  who?: Who | null,
  email?: string | null,
  phone?: string | null,
  firstName: string,
  bookingUrl?: string | null,
  oldBookingUrl?: string | null,
  paymentSuccess?: boolean | null,
  paymentAmount?: string | null,
  questions?: string | null,
  answers?: string | null,
  hidden?: string | null,
  calendlyStatus?: string | null,
  rescheduled?: boolean | null,
  canceled?: boolean | null,
  cancelReason?: string | null,
  landedAt?: string | null,
  submittedAt?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  createdAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  user?: User | null,
  givenName: string,
  surName?: string | null,
  medicareNumber?: string | null,
  addressOneLine?: string | null,
  dateOfBirth?: string | null,
  contactNumber?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  primaryDiagnosis?: string | null,
  otherTreatments?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  sub: string,
  username: string,
  email?: string | null,
  phoneNumber?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type UpdateConsentEventInput = {
  id: string,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  patientID?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  consenter?: string | null,
  consentContent?: string | null,
  researchAllowed?: boolean | null,
  manual?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelConsentEventConditionInput = {
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  consenter?: ModelStringInput | null,
  consentContent?: ModelStringInput | null,
  researchAllowed?: ModelBooleanInput | null,
  manual?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelConsentEventConditionInput | null > | null,
  or?: Array< ModelConsentEventConditionInput | null > | null,
  not?: ModelConsentEventConditionInput | null,
};

export type ConsentEvent = {
  __typename: "ConsentEvent",
  id: string,
  email?: string | null,
  phone?: string | null,
  userID: string,
  user?: User | null,
  patientID?: string | null,
  patient?: Patient | null,
  givenName: string,
  familyName: string,
  consenter: string,
  consentContent: string,
  researchAllowed?: boolean | null,
  manual?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type DeleteConsentEventInput = {
  id: string,
};

export type UpdateQuizInput = {
  id: string,
  userID?: string | null,
  quizType?: QuizType | null,
  questions?: string | null,
  givenName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export enum QuizType {
  CHRONIC_PAIN = "CHRONIC_PAIN",
  DASS_21 = "DASS_21",
  MIDAS = "MIDAS",
}


export type ModelQuizConditionInput = {
  userID?: ModelIDInput | null,
  quizType?: ModelQuizTypeInput | null,
  questions?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelQuizConditionInput | null > | null,
  or?: Array< ModelQuizConditionInput | null > | null,
  not?: ModelQuizConditionInput | null,
};

export type ModelQuizTypeInput = {
  eq?: QuizType | null,
  ne?: QuizType | null,
};

export type Quiz = {
  __typename: "Quiz",
  id: string,
  userID: string,
  quizType?: QuizType | null,
  questions: string,
  givenName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type DeleteQuizInput = {
  id: string,
};

export type UpdateAppointmentNoteInput = {
  id: string,
  note?: string | null,
  appointmentID?: string | null,
  doctorID?: string | null,
  patientID?: string | null,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelAppointmentNoteConditionInput = {
  note?: ModelStringInput | null,
  appointmentID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  phone?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentNoteConditionInput | null > | null,
  or?: Array< ModelAppointmentNoteConditionInput | null > | null,
  not?: ModelAppointmentNoteConditionInput | null,
};

export type AppointmentNote = {
  __typename: "AppointmentNote",
  id: string,
  note: string,
  appointmentID: string,
  appointment?: TypeFormEvent | null,
  doctorID: string,
  doctor?: Doctor | null,
  patientID: string,
  patient?: Patient | null,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Doctor = {
  __typename: "Doctor",
  id: string,
  drId: string,
  drName: string,
  userID: string,
  user?: User | null,
  avatar?: string | null,
  qualifications?: string | null,
  prescriberNumber?: string | null,
  clinics?: ModelClinicDoctorConnection | null,
  appointmentDefinitions?: ModelAppointmentDefinitionConnection | null,
  status?: DoctorStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelClinicDoctorConnection = {
  __typename: "ModelClinicDoctorConnection",
  items:  Array<ClinicDoctor | null >,
  nextToken?: string | null,
};

export type ClinicDoctor = {
  __typename: "ClinicDoctor",
  id: string,
  clinicID: string,
  clinic: Clinic,
  doctorID: string,
  doctor: Doctor,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Clinic = {
  __typename: "Clinic",
  id: string,
  publicId: string,
  name: string,
  formerlyKnownAs?: string | null,
  status?: ClinicStatus | null,
  receptions?: ModelClinicReceptionConnection | null,
  accountants?: ModelClinicAccountantConnection | null,
  doctors?: ModelClinicDoctorConnection | null,
  admins?: ModelClinicAdminConnection | null,
  address?: string | null,
  phone?: string | null,
  logoImage?: string | null,
  location?: Location | null,
  openingHours?: string | null,
  bankName?: string | null,
  bankStateBranch?: string | null,
  bankAccountName?: string | null,
  bankAccountNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sorter: string,
  owner?: string | null,
};

export enum ClinicStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}


export type ModelClinicReceptionConnection = {
  __typename: "ModelClinicReceptionConnection",
  items:  Array<ClinicReception | null >,
  nextToken?: string | null,
};

export type ClinicReception = {
  __typename: "ClinicReception",
  id: string,
  clinicID: string,
  clinic: Clinic,
  receptionID: string,
  reception: Reception,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Reception = {
  __typename: "Reception",
  id: string,
  userID: string,
  user?: User | null,
  clinics?: ModelClinicReceptionConnection | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type ModelClinicAccountantConnection = {
  __typename: "ModelClinicAccountantConnection",
  items:  Array<ClinicAccountant | null >,
  nextToken?: string | null,
};

export type ClinicAccountant = {
  __typename: "ClinicAccountant",
  id: string,
  clinicID: string,
  clinic: Clinic,
  accountantID: string,
  accountant: Accountant,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Accountant = {
  __typename: "Accountant",
  id: string,
  userID: string,
  user?: User | null,
  clinics?: ModelClinicAccountantConnection | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type ModelClinicAdminConnection = {
  __typename: "ModelClinicAdminConnection",
  items:  Array<ClinicAdmin | null >,
  nextToken?: string | null,
};

export type ClinicAdmin = {
  __typename: "ClinicAdmin",
  id: string,
  clinicID: string,
  clinic: Clinic,
  adminID: string,
  admin: Admin,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Admin = {
  __typename: "Admin",
  id: string,
  userID: string,
  user?: User | null,
  clinics?: ModelClinicAdminConnection | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type Location = {
  __typename: "Location",
  lat: string,
  lon: string,
};

export type ModelAppointmentDefinitionConnection = {
  __typename: "ModelAppointmentDefinitionConnection",
  items:  Array<AppointmentDefinition | null >,
  nextToken?: string | null,
};

export type AppointmentDefinition = {
  __typename: "AppointmentDefinition",
  id: string,
  doctorID: string,
  doctor?: Doctor | null,
  calendlyAppointmentID?: string | null,
  calendlyAppointment?: CalendlyAppointment | null,
  clinicID?: string | null,
  clinic?: Clinic | null,
  appointmentType?: AppointmentType | null,
  appointmentSubject?: AppointmentSubject | null,
  drPublicId?: string | null,
  drName?: string | null,
  drAvatar?: string | null,
  clinicPublicId?: string | null,
  clinicName?: string | null,
  clinicFormerlyKnownAs?: string | null,
  clinicStatus?: ClinicStatus | null,
  clinicLogoImage?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type CalendlyAppointment = {
  __typename: "CalendlyAppointment",
  id: string,
  slug: string,
  name?: string | null,
  uri?: string | null,
  scheduling_url?: string | null,
  duration?: number | null,
  kind?: string | null,
  type?: string | null,
  color?: string | null,
  active?: boolean | null,
  booking_method?: string | null,
  description_plain?: string | null,
  description_html?: string | null,
  internal_note?: string | null,
  pooling_type?: string | null,
  secret?: boolean | null,
  profile?: string | null,
  custom_questions?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export enum DoctorStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}


export type UpdateAppointmentMedicationInput = {
  id: string,
  mapID?: string | null,
  productID?: string | null,
  appointmentID?: string | null,
  doctorID?: string | null,
  patientID?: string | null,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  dailyDose?: string | null,
  measurement?: Measurement | null,
  quantity?: string | null,
  repeats?: string | null,
  repeatInterval?: string | null,
  frequency?: string | null,
  prn?: boolean | null,
  instruction?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export enum Measurement {
  GRAM = "GRAM",
  MILLIGRAM = "MILLIGRAM",
  MILLILITER = "MILLILITER",
  NUMERAL = "NUMERAL",
}


export type ModelAppointmentMedicationConditionInput = {
  mapID?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  appointmentID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  phone?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  dailyDose?: ModelStringInput | null,
  measurement?: ModelMeasurementInput | null,
  quantity?: ModelStringInput | null,
  repeats?: ModelStringInput | null,
  repeatInterval?: ModelStringInput | null,
  frequency?: ModelStringInput | null,
  prn?: ModelBooleanInput | null,
  instruction?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentMedicationConditionInput | null > | null,
  or?: Array< ModelAppointmentMedicationConditionInput | null > | null,
  not?: ModelAppointmentMedicationConditionInput | null,
};

export type ModelMeasurementInput = {
  eq?: Measurement | null,
  ne?: Measurement | null,
};

export type AppointmentMedication = {
  __typename: "AppointmentMedication",
  id: string,
  mapID: string,
  map?: MedicalAuthorisedPrescriber | null,
  productID: string,
  product?: CannabisProduct | null,
  appointmentID: string,
  appointment?: TypeFormEvent | null,
  doctorID: string,
  doctor?: Doctor | null,
  patientID: string,
  patient?: Patient | null,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  dailyDose?: string | null,
  measurement?: Measurement | null,
  quantity?: string | null,
  repeats?: string | null,
  repeatInterval?: string | null,
  frequency?: string | null,
  prn?: boolean | null,
  instruction?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type MedicalAuthorisedPrescriber = {
  __typename: "MedicalAuthorisedPrescriber",
  id: string,
  mapNumber: string,
  category?: ProductCategory | null,
  idxOrder: number,
  doctorID: string,
  doctor: Doctor,
  patientID?: string | null,
  patient?: Patient | null,
  status?: TgaStatus | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  createdDate?: string | null,
  decisionDate?: string | null,
  expiryDate?: string | null,
  approvalDate?: string | null,
  isSAS?: boolean | null,
  archived?: boolean | null,
  products?: ModelProductMapConnection | null,
  createdAt?: string | null,
  sorter: string,
  owner?: string | null,
  updatedAt: string,
};

export enum ProductCategory {
  CATEGORY_1 = "CATEGORY_1",
  CATEGORY_2 = "CATEGORY_2",
  CATEGORY_3 = "CATEGORY_3",
  CATEGORY_4 = "CATEGORY_4",
  CATEGORY_5 = "CATEGORY_5",
}


export enum TgaStatus {
  TGA_APPROVED = "TGA_APPROVED",
  TGA_UNDER_REVIEW = "TGA_UNDER_REVIEW",
  SAS_APPLIED = "SAS_APPLIED",
  SAS_APPROVED = "SAS_APPROVED",
  SAS_DENIED = "SAS_DENIED",
  EXPIRED = "EXPIRED",
}


export enum ProductPresentation {
  CAPSULE = "CAPSULE",
  ORAL_LIQUID = "ORAL_LIQUID",
  DRIED_HERB = "DRIED_HERB",
  SPRAY_SOLUTION = "SPRAY_SOLUTION",
}


export type ModelProductMapConnection = {
  __typename: "ModelProductMapConnection",
  items:  Array<ProductMap | null >,
  nextToken?: string | null,
};

export type ProductMap = {
  __typename: "ProductMap",
  id: string,
  productID: string,
  product: CannabisProduct,
  mapID: string,
  map: MedicalAuthorisedPrescriber,
  doctorID: string,
  doctor: Doctor,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type CannabisProduct = {
  __typename: "CannabisProduct",
  id: string,
  idxOrder: number,
  name: string,
  ingredients?: string | null,
  qtyPerDosageUnit?: string | null,
  category?: ProductCategory | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  strength?: string | null,
  volume?: string | null,
  sponsor?: string | null,
  archived?: boolean | null,
  maps?: ModelProductMapConnection | null,
  createdAt?: string | null,
  sorter: string,
  owner?: string | null,
  updatedAt: string,
};

export type CreateTypeFormEventInput = {
  id?: string | null,
  doctorPublicId: string,
  clinicPublicId?: string | null,
  patientID?: string | null,
  bookerID?: string | null,
  definitionID?: string | null,
  definitionTitle?: string | null,
  definitionSlug?: string | null,
  definitionType?: AppointmentType | null,
  definitionSubject?: AppointmentSubject | null,
  definitionDoctorName?: string | null,
  definitionClinicName?: string | null,
  definitionClinicLogo?: string | null,
  who?: Who | null,
  email?: string | null,
  phone?: string | null,
  firstName: string,
  bookingUrl?: string | null,
  oldBookingUrl?: string | null,
  paymentSuccess?: boolean | null,
  paymentAmount?: string | null,
  questions?: string | null,
  answers?: string | null,
  hidden?: string | null,
  calendlyStatus?: string | null,
  rescheduled?: boolean | null,
  canceled?: boolean | null,
  cancelReason?: string | null,
  landedAt?: string | null,
  submittedAt?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  createdAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
};

export type UpdateTypeFormEventInput = {
  id: string,
  doctorPublicId?: string | null,
  clinicPublicId?: string | null,
  patientID?: string | null,
  bookerID?: string | null,
  definitionID?: string | null,
  definitionTitle?: string | null,
  definitionSlug?: string | null,
  definitionType?: AppointmentType | null,
  definitionSubject?: AppointmentSubject | null,
  definitionDoctorName?: string | null,
  definitionClinicName?: string | null,
  definitionClinicLogo?: string | null,
  who?: Who | null,
  email?: string | null,
  phone?: string | null,
  firstName?: string | null,
  bookingUrl?: string | null,
  oldBookingUrl?: string | null,
  paymentSuccess?: boolean | null,
  paymentAmount?: string | null,
  questions?: string | null,
  answers?: string | null,
  hidden?: string | null,
  calendlyStatus?: string | null,
  rescheduled?: boolean | null,
  canceled?: boolean | null,
  cancelReason?: string | null,
  landedAt?: string | null,
  submittedAt?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  createdAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  sub: string,
  username: string,
  email?: string | null,
  phoneNumber?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelUserConditionInput = {
  sub?: ModelStringInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  sub?: string | null,
  username?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreatePatientInput = {
  id?: string | null,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  givenName: string,
  surName?: string | null,
  medicareNumber?: string | null,
  addressOneLine?: string | null,
  dateOfBirth?: string | null,
  contactNumber?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  primaryDiagnosis?: string | null,
  otherTreatments?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelPatientConditionInput = {
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  surName?: ModelStringInput | null,
  medicareNumber?: ModelStringInput | null,
  addressOneLine?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  nextOfKin?: ModelStringInput | null,
  nextOfKinRelation?: ModelStringInput | null,
  nextOfKinContact?: ModelStringInput | null,
  powerOfAttorney?: ModelStringInput | null,
  powerOfAttorneyRelation?: ModelStringInput | null,
  powerOfAttorneyContact?: ModelStringInput | null,
  carer?: ModelStringInput | null,
  carerRelation?: ModelStringInput | null,
  carerContact?: ModelStringInput | null,
  emergency?: ModelStringInput | null,
  emergencyRelation?: ModelStringInput | null,
  emergencyContact?: ModelStringInput | null,
  primaryDiagnosis?: ModelStringInput | null,
  otherTreatments?: ModelStringInput | null,
  otherMedications?: ModelStringInput | null,
  selfMedicated?: ModelStringInput | null,
  kidneyLiver?: ModelStringInput | null,
  cardiovascular?: ModelStringInput | null,
  substanceAbuseDisorder?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  smoke?: ModelStringInput | null,
  drinkAlcohol?: ModelStringInput | null,
  otherConditions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
};

export type UpdatePatientInput = {
  id: string,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  givenName?: string | null,
  surName?: string | null,
  medicareNumber?: string | null,
  addressOneLine?: string | null,
  dateOfBirth?: string | null,
  contactNumber?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  primaryDiagnosis?: string | null,
  otherTreatments?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeletePatientInput = {
  id: string,
};

export type CreateReferredInput = {
  id?: string | null,
  givenName: string,
  surName?: string | null,
  addressOneLine?: string | null,
  medicareNumber?: string | null,
  dateOfBirth?: string | null,
  phone?: string | null,
  ihiNo?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  symptoms?: string | null,
  otherTreatments?: string | null,
  primaryDiagnosis?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  cvConditions?: string | null,
  practitionerConcerns?: string | null,
  recentReports?: string | null,
  clinicPublicId?: string | null,
  doctorPublicId?: string | null,
  readByClinic?: boolean | null,
  readByDoctor?: boolean | null,
  practitionerFirstName?: string | null,
  practitionerLastName?: string | null,
  practitionerAddress?: string | null,
  practitionerProviderNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelReferredConditionInput = {
  givenName?: ModelStringInput | null,
  surName?: ModelStringInput | null,
  addressOneLine?: ModelStringInput | null,
  medicareNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  ihiNo?: ModelStringInput | null,
  nextOfKin?: ModelStringInput | null,
  nextOfKinRelation?: ModelStringInput | null,
  nextOfKinContact?: ModelStringInput | null,
  powerOfAttorney?: ModelStringInput | null,
  powerOfAttorneyRelation?: ModelStringInput | null,
  powerOfAttorneyContact?: ModelStringInput | null,
  carer?: ModelStringInput | null,
  carerRelation?: ModelStringInput | null,
  carerContact?: ModelStringInput | null,
  emergency?: ModelStringInput | null,
  emergencyRelation?: ModelStringInput | null,
  emergencyContact?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  otherTreatments?: ModelStringInput | null,
  primaryDiagnosis?: ModelStringInput | null,
  otherMedications?: ModelStringInput | null,
  selfMedicated?: ModelStringInput | null,
  kidneyLiver?: ModelStringInput | null,
  cardiovascular?: ModelStringInput | null,
  substanceAbuseDisorder?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  smoke?: ModelStringInput | null,
  drinkAlcohol?: ModelStringInput | null,
  otherConditions?: ModelStringInput | null,
  cvConditions?: ModelStringInput | null,
  practitionerConcerns?: ModelStringInput | null,
  recentReports?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  doctorPublicId?: ModelStringInput | null,
  readByClinic?: ModelBooleanInput | null,
  readByDoctor?: ModelBooleanInput | null,
  practitionerFirstName?: ModelStringInput | null,
  practitionerLastName?: ModelStringInput | null,
  practitionerAddress?: ModelStringInput | null,
  practitionerProviderNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelReferredConditionInput | null > | null,
  or?: Array< ModelReferredConditionInput | null > | null,
  not?: ModelReferredConditionInput | null,
};

export type Referred = {
  __typename: "Referred",
  id: string,
  givenName: string,
  surName?: string | null,
  addressOneLine?: string | null,
  medicareNumber?: string | null,
  dateOfBirth?: string | null,
  phone?: string | null,
  ihiNo?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  symptoms?: string | null,
  otherTreatments?: string | null,
  primaryDiagnosis?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  cvConditions?: string | null,
  practitionerConcerns?: string | null,
  recentReports?: string | null,
  clinicPublicId?: string | null,
  doctorPublicId?: string | null,
  readByClinic?: boolean | null,
  readByDoctor?: boolean | null,
  practitionerFirstName?: string | null,
  practitionerLastName?: string | null,
  practitionerAddress?: string | null,
  practitionerProviderNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateReferredInput = {
  id: string,
  givenName?: string | null,
  surName?: string | null,
  addressOneLine?: string | null,
  medicareNumber?: string | null,
  dateOfBirth?: string | null,
  phone?: string | null,
  ihiNo?: string | null,
  nextOfKin?: string | null,
  nextOfKinRelation?: string | null,
  nextOfKinContact?: string | null,
  powerOfAttorney?: string | null,
  powerOfAttorneyRelation?: string | null,
  powerOfAttorneyContact?: string | null,
  carer?: string | null,
  carerRelation?: string | null,
  carerContact?: string | null,
  emergency?: string | null,
  emergencyRelation?: string | null,
  emergencyContact?: string | null,
  symptoms?: string | null,
  otherTreatments?: string | null,
  primaryDiagnosis?: string | null,
  otherMedications?: string | null,
  selfMedicated?: string | null,
  kidneyLiver?: string | null,
  cardiovascular?: string | null,
  substanceAbuseDisorder?: string | null,
  allergies?: string | null,
  smoke?: string | null,
  drinkAlcohol?: string | null,
  otherConditions?: string | null,
  cvConditions?: string | null,
  practitionerConcerns?: string | null,
  recentReports?: string | null,
  clinicPublicId?: string | null,
  doctorPublicId?: string | null,
  readByClinic?: boolean | null,
  readByDoctor?: boolean | null,
  practitionerFirstName?: string | null,
  practitionerLastName?: string | null,
  practitionerAddress?: string | null,
  practitionerProviderNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeleteReferredInput = {
  id: string,
};

export type CreateConsentEventInput = {
  id?: string | null,
  email?: string | null,
  phone?: string | null,
  userID: string,
  patientID?: string | null,
  givenName: string,
  familyName: string,
  consenter: string,
  consentContent: string,
  researchAllowed?: boolean | null,
  manual?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type CreateQuizInput = {
  id?: string | null,
  userID: string,
  quizType?: QuizType | null,
  questions: string,
  givenName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type CreateCalendlyAppointmentInput = {
  id?: string | null,
  slug: string,
  name?: string | null,
  uri?: string | null,
  scheduling_url?: string | null,
  duration?: number | null,
  kind?: string | null,
  type?: string | null,
  color?: string | null,
  active?: boolean | null,
  booking_method?: string | null,
  description_plain?: string | null,
  description_html?: string | null,
  internal_note?: string | null,
  pooling_type?: string | null,
  secret?: boolean | null,
  profile?: string | null,
  custom_questions?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelCalendlyAppointmentConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  uri?: ModelStringInput | null,
  scheduling_url?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  kind?: ModelStringInput | null,
  type?: ModelStringInput | null,
  color?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  booking_method?: ModelStringInput | null,
  description_plain?: ModelStringInput | null,
  description_html?: ModelStringInput | null,
  internal_note?: ModelStringInput | null,
  pooling_type?: ModelStringInput | null,
  secret?: ModelBooleanInput | null,
  profile?: ModelStringInput | null,
  custom_questions?: ModelStringInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  deleted_at?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCalendlyAppointmentConditionInput | null > | null,
  or?: Array< ModelCalendlyAppointmentConditionInput | null > | null,
  not?: ModelCalendlyAppointmentConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateCalendlyAppointmentInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  uri?: string | null,
  scheduling_url?: string | null,
  duration?: number | null,
  kind?: string | null,
  type?: string | null,
  color?: string | null,
  active?: boolean | null,
  booking_method?: string | null,
  description_plain?: string | null,
  description_html?: string | null,
  internal_note?: string | null,
  pooling_type?: string | null,
  secret?: boolean | null,
  profile?: string | null,
  custom_questions?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteCalendlyAppointmentInput = {
  id: string,
};

export type CreateDoctorInput = {
  id?: string | null,
  drId: string,
  drName: string,
  userID: string,
  avatar?: string | null,
  qualifications?: string | null,
  prescriberNumber?: string | null,
  status?: DoctorStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelDoctorConditionInput = {
  drId?: ModelStringInput | null,
  drName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  avatar?: ModelStringInput | null,
  qualifications?: ModelStringInput | null,
  prescriberNumber?: ModelStringInput | null,
  status?: ModelDoctorStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelDoctorConditionInput | null > | null,
  or?: Array< ModelDoctorConditionInput | null > | null,
  not?: ModelDoctorConditionInput | null,
};

export type ModelDoctorStatusInput = {
  eq?: DoctorStatus | null,
  ne?: DoctorStatus | null,
};

export type UpdateDoctorInput = {
  id: string,
  drId?: string | null,
  drName?: string | null,
  userID?: string | null,
  avatar?: string | null,
  qualifications?: string | null,
  prescriberNumber?: string | null,
  status?: DoctorStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeleteDoctorInput = {
  id: string,
};

export type CreateAppointmentDefinitionInput = {
  id?: string | null,
  doctorID: string,
  calendlyAppointmentID?: string | null,
  clinicID?: string | null,
  appointmentType?: AppointmentType | null,
  appointmentSubject?: AppointmentSubject | null,
  drPublicId?: string | null,
  drName?: string | null,
  drAvatar?: string | null,
  clinicPublicId?: string | null,
  clinicName?: string | null,
  clinicFormerlyKnownAs?: string | null,
  clinicStatus?: ClinicStatus | null,
  clinicLogoImage?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelAppointmentDefinitionConditionInput = {
  doctorID?: ModelIDInput | null,
  calendlyAppointmentID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  appointmentType?: ModelAppointmentTypeInput | null,
  appointmentSubject?: ModelAppointmentSubjectInput | null,
  drPublicId?: ModelStringInput | null,
  drName?: ModelStringInput | null,
  drAvatar?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  clinicFormerlyKnownAs?: ModelStringInput | null,
  clinicStatus?: ModelClinicStatusInput | null,
  clinicLogoImage?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentDefinitionConditionInput | null > | null,
  or?: Array< ModelAppointmentDefinitionConditionInput | null > | null,
  not?: ModelAppointmentDefinitionConditionInput | null,
};

export type ModelClinicStatusInput = {
  eq?: ClinicStatus | null,
  ne?: ClinicStatus | null,
};

export type UpdateAppointmentDefinitionInput = {
  id: string,
  doctorID?: string | null,
  calendlyAppointmentID?: string | null,
  clinicID?: string | null,
  appointmentType?: AppointmentType | null,
  appointmentSubject?: AppointmentSubject | null,
  drPublicId?: string | null,
  drName?: string | null,
  drAvatar?: string | null,
  clinicPublicId?: string | null,
  clinicName?: string | null,
  clinicFormerlyKnownAs?: string | null,
  clinicStatus?: ClinicStatus | null,
  clinicLogoImage?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAppointmentDefinitionInput = {
  id: string,
};

export type CreateAppointmentNoteInput = {
  id?: string | null,
  note: string,
  appointmentID: string,
  doctorID: string,
  patientID: string,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAppointmentNoteInput = {
  id: string,
};

export type CreateAppointmentMedicationInput = {
  id?: string | null,
  mapID: string,
  productID: string,
  appointmentID: string,
  doctorID: string,
  patientID: string,
  phone?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  dailyDose?: string | null,
  measurement?: Measurement | null,
  quantity?: string | null,
  repeats?: string | null,
  repeatInterval?: string | null,
  frequency?: string | null,
  prn?: boolean | null,
  instruction?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAppointmentMedicationInput = {
  id: string,
};

export type CreateAdminInput = {
  id?: string | null,
  userID: string,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelAdminConditionInput = {
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAdminConditionInput | null > | null,
  or?: Array< ModelAdminConditionInput | null > | null,
  not?: ModelAdminConditionInput | null,
};

export type UpdateAdminInput = {
  id: string,
  userID?: string | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAdminInput = {
  id: string,
};

export type CreateReceptionInput = {
  id?: string | null,
  userID: string,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelReceptionConditionInput = {
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelReceptionConditionInput | null > | null,
  or?: Array< ModelReceptionConditionInput | null > | null,
  not?: ModelReceptionConditionInput | null,
};

export type UpdateReceptionInput = {
  id: string,
  userID?: string | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteReceptionInput = {
  id: string,
};

export type CreateAccountantInput = {
  id?: string | null,
  userID: string,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelAccountantConditionInput = {
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAccountantConditionInput | null > | null,
  or?: Array< ModelAccountantConditionInput | null > | null,
  not?: ModelAccountantConditionInput | null,
};

export type UpdateAccountantInput = {
  id: string,
  userID?: string | null,
  name?: string | null,
  avatar?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteAccountantInput = {
  id: string,
};

export type CreateClinicInput = {
  id?: string | null,
  publicId: string,
  name: string,
  formerlyKnownAs?: string | null,
  status?: ClinicStatus | null,
  address?: string | null,
  phone?: string | null,
  logoImage?: string | null,
  location?: LocationInput | null,
  openingHours?: string | null,
  bankName?: string | null,
  bankStateBranch?: string | null,
  bankAccountName?: string | null,
  bankAccountNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sorter: string,
  owner?: string | null,
};

export type LocationInput = {
  lat: string,
  lon: string,
};

export type ModelClinicConditionInput = {
  publicId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  formerlyKnownAs?: ModelStringInput | null,
  status?: ModelClinicStatusInput | null,
  address?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  logoImage?: ModelStringInput | null,
  openingHours?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankStateBranch?: ModelStringInput | null,
  bankAccountName?: ModelStringInput | null,
  bankAccountNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicConditionInput | null > | null,
  or?: Array< ModelClinicConditionInput | null > | null,
  not?: ModelClinicConditionInput | null,
};

export type UpdateClinicInput = {
  id: string,
  publicId?: string | null,
  name?: string | null,
  formerlyKnownAs?: string | null,
  status?: ClinicStatus | null,
  address?: string | null,
  phone?: string | null,
  logoImage?: string | null,
  location?: LocationInput | null,
  openingHours?: string | null,
  bankName?: string | null,
  bankStateBranch?: string | null,
  bankAccountName?: string | null,
  bankAccountNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
};

export type DeleteClinicInput = {
  id: string,
};

export type CreateClinicAdminInput = {
  id?: string | null,
  clinicID: string,
  adminID: string,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelClinicAdminConditionInput = {
  clinicID?: ModelIDInput | null,
  adminID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicAdminConditionInput | null > | null,
  or?: Array< ModelClinicAdminConditionInput | null > | null,
  not?: ModelClinicAdminConditionInput | null,
};

export type UpdateClinicAdminInput = {
  id: string,
  clinicID?: string | null,
  adminID?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteClinicAdminInput = {
  id: string,
};

export type CreateClinicReceptionInput = {
  id?: string | null,
  clinicID: string,
  receptionID: string,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelClinicReceptionConditionInput = {
  clinicID?: ModelIDInput | null,
  receptionID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicReceptionConditionInput | null > | null,
  or?: Array< ModelClinicReceptionConditionInput | null > | null,
  not?: ModelClinicReceptionConditionInput | null,
};

export type UpdateClinicReceptionInput = {
  id: string,
  clinicID?: string | null,
  receptionID?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteClinicReceptionInput = {
  id: string,
};

export type CreateClinicAccountantInput = {
  id?: string | null,
  clinicID: string,
  accountantID: string,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelClinicAccountantConditionInput = {
  clinicID?: ModelIDInput | null,
  accountantID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicAccountantConditionInput | null > | null,
  or?: Array< ModelClinicAccountantConditionInput | null > | null,
  not?: ModelClinicAccountantConditionInput | null,
};

export type UpdateClinicAccountantInput = {
  id: string,
  clinicID?: string | null,
  accountantID?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteClinicAccountantInput = {
  id: string,
};

export type CreateClinicDoctorInput = {
  id?: string | null,
  clinicID: string,
  doctorID: string,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelClinicDoctorConditionInput = {
  clinicID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicDoctorConditionInput | null > | null,
  or?: Array< ModelClinicDoctorConditionInput | null > | null,
  not?: ModelClinicDoctorConditionInput | null,
};

export type UpdateClinicDoctorInput = {
  id: string,
  clinicID?: string | null,
  doctorID?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteClinicDoctorInput = {
  id: string,
};

export type CreateCannabisProductInput = {
  id?: string | null,
  idxOrder: number,
  name: string,
  ingredients?: string | null,
  qtyPerDosageUnit?: string | null,
  category?: ProductCategory | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  strength?: string | null,
  volume?: string | null,
  sponsor?: string | null,
  archived?: boolean | null,
  createdAt?: string | null,
  sorter: string,
  owner?: string | null,
};

export type ModelCannabisProductConditionInput = {
  idxOrder?: ModelIntInput | null,
  name?: ModelStringInput | null,
  ingredients?: ModelStringInput | null,
  qtyPerDosageUnit?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  presentation?: ModelProductPresentationInput | null,
  presentationText?: ModelStringInput | null,
  strength?: ModelStringInput | null,
  volume?: ModelStringInput | null,
  sponsor?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCannabisProductConditionInput | null > | null,
  or?: Array< ModelCannabisProductConditionInput | null > | null,
  not?: ModelCannabisProductConditionInput | null,
};

export type ModelProductCategoryInput = {
  eq?: ProductCategory | null,
  ne?: ProductCategory | null,
};

export type ModelProductPresentationInput = {
  eq?: ProductPresentation | null,
  ne?: ProductPresentation | null,
};

export type UpdateCannabisProductInput = {
  id: string,
  idxOrder?: number | null,
  name?: string | null,
  ingredients?: string | null,
  qtyPerDosageUnit?: string | null,
  category?: ProductCategory | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  strength?: string | null,
  volume?: string | null,
  sponsor?: string | null,
  archived?: boolean | null,
  createdAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
};

export type DeleteCannabisProductInput = {
  id: string,
};

export type CreateMedicalAuthorisedPrescriberInput = {
  id?: string | null,
  mapNumber: string,
  category?: ProductCategory | null,
  idxOrder: number,
  doctorID: string,
  patientID?: string | null,
  status?: TgaStatus | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  createdDate?: string | null,
  decisionDate?: string | null,
  expiryDate?: string | null,
  approvalDate?: string | null,
  isSAS?: boolean | null,
  archived?: boolean | null,
  createdAt?: string | null,
  sorter: string,
  owner?: string | null,
};

export type ModelMedicalAuthorisedPrescriberConditionInput = {
  mapNumber?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  idxOrder?: ModelIntInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  status?: ModelTgaStatusInput | null,
  presentation?: ModelProductPresentationInput | null,
  presentationText?: ModelStringInput | null,
  createdDate?: ModelStringInput | null,
  decisionDate?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  approvalDate?: ModelStringInput | null,
  isSAS?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMedicalAuthorisedPrescriberConditionInput | null > | null,
  or?: Array< ModelMedicalAuthorisedPrescriberConditionInput | null > | null,
  not?: ModelMedicalAuthorisedPrescriberConditionInput | null,
};

export type ModelTgaStatusInput = {
  eq?: TgaStatus | null,
  ne?: TgaStatus | null,
};

export type UpdateMedicalAuthorisedPrescriberInput = {
  id: string,
  mapNumber?: string | null,
  category?: ProductCategory | null,
  idxOrder?: number | null,
  doctorID?: string | null,
  patientID?: string | null,
  status?: TgaStatus | null,
  presentation?: ProductPresentation | null,
  presentationText?: string | null,
  createdDate?: string | null,
  decisionDate?: string | null,
  expiryDate?: string | null,
  approvalDate?: string | null,
  isSAS?: boolean | null,
  archived?: boolean | null,
  createdAt?: string | null,
  sorter?: string | null,
  owner?: string | null,
};

export type DeleteMedicalAuthorisedPrescriberInput = {
  id: string,
};

export type CreateScriptReceiverInput = {
  id?: string | null,
  doctorID?: string | null,
  name?: string | null,
  email?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelScriptReceiverConditionInput = {
  doctorID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelScriptReceiverConditionInput | null > | null,
  or?: Array< ModelScriptReceiverConditionInput | null > | null,
  not?: ModelScriptReceiverConditionInput | null,
};

export type ScriptReceiver = {
  __typename: "ScriptReceiver",
  id: string,
  doctorID?: string | null,
  doctor?: Doctor | null,
  name?: string | null,
  email?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt: string,
};

export type UpdateScriptReceiverInput = {
  id: string,
  doctorID?: string | null,
  name?: string | null,
  email?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteScriptReceiverInput = {
  id: string,
};

export type CreateProductMapInput = {
  id?: string | null,
  productID: string,
  mapID: string,
  doctorID: string,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelProductMapConditionInput = {
  productID?: ModelIDInput | null,
  mapID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductMapConditionInput | null > | null,
  or?: Array< ModelProductMapConditionInput | null > | null,
  not?: ModelProductMapConditionInput | null,
};

export type UpdateProductMapInput = {
  id: string,
  productID?: string | null,
  mapID?: string | null,
  doctorID?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteProductMapInput = {
  id: string,
};

export type CreatePatientLinkInput = {
  doctorID: string,
  patientID: string,
  clinicID?: string | null,
  createdAt?: string | null,
};

export type ModelPatientLinkConditionInput = {
  clinicID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPatientLinkConditionInput | null > | null,
  or?: Array< ModelPatientLinkConditionInput | null > | null,
  not?: ModelPatientLinkConditionInput | null,
};

export type PatientLink = {
  __typename: "PatientLink",
  doctorID: string,
  patientID: string,
  patient?: Patient | null,
  clinicID?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdatePatientLinkInput = {
  doctorID: string,
  patientID: string,
  clinicID?: string | null,
  createdAt?: string | null,
};

export type DeletePatientLinkInput = {
  doctorID: string,
  patientID: string,
};

export type ModelTypeFormEventFilterInput = {
  id?: ModelIDInput | null,
  doctorPublicId?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  bookerID?: ModelIDInput | null,
  definitionID?: ModelIDInput | null,
  definitionTitle?: ModelStringInput | null,
  definitionSlug?: ModelStringInput | null,
  definitionType?: ModelAppointmentTypeInput | null,
  definitionSubject?: ModelAppointmentSubjectInput | null,
  definitionDoctorName?: ModelStringInput | null,
  definitionClinicName?: ModelStringInput | null,
  definitionClinicLogo?: ModelStringInput | null,
  who?: ModelWhoInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  bookingUrl?: ModelStringInput | null,
  oldBookingUrl?: ModelStringInput | null,
  paymentSuccess?: ModelBooleanInput | null,
  paymentAmount?: ModelStringInput | null,
  questions?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  hidden?: ModelStringInput | null,
  calendlyStatus?: ModelStringInput | null,
  rescheduled?: ModelBooleanInput | null,
  canceled?: ModelBooleanInput | null,
  cancelReason?: ModelStringInput | null,
  landedAt?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTypeFormEventFilterInput | null > | null,
  or?: Array< ModelTypeFormEventFilterInput | null > | null,
  not?: ModelTypeFormEventFilterInput | null,
};

export type ModelTypeFormEventConnection = {
  __typename: "ModelTypeFormEventConnection",
  items:  Array<TypeFormEvent | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  surName?: ModelStringInput | null,
  medicareNumber?: ModelStringInput | null,
  addressOneLine?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  contactNumber?: ModelStringInput | null,
  nextOfKin?: ModelStringInput | null,
  nextOfKinRelation?: ModelStringInput | null,
  nextOfKinContact?: ModelStringInput | null,
  powerOfAttorney?: ModelStringInput | null,
  powerOfAttorneyRelation?: ModelStringInput | null,
  powerOfAttorneyContact?: ModelStringInput | null,
  carer?: ModelStringInput | null,
  carerRelation?: ModelStringInput | null,
  carerContact?: ModelStringInput | null,
  emergency?: ModelStringInput | null,
  emergencyRelation?: ModelStringInput | null,
  emergencyContact?: ModelStringInput | null,
  primaryDiagnosis?: ModelStringInput | null,
  otherTreatments?: ModelStringInput | null,
  otherMedications?: ModelStringInput | null,
  selfMedicated?: ModelStringInput | null,
  kidneyLiver?: ModelStringInput | null,
  cardiovascular?: ModelStringInput | null,
  substanceAbuseDisorder?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  smoke?: ModelStringInput | null,
  drinkAlcohol?: ModelStringInput | null,
  otherConditions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
};

export type ModelReferredFilterInput = {
  id?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  surName?: ModelStringInput | null,
  addressOneLine?: ModelStringInput | null,
  medicareNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  ihiNo?: ModelStringInput | null,
  nextOfKin?: ModelStringInput | null,
  nextOfKinRelation?: ModelStringInput | null,
  nextOfKinContact?: ModelStringInput | null,
  powerOfAttorney?: ModelStringInput | null,
  powerOfAttorneyRelation?: ModelStringInput | null,
  powerOfAttorneyContact?: ModelStringInput | null,
  carer?: ModelStringInput | null,
  carerRelation?: ModelStringInput | null,
  carerContact?: ModelStringInput | null,
  emergency?: ModelStringInput | null,
  emergencyRelation?: ModelStringInput | null,
  emergencyContact?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  otherTreatments?: ModelStringInput | null,
  primaryDiagnosis?: ModelStringInput | null,
  otherMedications?: ModelStringInput | null,
  selfMedicated?: ModelStringInput | null,
  kidneyLiver?: ModelStringInput | null,
  cardiovascular?: ModelStringInput | null,
  substanceAbuseDisorder?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  smoke?: ModelStringInput | null,
  drinkAlcohol?: ModelStringInput | null,
  otherConditions?: ModelStringInput | null,
  cvConditions?: ModelStringInput | null,
  practitionerConcerns?: ModelStringInput | null,
  recentReports?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  doctorPublicId?: ModelStringInput | null,
  readByClinic?: ModelBooleanInput | null,
  readByDoctor?: ModelBooleanInput | null,
  practitionerFirstName?: ModelStringInput | null,
  practitionerLastName?: ModelStringInput | null,
  practitionerAddress?: ModelStringInput | null,
  practitionerProviderNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelReferredFilterInput | null > | null,
  or?: Array< ModelReferredFilterInput | null > | null,
  not?: ModelReferredFilterInput | null,
};

export type ModelReferredConnection = {
  __typename: "ModelReferredConnection",
  items:  Array<Referred | null >,
  nextToken?: string | null,
};

export type ModelConsentEventFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  consenter?: ModelStringInput | null,
  consentContent?: ModelStringInput | null,
  researchAllowed?: ModelBooleanInput | null,
  manual?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelConsentEventFilterInput | null > | null,
  or?: Array< ModelConsentEventFilterInput | null > | null,
  not?: ModelConsentEventFilterInput | null,
};

export type ModelConsentEventConnection = {
  __typename: "ModelConsentEventConnection",
  items:  Array<ConsentEvent | null >,
  nextToken?: string | null,
};

export type ModelQuizFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  quizType?: ModelQuizTypeInput | null,
  questions?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelQuizFilterInput | null > | null,
  or?: Array< ModelQuizFilterInput | null > | null,
  not?: ModelQuizFilterInput | null,
};

export type ModelQuizConnection = {
  __typename: "ModelQuizConnection",
  items:  Array<Quiz | null >,
  nextToken?: string | null,
};

export type ModelCalendlyAppointmentFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  uri?: ModelStringInput | null,
  scheduling_url?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  kind?: ModelStringInput | null,
  type?: ModelStringInput | null,
  color?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  booking_method?: ModelStringInput | null,
  description_plain?: ModelStringInput | null,
  description_html?: ModelStringInput | null,
  internal_note?: ModelStringInput | null,
  pooling_type?: ModelStringInput | null,
  secret?: ModelBooleanInput | null,
  profile?: ModelStringInput | null,
  custom_questions?: ModelStringInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  deleted_at?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCalendlyAppointmentFilterInput | null > | null,
  or?: Array< ModelCalendlyAppointmentFilterInput | null > | null,
  not?: ModelCalendlyAppointmentFilterInput | null,
};

export type ModelCalendlyAppointmentConnection = {
  __typename: "ModelCalendlyAppointmentConnection",
  items:  Array<CalendlyAppointment | null >,
  nextToken?: string | null,
};

export type ModelDoctorFilterInput = {
  id?: ModelIDInput | null,
  drId?: ModelStringInput | null,
  drName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  avatar?: ModelStringInput | null,
  qualifications?: ModelStringInput | null,
  prescriberNumber?: ModelStringInput | null,
  status?: ModelDoctorStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelDoctorFilterInput | null > | null,
  or?: Array< ModelDoctorFilterInput | null > | null,
  not?: ModelDoctorFilterInput | null,
};

export type ModelDoctorConnection = {
  __typename: "ModelDoctorConnection",
  items:  Array<Doctor | null >,
  nextToken?: string | null,
};

export type ModelAppointmentDefinitionFilterInput = {
  id?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  calendlyAppointmentID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  appointmentType?: ModelAppointmentTypeInput | null,
  appointmentSubject?: ModelAppointmentSubjectInput | null,
  drPublicId?: ModelStringInput | null,
  drName?: ModelStringInput | null,
  drAvatar?: ModelStringInput | null,
  clinicPublicId?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  clinicFormerlyKnownAs?: ModelStringInput | null,
  clinicStatus?: ModelClinicStatusInput | null,
  clinicLogoImage?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentDefinitionFilterInput | null > | null,
  or?: Array< ModelAppointmentDefinitionFilterInput | null > | null,
  not?: ModelAppointmentDefinitionFilterInput | null,
};

export type ModelAppointmentNoteFilterInput = {
  id?: ModelIDInput | null,
  note?: ModelStringInput | null,
  appointmentID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  phone?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentNoteFilterInput | null > | null,
  or?: Array< ModelAppointmentNoteFilterInput | null > | null,
  not?: ModelAppointmentNoteFilterInput | null,
};

export type ModelAppointmentNoteConnection = {
  __typename: "ModelAppointmentNoteConnection",
  items:  Array<AppointmentNote | null >,
  nextToken?: string | null,
};

export type ModelAppointmentMedicationFilterInput = {
  id?: ModelIDInput | null,
  mapID?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  appointmentID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  phone?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  dailyDose?: ModelStringInput | null,
  measurement?: ModelMeasurementInput | null,
  quantity?: ModelStringInput | null,
  repeats?: ModelStringInput | null,
  repeatInterval?: ModelStringInput | null,
  frequency?: ModelStringInput | null,
  prn?: ModelBooleanInput | null,
  instruction?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAppointmentMedicationFilterInput | null > | null,
  or?: Array< ModelAppointmentMedicationFilterInput | null > | null,
  not?: ModelAppointmentMedicationFilterInput | null,
};

export type ModelAppointmentMedicationConnection = {
  __typename: "ModelAppointmentMedicationConnection",
  items:  Array<AppointmentMedication | null >,
  nextToken?: string | null,
};

export type ModelAdminFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAdminFilterInput | null > | null,
  or?: Array< ModelAdminFilterInput | null > | null,
  not?: ModelAdminFilterInput | null,
};

export type ModelAdminConnection = {
  __typename: "ModelAdminConnection",
  items:  Array<Admin | null >,
  nextToken?: string | null,
};

export type ModelReceptionFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelReceptionFilterInput | null > | null,
  or?: Array< ModelReceptionFilterInput | null > | null,
  not?: ModelReceptionFilterInput | null,
};

export type ModelReceptionConnection = {
  __typename: "ModelReceptionConnection",
  items:  Array<Reception | null >,
  nextToken?: string | null,
};

export type ModelAccountantFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAccountantFilterInput | null > | null,
  or?: Array< ModelAccountantFilterInput | null > | null,
  not?: ModelAccountantFilterInput | null,
};

export type ModelAccountantConnection = {
  __typename: "ModelAccountantConnection",
  items:  Array<Accountant | null >,
  nextToken?: string | null,
};

export type ModelClinicFilterInput = {
  id?: ModelIDInput | null,
  publicId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  formerlyKnownAs?: ModelStringInput | null,
  status?: ModelClinicStatusInput | null,
  address?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  logoImage?: ModelStringInput | null,
  openingHours?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankStateBranch?: ModelStringInput | null,
  bankAccountName?: ModelStringInput | null,
  bankAccountNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicFilterInput | null > | null,
  or?: Array< ModelClinicFilterInput | null > | null,
  not?: ModelClinicFilterInput | null,
};

export type ModelClinicConnection = {
  __typename: "ModelClinicConnection",
  items:  Array<Clinic | null >,
  nextToken?: string | null,
};

export type ModelClinicAdminFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  adminID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicAdminFilterInput | null > | null,
  or?: Array< ModelClinicAdminFilterInput | null > | null,
  not?: ModelClinicAdminFilterInput | null,
};

export type ModelClinicReceptionFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  receptionID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicReceptionFilterInput | null > | null,
  or?: Array< ModelClinicReceptionFilterInput | null > | null,
  not?: ModelClinicReceptionFilterInput | null,
};

export type ModelClinicAccountantFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  accountantID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicAccountantFilterInput | null > | null,
  or?: Array< ModelClinicAccountantFilterInput | null > | null,
  not?: ModelClinicAccountantFilterInput | null,
};

export type ModelClinicDoctorFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelClinicDoctorFilterInput | null > | null,
  or?: Array< ModelClinicDoctorFilterInput | null > | null,
  not?: ModelClinicDoctorFilterInput | null,
};

export type ModelCannabisProductFilterInput = {
  id?: ModelIDInput | null,
  idxOrder?: ModelIntInput | null,
  name?: ModelStringInput | null,
  ingredients?: ModelStringInput | null,
  qtyPerDosageUnit?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  presentation?: ModelProductPresentationInput | null,
  presentationText?: ModelStringInput | null,
  strength?: ModelStringInput | null,
  volume?: ModelStringInput | null,
  sponsor?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCannabisProductFilterInput | null > | null,
  or?: Array< ModelCannabisProductFilterInput | null > | null,
  not?: ModelCannabisProductFilterInput | null,
};

export type ModelCannabisProductConnection = {
  __typename: "ModelCannabisProductConnection",
  items:  Array<CannabisProduct | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelMedicalAuthorisedPrescriberFilterInput = {
  id?: ModelIDInput | null,
  mapNumber?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  idxOrder?: ModelIntInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  status?: ModelTgaStatusInput | null,
  presentation?: ModelProductPresentationInput | null,
  presentationText?: ModelStringInput | null,
  createdDate?: ModelStringInput | null,
  decisionDate?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  approvalDate?: ModelStringInput | null,
  isSAS?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  sorter?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMedicalAuthorisedPrescriberFilterInput | null > | null,
  or?: Array< ModelMedicalAuthorisedPrescriberFilterInput | null > | null,
  not?: ModelMedicalAuthorisedPrescriberFilterInput | null,
};

export type ModelMedicalAuthorisedPrescriberConnection = {
  __typename: "ModelMedicalAuthorisedPrescriberConnection",
  items:  Array<MedicalAuthorisedPrescriber | null >,
  nextToken?: string | null,
};

export type ModelScriptReceiverFilterInput = {
  id?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelScriptReceiverFilterInput | null > | null,
  or?: Array< ModelScriptReceiverFilterInput | null > | null,
  not?: ModelScriptReceiverFilterInput | null,
};

export type ModelScriptReceiverConnection = {
  __typename: "ModelScriptReceiverConnection",
  items:  Array<ScriptReceiver | null >,
  nextToken?: string | null,
};

export type ModelProductMapFilterInput = {
  id?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  mapID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductMapFilterInput | null > | null,
  or?: Array< ModelProductMapFilterInput | null > | null,
  not?: ModelProductMapFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPatientLinkFilterInput = {
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPatientLinkFilterInput | null > | null,
  or?: Array< ModelPatientLinkFilterInput | null > | null,
  not?: ModelPatientLinkFilterInput | null,
};

export type ModelPatientLinkConnection = {
  __typename: "ModelPatientLinkConnection",
  items:  Array<PatientLink | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionTypeFormEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  doctorPublicId?: ModelSubscriptionStringInput | null,
  clinicPublicId?: ModelSubscriptionStringInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  bookerID?: ModelSubscriptionIDInput | null,
  definitionID?: ModelSubscriptionIDInput | null,
  definitionTitle?: ModelSubscriptionStringInput | null,
  definitionSlug?: ModelSubscriptionStringInput | null,
  definitionType?: ModelSubscriptionStringInput | null,
  definitionSubject?: ModelSubscriptionStringInput | null,
  definitionDoctorName?: ModelSubscriptionStringInput | null,
  definitionClinicName?: ModelSubscriptionStringInput | null,
  definitionClinicLogo?: ModelSubscriptionStringInput | null,
  who?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  bookingUrl?: ModelSubscriptionStringInput | null,
  oldBookingUrl?: ModelSubscriptionStringInput | null,
  paymentSuccess?: ModelSubscriptionBooleanInput | null,
  paymentAmount?: ModelSubscriptionStringInput | null,
  questions?: ModelSubscriptionStringInput | null,
  answers?: ModelSubscriptionStringInput | null,
  hidden?: ModelSubscriptionStringInput | null,
  calendlyStatus?: ModelSubscriptionStringInput | null,
  rescheduled?: ModelSubscriptionBooleanInput | null,
  canceled?: ModelSubscriptionBooleanInput | null,
  cancelReason?: ModelSubscriptionStringInput | null,
  landedAt?: ModelSubscriptionStringInput | null,
  submittedAt?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  sorter?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTypeFormEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionTypeFormEventFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sub?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionPatientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  surName?: ModelSubscriptionStringInput | null,
  medicareNumber?: ModelSubscriptionStringInput | null,
  addressOneLine?: ModelSubscriptionStringInput | null,
  dateOfBirth?: ModelSubscriptionStringInput | null,
  contactNumber?: ModelSubscriptionStringInput | null,
  nextOfKin?: ModelSubscriptionStringInput | null,
  nextOfKinRelation?: ModelSubscriptionStringInput | null,
  nextOfKinContact?: ModelSubscriptionStringInput | null,
  powerOfAttorney?: ModelSubscriptionStringInput | null,
  powerOfAttorneyRelation?: ModelSubscriptionStringInput | null,
  powerOfAttorneyContact?: ModelSubscriptionStringInput | null,
  carer?: ModelSubscriptionStringInput | null,
  carerRelation?: ModelSubscriptionStringInput | null,
  carerContact?: ModelSubscriptionStringInput | null,
  emergency?: ModelSubscriptionStringInput | null,
  emergencyRelation?: ModelSubscriptionStringInput | null,
  emergencyContact?: ModelSubscriptionStringInput | null,
  primaryDiagnosis?: ModelSubscriptionStringInput | null,
  otherTreatments?: ModelSubscriptionStringInput | null,
  otherMedications?: ModelSubscriptionStringInput | null,
  selfMedicated?: ModelSubscriptionStringInput | null,
  kidneyLiver?: ModelSubscriptionStringInput | null,
  cardiovascular?: ModelSubscriptionStringInput | null,
  substanceAbuseDisorder?: ModelSubscriptionStringInput | null,
  allergies?: ModelSubscriptionStringInput | null,
  smoke?: ModelSubscriptionStringInput | null,
  drinkAlcohol?: ModelSubscriptionStringInput | null,
  otherConditions?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientFilterInput | null > | null,
};

export type ModelSubscriptionReferredFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  surName?: ModelSubscriptionStringInput | null,
  addressOneLine?: ModelSubscriptionStringInput | null,
  medicareNumber?: ModelSubscriptionStringInput | null,
  dateOfBirth?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  ihiNo?: ModelSubscriptionStringInput | null,
  nextOfKin?: ModelSubscriptionStringInput | null,
  nextOfKinRelation?: ModelSubscriptionStringInput | null,
  nextOfKinContact?: ModelSubscriptionStringInput | null,
  powerOfAttorney?: ModelSubscriptionStringInput | null,
  powerOfAttorneyRelation?: ModelSubscriptionStringInput | null,
  powerOfAttorneyContact?: ModelSubscriptionStringInput | null,
  carer?: ModelSubscriptionStringInput | null,
  carerRelation?: ModelSubscriptionStringInput | null,
  carerContact?: ModelSubscriptionStringInput | null,
  emergency?: ModelSubscriptionStringInput | null,
  emergencyRelation?: ModelSubscriptionStringInput | null,
  emergencyContact?: ModelSubscriptionStringInput | null,
  symptoms?: ModelSubscriptionStringInput | null,
  otherTreatments?: ModelSubscriptionStringInput | null,
  primaryDiagnosis?: ModelSubscriptionStringInput | null,
  otherMedications?: ModelSubscriptionStringInput | null,
  selfMedicated?: ModelSubscriptionStringInput | null,
  kidneyLiver?: ModelSubscriptionStringInput | null,
  cardiovascular?: ModelSubscriptionStringInput | null,
  substanceAbuseDisorder?: ModelSubscriptionStringInput | null,
  allergies?: ModelSubscriptionStringInput | null,
  smoke?: ModelSubscriptionStringInput | null,
  drinkAlcohol?: ModelSubscriptionStringInput | null,
  otherConditions?: ModelSubscriptionStringInput | null,
  cvConditions?: ModelSubscriptionStringInput | null,
  practitionerConcerns?: ModelSubscriptionStringInput | null,
  recentReports?: ModelSubscriptionStringInput | null,
  clinicPublicId?: ModelSubscriptionStringInput | null,
  doctorPublicId?: ModelSubscriptionStringInput | null,
  readByClinic?: ModelSubscriptionBooleanInput | null,
  readByDoctor?: ModelSubscriptionBooleanInput | null,
  practitionerFirstName?: ModelSubscriptionStringInput | null,
  practitionerLastName?: ModelSubscriptionStringInput | null,
  practitionerAddress?: ModelSubscriptionStringInput | null,
  practitionerProviderNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReferredFilterInput | null > | null,
  or?: Array< ModelSubscriptionReferredFilterInput | null > | null,
};

export type ModelSubscriptionConsentEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  consenter?: ModelSubscriptionStringInput | null,
  consentContent?: ModelSubscriptionStringInput | null,
  researchAllowed?: ModelSubscriptionBooleanInput | null,
  manual?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConsentEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsentEventFilterInput | null > | null,
};

export type ModelSubscriptionQuizFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  quizType?: ModelSubscriptionStringInput | null,
  questions?: ModelSubscriptionStringInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizFilterInput | null > | null,
};

export type ModelSubscriptionCalendlyAppointmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  uri?: ModelSubscriptionStringInput | null,
  scheduling_url?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionIntInput | null,
  kind?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  booking_method?: ModelSubscriptionStringInput | null,
  description_plain?: ModelSubscriptionStringInput | null,
  description_html?: ModelSubscriptionStringInput | null,
  internal_note?: ModelSubscriptionStringInput | null,
  pooling_type?: ModelSubscriptionStringInput | null,
  secret?: ModelSubscriptionBooleanInput | null,
  profile?: ModelSubscriptionStringInput | null,
  custom_questions?: ModelSubscriptionStringInput | null,
  created_at?: ModelSubscriptionStringInput | null,
  updated_at?: ModelSubscriptionStringInput | null,
  deleted_at?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCalendlyAppointmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCalendlyAppointmentFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDoctorFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  drId?: ModelSubscriptionStringInput | null,
  drName?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  qualifications?: ModelSubscriptionStringInput | null,
  prescriberNumber?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDoctorFilterInput | null > | null,
  or?: Array< ModelSubscriptionDoctorFilterInput | null > | null,
};

export type ModelSubscriptionAppointmentDefinitionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  calendlyAppointmentID?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  appointmentType?: ModelSubscriptionStringInput | null,
  appointmentSubject?: ModelSubscriptionStringInput | null,
  drPublicId?: ModelSubscriptionStringInput | null,
  drName?: ModelSubscriptionStringInput | null,
  drAvatar?: ModelSubscriptionStringInput | null,
  clinicPublicId?: ModelSubscriptionStringInput | null,
  clinicName?: ModelSubscriptionStringInput | null,
  clinicFormerlyKnownAs?: ModelSubscriptionStringInput | null,
  clinicStatus?: ModelSubscriptionStringInput | null,
  clinicLogoImage?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAppointmentDefinitionFilterInput | null > | null,
  or?: Array< ModelSubscriptionAppointmentDefinitionFilterInput | null > | null,
};

export type ModelSubscriptionAppointmentNoteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  note?: ModelSubscriptionStringInput | null,
  appointmentID?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  phone?: ModelSubscriptionStringInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAppointmentNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionAppointmentNoteFilterInput | null > | null,
};

export type ModelSubscriptionAppointmentMedicationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mapID?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionIDInput | null,
  appointmentID?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  phone?: ModelSubscriptionStringInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  dailyDose?: ModelSubscriptionStringInput | null,
  measurement?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionStringInput | null,
  repeats?: ModelSubscriptionStringInput | null,
  repeatInterval?: ModelSubscriptionStringInput | null,
  frequency?: ModelSubscriptionStringInput | null,
  prn?: ModelSubscriptionBooleanInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAppointmentMedicationFilterInput | null > | null,
  or?: Array< ModelSubscriptionAppointmentMedicationFilterInput | null > | null,
};

export type ModelSubscriptionAdminFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminFilterInput | null > | null,
};

export type ModelSubscriptionReceptionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReceptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionReceptionFilterInput | null > | null,
};

export type ModelSubscriptionAccountantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountantFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountantFilterInput | null > | null,
};

export type ModelSubscriptionClinicFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  publicId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  formerlyKnownAs?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  logoImage?: ModelSubscriptionStringInput | null,
  openingHours?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  bankStateBranch?: ModelSubscriptionStringInput | null,
  bankAccountName?: ModelSubscriptionStringInput | null,
  bankAccountNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  sorter?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicFilterInput | null > | null,
};

export type ModelSubscriptionClinicAdminFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  adminID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicAdminFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicAdminFilterInput | null > | null,
};

export type ModelSubscriptionClinicReceptionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  receptionID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicReceptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicReceptionFilterInput | null > | null,
};

export type ModelSubscriptionClinicAccountantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  accountantID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicAccountantFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicAccountantFilterInput | null > | null,
};

export type ModelSubscriptionClinicDoctorFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicDoctorFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicDoctorFilterInput | null > | null,
};

export type ModelSubscriptionCannabisProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  idxOrder?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  ingredients?: ModelSubscriptionStringInput | null,
  qtyPerDosageUnit?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  presentation?: ModelSubscriptionStringInput | null,
  presentationText?: ModelSubscriptionStringInput | null,
  strength?: ModelSubscriptionStringInput | null,
  volume?: ModelSubscriptionStringInput | null,
  sponsor?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  sorter?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCannabisProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionCannabisProductFilterInput | null > | null,
};

export type ModelSubscriptionMedicalAuthorisedPrescriberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mapNumber?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  idxOrder?: ModelSubscriptionIntInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  presentation?: ModelSubscriptionStringInput | null,
  presentationText?: ModelSubscriptionStringInput | null,
  createdDate?: ModelSubscriptionStringInput | null,
  decisionDate?: ModelSubscriptionStringInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  approvalDate?: ModelSubscriptionStringInput | null,
  isSAS?: ModelSubscriptionBooleanInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  sorter?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMedicalAuthorisedPrescriberFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedicalAuthorisedPrescriberFilterInput | null > | null,
};

export type ModelSubscriptionScriptReceiverFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionScriptReceiverFilterInput | null > | null,
  or?: Array< ModelSubscriptionScriptReceiverFilterInput | null > | null,
};

export type ModelSubscriptionProductMapFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionIDInput | null,
  mapID?: ModelSubscriptionIDInput | null,
  doctorID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductMapFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductMapFilterInput | null > | null,
};

export type ModelSubscriptionPatientLinkFilterInput = {
  doctorID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientLinkFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientLinkFilterInput | null > | null,
};

export type DeleteTypeFormEventMutationVariables = {
  input: DeleteTypeFormEventInput,
  condition?: ModelTypeFormEventConditionInput | null,
};

export type DeleteTypeFormEventMutation = {
  deleteTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateConsentEventMutationVariables = {
  input: UpdateConsentEventInput,
  condition?: ModelConsentEventConditionInput | null,
};

export type UpdateConsentEventMutation = {
  updateConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteConsentEventMutationVariables = {
  input: DeleteConsentEventInput,
  condition?: ModelConsentEventConditionInput | null,
};

export type DeleteConsentEventMutation = {
  deleteConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateQuizMutationVariables = {
  input: UpdateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type UpdateQuizMutation = {
  updateQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteQuizMutationVariables = {
  input: DeleteQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type DeleteQuizMutation = {
  deleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentNoteMutationVariables = {
  input: UpdateAppointmentNoteInput,
  condition?: ModelAppointmentNoteConditionInput | null,
};

export type UpdateAppointmentNoteMutation = {
  updateAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentMedicationMutationVariables = {
  input: UpdateAppointmentMedicationInput,
  condition?: ModelAppointmentMedicationConditionInput | null,
};

export type UpdateAppointmentMedicationMutation = {
  updateAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateTypeFormEventMutationVariables = {
  input: CreateTypeFormEventInput,
  condition?: ModelTypeFormEventConditionInput | null,
};

export type CreateTypeFormEventMutation = {
  createTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateTypeFormEventMutationVariables = {
  input: UpdateTypeFormEventInput,
  condition?: ModelTypeFormEventConditionInput | null,
};

export type UpdateTypeFormEventMutation = {
  updateTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateReferredMutationVariables = {
  input: CreateReferredInput,
  condition?: ModelReferredConditionInput | null,
};

export type CreateReferredMutation = {
  createReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateReferredMutationVariables = {
  input: UpdateReferredInput,
  condition?: ModelReferredConditionInput | null,
};

export type UpdateReferredMutation = {
  updateReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteReferredMutationVariables = {
  input: DeleteReferredInput,
  condition?: ModelReferredConditionInput | null,
};

export type DeleteReferredMutation = {
  deleteReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateConsentEventMutationVariables = {
  input: CreateConsentEventInput,
  condition?: ModelConsentEventConditionInput | null,
};

export type CreateConsentEventMutation = {
  createConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateQuizMutationVariables = {
  input: CreateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type CreateQuizMutation = {
  createQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateCalendlyAppointmentMutationVariables = {
  input: CreateCalendlyAppointmentInput,
  condition?: ModelCalendlyAppointmentConditionInput | null,
};

export type CreateCalendlyAppointmentMutation = {
  createCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateCalendlyAppointmentMutationVariables = {
  input: UpdateCalendlyAppointmentInput,
  condition?: ModelCalendlyAppointmentConditionInput | null,
};

export type UpdateCalendlyAppointmentMutation = {
  updateCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteCalendlyAppointmentMutationVariables = {
  input: DeleteCalendlyAppointmentInput,
  condition?: ModelCalendlyAppointmentConditionInput | null,
};

export type DeleteCalendlyAppointmentMutation = {
  deleteCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateDoctorMutationVariables = {
  input: CreateDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type CreateDoctorMutation = {
  createDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDoctorMutationVariables = {
  input: UpdateDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type UpdateDoctorMutation = {
  updateDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDoctorMutationVariables = {
  input: DeleteDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type DeleteDoctorMutation = {
  deleteDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateAppointmentDefinitionMutationVariables = {
  input: CreateAppointmentDefinitionInput,
  condition?: ModelAppointmentDefinitionConditionInput | null,
};

export type CreateAppointmentDefinitionMutation = {
  createAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentDefinitionMutationVariables = {
  input: UpdateAppointmentDefinitionInput,
  condition?: ModelAppointmentDefinitionConditionInput | null,
};

export type UpdateAppointmentDefinitionMutation = {
  updateAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteAppointmentDefinitionMutationVariables = {
  input: DeleteAppointmentDefinitionInput,
  condition?: ModelAppointmentDefinitionConditionInput | null,
};

export type DeleteAppointmentDefinitionMutation = {
  deleteAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAppointmentNoteMutationVariables = {
  input: CreateAppointmentNoteInput,
  condition?: ModelAppointmentNoteConditionInput | null,
};

export type CreateAppointmentNoteMutation = {
  createAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteAppointmentNoteMutationVariables = {
  input: DeleteAppointmentNoteInput,
  condition?: ModelAppointmentNoteConditionInput | null,
};

export type DeleteAppointmentNoteMutation = {
  deleteAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAppointmentMedicationMutationVariables = {
  input: CreateAppointmentMedicationInput,
  condition?: ModelAppointmentMedicationConditionInput | null,
};

export type CreateAppointmentMedicationMutation = {
  createAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteAppointmentMedicationMutationVariables = {
  input: DeleteAppointmentMedicationInput,
  condition?: ModelAppointmentMedicationConditionInput | null,
};

export type DeleteAppointmentMedicationMutation = {
  deleteAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAdminMutationVariables = {
  input: CreateAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type CreateAdminMutation = {
  createAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateAdminMutationVariables = {
  input: UpdateAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type UpdateAdminMutation = {
  updateAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteAdminMutationVariables = {
  input: DeleteAdminInput,
  condition?: ModelAdminConditionInput | null,
};

export type DeleteAdminMutation = {
  deleteAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateReceptionMutationVariables = {
  input: CreateReceptionInput,
  condition?: ModelReceptionConditionInput | null,
};

export type CreateReceptionMutation = {
  createReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateReceptionMutationVariables = {
  input: UpdateReceptionInput,
  condition?: ModelReceptionConditionInput | null,
};

export type UpdateReceptionMutation = {
  updateReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteReceptionMutationVariables = {
  input: DeleteReceptionInput,
  condition?: ModelReceptionConditionInput | null,
};

export type DeleteReceptionMutation = {
  deleteReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAccountantMutationVariables = {
  input: CreateAccountantInput,
  condition?: ModelAccountantConditionInput | null,
};

export type CreateAccountantMutation = {
  createAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateAccountantMutationVariables = {
  input: UpdateAccountantInput,
  condition?: ModelAccountantConditionInput | null,
};

export type UpdateAccountantMutation = {
  updateAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteAccountantMutationVariables = {
  input: DeleteAccountantInput,
  condition?: ModelAccountantConditionInput | null,
};

export type DeleteAccountantMutation = {
  deleteAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateClinicMutationVariables = {
  input: CreateClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type CreateClinicMutation = {
  createClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type UpdateClinicMutationVariables = {
  input: UpdateClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type UpdateClinicMutation = {
  updateClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type DeleteClinicMutationVariables = {
  input: DeleteClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type DeleteClinicMutation = {
  deleteClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type CreateClinicAdminMutationVariables = {
  input: CreateClinicAdminInput,
  condition?: ModelClinicAdminConditionInput | null,
};

export type CreateClinicAdminMutation = {
  createClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateClinicAdminMutationVariables = {
  input: UpdateClinicAdminInput,
  condition?: ModelClinicAdminConditionInput | null,
};

export type UpdateClinicAdminMutation = {
  updateClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteClinicAdminMutationVariables = {
  input: DeleteClinicAdminInput,
  condition?: ModelClinicAdminConditionInput | null,
};

export type DeleteClinicAdminMutation = {
  deleteClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateClinicReceptionMutationVariables = {
  input: CreateClinicReceptionInput,
  condition?: ModelClinicReceptionConditionInput | null,
};

export type CreateClinicReceptionMutation = {
  createClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateClinicReceptionMutationVariables = {
  input: UpdateClinicReceptionInput,
  condition?: ModelClinicReceptionConditionInput | null,
};

export type UpdateClinicReceptionMutation = {
  updateClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteClinicReceptionMutationVariables = {
  input: DeleteClinicReceptionInput,
  condition?: ModelClinicReceptionConditionInput | null,
};

export type DeleteClinicReceptionMutation = {
  deleteClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateClinicAccountantMutationVariables = {
  input: CreateClinicAccountantInput,
  condition?: ModelClinicAccountantConditionInput | null,
};

export type CreateClinicAccountantMutation = {
  createClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateClinicAccountantMutationVariables = {
  input: UpdateClinicAccountantInput,
  condition?: ModelClinicAccountantConditionInput | null,
};

export type UpdateClinicAccountantMutation = {
  updateClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteClinicAccountantMutationVariables = {
  input: DeleteClinicAccountantInput,
  condition?: ModelClinicAccountantConditionInput | null,
};

export type DeleteClinicAccountantMutation = {
  deleteClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateClinicDoctorMutationVariables = {
  input: CreateClinicDoctorInput,
  condition?: ModelClinicDoctorConditionInput | null,
};

export type CreateClinicDoctorMutation = {
  createClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateClinicDoctorMutationVariables = {
  input: UpdateClinicDoctorInput,
  condition?: ModelClinicDoctorConditionInput | null,
};

export type UpdateClinicDoctorMutation = {
  updateClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteClinicDoctorMutationVariables = {
  input: DeleteClinicDoctorInput,
  condition?: ModelClinicDoctorConditionInput | null,
};

export type DeleteClinicDoctorMutation = {
  deleteClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateCannabisProductMutationVariables = {
  input: CreateCannabisProductInput,
  condition?: ModelCannabisProductConditionInput | null,
};

export type CreateCannabisProductMutation = {
  createCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateCannabisProductMutationVariables = {
  input: UpdateCannabisProductInput,
  condition?: ModelCannabisProductConditionInput | null,
};

export type UpdateCannabisProductMutation = {
  updateCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteCannabisProductMutationVariables = {
  input: DeleteCannabisProductInput,
  condition?: ModelCannabisProductConditionInput | null,
};

export type DeleteCannabisProductMutation = {
  deleteCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateMedicalAuthorisedPrescriberMutationVariables = {
  input: CreateMedicalAuthorisedPrescriberInput,
  condition?: ModelMedicalAuthorisedPrescriberConditionInput | null,
};

export type CreateMedicalAuthorisedPrescriberMutation = {
  createMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateMedicalAuthorisedPrescriberMutationVariables = {
  input: UpdateMedicalAuthorisedPrescriberInput,
  condition?: ModelMedicalAuthorisedPrescriberConditionInput | null,
};

export type UpdateMedicalAuthorisedPrescriberMutation = {
  updateMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteMedicalAuthorisedPrescriberMutationVariables = {
  input: DeleteMedicalAuthorisedPrescriberInput,
  condition?: ModelMedicalAuthorisedPrescriberConditionInput | null,
};

export type DeleteMedicalAuthorisedPrescriberMutation = {
  deleteMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateScriptReceiverMutationVariables = {
  input: CreateScriptReceiverInput,
  condition?: ModelScriptReceiverConditionInput | null,
};

export type CreateScriptReceiverMutation = {
  createScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateScriptReceiverMutationVariables = {
  input: UpdateScriptReceiverInput,
  condition?: ModelScriptReceiverConditionInput | null,
};

export type UpdateScriptReceiverMutation = {
  updateScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteScriptReceiverMutationVariables = {
  input: DeleteScriptReceiverInput,
  condition?: ModelScriptReceiverConditionInput | null,
};

export type DeleteScriptReceiverMutation = {
  deleteScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateProductMapMutationVariables = {
  input: CreateProductMapInput,
  condition?: ModelProductMapConditionInput | null,
};

export type CreateProductMapMutation = {
  createProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateProductMapMutationVariables = {
  input: UpdateProductMapInput,
  condition?: ModelProductMapConditionInput | null,
};

export type UpdateProductMapMutation = {
  updateProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteProductMapMutationVariables = {
  input: DeleteProductMapInput,
  condition?: ModelProductMapConditionInput | null,
};

export type DeleteProductMapMutation = {
  deleteProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreatePatientLinkMutationVariables = {
  input: CreatePatientLinkInput,
  condition?: ModelPatientLinkConditionInput | null,
};

export type CreatePatientLinkMutation = {
  createPatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdatePatientLinkMutationVariables = {
  input: UpdatePatientLinkInput,
  condition?: ModelPatientLinkConditionInput | null,
};

export type UpdatePatientLinkMutation = {
  updatePatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeletePatientLinkMutationVariables = {
  input: DeletePatientLinkInput,
  condition?: ModelPatientLinkConditionInput | null,
};

export type DeletePatientLinkMutation = {
  deletePatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type GetTypeFormEventQueryVariables = {
  id: string,
};

export type GetTypeFormEventQuery = {
  getTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListTypeFormEventsQueryVariables = {
  filter?: ModelTypeFormEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTypeFormEventsQuery = {
  listTypeFormEvents?:  {
    __typename: "ModelTypeFormEventConnection",
    items:  Array< {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindTypeFormEventsByDoctorPublicIdQueryVariables = {
  doctorPublicId: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTypeFormEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindTypeFormEventsByDoctorPublicIdQuery = {
  findTypeFormEventsByDoctorPublicId?:  {
    __typename: "ModelTypeFormEventConnection",
    items:  Array< {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindTypeFormEventsByClinicPublicIdQueryVariables = {
  clinicPublicId: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTypeFormEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindTypeFormEventsByClinicPublicIdQuery = {
  findTypeFormEventsByClinicPublicId?:  {
    __typename: "ModelTypeFormEventConnection",
    items:  Array< {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindTypeFormEventsBySorterQueryVariables = {
  sorter: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTypeFormEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindTypeFormEventsBySorterQuery = {
  findTypeFormEventsBySorter?:  {
    __typename: "ModelTypeFormEventConnection",
    items:  Array< {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindUserBySubQueryVariables = {
  sub: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindUserBySubQuery = {
  findUserBySub?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindUserByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindUserByUsernameQuery = {
  findUserByUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindUserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindUserByEmailQuery = {
  findUserByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindUserByPhoneNumberQueryVariables = {
  phoneNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindUserByPhoneNumberQuery = {
  findUserByPhoneNumber?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindUserByGivenNameQueryVariables = {
  givenName: string,
  phoneNumber?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindUserByGivenNameQuery = {
  findUserByGivenName?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindPatientByEmailQueryVariables = {
  email: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindPatientByEmailQuery = {
  findPatientByEmail?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindPatientByPhoneQueryVariables = {
  phone: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindPatientByPhoneQuery = {
  findPatientByPhone?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindPatientByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindPatientByUserIdQuery = {
  findPatientByUserId?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReferredQueryVariables = {
  id: string,
};

export type GetReferredQuery = {
  getReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListReferredsQueryVariables = {
  filter?: ModelReferredFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferredsQuery = {
  listReferreds?:  {
    __typename: "ModelReferredConnection",
    items:  Array< {
      __typename: "Referred",
      id: string,
      givenName: string,
      surName?: string | null,
      addressOneLine?: string | null,
      medicareNumber?: string | null,
      dateOfBirth?: string | null,
      phone?: string | null,
      ihiNo?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      symptoms?: string | null,
      otherTreatments?: string | null,
      primaryDiagnosis?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      cvConditions?: string | null,
      practitionerConcerns?: string | null,
      recentReports?: string | null,
      clinicPublicId?: string | null,
      doctorPublicId?: string | null,
      readByClinic?: boolean | null,
      readByDoctor?: boolean | null,
      practitionerFirstName?: string | null,
      practitionerLastName?: string | null,
      practitionerAddress?: string | null,
      practitionerProviderNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindReferredsByClinicPublicIdQueryVariables = {
  clinicPublicId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferredFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindReferredsByClinicPublicIdQuery = {
  findReferredsByClinicPublicId?:  {
    __typename: "ModelReferredConnection",
    items:  Array< {
      __typename: "Referred",
      id: string,
      givenName: string,
      surName?: string | null,
      addressOneLine?: string | null,
      medicareNumber?: string | null,
      dateOfBirth?: string | null,
      phone?: string | null,
      ihiNo?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      symptoms?: string | null,
      otherTreatments?: string | null,
      primaryDiagnosis?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      cvConditions?: string | null,
      practitionerConcerns?: string | null,
      recentReports?: string | null,
      clinicPublicId?: string | null,
      doctorPublicId?: string | null,
      readByClinic?: boolean | null,
      readByDoctor?: boolean | null,
      practitionerFirstName?: string | null,
      practitionerLastName?: string | null,
      practitionerAddress?: string | null,
      practitionerProviderNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindReferredsByDoctorPublicIdQueryVariables = {
  doctorPublicId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferredFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindReferredsByDoctorPublicIdQuery = {
  findReferredsByDoctorPublicId?:  {
    __typename: "ModelReferredConnection",
    items:  Array< {
      __typename: "Referred",
      id: string,
      givenName: string,
      surName?: string | null,
      addressOneLine?: string | null,
      medicareNumber?: string | null,
      dateOfBirth?: string | null,
      phone?: string | null,
      ihiNo?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      symptoms?: string | null,
      otherTreatments?: string | null,
      primaryDiagnosis?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      cvConditions?: string | null,
      practitionerConcerns?: string | null,
      recentReports?: string | null,
      clinicPublicId?: string | null,
      doctorPublicId?: string | null,
      readByClinic?: boolean | null,
      readByDoctor?: boolean | null,
      practitionerFirstName?: string | null,
      practitionerLastName?: string | null,
      practitionerAddress?: string | null,
      practitionerProviderNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConsentEventQueryVariables = {
  id: string,
};

export type GetConsentEventQuery = {
  getConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListConsentEventsQueryVariables = {
  filter?: ModelConsentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsentEventsQuery = {
  listConsentEvents?:  {
    __typename: "ModelConsentEventConnection",
    items:  Array< {
      __typename: "ConsentEvent",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      givenName: string,
      familyName: string,
      consenter: string,
      consentContent: string,
      researchAllowed?: boolean | null,
      manual?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindConsentEventByEmailQueryVariables = {
  email: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindConsentEventByEmailQuery = {
  findConsentEventByEmail?:  {
    __typename: "ModelConsentEventConnection",
    items:  Array< {
      __typename: "ConsentEvent",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      givenName: string,
      familyName: string,
      consenter: string,
      consentContent: string,
      researchAllowed?: boolean | null,
      manual?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindConsentEventByPhoneQueryVariables = {
  phone: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindConsentEventByPhoneQuery = {
  findConsentEventByPhone?:  {
    __typename: "ModelConsentEventConnection",
    items:  Array< {
      __typename: "ConsentEvent",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      givenName: string,
      familyName: string,
      consenter: string,
      consentContent: string,
      researchAllowed?: boolean | null,
      manual?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindConsentEventByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindConsentEventByUserIdQuery = {
  findConsentEventByUserId?:  {
    __typename: "ModelConsentEventConnection",
    items:  Array< {
      __typename: "ConsentEvent",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      givenName: string,
      familyName: string,
      consenter: string,
      consentContent: string,
      researchAllowed?: boolean | null,
      manual?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindConsentEventByPatientIdQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindConsentEventByPatientIdQuery = {
  findConsentEventByPatientId?:  {
    __typename: "ModelConsentEventConnection",
    items:  Array< {
      __typename: "ConsentEvent",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      givenName: string,
      familyName: string,
      consenter: string,
      consentContent: string,
      researchAllowed?: boolean | null,
      manual?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQueryVariables = {
  id: string,
};

export type GetQuizQuery = {
  getQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListQuizzesQueryVariables = {
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizzesQuery = {
  listQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      userID: string,
      quizType?: QuizType | null,
      questions: string,
      givenName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindQuizByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindQuizByUserIdQuery = {
  findQuizByUserId?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      userID: string,
      quizType?: QuizType | null,
      questions: string,
      givenName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindQuizByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindQuizByOwnerQuery = {
  findQuizByOwner?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      userID: string,
      quizType?: QuizType | null,
      questions: string,
      givenName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCalendlyAppointmentQueryVariables = {
  id: string,
};

export type GetCalendlyAppointmentQuery = {
  getCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListCalendlyAppointmentsQueryVariables = {
  filter?: ModelCalendlyAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalendlyAppointmentsQuery = {
  listCalendlyAppointments?:  {
    __typename: "ModelCalendlyAppointmentConnection",
    items:  Array< {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCalendlyAppointmentBySlugQueryVariables = {
  slug: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendlyAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCalendlyAppointmentBySlugQuery = {
  findCalendlyAppointmentBySlug?:  {
    __typename: "ModelCalendlyAppointmentConnection",
    items:  Array< {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCalendlyAppointmentByNameQueryVariables = {
  name: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendlyAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCalendlyAppointmentByNameQuery = {
  findCalendlyAppointmentByName?:  {
    __typename: "ModelCalendlyAppointmentConnection",
    items:  Array< {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCalendlyAppointmentByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendlyAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCalendlyAppointmentByOwnerQuery = {
  findCalendlyAppointmentByOwner?:  {
    __typename: "ModelCalendlyAppointmentConnection",
    items:  Array< {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDoctorQueryVariables = {
  id: string,
};

export type GetDoctorQuery = {
  getDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDoctorsQueryVariables = {
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoctorsQuery = {
  listDoctors?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindDoctorByDrIdQueryVariables = {
  drId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindDoctorByDrIdQuery = {
  findDoctorByDrId?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindDoctorByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindDoctorByUserIdQuery = {
  findDoctorByUserId?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindDoctorByStatusQueryVariables = {
  status: DoctorStatus,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindDoctorByStatusQuery = {
  findDoctorByStatus?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppointmentDefinitionQueryVariables = {
  id: string,
};

export type GetAppointmentDefinitionQuery = {
  getAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListAppointmentDefinitionsQueryVariables = {
  filter?: ModelAppointmentDefinitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentDefinitionsQuery = {
  listAppointmentDefinitions?:  {
    __typename: "ModelAppointmentDefinitionConnection",
    items:  Array< {
      __typename: "AppointmentDefinition",
      id: string,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      calendlyAppointmentID?: string | null,
      calendlyAppointment?:  {
        __typename: "CalendlyAppointment",
        id: string,
        slug: string,
        name?: string | null,
        uri?: string | null,
        scheduling_url?: string | null,
        duration?: number | null,
        kind?: string | null,
        type?: string | null,
        color?: string | null,
        active?: boolean | null,
        booking_method?: string | null,
        description_plain?: string | null,
        description_html?: string | null,
        internal_note?: string | null,
        pooling_type?: string | null,
        secret?: boolean | null,
        profile?: string | null,
        custom_questions?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinicID?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      } | null,
      appointmentType?: AppointmentType | null,
      appointmentSubject?: AppointmentSubject | null,
      drPublicId?: string | null,
      drName?: string | null,
      drAvatar?: string | null,
      clinicPublicId?: string | null,
      clinicName?: string | null,
      clinicFormerlyKnownAs?: string | null,
      clinicStatus?: ClinicStatus | null,
      clinicLogoImage?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentDefinitionByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentDefinitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentDefinitionByDoctorIdQuery = {
  findAppointmentDefinitionByDoctorId?:  {
    __typename: "ModelAppointmentDefinitionConnection",
    items:  Array< {
      __typename: "AppointmentDefinition",
      id: string,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      calendlyAppointmentID?: string | null,
      calendlyAppointment?:  {
        __typename: "CalendlyAppointment",
        id: string,
        slug: string,
        name?: string | null,
        uri?: string | null,
        scheduling_url?: string | null,
        duration?: number | null,
        kind?: string | null,
        type?: string | null,
        color?: string | null,
        active?: boolean | null,
        booking_method?: string | null,
        description_plain?: string | null,
        description_html?: string | null,
        internal_note?: string | null,
        pooling_type?: string | null,
        secret?: boolean | null,
        profile?: string | null,
        custom_questions?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinicID?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      } | null,
      appointmentType?: AppointmentType | null,
      appointmentSubject?: AppointmentSubject | null,
      drPublicId?: string | null,
      drName?: string | null,
      drAvatar?: string | null,
      clinicPublicId?: string | null,
      clinicName?: string | null,
      clinicFormerlyKnownAs?: string | null,
      clinicStatus?: ClinicStatus | null,
      clinicLogoImage?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentDefinitionByClinicIdQueryVariables = {
  clinicID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentDefinitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentDefinitionByClinicIdQuery = {
  findAppointmentDefinitionByClinicId?:  {
    __typename: "ModelAppointmentDefinitionConnection",
    items:  Array< {
      __typename: "AppointmentDefinition",
      id: string,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      calendlyAppointmentID?: string | null,
      calendlyAppointment?:  {
        __typename: "CalendlyAppointment",
        id: string,
        slug: string,
        name?: string | null,
        uri?: string | null,
        scheduling_url?: string | null,
        duration?: number | null,
        kind?: string | null,
        type?: string | null,
        color?: string | null,
        active?: boolean | null,
        booking_method?: string | null,
        description_plain?: string | null,
        description_html?: string | null,
        internal_note?: string | null,
        pooling_type?: string | null,
        secret?: boolean | null,
        profile?: string | null,
        custom_questions?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinicID?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      } | null,
      appointmentType?: AppointmentType | null,
      appointmentSubject?: AppointmentSubject | null,
      drPublicId?: string | null,
      drName?: string | null,
      drAvatar?: string | null,
      clinicPublicId?: string | null,
      clinicName?: string | null,
      clinicFormerlyKnownAs?: string | null,
      clinicStatus?: ClinicStatus | null,
      clinicLogoImage?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentDefinitionByDrPublicIdQueryVariables = {
  drPublicId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentDefinitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentDefinitionByDrPublicIdQuery = {
  findAppointmentDefinitionByDrPublicId?:  {
    __typename: "ModelAppointmentDefinitionConnection",
    items:  Array< {
      __typename: "AppointmentDefinition",
      id: string,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      calendlyAppointmentID?: string | null,
      calendlyAppointment?:  {
        __typename: "CalendlyAppointment",
        id: string,
        slug: string,
        name?: string | null,
        uri?: string | null,
        scheduling_url?: string | null,
        duration?: number | null,
        kind?: string | null,
        type?: string | null,
        color?: string | null,
        active?: boolean | null,
        booking_method?: string | null,
        description_plain?: string | null,
        description_html?: string | null,
        internal_note?: string | null,
        pooling_type?: string | null,
        secret?: boolean | null,
        profile?: string | null,
        custom_questions?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinicID?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      } | null,
      appointmentType?: AppointmentType | null,
      appointmentSubject?: AppointmentSubject | null,
      drPublicId?: string | null,
      drName?: string | null,
      drAvatar?: string | null,
      clinicPublicId?: string | null,
      clinicName?: string | null,
      clinicFormerlyKnownAs?: string | null,
      clinicStatus?: ClinicStatus | null,
      clinicLogoImage?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentDefinitionByClinicPublicIdQueryVariables = {
  clinicPublicId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentDefinitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentDefinitionByClinicPublicIdQuery = {
  findAppointmentDefinitionByClinicPublicId?:  {
    __typename: "ModelAppointmentDefinitionConnection",
    items:  Array< {
      __typename: "AppointmentDefinition",
      id: string,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      calendlyAppointmentID?: string | null,
      calendlyAppointment?:  {
        __typename: "CalendlyAppointment",
        id: string,
        slug: string,
        name?: string | null,
        uri?: string | null,
        scheduling_url?: string | null,
        duration?: number | null,
        kind?: string | null,
        type?: string | null,
        color?: string | null,
        active?: boolean | null,
        booking_method?: string | null,
        description_plain?: string | null,
        description_html?: string | null,
        internal_note?: string | null,
        pooling_type?: string | null,
        secret?: boolean | null,
        profile?: string | null,
        custom_questions?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinicID?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      } | null,
      appointmentType?: AppointmentType | null,
      appointmentSubject?: AppointmentSubject | null,
      drPublicId?: string | null,
      drName?: string | null,
      drAvatar?: string | null,
      clinicPublicId?: string | null,
      clinicName?: string | null,
      clinicFormerlyKnownAs?: string | null,
      clinicStatus?: ClinicStatus | null,
      clinicLogoImage?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppointmentNoteQueryVariables = {
  id: string,
};

export type GetAppointmentNoteQuery = {
  getAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListAppointmentNotesQueryVariables = {
  filter?: ModelAppointmentNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentNotesQuery = {
  listAppointmentNotes?:  {
    __typename: "ModelAppointmentNoteConnection",
    items:  Array< {
      __typename: "AppointmentNote",
      id: string,
      note: string,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentNoteByAppointmentIdQueryVariables = {
  appointmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentNoteByAppointmentIdQuery = {
  findAppointmentNoteByAppointmentId?:  {
    __typename: "ModelAppointmentNoteConnection",
    items:  Array< {
      __typename: "AppointmentNote",
      id: string,
      note: string,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentNoteByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentNoteByDoctorIdQuery = {
  findAppointmentNoteByDoctorId?:  {
    __typename: "ModelAppointmentNoteConnection",
    items:  Array< {
      __typename: "AppointmentNote",
      id: string,
      note: string,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentNoteByPatientIdQueryVariables = {
  patientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentNoteByPatientIdQuery = {
  findAppointmentNoteByPatientId?:  {
    __typename: "ModelAppointmentNoteConnection",
    items:  Array< {
      __typename: "AppointmentNote",
      id: string,
      note: string,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppointmentMedicationQueryVariables = {
  id: string,
};

export type GetAppointmentMedicationQuery = {
  getAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListAppointmentMedicationsQueryVariables = {
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentMedicationsQuery = {
  listAppointmentMedications?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentMedicationByMapIdQueryVariables = {
  mapID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentMedicationByMapIdQuery = {
  findAppointmentMedicationByMapId?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentMedicationByProductIdQueryVariables = {
  productID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentMedicationByProductIdQuery = {
  findAppointmentMedicationByProductId?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentMedicationByAppointmentIdQueryVariables = {
  appointmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentMedicationByAppointmentIdQuery = {
  findAppointmentMedicationByAppointmentId?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentMedicationByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentMedicationByDoctorIdQuery = {
  findAppointmentMedicationByDoctorId?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAppointmentMedicationByPatientIdQueryVariables = {
  patientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAppointmentMedicationByPatientIdQuery = {
  findAppointmentMedicationByPatientId?:  {
    __typename: "ModelAppointmentMedicationConnection",
    items:  Array< {
      __typename: "AppointmentMedication",
      id: string,
      mapID: string,
      map?:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      productID: string,
      product?:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      } | null,
      appointmentID: string,
      appointment?:  {
        __typename: "TypeFormEvent",
        id: string,
        doctorPublicId: string,
        clinicPublicId?: string | null,
        patientID?: string | null,
        bookerID?: string | null,
        definitionID?: string | null,
        definitionTitle?: string | null,
        definitionSlug?: string | null,
        definitionType?: AppointmentType | null,
        definitionSubject?: AppointmentSubject | null,
        definitionDoctorName?: string | null,
        definitionClinicName?: string | null,
        definitionClinicLogo?: string | null,
        who?: Who | null,
        email?: string | null,
        phone?: string | null,
        firstName: string,
        bookingUrl?: string | null,
        oldBookingUrl?: string | null,
        paymentSuccess?: boolean | null,
        paymentAmount?: string | null,
        questions?: string | null,
        answers?: string | null,
        hidden?: string | null,
        calendlyStatus?: string | null,
        rescheduled?: boolean | null,
        canceled?: boolean | null,
        cancelReason?: string | null,
        landedAt?: string | null,
        submittedAt?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        createdAt?: string | null,
        sorter?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      doctorID: string,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      phone?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      dailyDose?: string | null,
      measurement?: Measurement | null,
      quantity?: string | null,
      repeats?: string | null,
      repeatInterval?: string | null,
      frequency?: string | null,
      prn?: boolean | null,
      instruction?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminQueryVariables = {
  id: string,
};

export type GetAdminQuery = {
  getAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListAdminsQueryVariables = {
  filter?: ModelAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminsQuery = {
  listAdmins?:  {
    __typename: "ModelAdminConnection",
    items:  Array< {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAdminByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAdminByUserIdQuery = {
  findAdminByUserId?:  {
    __typename: "ModelAdminConnection",
    items:  Array< {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReceptionQueryVariables = {
  id: string,
};

export type GetReceptionQuery = {
  getReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListReceptionsQueryVariables = {
  filter?: ModelReceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReceptionsQuery = {
  listReceptions?:  {
    __typename: "ModelReceptionConnection",
    items:  Array< {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindReceptionByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindReceptionByUserIdQuery = {
  findReceptionByUserId?:  {
    __typename: "ModelReceptionConnection",
    items:  Array< {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountantQueryVariables = {
  id: string,
};

export type GetAccountantQuery = {
  getAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListAccountantsQueryVariables = {
  filter?: ModelAccountantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountantsQuery = {
  listAccountants?:  {
    __typename: "ModelAccountantConnection",
    items:  Array< {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindAccountantByUserIdQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindAccountantByUserIdQuery = {
  findAccountantByUserId?:  {
    __typename: "ModelAccountantConnection",
    items:  Array< {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicQueryVariables = {
  id: string,
};

export type GetClinicQuery = {
  getClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type ListClinicsQueryVariables = {
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsQuery = {
  listClinics?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicByPublicIdQueryVariables = {
  publicId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicByPublicIdQuery = {
  findClinicByPublicId?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicByNameQueryVariables = {
  name: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicByNameQuery = {
  findClinicByName?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicByStatusQueryVariables = {
  status: ClinicStatus,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicByStatusQuery = {
  findClinicByStatus?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicBySorterQueryVariables = {
  sorter: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicBySorterQuery = {
  findClinicBySorter?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicAdminQueryVariables = {
  id: string,
};

export type GetClinicAdminQuery = {
  getClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListClinicAdminsQueryVariables = {
  filter?: ModelClinicAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicAdminsQuery = {
  listClinicAdmins?:  {
    __typename: "ModelClinicAdminConnection",
    items:  Array< {
      __typename: "ClinicAdmin",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      adminID: string,
      admin:  {
        __typename: "Admin",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicAdminByClinicIdQueryVariables = {
  clinicID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicAdminByClinicIdQuery = {
  findClinicAdminByClinicId?:  {
    __typename: "ModelClinicAdminConnection",
    items:  Array< {
      __typename: "ClinicAdmin",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      adminID: string,
      admin:  {
        __typename: "Admin",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicAdminByAdminIdQueryVariables = {
  adminID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicAdminByAdminIdQuery = {
  findClinicAdminByAdminId?:  {
    __typename: "ModelClinicAdminConnection",
    items:  Array< {
      __typename: "ClinicAdmin",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      adminID: string,
      admin:  {
        __typename: "Admin",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicReceptionQueryVariables = {
  id: string,
};

export type GetClinicReceptionQuery = {
  getClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListClinicReceptionsQueryVariables = {
  filter?: ModelClinicReceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicReceptionsQuery = {
  listClinicReceptions?:  {
    __typename: "ModelClinicReceptionConnection",
    items:  Array< {
      __typename: "ClinicReception",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      receptionID: string,
      reception:  {
        __typename: "Reception",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicReceptionByClinicIdQueryVariables = {
  clinicID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicReceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicReceptionByClinicIdQuery = {
  findClinicReceptionByClinicId?:  {
    __typename: "ModelClinicReceptionConnection",
    items:  Array< {
      __typename: "ClinicReception",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      receptionID: string,
      reception:  {
        __typename: "Reception",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicReceptionByReceptionIdQueryVariables = {
  receptionID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicReceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicReceptionByReceptionIdQuery = {
  findClinicReceptionByReceptionId?:  {
    __typename: "ModelClinicReceptionConnection",
    items:  Array< {
      __typename: "ClinicReception",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      receptionID: string,
      reception:  {
        __typename: "Reception",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicAccountantQueryVariables = {
  id: string,
};

export type GetClinicAccountantQuery = {
  getClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListClinicAccountantsQueryVariables = {
  filter?: ModelClinicAccountantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicAccountantsQuery = {
  listClinicAccountants?:  {
    __typename: "ModelClinicAccountantConnection",
    items:  Array< {
      __typename: "ClinicAccountant",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      accountantID: string,
      accountant:  {
        __typename: "Accountant",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicAccountantByClinicIdQueryVariables = {
  clinicID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicAccountantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicAccountantByClinicIdQuery = {
  findClinicAccountantByClinicId?:  {
    __typename: "ModelClinicAccountantConnection",
    items:  Array< {
      __typename: "ClinicAccountant",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      accountantID: string,
      accountant:  {
        __typename: "Accountant",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicAccountantByAccountantIdQueryVariables = {
  accountantID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicAccountantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicAccountantByAccountantIdQuery = {
  findClinicAccountantByAccountantId?:  {
    __typename: "ModelClinicAccountantConnection",
    items:  Array< {
      __typename: "ClinicAccountant",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      accountantID: string,
      accountant:  {
        __typename: "Accountant",
        id: string,
        userID: string,
        name?: string | null,
        avatar?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicDoctorQueryVariables = {
  id: string,
};

export type GetClinicDoctorQuery = {
  getClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListClinicDoctorsQueryVariables = {
  filter?: ModelClinicDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicDoctorsQuery = {
  listClinicDoctors?:  {
    __typename: "ModelClinicDoctorConnection",
    items:  Array< {
      __typename: "ClinicDoctor",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicDoctorByClinicIdQueryVariables = {
  clinicID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicDoctorByClinicIdQuery = {
  findClinicDoctorByClinicId?:  {
    __typename: "ModelClinicDoctorConnection",
    items:  Array< {
      __typename: "ClinicDoctor",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindClinicDoctorByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindClinicDoctorByDoctorIdQuery = {
  findClinicDoctorByDoctorId?:  {
    __typename: "ModelClinicDoctorConnection",
    items:  Array< {
      __typename: "ClinicDoctor",
      id: string,
      clinicID: string,
      clinic:  {
        __typename: "Clinic",
        id: string,
        publicId: string,
        name: string,
        formerlyKnownAs?: string | null,
        status?: ClinicStatus | null,
        address?: string | null,
        phone?: string | null,
        logoImage?: string | null,
        openingHours?: string | null,
        bankName?: string | null,
        bankStateBranch?: string | null,
        bankAccountName?: string | null,
        bankAccountNumber?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sorter: string,
        owner?: string | null,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCannabisProductQueryVariables = {
  id: string,
};

export type GetCannabisProductQuery = {
  getCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListCannabisProductsQueryVariables = {
  filter?: ModelCannabisProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCannabisProductsQuery = {
  listCannabisProducts?:  {
    __typename: "ModelCannabisProductConnection",
    items:  Array< {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCannabisProductByNameQueryVariables = {
  name: string,
  idxOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCannabisProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCannabisProductByNameQuery = {
  findCannabisProductByName?:  {
    __typename: "ModelCannabisProductConnection",
    items:  Array< {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCannabisProductBySorterQueryVariables = {
  sorter: string,
  idxOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCannabisProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCannabisProductBySorterQuery = {
  findCannabisProductBySorter?:  {
    __typename: "ModelCannabisProductConnection",
    items:  Array< {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMedicalAuthorisedPrescriberQueryVariables = {
  id: string,
};

export type GetMedicalAuthorisedPrescriberQuery = {
  getMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListMedicalAuthorisedPrescribersQueryVariables = {
  filter?: ModelMedicalAuthorisedPrescriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicalAuthorisedPrescribersQuery = {
  listMedicalAuthorisedPrescribers?:  {
    __typename: "ModelMedicalAuthorisedPrescriberConnection",
    items:  Array< {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindMedicalAuthorisedPrescriberByNumberQueryVariables = {
  mapNumber: string,
  idxOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicalAuthorisedPrescriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindMedicalAuthorisedPrescriberByNumberQuery = {
  findMedicalAuthorisedPrescriberByNumber?:  {
    __typename: "ModelMedicalAuthorisedPrescriberConnection",
    items:  Array< {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindMedicalAuthorisedPrescriberByDoctorIdQueryVariables = {
  doctorID: string,
  idxOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicalAuthorisedPrescriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindMedicalAuthorisedPrescriberByDoctorIdQuery = {
  findMedicalAuthorisedPrescriberByDoctorId?:  {
    __typename: "ModelMedicalAuthorisedPrescriberConnection",
    items:  Array< {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindMedicalAuthorisedPrescriberBySorterQueryVariables = {
  sorter: string,
  idxOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicalAuthorisedPrescriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindMedicalAuthorisedPrescriberBySorterQuery = {
  findMedicalAuthorisedPrescriberBySorter?:  {
    __typename: "ModelMedicalAuthorisedPrescriberConnection",
    items:  Array< {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScriptReceiverQueryVariables = {
  id: string,
};

export type GetScriptReceiverQuery = {
  getScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListScriptReceiversQueryVariables = {
  filter?: ModelScriptReceiverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScriptReceiversQuery = {
  listScriptReceivers?:  {
    __typename: "ModelScriptReceiverConnection",
    items:  Array< {
      __typename: "ScriptReceiver",
      id: string,
      doctorID?: string | null,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      name?: string | null,
      email?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindScriptReceiverByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScriptReceiverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindScriptReceiverByDoctorIdQuery = {
  findScriptReceiverByDoctorId?:  {
    __typename: "ModelScriptReceiverConnection",
    items:  Array< {
      __typename: "ScriptReceiver",
      id: string,
      doctorID?: string | null,
      doctor?:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      name?: string | null,
      email?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductMapQueryVariables = {
  id: string,
};

export type GetProductMapQuery = {
  getProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListProductMapsQueryVariables = {
  filter?: ModelProductMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductMapsQuery = {
  listProductMaps?:  {
    __typename: "ModelProductMapConnection",
    items:  Array< {
      __typename: "ProductMap",
      id: string,
      productID: string,
      product:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      mapID: string,
      map:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindProductMapByProductIdQueryVariables = {
  productID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindProductMapByProductIdQuery = {
  findProductMapByProductId?:  {
    __typename: "ModelProductMapConnection",
    items:  Array< {
      __typename: "ProductMap",
      id: string,
      productID: string,
      product:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      mapID: string,
      map:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindProductMapByMapIdQueryVariables = {
  mapID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindProductMapByMapIdQuery = {
  findProductMapByMapId?:  {
    __typename: "ModelProductMapConnection",
    items:  Array< {
      __typename: "ProductMap",
      id: string,
      productID: string,
      product:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      mapID: string,
      map:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindProductMapByDoctorIdQueryVariables = {
  doctorID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindProductMapByDoctorIdQuery = {
  findProductMapByDoctorId?:  {
    __typename: "ModelProductMapConnection",
    items:  Array< {
      __typename: "ProductMap",
      id: string,
      productID: string,
      product:  {
        __typename: "CannabisProduct",
        id: string,
        idxOrder: number,
        name: string,
        ingredients?: string | null,
        qtyPerDosageUnit?: string | null,
        category?: ProductCategory | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        strength?: string | null,
        volume?: string | null,
        sponsor?: string | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      mapID: string,
      map:  {
        __typename: "MedicalAuthorisedPrescriber",
        id: string,
        mapNumber: string,
        category?: ProductCategory | null,
        idxOrder: number,
        doctorID: string,
        patientID?: string | null,
        status?: TgaStatus | null,
        presentation?: ProductPresentation | null,
        presentationText?: string | null,
        createdDate?: string | null,
        decisionDate?: string | null,
        expiryDate?: string | null,
        approvalDate?: string | null,
        isSAS?: boolean | null,
        archived?: boolean | null,
        createdAt?: string | null,
        sorter: string,
        owner?: string | null,
        updatedAt: string,
      },
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPatientLinkQueryVariables = {
  doctorID: string,
  patientID: string,
};

export type GetPatientLinkQuery = {
  getPatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListPatientLinksQueryVariables = {
  doctorID?: string | null,
  patientID?: ModelIDKeyConditionInput | null,
  filter?: ModelPatientLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPatientLinksQuery = {
  listPatientLinks?:  {
    __typename: "ModelPatientLinkConnection",
    items:  Array< {
      __typename: "PatientLink",
      doctorID: string,
      patientID: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      clinicID?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateTypeFormEventSubscriptionVariables = {
  filter?: ModelSubscriptionTypeFormEventFilterInput | null,
  owner?: string | null,
};

export type OnCreateTypeFormEventSubscription = {
  onCreateTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateTypeFormEventSubscriptionVariables = {
  filter?: ModelSubscriptionTypeFormEventFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTypeFormEventSubscription = {
  onUpdateTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteTypeFormEventSubscriptionVariables = {
  filter?: ModelSubscriptionTypeFormEventFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTypeFormEventSubscription = {
  onDeleteTypeFormEvent?:  {
    __typename: "TypeFormEvent",
    id: string,
    doctorPublicId: string,
    clinicPublicId?: string | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookerID?: string | null,
    definitionID?: string | null,
    definitionTitle?: string | null,
    definitionSlug?: string | null,
    definitionType?: AppointmentType | null,
    definitionSubject?: AppointmentSubject | null,
    definitionDoctorName?: string | null,
    definitionClinicName?: string | null,
    definitionClinicLogo?: string | null,
    who?: Who | null,
    email?: string | null,
    phone?: string | null,
    firstName: string,
    bookingUrl?: string | null,
    oldBookingUrl?: string | null,
    paymentSuccess?: boolean | null,
    paymentAmount?: string | null,
    questions?: string | null,
    answers?: string | null,
    hidden?: string | null,
    calendlyStatus?: string | null,
    rescheduled?: boolean | null,
    canceled?: boolean | null,
    cancelReason?: string | null,
    landedAt?: string | null,
    submittedAt?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    createdAt?: string | null,
    sorter?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    username: string,
    email?: string | null,
    phoneNumber?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
  owner?: string | null,
};

export type OnCreatePatientSubscription = {
  onCreatePatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePatientSubscription = {
  onUpdatePatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeletePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
  owner?: string | null,
};

export type OnDeletePatientSubscription = {
  onDeletePatient?:  {
    __typename: "Patient",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    givenName: string,
    surName?: string | null,
    medicareNumber?: string | null,
    addressOneLine?: string | null,
    dateOfBirth?: string | null,
    contactNumber?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    primaryDiagnosis?: string | null,
    otherTreatments?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateReferredSubscriptionVariables = {
  filter?: ModelSubscriptionReferredFilterInput | null,
};

export type OnCreateReferredSubscription = {
  onCreateReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateReferredSubscriptionVariables = {
  filter?: ModelSubscriptionReferredFilterInput | null,
};

export type OnUpdateReferredSubscription = {
  onUpdateReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteReferredSubscriptionVariables = {
  filter?: ModelSubscriptionReferredFilterInput | null,
};

export type OnDeleteReferredSubscription = {
  onDeleteReferred?:  {
    __typename: "Referred",
    id: string,
    givenName: string,
    surName?: string | null,
    addressOneLine?: string | null,
    medicareNumber?: string | null,
    dateOfBirth?: string | null,
    phone?: string | null,
    ihiNo?: string | null,
    nextOfKin?: string | null,
    nextOfKinRelation?: string | null,
    nextOfKinContact?: string | null,
    powerOfAttorney?: string | null,
    powerOfAttorneyRelation?: string | null,
    powerOfAttorneyContact?: string | null,
    carer?: string | null,
    carerRelation?: string | null,
    carerContact?: string | null,
    emergency?: string | null,
    emergencyRelation?: string | null,
    emergencyContact?: string | null,
    symptoms?: string | null,
    otherTreatments?: string | null,
    primaryDiagnosis?: string | null,
    otherMedications?: string | null,
    selfMedicated?: string | null,
    kidneyLiver?: string | null,
    cardiovascular?: string | null,
    substanceAbuseDisorder?: string | null,
    allergies?: string | null,
    smoke?: string | null,
    drinkAlcohol?: string | null,
    otherConditions?: string | null,
    cvConditions?: string | null,
    practitionerConcerns?: string | null,
    recentReports?: string | null,
    clinicPublicId?: string | null,
    doctorPublicId?: string | null,
    readByClinic?: boolean | null,
    readByDoctor?: boolean | null,
    practitionerFirstName?: string | null,
    practitionerLastName?: string | null,
    practitionerAddress?: string | null,
    practitionerProviderNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateConsentEventSubscriptionVariables = {
  filter?: ModelSubscriptionConsentEventFilterInput | null,
  owner?: string | null,
};

export type OnCreateConsentEventSubscription = {
  onCreateConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateConsentEventSubscriptionVariables = {
  filter?: ModelSubscriptionConsentEventFilterInput | null,
  owner?: string | null,
};

export type OnUpdateConsentEventSubscription = {
  onUpdateConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteConsentEventSubscriptionVariables = {
  filter?: ModelSubscriptionConsentEventFilterInput | null,
  owner?: string | null,
};

export type OnDeleteConsentEventSubscription = {
  onDeleteConsentEvent?:  {
    __typename: "ConsentEvent",
    id: string,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    givenName: string,
    familyName: string,
    consenter: string,
    consentContent: string,
    researchAllowed?: boolean | null,
    manual?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuizSubscription = {
  onCreateQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuizSubscription = {
  onUpdateQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuizSubscription = {
  onDeleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    userID: string,
    quizType?: QuizType | null,
    questions: string,
    givenName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCalendlyAppointmentSubscriptionVariables = {
  filter?: ModelSubscriptionCalendlyAppointmentFilterInput | null,
};

export type OnCreateCalendlyAppointmentSubscription = {
  onCreateCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCalendlyAppointmentSubscriptionVariables = {
  filter?: ModelSubscriptionCalendlyAppointmentFilterInput | null,
};

export type OnUpdateCalendlyAppointmentSubscription = {
  onUpdateCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCalendlyAppointmentSubscriptionVariables = {
  filter?: ModelSubscriptionCalendlyAppointmentFilterInput | null,
};

export type OnDeleteCalendlyAppointmentSubscription = {
  onDeleteCalendlyAppointment?:  {
    __typename: "CalendlyAppointment",
    id: string,
    slug: string,
    name?: string | null,
    uri?: string | null,
    scheduling_url?: string | null,
    duration?: number | null,
    kind?: string | null,
    type?: string | null,
    color?: string | null,
    active?: boolean | null,
    booking_method?: string | null,
    description_plain?: string | null,
    description_html?: string | null,
    internal_note?: string | null,
    pooling_type?: string | null,
    secret?: boolean | null,
    profile?: string | null,
    custom_questions?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionDoctorFilterInput | null,
  owner?: string | null,
};

export type OnCreateDoctorSubscription = {
  onCreateDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionDoctorFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDoctorSubscription = {
  onUpdateDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionDoctorFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDoctorSubscription = {
  onDeleteDoctor?:  {
    __typename: "Doctor",
    id: string,
    drId: string,
    drName: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    avatar?: string | null,
    qualifications?: string | null,
    prescriberNumber?: string | null,
    clinics?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    appointmentDefinitions?:  {
      __typename: "ModelAppointmentDefinitionConnection",
      items:  Array< {
        __typename: "AppointmentDefinition",
        id: string,
        doctorID: string,
        calendlyAppointmentID?: string | null,
        clinicID?: string | null,
        appointmentType?: AppointmentType | null,
        appointmentSubject?: AppointmentSubject | null,
        drPublicId?: string | null,
        drName?: string | null,
        drAvatar?: string | null,
        clinicPublicId?: string | null,
        clinicName?: string | null,
        clinicFormerlyKnownAs?: string | null,
        clinicStatus?: ClinicStatus | null,
        clinicLogoImage?: string | null,
        active?: boolean | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: DoctorStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateAppointmentDefinitionSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentDefinitionFilterInput | null,
};

export type OnCreateAppointmentDefinitionSubscription = {
  onCreateAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppointmentDefinitionSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentDefinitionFilterInput | null,
};

export type OnUpdateAppointmentDefinitionSubscription = {
  onUpdateAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppointmentDefinitionSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentDefinitionFilterInput | null,
};

export type OnDeleteAppointmentDefinitionSubscription = {
  onDeleteAppointmentDefinition?:  {
    __typename: "AppointmentDefinition",
    id: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    calendlyAppointmentID?: string | null,
    calendlyAppointment?:  {
      __typename: "CalendlyAppointment",
      id: string,
      slug: string,
      name?: string | null,
      uri?: string | null,
      scheduling_url?: string | null,
      duration?: number | null,
      kind?: string | null,
      type?: string | null,
      color?: string | null,
      active?: boolean | null,
      booking_method?: string | null,
      description_plain?: string | null,
      description_html?: string | null,
      internal_note?: string | null,
      pooling_type?: string | null,
      secret?: boolean | null,
      profile?: string | null,
      custom_questions?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinicID?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    } | null,
    appointmentType?: AppointmentType | null,
    appointmentSubject?: AppointmentSubject | null,
    drPublicId?: string | null,
    drName?: string | null,
    drAvatar?: string | null,
    clinicPublicId?: string | null,
    clinicName?: string | null,
    clinicFormerlyKnownAs?: string | null,
    clinicStatus?: ClinicStatus | null,
    clinicLogoImage?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAppointmentNoteSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentNoteFilterInput | null,
  owner?: string | null,
};

export type OnCreateAppointmentNoteSubscription = {
  onCreateAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppointmentNoteSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentNoteFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAppointmentNoteSubscription = {
  onUpdateAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppointmentNoteSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentNoteFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAppointmentNoteSubscription = {
  onDeleteAppointmentNote?:  {
    __typename: "AppointmentNote",
    id: string,
    note: string,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAppointmentMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentMedicationFilterInput | null,
  owner?: string | null,
};

export type OnCreateAppointmentMedicationSubscription = {
  onCreateAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppointmentMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentMedicationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAppointmentMedicationSubscription = {
  onUpdateAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppointmentMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionAppointmentMedicationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAppointmentMedicationSubscription = {
  onDeleteAppointmentMedication?:  {
    __typename: "AppointmentMedication",
    id: string,
    mapID: string,
    map?:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    productID: string,
    product?:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    } | null,
    appointmentID: string,
    appointment?:  {
      __typename: "TypeFormEvent",
      id: string,
      doctorPublicId: string,
      clinicPublicId?: string | null,
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      bookerID?: string | null,
      definitionID?: string | null,
      definitionTitle?: string | null,
      definitionSlug?: string | null,
      definitionType?: AppointmentType | null,
      definitionSubject?: AppointmentSubject | null,
      definitionDoctorName?: string | null,
      definitionClinicName?: string | null,
      definitionClinicLogo?: string | null,
      who?: Who | null,
      email?: string | null,
      phone?: string | null,
      firstName: string,
      bookingUrl?: string | null,
      oldBookingUrl?: string | null,
      paymentSuccess?: boolean | null,
      paymentAmount?: string | null,
      questions?: string | null,
      answers?: string | null,
      hidden?: string | null,
      calendlyStatus?: string | null,
      rescheduled?: boolean | null,
      canceled?: boolean | null,
      cancelReason?: string | null,
      landedAt?: string | null,
      submittedAt?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      createdAt?: string | null,
      sorter?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    phone?: string | null,
    givenName?: string | null,
    familyName?: string | null,
    dailyDose?: string | null,
    measurement?: Measurement | null,
    quantity?: string | null,
    repeats?: string | null,
    repeatInterval?: string | null,
    frequency?: string | null,
    prn?: boolean | null,
    instruction?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnCreateAdminSubscription = {
  onCreateAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAdminSubscription = {
  onUpdateAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdminSubscriptionVariables = {
  filter?: ModelSubscriptionAdminFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAdminSubscription = {
  onDeleteAdmin?:  {
    __typename: "Admin",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionReceptionFilterInput | null,
  owner?: string | null,
};

export type OnCreateReceptionSubscription = {
  onCreateReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionReceptionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateReceptionSubscription = {
  onUpdateReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionReceptionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteReceptionSubscription = {
  onDeleteReception?:  {
    __typename: "Reception",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionAccountantFilterInput | null,
  owner?: string | null,
};

export type OnCreateAccountantSubscription = {
  onCreateAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionAccountantFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAccountantSubscription = {
  onUpdateAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionAccountantFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAccountantSubscription = {
  onDeleteAccountant?:  {
    __typename: "Accountant",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      sub: string,
      username: string,
      email?: string | null,
      phoneNumber?: string | null,
      givenName?: string | null,
      familyName?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    } | null,
    clinics?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    avatar?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
  owner?: string | null,
};

export type OnCreateClinicSubscription = {
  onCreateClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
  owner?: string | null,
};

export type OnUpdateClinicSubscription = {
  onUpdateClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
  owner?: string | null,
};

export type OnDeleteClinicSubscription = {
  onDeleteClinic?:  {
    __typename: "Clinic",
    id: string,
    publicId: string,
    name: string,
    formerlyKnownAs?: string | null,
    status?: ClinicStatus | null,
    receptions?:  {
      __typename: "ModelClinicReceptionConnection",
      items:  Array< {
        __typename: "ClinicReception",
        id: string,
        clinicID: string,
        receptionID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountants?:  {
      __typename: "ModelClinicAccountantConnection",
      items:  Array< {
        __typename: "ClinicAccountant",
        id: string,
        clinicID: string,
        accountantID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelClinicDoctorConnection",
      items:  Array< {
        __typename: "ClinicDoctor",
        id: string,
        clinicID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelClinicAdminConnection",
      items:  Array< {
        __typename: "ClinicAdmin",
        id: string,
        clinicID: string,
        adminID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    phone?: string | null,
    logoImage?: string | null,
    location?:  {
      __typename: "Location",
      lat: string,
      lon: string,
    } | null,
    openingHours?: string | null,
    bankName?: string | null,
    bankStateBranch?: string | null,
    bankAccountName?: string | null,
    bankAccountNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sorter: string,
    owner?: string | null,
  } | null,
};

export type OnCreateClinicAdminSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAdminFilterInput | null,
  owner?: string | null,
};

export type OnCreateClinicAdminSubscription = {
  onCreateClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateClinicAdminSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAdminFilterInput | null,
  owner?: string | null,
};

export type OnUpdateClinicAdminSubscription = {
  onUpdateClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteClinicAdminSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAdminFilterInput | null,
  owner?: string | null,
};

export type OnDeleteClinicAdminSubscription = {
  onDeleteClinicAdmin?:  {
    __typename: "ClinicAdmin",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    adminID: string,
    admin:  {
      __typename: "Admin",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicReceptionFilterInput | null,
  owner?: string | null,
};

export type OnCreateClinicReceptionSubscription = {
  onCreateClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateClinicReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicReceptionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateClinicReceptionSubscription = {
  onUpdateClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteClinicReceptionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicReceptionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteClinicReceptionSubscription = {
  onDeleteClinicReception?:  {
    __typename: "ClinicReception",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    receptionID: string,
    reception:  {
      __typename: "Reception",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAccountantFilterInput | null,
  owner?: string | null,
};

export type OnCreateClinicAccountantSubscription = {
  onCreateClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateClinicAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAccountantFilterInput | null,
  owner?: string | null,
};

export type OnUpdateClinicAccountantSubscription = {
  onUpdateClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteClinicAccountantSubscriptionVariables = {
  filter?: ModelSubscriptionClinicAccountantFilterInput | null,
  owner?: string | null,
};

export type OnDeleteClinicAccountantSubscription = {
  onDeleteClinicAccountant?:  {
    __typename: "ClinicAccountant",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    accountantID: string,
    accountant:  {
      __typename: "Accountant",
      id: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      clinics?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      avatar?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt: string,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionClinicDoctorFilterInput | null,
  owner?: string | null,
};

export type OnCreateClinicDoctorSubscription = {
  onCreateClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateClinicDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionClinicDoctorFilterInput | null,
  owner?: string | null,
};

export type OnUpdateClinicDoctorSubscription = {
  onUpdateClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteClinicDoctorSubscriptionVariables = {
  filter?: ModelSubscriptionClinicDoctorFilterInput | null,
  owner?: string | null,
};

export type OnDeleteClinicDoctorSubscription = {
  onDeleteClinicDoctor?:  {
    __typename: "ClinicDoctor",
    id: string,
    clinicID: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      publicId: string,
      name: string,
      formerlyKnownAs?: string | null,
      status?: ClinicStatus | null,
      receptions?:  {
        __typename: "ModelClinicReceptionConnection",
        nextToken?: string | null,
      } | null,
      accountants?:  {
        __typename: "ModelClinicAccountantConnection",
        nextToken?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelClinicAdminConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      phone?: string | null,
      logoImage?: string | null,
      location?:  {
        __typename: "Location",
        lat: string,
        lon: string,
      } | null,
      openingHours?: string | null,
      bankName?: string | null,
      bankStateBranch?: string | null,
      bankAccountName?: string | null,
      bankAccountNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sorter: string,
      owner?: string | null,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCannabisProductSubscriptionVariables = {
  filter?: ModelSubscriptionCannabisProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateCannabisProductSubscription = {
  onCreateCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCannabisProductSubscriptionVariables = {
  filter?: ModelSubscriptionCannabisProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCannabisProductSubscription = {
  onUpdateCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCannabisProductSubscriptionVariables = {
  filter?: ModelSubscriptionCannabisProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCannabisProductSubscription = {
  onDeleteCannabisProduct?:  {
    __typename: "CannabisProduct",
    id: string,
    idxOrder: number,
    name: string,
    ingredients?: string | null,
    qtyPerDosageUnit?: string | null,
    category?: ProductCategory | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    strength?: string | null,
    volume?: string | null,
    sponsor?: string | null,
    archived?: boolean | null,
    maps?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateMedicalAuthorisedPrescriberSubscriptionVariables = {
  filter?: ModelSubscriptionMedicalAuthorisedPrescriberFilterInput | null,
  owner?: string | null,
};

export type OnCreateMedicalAuthorisedPrescriberSubscription = {
  onCreateMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateMedicalAuthorisedPrescriberSubscriptionVariables = {
  filter?: ModelSubscriptionMedicalAuthorisedPrescriberFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMedicalAuthorisedPrescriberSubscription = {
  onUpdateMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteMedicalAuthorisedPrescriberSubscriptionVariables = {
  filter?: ModelSubscriptionMedicalAuthorisedPrescriberFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMedicalAuthorisedPrescriberSubscription = {
  onDeleteMedicalAuthorisedPrescriber?:  {
    __typename: "MedicalAuthorisedPrescriber",
    id: string,
    mapNumber: string,
    category?: ProductCategory | null,
    idxOrder: number,
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    patientID?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    status?: TgaStatus | null,
    presentation?: ProductPresentation | null,
    presentationText?: string | null,
    createdDate?: string | null,
    decisionDate?: string | null,
    expiryDate?: string | null,
    approvalDate?: string | null,
    isSAS?: boolean | null,
    archived?: boolean | null,
    products?:  {
      __typename: "ModelProductMapConnection",
      items:  Array< {
        __typename: "ProductMap",
        id: string,
        productID: string,
        mapID: string,
        doctorID: string,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    sorter: string,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateScriptReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionScriptReceiverFilterInput | null,
  owner?: string | null,
};

export type OnCreateScriptReceiverSubscription = {
  onCreateScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateScriptReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionScriptReceiverFilterInput | null,
  owner?: string | null,
};

export type OnUpdateScriptReceiverSubscription = {
  onUpdateScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteScriptReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionScriptReceiverFilterInput | null,
  owner?: string | null,
};

export type OnDeleteScriptReceiverSubscription = {
  onDeleteScriptReceiver?:  {
    __typename: "ScriptReceiver",
    id: string,
    doctorID?: string | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    name?: string | null,
    email?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateProductMapSubscriptionVariables = {
  filter?: ModelSubscriptionProductMapFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductMapSubscription = {
  onCreateProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductMapSubscriptionVariables = {
  filter?: ModelSubscriptionProductMapFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductMapSubscription = {
  onUpdateProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductMapSubscriptionVariables = {
  filter?: ModelSubscriptionProductMapFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductMapSubscription = {
  onDeleteProductMap?:  {
    __typename: "ProductMap",
    id: string,
    productID: string,
    product:  {
      __typename: "CannabisProduct",
      id: string,
      idxOrder: number,
      name: string,
      ingredients?: string | null,
      qtyPerDosageUnit?: string | null,
      category?: ProductCategory | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      strength?: string | null,
      volume?: string | null,
      sponsor?: string | null,
      archived?: boolean | null,
      maps?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    mapID: string,
    map:  {
      __typename: "MedicalAuthorisedPrescriber",
      id: string,
      mapNumber: string,
      category?: ProductCategory | null,
      idxOrder: number,
      doctorID: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        drId: string,
        drName: string,
        userID: string,
        avatar?: string | null,
        qualifications?: string | null,
        prescriberNumber?: string | null,
        status?: DoctorStatus | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      },
      patientID?: string | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        email?: string | null,
        phone?: string | null,
        userID?: string | null,
        givenName: string,
        surName?: string | null,
        medicareNumber?: string | null,
        addressOneLine?: string | null,
        dateOfBirth?: string | null,
        contactNumber?: string | null,
        nextOfKin?: string | null,
        nextOfKinRelation?: string | null,
        nextOfKinContact?: string | null,
        powerOfAttorney?: string | null,
        powerOfAttorneyRelation?: string | null,
        powerOfAttorneyContact?: string | null,
        carer?: string | null,
        carerRelation?: string | null,
        carerContact?: string | null,
        emergency?: string | null,
        emergencyRelation?: string | null,
        emergencyContact?: string | null,
        primaryDiagnosis?: string | null,
        otherTreatments?: string | null,
        otherMedications?: string | null,
        selfMedicated?: string | null,
        kidneyLiver?: string | null,
        cardiovascular?: string | null,
        substanceAbuseDisorder?: string | null,
        allergies?: string | null,
        smoke?: string | null,
        drinkAlcohol?: string | null,
        otherConditions?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: string | null,
      } | null,
      status?: TgaStatus | null,
      presentation?: ProductPresentation | null,
      presentationText?: string | null,
      createdDate?: string | null,
      decisionDate?: string | null,
      expiryDate?: string | null,
      approvalDate?: string | null,
      isSAS?: boolean | null,
      archived?: boolean | null,
      products?:  {
        __typename: "ModelProductMapConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      sorter: string,
      owner?: string | null,
      updatedAt: string,
    },
    doctorID: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      drId: string,
      drName: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      avatar?: string | null,
      qualifications?: string | null,
      prescriberNumber?: string | null,
      clinics?:  {
        __typename: "ModelClinicDoctorConnection",
        nextToken?: string | null,
      } | null,
      appointmentDefinitions?:  {
        __typename: "ModelAppointmentDefinitionConnection",
        nextToken?: string | null,
      } | null,
      status?: DoctorStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    },
    createdAt?: string | null,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePatientLinkSubscriptionVariables = {
  filter?: ModelSubscriptionPatientLinkFilterInput | null,
};

export type OnCreatePatientLinkSubscription = {
  onCreatePatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdatePatientLinkSubscriptionVariables = {
  filter?: ModelSubscriptionPatientLinkFilterInput | null,
};

export type OnUpdatePatientLinkSubscription = {
  onUpdatePatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeletePatientLinkSubscriptionVariables = {
  filter?: ModelSubscriptionPatientLinkFilterInput | null,
};

export type OnDeletePatientLinkSubscription = {
  onDeletePatientLink?:  {
    __typename: "PatientLink",
    doctorID: string,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        sub: string,
        username: string,
        email?: string | null,
        phoneNumber?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        createdAt?: string | null,
        owner?: string | null,
        updatedAt: string,
      } | null,
      givenName: string,
      surName?: string | null,
      medicareNumber?: string | null,
      addressOneLine?: string | null,
      dateOfBirth?: string | null,
      contactNumber?: string | null,
      nextOfKin?: string | null,
      nextOfKinRelation?: string | null,
      nextOfKinContact?: string | null,
      powerOfAttorney?: string | null,
      powerOfAttorneyRelation?: string | null,
      powerOfAttorneyContact?: string | null,
      carer?: string | null,
      carerRelation?: string | null,
      carerContact?: string | null,
      emergency?: string | null,
      emergencyRelation?: string | null,
      emergencyContact?: string | null,
      primaryDiagnosis?: string | null,
      otherTreatments?: string | null,
      otherMedications?: string | null,
      selfMedicated?: string | null,
      kidneyLiver?: string | null,
      cardiovascular?: string | null,
      substanceAbuseDisorder?: string | null,
      allergies?: string | null,
      smoke?: string | null,
      drinkAlcohol?: string | null,
      otherConditions?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    clinicID?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};
