import { FC, useEffect, useState } from 'react'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonSpinner, IonText, useIonToast } from '@ionic/react'
import { IonCardSubtitle, IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ConsentEvent, Patient } from '../../API'
import { CurrentConsentService } from '../../components/services/CurrentConsentService'
import { CurrentPatientService } from '../../components/services/CurrentPatientService'
import { CurrentUserService } from '../../components/services/CurrentUserService'
import { connect } from '../../data'
import { setConsent } from '../../data/user/user.actions'
import { ConsentModel } from './consent/Consent.model'
import healthQuestions from './healthQuestions.json'

interface OwnProps {
  reCheck: Function
}
interface StateProps {
  consent: ConsentModel
  patientDetail: Patient
  patientHealth: Patient
}
interface DispatchProps {
  setConsent: typeof setConsent
}
interface PatientDeclarationProps extends OwnProps, StateProps, DispatchProps {}
const PatientDeclaration: FC<PatientDeclarationProps> = ({
  consent,
  patientDetail,
  patientHealth,
  setConsent,
  reCheck,
}) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [consentLoaded, setConsentLoaded] = useState(false)
  const [consentAlreadyAcknowledged, setConsentAlreadyAcknowledged] = useState<string | null>()
  const [consenter, setConsenter] = useState<string | null>()
  const [entity, setEntity] = useState<ConsentEvent>()
  const [inprogress, setInprogress] = useState(false)
  const [present] = useIonToast()
  const [errorList, setErrorList] = useState<string[]>([])
  const questions = healthQuestions.questions

  const submit = async () => {
    if (!isReadyForSubmit()) {
      return
    }
    setInprogress(true)
    const doubleCheck = await loadConsent(false)
    if (!!doubleCheck) {
      return
    } else {
      const email = user?.attributes?.email
      const phone = user?.attributes?.phone_number
      const givenName = user?.attributes?.given_name
      const familyName = user?.attributes?.family_name
      if (!givenName || !familyName) {
        return
      }
      try {
        const user: any = await CurrentUserService.Instance?.currentUser()
        const patient: Patient = await CurrentPatientService.Instance?.currentPatient()
        const res: ConsentEvent | undefined = await CurrentConsentService.Instance.createConsent({
          userID: user.id,
          patientID: patient.id,
          email: email,
          phone: phone,
          givenName,
          familyName,
          consentContent: JSON.stringify({
            patientDetail: {
              ...patientDetail,
              givenName,
              surName: familyName,
            },
            patientHealth,
            declrations,
          }),
          consenter: `${givenName} ${familyName}`,
          researchAllowed: consent.researchAllowed,
        })
        if (!!res?.createdAt) {
          setConsentAlreadyAcknowledged(res.createdAt)
          setConsenter(res.consenter)
          setEntity(res)
          present({ message: 'Consent form acknowledged successfully.', color: 'success', duration: 3000 })
          reCheck()
        }
      } catch (err: any) {
        present({ message: `Error: ${err?.errors?.[0]?.message}`, color: 'danger', duration: 3000 })
      }
    }
  }

  const loadConsent = async (withLoader = true) => {
    if (withLoader) {
      setConsentLoaded(false)
    }
    try {
      const currentConsent: ConsentEvent = await CurrentConsentService.Instance.currentConsent()
      if (!!currentConsent) {
        setConsentAlreadyAcknowledged(currentConsent.createdAt)
        setConsenter(currentConsent.consenter)
        setEntity(currentConsent)
      }
      setConsentLoaded(true)
      return currentConsent
    } catch (ignore) {
      setConsentLoaded(true)
    }
  }

  const isReadyForSubmit = () => {
    let result = true
    const errors: string[] = []
    if (!user?.attributes?.given_name) {
      errors.push('Given name')
      result = false
    }
    if (!user?.attributes?.family_name) {
      errors.push('Surname')
      result = false
    }
    if (checkRequiredField('surName', 'Surname', errors)) {
      result = false
    }
    if (checkRequiredField('addressOneLine', 'Address', errors)) {
      result = false
    }
    if (checkRequiredField('dateOfBirth', 'Date of Birth', errors)) {
      result = false
    }
    if (checkRequiredField('contactNumber', 'Contact Number', errors)) {
      result = false
    }
    if (checkRequiredField('emergency', 'Emergency Contact', errors)) {
      result = false
    }
    if (checkRequiredField('emergencyRelation', 'Emergency Contact Relationship', errors)) {
      result = false
    }
    if (checkRequiredField('emergencyContact', 'Emergency Contact Number', errors)) {
      result = false
    }
    questions.map((q: any) => {
      if (q.required && !(patientHealth as any)?.[q.id]) {
        errors.push(q.label)
        result = false
      }
      return q
    })
    declrations.map((declar) => {
      if (declar.required && !consent?.pd?.[declar.id]) {
        result = false
      }
      return declar
    })
    if (!consent.acknowledged) {
      result = false
    }
    setErrorList(errors)
    return result
  }

  const isAcknowdged = () => {
    let result = true
    declrations.map((declar) => {
      if (declar.required && !consent?.pd?.[declar.id]) {
        result = false
      }
      return declar
    })
    if (!consent.acknowledged) {
      result = false
    }
    return result
  }

  const checkRequiredField = (field: string, message: string, errors: string[]) => {
    if (!(patientDetail as any)?.[field]) {
      errors.push(message)
      return true
    } else {
      return false
    }
  }

  const setProp = async (prop: string, value: any) => {
    setConsent({ ...consent, pd: { ...consent.pd, [prop]: value } })
  }

  useEffect(() => {
    loadConsent()
  }, [])

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle className='ion-text-wrap'>PATIENT DECLRATION</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent style={{ padding: 0 }}>
        {!consentLoaded && (
          <div className='ion-text-center'>
            <IonSpinner />
          </div>
        )}
        {consentLoaded && (
          <IonList>
            {declrations.map((d: any) => (
              <IonItem key={d.id}>
                <IonLabel className={`ion-text-wrap ${d.required ? 'required' : ''}`}>{d.text}</IonLabel>
                <IonCheckbox
                  checked={!!consentAlreadyAcknowledged || consent?.pd?.[d.id]}
                  onIonChange={(e) => setProp(d.id, e.detail.checked)}
                  disabled={!!consentAlreadyAcknowledged}
                />
              </IonItem>
            ))}
            <IonItem>
              <IonLabel>I agree to share my clinical outcomes for research purposes.</IonLabel>
              <IonCheckbox
                checked={entity?.researchAllowed || consent?.researchAllowed}
                onIonChange={(e) => setConsent({ ...consent, researchAllowed: e.detail.checked })}
                disabled={!!entity}
              />
            </IonItem>
            <IonItem lines='none'>
              <IonLabel style={{ fontWeight: 'bold' }} className='ion-text-wrap required'>
                I,{' '}
                <IonText color='primary'>
                  {!!consenter
                    ? consenter
                    : `${!!user?.attributes?.given_name ? user?.attributes?.given_name : ''} ${
                        !!user?.attributes?.family_name ? user?.attributes?.family_name : ''
                      }`}
                </IonText>
                , declare that all answers in this Consent Form are true and correct to the best of my knowledge and
                beleif.
              </IonLabel>
              <IonCheckbox
                checked={!!consentAlreadyAcknowledged || consent?.acknowledged}
                onIonChange={(e) => setConsent({ ...consent, acknowledged: e.detail.checked })}
                disabled={!!consentAlreadyAcknowledged}
              />
            </IonItem>
            {!consentAlreadyAcknowledged && (
              <>
                <ul className='ion-padding-horizontal ion-margin-horizontal'>
                  {errorList.length > 0 && <IonText color='danger'>Please fill out the required fields:</IonText>}
                  {errorList.map((err) => (
                    <li className='ion-margin-horizontal' key={err}>
                      {err}
                    </li>
                  ))}
                  {!isAcknowdged() && <IonText color='danger'>Please accept all declrations.</IonText>}
                </ul>
                <div className='ion-text-center' style={{ marginBottom: '100px' }}>
                  <IonButton onClick={() => submit()} style={{ minWidth: '130px' }} disabled={inprogress}>
                    Submit
                    {inprogress && <IonSpinner slot='end'></IonSpinner>}
                  </IonButton>
                </div>
              </>
            )}
            {!!consentAlreadyAcknowledged && (
              <IonText color='primary' className='ion-padding-horizontal ion-margin-horizontal'>
                Acknowledged at{' '}
                <b>
                  {new Date(consentAlreadyAcknowledged).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </b>
              </IonText>
            )}
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  )
}

const declrations = [
  {
    id: '1',
    text: 'I understand I am attending video/telephone consult and these have limitations compared to in person consults',
    required: true,
  },
  {
    id: '2',
    text: 'I understand medical cannabis and Telehealth consults are not currently covered by medicare. Our consults are completely private and there are no medicare rebates.',
    required: true,
  },
  { id: '3', text: 'I understand that the long-term side-effects of medicinal cannabis are unknown.', required: true },
  { id: '4', text: 'I understand that medicinal cannabis is an unregistered medicine in Australia.', required: true },
  {
    id: '5',
    text: 'I understand that the quality, safety, and efficacy of medicinal cannabis have not been assessed by the Australian government’s Therapeutic Goods Administration.',
    required: true,
  },
  {
    id: '6',
    text: 'I understand that the prescribing doctor will report my treatment outcomes to the government.',
    required: true,
  },
  { id: '7', text: 'I understand that the cost of medicinal cannabis is solely my responsibility.', required: true },
  {
    id: '8',
    text: 'I understand that I must not drive or operate heavy machinery whilst taking medicinal cannabis containing THC. If I drive under these circumstances, I am breaking the law. I understand that a legally issued prescription does not provide a defence to such an offence.',
    required: true,
  },
  {
    id: '9',
    text: 'I understand the risks and complications associated with medicinal cannabis treatment. I agree to follow my doctor’s recommendation regarding dosing. I agree to report any adverse effects I experience from taking medicinal cannabis, including but not limited changes in the levels of sedation, lethargy, fatigue, dry mouth, nausea, vomiting, diarrhoea, drowsiness, dizziness, disorientation, agitation, balance problems, changes in memory, paranoid delusions, or hallucinations.',
    required: true,
  },
  { id: '10', text: 'I understand that there is a possibility of unknown risks and side effects.', required: true },
  {
    id: '11',
    text: 'I understand that medicinal cannabis might interact with my other medications, and doses may need to be adjusted accordingly.',
    required: true,
  },
  {
    id: '12',
    text: 'I agree to keep a log of my doses and changes in symptoms due to medicinal cannabis.',
    required: true,
  },
  {
    id: '13',
    text: 'I agree to attend regular follow-up consultations in the clinic or over the phone as directed by my doctor.',
    required: true,
  },
  {
    id: '14',
    text: 'I agree that I will not use any form of cannabis other than that prescribed by my doctor, including any illicit form of cannabis (marijuana).',
    required: true,
  },
  { id: '15', text: 'I agree to notify my prescribing doctor of any changes in my other medications.', required: true },
]

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    consent: state.user.consent,
    patientDetail: state.user.patientDetail,
    patientHealth: state.user.patientHealth,
  }),
  mapDispatchToProps: {
    setConsent,
  },
  component: PatientDeclaration,
})
