import { FC, useState } from 'react'
import { IonButton, IonIcon, useIonToast } from '@ionic/react'
import { add } from 'ionicons/icons'
import { ClinicDoctor, Doctor } from '../../../API'
import DoctorSelect from '../../../components/select/DoctorSelect'
import { ClinicDoctorService } from '../../../components/services/ClinicDoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure, success } from '../../../components/util/Toast'
import { isMobile } from '../../../components/util/isMobile'

interface ClinicDoctorAddButtonProps {
  clinicID: string
  onSuccess: Function
}
const ClinicDoctorAddButton: FC<ClinicDoctorAddButtonProps> = ({ clinicID, onSuccess }) => {
  const [selected, setSelected] = useState<Doctor>()
  const [model] = useState<ClinicDoctor>({
    clinicID,
  } as ClinicDoctor)
  const [adding, setAdding] = useState(false)
  const [presentToast] = useIonToast()

  const addItem = async () => {
    setAdding(true)
    if (!!selected) {
      const res: TbnResponse = await ClinicDoctorService.Instance.store({ ...model, doctorID: selected.id })
      if (res.data) {
        success('Success adding new doctor to the clinic', presentToast)
        onSuccess()
      } else if (res.errorMessage) {
        failure(res.errorMessage, presentToast)
      }
    }
    setAdding(false)
  }

  return (
    <div className='flex-container flex-space-between align-items-center'>
      <div style={{ width: '90%' }}>
        <DoctorSelect onChange={(itm: Doctor) => setSelected(itm)} />
      </div>
      <IonButton
        onClick={addItem}
        disabled={!selected || adding}
        className='ion-float-end'
        size={isMobile() ? 'small' : 'default'}
      >
        <IonIcon icon={add} slot='icon-only' />
      </IonButton>
    </div>
  )
}

export default ClinicDoctorAddButton
