import { useState, useEffect, FC } from 'react'
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import useDebounce from '../../components/hooks/useDebounce'
import { CurrentUserService } from '../../components/services/CurrentUserService'
import capitalizeFirstName from '../../components/util/CapitalizeFirstName'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'

interface OwnProps {
  required?: boolean
  onTbnChange?: Function
}
interface StateProps {}
interface DispatchProps {
  setNet: typeof setNet
}
interface GivenNameProps extends OwnProps, StateProps, DispatchProps {}

const GivenName: FC<GivenNameProps> = ({ required = false, onTbnChange, setNet }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [givenName, setGivenName] = useState<string | undefined>(user?.attributes?.given_name)
  const debounced = useDebounce(givenName, 500)

  const updateGivenName = async (given_name?: string) => {
    setNet(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user?.attributes?.given_name !== given_name) {
        const res = await Auth.updateUserAttributes(user, {
          given_name: capitalizeFirstName(given_name?.trim()),
        })
        if (res === 'SUCCESS') {
          try {
            CurrentUserService.Instance?.updateCurrentGivenName(capitalizeFirstName(given_name?.trim()))
          } catch (ignore) {}
        }
      }
    } catch (ignore) {}
    setNet(false)
  }

  useEffect(() => {
    updateGivenName(debounced)
  }, [debounced]) // eslint-disable-line

  useEffect(() => {
    if (!!onTbnChange) {
      onTbnChange(givenName)
    }
  }, [givenName]) // eslint-disable-line

  useEffect(() => {
    try {
      CurrentUserService.Instance?.currentUser().catch(() => {})
    } catch (ignore) {}
    if (!!user?.attributes?.given_name && !givenName) {
      setGivenName(user?.attributes?.given_name)
    }
  }, []) // eslint-disable-line

  return (
    <IonItem>
      <IonLabel position='stacked' className={required ? 'required' : ''}>
        Given name
      </IonLabel>
      <IonInput
        value={givenName}
        defaultValue={user?.attributes?.given_name}
        placeholder={'Given name'}
        onIonChange={(e: any) => setGivenName(capitalizeFirstName(e.detail.value))}
      />
    </IonItem>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setNet,
  },
  component: GivenName,
})
