import { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import PageContainer from '../PageContainer'
import ChronicPainList from './chronic-pain/ChronicPainList'
import Dass21List from './dass-21/Dass21List'

interface OwnProps extends RouteComponentProps {}
interface QuizPageProps extends OwnProps {}
const QuizPage: FC<QuizPageProps> = () => {
  return (
    <PageContainer id='quiz-page' padding={true} title='Tools'>
      <ChronicPainList />
      <Dass21List />
    </PageContainer>
  )
}

export default withRouter(QuizPage)
