import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Reception, ModelReceptionFilterInput, UpdateReceptionInput } from '../../API'
import { createReception, updateReception } from '../../graphql/mutations'
import { findReceptionByUserId, listReceptions } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import hasNewChanges from '../util/hasNewChanges'
import { TbnResponse } from './TbnResponse'

export class ReceptionService {
  private static receptionService: ReceptionService | undefined

  public async list(filter?: ModelReceptionFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listReceptions,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return Promise.resolve({ data: res?.data?.listReceptions?.items, errorMessage: res?.errors?.[0]?.message })
    } catch (err) {
      warn('error fetching Receptions: ', err)
      return Promise.reject(err)
    }
  }

  public async findReception(userID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findReceptionByUserId,
        variables: { userID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForReceptionDuplication(res?.data?.findReceptionByUserId?.items)
      return { data: res?.data?.findReceptionByUserId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error fetching Reception: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async addOrEditReception(userID: string, reception: Reception): Promise<TbnResponse> {
    try {
      const res: TbnResponse = await this.findReception(userID)
      if (!!res.data) {
        return { data: this.editReception(res.data, reception) }
      } else {
        return { data: this.addReception(userID, reception) }
      }
    } catch (err) {
      log('error fetching reception: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async editReception(previous: Reception, reception: Reception) {
    const next: UpdateReceptionInput = {
      id: previous.id,
      name: reception.name?.trim(),
      avatar: reception.avatar,
      owner: previous.owner,
    }
    log('Reception update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Reception update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updateReception,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Reception updated to', res?.data?.updateReception)
      return res?.data?.updateReception
    }
  }

  public async addReception(userID: string, reception: Reception) {
    try {
      const res: any = await API.graphql({
        query: createReception,
        variables: {
          input: {
            userID,
            name: reception.name?.trim(),
            avatar: reception.avatar,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create reception res', res)
      return res.data.createReception
    } catch (err) {
      log('Error creating reception! :', err)
      return err
    }
  }

  private checkForReceptionDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      warn('FOUND DUPLICATED RECEPTION!!!', items)
    }
  }

  private includesNoNewChanges(cmd: UpdateReceptionInput, previous: Reception) {
    return hasNewChanges(cmd, previous)
  }

  private async buildReceptionService(): Promise<ReceptionService> {
    try {
      let service = new ReceptionService()
      return Promise.resolve(service)
    } catch (err) {
      warn('Init ReceptionService failure:', err)
      return this.buildReceptionService()
    }
  }

  public static get Instance(): ReceptionService {
    if (!this.receptionService) {
      this.receptionService = new ReceptionService()
      this.receptionService.buildReceptionService().then((service: ReceptionService) => {
        this.receptionService = service
      })
    }
    return this.receptionService
  }
}
