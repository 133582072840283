import { FC, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader } from '@ionic/react'
import { IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { clipboard, close } from 'ionicons/icons'
import { Quiz } from '../../../API'
import { quizName } from '../../../components/models/QuizType'
import { utcToLocaleDate } from '../../../components/util/Date'
import { QuizQuestion } from '../helpers/QuizQuestion.model'
import QuizQuestionType from '../helpers/QuizQuestionType'

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  model?: Quiz | undefined
}

interface ChronicPainAnswerModalProps extends OwnProps {}

const ChronicPainAnswerModal: FC<ChronicPainAnswerModalProps> = ({ showModal, setShowModal, model }) => {
  const [present] = useIonToast()
  const [item, setItem] = useState(model)
  const modal = useRef<HTMLIonModalElement>(null)

  const resolveAnswer = (question: any) => {
    if ('multi-check' === question?.type || 'multi-check-two-columns' === question?.type) {
      return question?.answer?.split(',').join(', ')
    } else {
      return question?.answer
    }
  }

  const resolveAnswersText = () => {
    let result = ''
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      result += `${q.label}   ${resolveAnswer(q)} \n`
      return q
    })
    return result
  }

  useEffect(() => {
    const parsed: any = { ...item, qus: JSON.parse(item?.questions || '[]') }
    setItem(parsed)
  }, []) // eslint-disable-line

  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <span title={item?.id}>
                {quizName(item?.quizType)} - {utcToLocaleDate(item?.createdAt)}
              </span>
            </CopyToClipboard>
          </IonTitle>
          <IonButtons slot='end'>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <IonButton color='primary'>
                <IonIcon icon={clipboard} slot='icon-only'></IonIcon>
              </IonButton>
            </CopyToClipboard>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          {(item as any)?.qus?.map((q: QuizQuestion, idx: number) => (
            <IonRow key={q.id}>
              <IonCol size='12'>
                <QuizQuestionType quizQuestion={q} disabled={true} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default ChronicPainAnswerModal
