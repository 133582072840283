import { FC, useEffect, useState } from 'react'
import { IonCol, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react'

interface DateOfBirthProps {
  defaultValue?: string | null // in YYYY-MM-DD format
  onValueChange: Function
}
const DateOfBirth: FC<DateOfBirthProps> = ({ defaultValue, onValueChange }) => {
  const [value, setValue] = useState(defaultValue)
  const [year, setYear] = useState(defaultValue?.split('-')?.[0])
  const [month, setMonth] = useState(defaultValue?.split('-')?.[1])
  const [day, setDay] = useState(defaultValue?.split('-')?.[2])

  useEffect(() => {
    if (!!value) {
      onValueChange(value)
    }
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (!!year && !!month && !!day) {
      setValue(`${year}-${month}-${day}`)
    }
  }, [year, month, day]) // eslint-disable-line

  return (
    <IonRow style={{ width: '100%' }} className='ion-no-padding'>
      <IonCol className='ion-no-padding'>
        <IonItem>
          <IonLabel position='floating'>Day</IonLabel>
          <IonSelect value={day} onIonChange={(e) => setDay(e.detail.value)} placeholder='Day'>
            {Array.from({ length: 31 }).map((x: any, idx: number) => (
              <IonSelectOption key={1 + idx} value={`${idx < 9 ? `0${1 + idx}` : 1 + idx}`}>
                {1 + idx}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonCol>
      <IonCol className='ion-no-padding'>
        <IonItem>
          <IonLabel position='floating'>Month</IonLabel>
          <IonSelect value={month} onIonChange={(e) => setMonth(e.detail.value)} placeholder='Month'>
            <IonSelectOption key={'01'} value={'01'}>
              January
            </IonSelectOption>
            <IonSelectOption key={'02'} value={'02'}>
              February
            </IonSelectOption>
            <IonSelectOption key={'03'} value={'03'}>
              March
            </IonSelectOption>
            <IonSelectOption key={'04'} value={'04'}>
              April
            </IonSelectOption>
            <IonSelectOption key={'05'} value={'05'}>
              May
            </IonSelectOption>
            <IonSelectOption key={'06'} value={'06'}>
              June
            </IonSelectOption>
            <IonSelectOption key={'07'} value={'07'}>
              July
            </IonSelectOption>
            <IonSelectOption key={'08'} value={'08'}>
              August
            </IonSelectOption>
            <IonSelectOption key={'09'} value={'09'}>
              September
            </IonSelectOption>
            <IonSelectOption key={'10'} value={'10'}>
              October
            </IonSelectOption>
            <IonSelectOption key={'11'} value={'11'}>
              November
            </IonSelectOption>
            <IonSelectOption key={'12'} value={'12'}>
              December
            </IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonCol>
      <IonCol className='ion-no-padding'>
        <IonItem>
          <IonLabel position='floating'>Year</IonLabel>
          <IonSelect value={year} onIonChange={(e) => setYear(e.detail.value)} placeholder='Year'>
            {Array.from({ length: 111 }).map((x: any, idx: number) => (
              <IonSelectOption key={1900 + idx} value={1900 + idx + ''}>
                {1900 + idx}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonCol>
    </IonRow>
  )
}

export default DateOfBirth
