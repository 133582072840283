import { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { TabItem, Tabs } from '@aws-amplify/ui-react'
import { isDesktop } from '../../../components/util/isMobile'
import AdminContainer from '../AdminContainer'
import AccountantList from './AccountantList'
import Accountants from './Accountants'
import AdminList from './AdminList'
import Admins from './Admins'
import ClinicAdmins from './ClinicAdmins'
import DoctorList from './DoctorList'
import Doctors from './Doctors'
import ReceptionList from './ReceptionList'
import Receptions from './Receptions'
import Users from './Users'

interface OwnProps extends RouteComponentProps {}
interface UserManagementProps extends OwnProps {}
const UserManagement: FC<UserManagementProps> = ({ history, location, match, staticContext }) => {
  return (
    <AdminContainer id='user-management'>
      <Tabs spacing='equal'>
        <TabItem title={isDesktop() ? 'Users' : 'U'}>
          <Users />
        </TabItem>
        <TabItem title={isDesktop() ? 'Receptions' : 'R'}>
          <Receptions history={history} location={location} match={match} staticContext={staticContext} />
          <ReceptionList />
        </TabItem>
        <TabItem title={isDesktop() ? 'Doctors' : 'D'}>
          <Doctors history={history} location={location} match={match} staticContext={staticContext} />
          <DoctorList />
        </TabItem>
        <TabItem title={isDesktop() ? 'Accountants' : 'A'}>
          <Accountants history={history} location={location} match={match} staticContext={staticContext} />
          <AccountantList />
        </TabItem>
        <TabItem title={isDesktop() ? 'ClinicAdmins' : 'CA'}>
          <ClinicAdmins history={history} location={location} match={match} staticContext={staticContext} />
          <AdminList />
        </TabItem>
        <TabItem title={isDesktop() ? 'Admins' : 'A'}>
          <Admins />
        </TabItem>
      </Tabs>
    </AdminContainer>
  )
}

export default UserManagement
