import { FC } from 'react'
import { Card, Flex, Heading, Image, Text, useTheme } from '@aws-amplify/ui-react'
import { isDesktop } from '../../components/util/isMobile'
import { connect } from '../../data'
import PageContainer from '../PageContainer'

interface OwnProps {}
interface StateProps {
  darkMode: boolean
}
interface DispatchProps {}
interface AboutUsProps extends OwnProps, StateProps, DispatchProps {}
const AboutUs: FC<AboutUsProps> = ({ darkMode }) => {
  const { tokens } = useTheme()
  return (
    <PageContainer id='about-us' isPrivate={false} padding={true} title='About us'>
      <div className='ion-padding-bottom flex-container flex-center'>
        <Image
          alt='Telebinoid'
          src={`../../assets/img/logo-text-colorful${darkMode ? '-dark' : ''}.svg`}
          width={isDesktop() ? '33%' : '100%'}
          borderRadius={tokens.radii.large}
        />
      </div>
      <Card backgroundColor={tokens.colors.teal[10]} borderRadius={tokens.radii.large}>
        <Flex direction={'column'}>
          <Heading level={5}>About us</Heading>
          <Text as='span'>
            We are a team consisting of solution architects, software engineers, business analysts and healthcare
            professionals.
          </Text>
        </Flex>
      </Card>
      <div className='ion-margin-bottom'></div>
      <Card backgroundColor={tokens.colors.teal[10]} borderRadius={tokens.radii.large}>
        <Flex direction={'column'}>
          <Heading level={5}>What we do</Heading>
          <Text as='span'>
            We are in the process of developing a platform that streamlines booking, practice management, Health and
            treatment outcome assessments and billing.
          </Text>
        </Flex>
      </Card>
      <div className='ion-margin-bottom'></div>
      <Card backgroundColor={tokens.colors.teal[10]} borderRadius={tokens.radii.large}>
        <Flex direction={'column'}>
          <Heading level={5}>Our work will benefit</Heading>
          <Text as='span'>
            Independent practitioners, clinics, researchers and most importantly patients. We believe if doctors and
            their support teams spend less time on administration, more time can be spent on patients and their needs.
            This will make healthcare more accessible and more affordable.
          </Text>
        </Flex>
      </Card>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: AboutUs,
})
