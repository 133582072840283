import { ProductCategory } from '../../API'

const productCategoryToNumber = (productCategory?: ProductCategory | null) => {
  switch (productCategory) {
    case ProductCategory.CATEGORY_1:
      return 1
    case ProductCategory.CATEGORY_2:
      return 2
    case ProductCategory.CATEGORY_3:
      return 3
    case ProductCategory.CATEGORY_4:
      return 4
    case ProductCategory.CATEGORY_5:
      return 5
  }
  return ''
}

export default productCategoryToNumber
