import { Measurement, ProductPresentation } from '../../API'

const dosageMeasurementLabel = (measurement?: Measurement | null, presentation?: ProductPresentation | null) => {
  if (!!measurement) {
    switch (measurement) {
      case Measurement.GRAM:
        return 'gr'
      case Measurement.MILLIGRAM:
        return 'mg'
      case Measurement.MILLILITER:
        return 'ml'
      case Measurement.NUMERAL:
        return 'no.'
    }
  } else if (!!presentation) {
    switch (presentation) {
      case ProductPresentation.CAPSULE:
        return 'mg'
      case ProductPresentation.ORAL_LIQUID:
        return 'ml'
      case ProductPresentation.DRIED_HERB:
        return 'gr'
      case ProductPresentation.SPRAY_SOLUTION:
        return 'ml'
    }
  }
  return null
}

export default dosageMeasurementLabel
