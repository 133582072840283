import { FC, useEffect, useState } from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react'
import { IonSpinner, useIonToast } from '@ionic/react'
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Patient } from '../../API'
import DateOfBirth from '../../components/form/DateOfBirth'
import useDebounce from '../../components/hooks/useDebounce'
import { CurrentPatientService } from '../../components/services/CurrentPatientService'
import { log, warn } from '../../components/util/Log'
import { connect } from '../../data'
import { AppState } from '../../data/state'
import { setNet, setPatientDetail } from '../../data/user/user.actions'
import FamilyName from '../profile/FamilyName'
import GivenName from '../profile/GivenName'
import './PatientDetails.scss'

interface OwnProps {
  hideTitle?: boolean
}
interface StateProps {
  patientDetail: Patient
}
interface DispatchProps {
  setPatientDetail: typeof setPatientDetail
  setNet: typeof setNet
}
interface PatientDetailsProps extends OwnProps, StateProps, DispatchProps {}

const PatientDetails: FC<PatientDetailsProps> = ({ patientDetail, setPatientDetail, setNet, hideTitle = false }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [border1, setBorder1] = useState(false)
  const [border2, setBorder2] = useState(false)
  const [border3, setBorder3] = useState(false)
  const [border4, setBorder4] = useState(false)
  const [patientLoaded, setPatientLoaded] = useState(false)
  const debounced = useDebounce(patientDetail, 2000)
  const [present] = useIonToast()

  const loadPatientDetail = async () => {
    if (user?.attributes?.given_name) {
      try {
        const patient: Patient = await CurrentPatientService.Instance.currentPatient()
        await setPatientDetail({
          ...patientDetail,
          id: patient.id,
          __typename: 'Patient',
          addressOneLine: patient.addressOneLine,
          medicareNumber: patient.medicareNumber,
          givenName: user?.attributes?.given_name,
          surName: user?.attributes?.family_name,
          dateOfBirth: patient.dateOfBirth,
          contactNumber:
            patient.contactNumber ||
            (!!user?.attributes?.phone_number ? `0${user?.attributes?.phone_number?.substring(3)}` : ''),
          nextOfKin: patient.nextOfKin,
          nextOfKinRelation: patient.nextOfKinRelation,
          nextOfKinContact: patient.nextOfKinContact,
          powerOfAttorney: patient.powerOfAttorney,
          powerOfAttorneyRelation: patient.powerOfAttorneyRelation,
          powerOfAttorneyContact: patient.powerOfAttorneyContact,
          carer: patient.carer,
          carerRelation: patient.carerRelation,
          carerContact: patient.carerContact,
          emergency: patient.emergency,
          emergencyRelation: patient.emergencyRelation,
          emergencyContact: patient.emergencyContact,
        })
        log('Patient detail loaded', patient)
        setTimeout(() => {
          setPatientLoaded(true)
        }, 300)
      } catch (err) {
        warn('Loading user retry...')
        if (!patientLoaded) {
          await loadPatientDetail()
        }
      }
    }
  }

  const updatePatientDetails = async () => {
    if (!patientLoaded) {
      return
    }
    try {
      setNet(true)
      await CurrentPatientService.Instance.updateCurrentPatientDetail(patientDetail)
      setNet(false)
    } catch (err: any) {
      warn('Patient detail update failure.', err)
      present({ message: `Error: ${err?.errors?.[0]?.message}`, color: 'danger', duration: 3000 })
      setNet(false)
    }
  }

  const setProp = async (prop: string, value: any) => {
    await setPatientDetail({ ...patientDetail, [prop]: value })
  }

  useEffect(() => {
    if (patientLoaded) {
      updatePatientDetails()
    }
  }, [debounced]) // eslint-disable-line

  useEffect(() => {
    loadPatientDetail()
  }, [user?.attributes?.given_name]) // eslint-disable-line

  return (
    <IonCard>
      {!hideTitle && (
        <IonCardHeader style={{ paddingBottom: 0 }}>
          <IonCardSubtitle>Paitent Details</IonCardSubtitle>
        </IonCardHeader>
      )}
      <IonCardContent style={{ padding: 0 }}>
        {!patientLoaded && (
          <div className='ion-text-center'>
            <IonSpinner />
          </div>
        )}
        {patientLoaded && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <GivenName required={true} />
              </IonCol>
              <IonCol>
                <FamilyName required={true} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12'>
                <IonItem>
                  <IonLabel position='floating'>Medicare Number/ private health number</IonLabel>
                  <IonInput
                    value={patientDetail?.medicareNumber}
                    onIonChange={(e) => setProp('medicareNumber', e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12'>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Address
                  </IonLabel>
                  <IonTextarea
                    value={patientDetail?.addressOneLine}
                    onIonChange={(e) => setProp('addressOneLine', e.detail.value)}
                  ></IonTextarea>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-no-padding'>
                <IonLabel position='floating' className='required ion-padding-start'>
                  Date of Birth
                </IonLabel>
                <IonItem className='ion-no-padding' id='date-of-birth-container'>
                  <DateOfBirth
                    defaultValue={patientDetail?.dateOfBirth}
                    onValueChange={(value: any) => setProp('dateOfBirth', value)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Contact number
                  </IonLabel>
                  <IonInput
                    value={patientDetail?.contactNumber}
                    onIonChange={(e) => setProp('contactNumber', e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ border: border1 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Next of Kin</IonLabel>
                  <IonInput
                    value={patientDetail?.nextOfKin}
                    onIonChange={(e) => setProp('nextOfKin', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput
                    value={patientDetail?.nextOfKinRelation}
                    onIonChange={(e) => setProp('nextOfKinRelation', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput
                    value={patientDetail?.nextOfKinContact}
                    onIonChange={(e) => setProp('nextOfKinContact', e.detail.value)}
                    onIonFocus={() => setBorder1(true)}
                    onIonBlur={() => setBorder1(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ border: border2 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Power of Attorney</IonLabel>
                  <IonInput
                    value={patientDetail?.powerOfAttorney}
                    onIonChange={(e) => setProp('powerOfAttorney', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput
                    value={patientDetail?.powerOfAttorneyRelation}
                    onIonChange={(e) => setProp('powerOfAttorneyRelation', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput
                    value={patientDetail?.powerOfAttorneyContact}
                    onIonChange={(e) => setProp('powerOfAttorneyContact', e.detail.value)}
                    onIonFocus={() => setBorder2(true)}
                    onIonBlur={() => setBorder2(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ border: border3 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Parent/guardian/carer</IonLabel>
                  <IonInput
                    value={patientDetail?.carer}
                    onIonChange={(e) => setProp('carer', e.detail.value)}
                    onIonFocus={() => setBorder3(true)}
                    onIonBlur={() => setBorder3(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Relationship</IonLabel>
                  <IonInput
                    value={patientDetail?.carerRelation}
                    onIonChange={(e) => setProp('carerRelation', e.detail.value)}
                    onIonFocus={() => setBorder3(true)}
                    onIonBlur={() => setBorder3(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>Contact Number</IonLabel>
                  <IonInput
                    value={patientDetail?.carerContact}
                    onIonChange={(e) => setProp('carerContact', e.detail.value)}
                    onIonFocus={() => setBorder3(true)}
                    onIonBlur={() => setBorder3(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ border: border4 ? '1px solid #5FD0E7' : '' }}>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Emergency Contact
                  </IonLabel>
                  <IonInput
                    value={patientDetail?.emergency}
                    onIonChange={(e) => setProp('emergency', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Relationship
                  </IonLabel>
                  <IonInput
                    value={patientDetail?.emergencyRelation}
                    onIonChange={(e) => setProp('emergencyRelation', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating' className='required'>
                    Contact Number
                  </IonLabel>
                  <IonInput
                    value={patientDetail?.emergencyContact}
                    onIonChange={(e) => setProp('emergencyContact', e.detail.value)}
                    onIonFocus={() => setBorder4(true)}
                    onIonBlur={() => setBorder4(false)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state: AppState) => ({
    patientDetail: state.user.patientDetail,
  }),
  mapDispatchToProps: {
    setPatientDetail,
    setNet,
  },
  component: PatientDetails,
})
