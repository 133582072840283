import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { AppointmentSubject, CalendlyAppointment } from '../../API'
import { CalendlyAppointmentService } from '../services/CalendlyAppointmentService'
import './select.scss'

interface CalendlyAppointmentSelectProps {
  defaultValue?: string | null
  onChange: Function
  clinicPublicId?: string
  doctorPublicId?: string
  appointmentSubject?: AppointmentSubject
}
const CalendlyAppointmentSelect: FC<CalendlyAppointmentSelectProps> = ({
  defaultValue,
  onChange,
  clinicPublicId,
  doctorPublicId,
  appointmentSubject,
}) => {
  const [options, setOptions] = useState<any[]>()
  const [items, setitems] = useState<CalendlyAppointment[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(itm)
  }

  const fetchOptions = async () => {
    const res: any = await CalendlyAppointmentService.Instance.listAll()
    if (res.data) {
      setitems(res.data || [])
      setOptions(
        res.data
          ?.filter((ca: CalendlyAppointment) => ca.active)
          .flatMap((ca: CalendlyAppointment) => {
            return { value: ca.id, label: `${ca.slug} - ${ca.name}` }
          }),
      )
    }
  }

  const reloadOptions = async () => {
    const itms = items?.filter((ca: CalendlyAppointment) => {
      return (
        ca.active &&
        (!clinicPublicId || ca?.slug?.includes(clinicPublicId)) &&
        (!doctorPublicId || ca?.scheduling_url?.includes(doctorPublicId)) &&
        (!appointmentSubject || ca?.slug.includes(appointmentSubject?.toString()?.toLowerCase()?.split('_').join('-')))
      )
    })
    setOptions(
      itms?.flatMap((ca: CalendlyAppointment) => {
        return {
          value: ca.id,
          label: `${ca.name} / ${!!clinicPublicId ? `${clinicPublicId} /` : ''} ${ca?.scheduling_url?.substring(
            21,
            21 + ca?.scheduling_url?.substring(21)?.indexOf('/'),
          )}`,
        }
      }),
    )
  }

  useEffect(() => {
    reloadOptions()
  }, [clinicPublicId, doctorPublicId, appointmentSubject]) // eslint-disable-line

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  return <Select value={selectedOption} options={options} onChange={handleChange} />
}

export default CalendlyAppointmentSelect
