import { FC } from 'react'
import { IonButton } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'

interface BookingSlideZeroProps {
  swiper?: SwiperCore
}
const BookingSlideZero: FC<BookingSlideZeroProps> = ({ swiper }) => {
  return (
    <>
      <span className='first-top-element'>
        You are about to book for a consultation with Dr Nastaran Rafieian, You might benefit from what Dr Nas can offer
        in terms of mental and physical health management if:
      </span>
      <p className='ion-no-margin'>
        You have a medical condition diagnosed by a medical practitioner, affecting your physical or mental health and
        you have ongoing symptoms that do not respond to available treatments, or suggested treatments are not suitable
        for you or they cause undesired side effects.
      </p>
      <p className='ion-no-margin'>
        Suitability of treatments are assessed by the practitioner on a patient by patient basis.
      </p>
      <div className='ion-text-center'>
        <IonButton expand='block' color='primary' onClick={() => swiper?.slideNext()}>
          START
        </IonButton>
      </div>
    </>
  )
}

export default BookingSlideZero
