import { FC, useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react'
import { Patient } from '../../API'
import DateOfBirth from '../../components/form/DateOfBirth'
import { PatientService } from '../../components/services/PatientService'
import { TbnResponse } from '../../components/services/TbnResponse'
import TbnSpinner from '../../components/util/TbnSpinner'
import { failure, success } from '../../components/util/Toast'
import { connect } from '../../data'
import { AppState } from '../../data/state'
import { setNet } from '../../data/user/user.actions'
import '../forms/PatientDetails.scss'

interface OwnProps {
  onLoad: Function
  givenName: string
  phone: string
  email?: string | null
  patientID?: string | null
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface DoctorPatientDetailsProps extends OwnProps, StateProps, DispatchProps {}

const DoctorPatientDetails: FC<DoctorPatientDetailsProps> = ({
  givenName,
  patientID,
  net,
  setNet,
  onLoad,
  phone,
  email,
}) => {
  const [border1, setBorder1] = useState(false)
  const [border2, setBorder2] = useState(false)
  const [border3, setBorder3] = useState(false)
  const [border4, setBorder4] = useState(false)
  const [patientLoaded, setPatientLoaded] = useState(false)
  const [patient, setPatient] = useState<Patient>({
    givenName,
    phone,
    email,
    contactNumber: `0${phone?.substring(3)}`,
  } as Patient)
  const [present] = useIonToast()

  const loadPatientDetail = async () => {
    setNet(true)
    setPatientLoaded(false)
    if (!!patientID) {
      const res: TbnResponse = await PatientService.Instance.get(patientID)
      if (!!res.data) {
        setPatient(res.data)
        onLoad(res.data)
      } else if (!!res?.errorMessage) failure(res.errorMessage, present)
    } else {
      const res: TbnResponse = await PatientService.Instance.findByPhoneAndGivenName(phone, givenName)
      if (!!res.data) {
        setPatient(res.data)
        onLoad(res.data)
      } else if (!!res.errorMessage) {
        failure(res.errorMessage, present)
      }
    }
    setPatientLoaded(true)
    setNet(false)
  }

  const storePatient = async () => {
    setNet(true)
    const res: TbnResponse = await PatientService.Instance.store(patient)
    if (!!res?.data) {
      setPatient(res?.data)
      success('Patient details updated successfully', present)
      onLoad(res.data)
    } else if (!!res?.errorMessage) failure(res.errorMessage, present)
    setNet(false)
  }

  const setProp = async (prop: string, value: any) => {
    await setPatient({ ...patient, [prop]: value } as Patient)
  }

  useEffect(() => {
    loadPatientDetail()
  }, [patientID]) // eslint-disable-line

  return (
    <>
      {!patientLoaded && <TbnSpinner />}
      {patientLoaded && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Given name</IonLabel>
                <IonInput value={patient?.givenName} disabled={true}></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Surname</IonLabel>
                <IonInput
                  value={patient?.surName}
                  onIonChange={(e) => setProp('surName', e.detail.value)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonItem>
                <IonLabel position='floating'>Medicare Number/ private health number</IonLabel>
                <IonInput
                  value={patient?.medicareNumber}
                  onIonChange={(e) => setProp('medicareNumber', e.detail.value)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonItem>
                <IonLabel position='floating'>Address</IonLabel>
                <IonTextarea
                  value={patient?.addressOneLine}
                  onIonChange={(e) => setProp('addressOneLine', e.detail.value)}
                  disabled={!patientLoaded}
                ></IonTextarea>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonLabel position='floating' className='ion-padding-start'>
                Date of Birth
              </IonLabel>
              <IonItem className='ion-no-padding' id='date-of-birth-container'>
                <DateOfBirth
                  defaultValue={patient?.dateOfBirth}
                  onValueChange={(value: any) => setProp('dateOfBirth', value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact number</IonLabel>
                <IonInput
                  value={patient?.contactNumber}
                  onIonChange={(e) => setProp('contactNumber', e.detail.value)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ border: border1 ? '1px solid #5FD0E7' : '' }}>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Next of Kin</IonLabel>
                <IonInput
                  value={patient?.nextOfKin}
                  onIonChange={(e) => setProp('nextOfKin', e.detail.value)}
                  onIonFocus={() => setBorder1(true)}
                  onIonBlur={() => setBorder1(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput
                  value={patient?.nextOfKinRelation}
                  onIonChange={(e) => setProp('nextOfKinRelation', e.detail.value)}
                  onIonFocus={() => setBorder1(true)}
                  onIonBlur={() => setBorder1(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput
                  value={patient?.nextOfKinContact}
                  onIonChange={(e) => setProp('nextOfKinContact', e.detail.value)}
                  onIonFocus={() => setBorder1(true)}
                  onIonBlur={() => setBorder1(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ border: border2 ? '1px solid #5FD0E7' : '' }}>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Power of Attorney</IonLabel>
                <IonInput
                  value={patient?.powerOfAttorney}
                  onIonChange={(e) => setProp('powerOfAttorney', e.detail.value)}
                  onIonFocus={() => setBorder2(true)}
                  onIonBlur={() => setBorder2(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput
                  value={patient?.powerOfAttorneyRelation}
                  onIonChange={(e) => setProp('powerOfAttorneyRelation', e.detail.value)}
                  onIonFocus={() => setBorder2(true)}
                  onIonBlur={() => setBorder2(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput
                  value={patient?.powerOfAttorneyContact}
                  onIonChange={(e) => setProp('powerOfAttorneyContact', e.detail.value)}
                  onIonFocus={() => setBorder2(true)}
                  onIonBlur={() => setBorder2(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ border: border3 ? '1px solid #5FD0E7' : '' }}>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Parent/guardian/carer</IonLabel>
                <IonInput
                  value={patient?.carer}
                  onIonChange={(e) => setProp('carer', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput
                  value={patient?.carerRelation}
                  onIonChange={(e) => setProp('carerRelation', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput
                  value={patient?.carerContact}
                  onIonChange={(e) => setProp('carerContact', e.detail.value)}
                  onIonFocus={() => setBorder3(true)}
                  onIonBlur={() => setBorder3(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow style={{ border: border4 ? '1px solid #5FD0E7' : '' }}>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Emergency Contact</IonLabel>
                <IonInput
                  value={patient?.emergency}
                  onIonChange={(e) => setProp('emergency', e.detail.value)}
                  onIonFocus={() => setBorder4(true)}
                  onIonBlur={() => setBorder4(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Relationship</IonLabel>
                <IonInput
                  value={patient?.emergencyRelation}
                  onIonChange={(e) => setProp('emergencyRelation', e.detail.value)}
                  onIonFocus={() => setBorder4(true)}
                  onIonBlur={() => setBorder4(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position='floating'>Contact Number</IonLabel>
                <IonInput
                  value={patient?.emergencyContact}
                  onIonChange={(e) => setProp('emergencyContact', e.detail.value)}
                  onIonFocus={() => setBorder4(true)}
                  onIonBlur={() => setBorder4(false)}
                  disabled={!patientLoaded}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonButton expand='block' onClick={storePatient} disabled={!patientLoaded || net}>
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state: AppState) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: DoctorPatientDetails,
})
