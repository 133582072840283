import { parse } from 'papaparse'
import { FC, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IonButton, IonChip, IonIcon, IonSpinner, IonText, useIonToast } from '@ionic/react'
import { cloudUpload } from 'ionicons/icons'
import { CreateCannabisProductInput, CreateMedicalAuthorisedPrescriberInput, Doctor } from '../../../API'
import { DoctorMapCsvModel } from '../../../components/models/DoctorMapCsvModel'
import productCategoryExtractor from '../../../components/models/ProductCategoryExtractor'
import productPresentationLookup from '../../../components/models/ProductPresentationLookup'
import tgaStatusLookup from '../../../components/models/TgaStatusLookup'
import { MapImportService } from '../../../components/services/MapImportService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { convertDateFormat1ToISO } from '../../../components/util/Date'
import SORT_CHAR from '../../../components/util/Sorter'
import { failure } from '../../../components/util/Toast'

interface DoctorMapNumberImportButtonProps {
  doctor: Doctor
  onUploaded?: Function
}
const DoctorMapNumberImportButton: FC<DoctorMapNumberImportButtonProps> = ({ doctor, onUploaded }) => {
  const [uploading, setUploading] = useState(false)
  const fileInput = useRef<any>()
  const [present] = useIonToast()
  const [csvContent, setCsvContent] = useState<any[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [successfulImport, setSuccessfulImport] = useState(0)

  const importCsvContent = async () => {
    setUploading(true)
    let idxOrder = 0
    //const persistOperations = []
    const localErrors = []
    let successes = 0
    if (!!doctor?.id) {
      for (let rawRow of csvContent.slice(1)) {
        try {
          const row: DoctorMapCsvModel = {
            Product: rawRow[0],
            ProductPresentation: rawRow[1],
            CreatedDate: rawRow[2],
            ApplicationNumber: rawRow[3],
            Status: rawRow[4],
            DecisionDate: rawRow[5],
            ExpiryDate: rawRow[6],
          }
          const ingredStartIndex = row?.Product?.includes('(') ? row?.Product?.indexOf('(') : row?.Product.length
          const ingredEndIndex = row?.Product?.includes(')') ? row?.Product?.indexOf(')') : row?.Product.length
          const productName =
            row.Product?.startsWith('Category') && row.Product?.includes('-')
              ? row.Product?.substring(row.Product?.indexOf('-') + 1, ingredStartIndex)?.trim()
              : row.Product?.substring(0, ingredStartIndex).trim()
          const ingredients =
            !!ingredStartIndex && !!ingredEndIndex && ingredStartIndex !== ingredEndIndex
              ? row?.Product?.substring(ingredStartIndex + 1, ingredEndIndex)
              : null
          const productPresentation = productPresentationLookup(row.ProductPresentation?.trim())
          const productCmd: CreateCannabisProductInput = {
            name: productName,
            ingredients,
            presentation: productPresentation,
            presentationText: row.ProductPresentation?.trim(),
            category: productCategoryExtractor(row.Product?.trim()),
            idxOrder: JSON.parse(JSON.stringify(idxOrder)),
            sorter: SORT_CHAR,
            archived: false,
          }
          const mapCmd: CreateMedicalAuthorisedPrescriberInput = {
            doctorID: doctor?.id,
            idxOrder: JSON.parse(JSON.stringify(idxOrder)),
            mapNumber: row.ApplicationNumber?.trim(),
            presentation: productPresentation,
            presentationText: row.ProductPresentation?.trim(),
            category: productCategoryExtractor(row.Product?.trim()),
            status: tgaStatusLookup(row.Status),
            createdDate: !!row.CreatedDate ? convertDateFormat1ToISO(row.CreatedDate) : null,
            decisionDate: !!row.DecisionDate ? convertDateFormat1ToISO(row.DecisionDate) : null,
            expiryDate: !!row.ExpiryDate ? convertDateFormat1ToISO(row.ExpiryDate) : null,
            sorter: SORT_CHAR,
            archived: false,
          }
          if (!!row.Status && !!row.ApplicationNumber) {
            // persistOperations.push(MapImportService.Instance.import(doctor?.id, mapCmd, productCmd))
            const res: TbnResponse = await MapImportService.Instance.import(doctor?.id, mapCmd, productCmd)
            if (!!res?.errorMessage) {
              localErrors.push(res?.errorMessage)
            } else {
              successes++
            }
            idxOrder++
          } else {
            localErrors.push(`No match found for ${row.ApplicationNumber} with Status[${row.Status}].`)
          }
          setSuccessfulImport(idxOrder)
        } catch (err) {
          localErrors.push(JSON.stringify(err))
        }
      }
      //const resArray: any[] = await Promise.allSettled(persistOperations)
      // for (let res of resArray) {
      //   if (!!res?.value?.errorMessage) {
      //     localErrors.push(res?.value?.errorMessage)
      //   } else {
      //     successes++
      //   }
      // }
      setSuccessfulImport(successes)
    } else {
      localErrors.push('Doctor id is null')
    }
    setCsvContent([])
    setErrors(localErrors)
    setUploading(false)
    if (!!onUploaded) {
      onUploaded()
    }
  }

  const onProcessFile = async (e: any) => {
    setUploading(true)
    e.preventDefault()
    try {
      const file = e.target.files[0]
      parse(file, {
        delimiter: ',',
        header: false,
        encoding: 'UTF-8',
        complete: (result) => setCsvContent(result?.data as DoctorMapCsvModel[]),
      })
    } catch (err) {
      failure(JSON.stringify(err), present)
    }
    setUploading(false)
  }

  const onOpenFileDialog = () => {
    fileInput.current.click()
  }

  useEffect(() => {
    if (csvContent.length > 0) {
      importCsvContent()
    }
  }, [csvContent]) // eslint-disable-line

  return (
    <>
      <input type='file' accept='.csv' onChange={onProcessFile} ref={fileInput} hidden={true}></input>
      <IonButton onClick={onOpenFileDialog} slot='end' disabled={uploading}>
        {!!successfulImport && <IonText className='ion-padding-horizontal'>{successfulImport}</IonText>}
        {uploading && <IonSpinner slot='start' />}
        {!uploading && <IonIcon icon={cloudUpload} slot='start' />}
        CSV Import
      </IonButton>
      {!uploading &&
        errors?.map((err: string) => (
          <CopyToClipboard key={err} text={err}>
            <IonChip color='danger'>{err}</IonChip>
          </CopyToClipboard>
        ))}
      {!uploading && !!successfulImport && (
        <IonChip color='success'>{`${successfulImport} records imported or updated successfully.`}</IonChip>
      )}
    </>
  )
}

export default DoctorMapNumberImportButton
