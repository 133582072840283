import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonButton, IonChip, IonCol, IonGrid, IonIcon, IonRow, IonText, useIonToast } from '@ionic/react'
import { location } from 'ionicons/icons'
import { Clinic } from '../../API'
import statusColor from '../../components/models/ClinicStatusColor'
import TbnImage from '../../components/models/TbnImage'
import { CurrentAdminService } from '../../components/services/CurrentAdminService'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import ClinicAccountantList from '../admin/clinics/ClinicAccountantList'
import ClinicDoctorList from '../admin/clinics/ClinicDoctorList'
import ClinicReceptionList from '../admin/clinics/ClinicReceptionList'
import ClinicAdminContainer from './ClinicAdminContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AdminDeskProps extends OwnProps, StateProps, DispatchProps {}
const AdminDesk: FC<AdminDeskProps> = ({ setNet, net, history }) => {
  const [clinic, setClinic] = useState<Clinic>()
  const [presentToast] = useIonToast()

  const resolveAdminClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentAdminService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveAdminClinic()
    }
  }

  const resolveClinic = async () => {
    setNet(true)
    const clinic: Clinic = await resolveAdminClinic()
    if (!!clinic?.id) setClinic(clinic)
    else failure('Failed to load clinic', presentToast)
    setNet(false)
  }

  useEffect(() => {
    resolveClinic()
  }, []) // eslint-disable-line

  return (
    <ClinicAdminContainer
      id='clinic-admin-desk'
      title={'Admin Desk'}
      actionButtonsEnd={
        <>
          {!net && (
            <IonChip color={statusColor(clinic?.status)} outline={true} onClick={resolveClinic}>
              {clinic?.status}
            </IonChip>
          )}
        </>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <TbnImage path={clinic?.logoImage} alt={clinic?.publicId} />
          </IonCol>
          <IonCol>
            <IonRow>
              <IonCol title='Name'>{clinic?.name}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol title='Formerly known as'>
                {!!clinic?.formerlyKnownAs ? `(${clinic?.formerlyKnownAs})` : ''}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{clinic?.phone}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div style={{ whiteSpace: 'pre' }}>
                  {clinic?.address}
                  {!!clinic?.location?.lat && !!clinic?.location?.lon && (
                    <a
                      href={`https://www.google.com/maps/@${clinic?.location?.lat},${clinic?.location?.lon},18z`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IonButton fill='clear'>
                        <IonIcon icon={location} />
                      </IonButton>
                    </a>
                  )}
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        {!!clinic?.bankName && false && (
          <IonRow>
            <IonCol>
              <>
                <h6 style={{ marginTop: '0' }}>Bank details</h6>
                <IonRow>
                  <IonCol>
                    <IonText>{clinic?.bankName}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText>{clinic?.bankAccountName}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText>{clinic?.bankStateBranch}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText>{clinic?.bankAccountNumber}</IonText>
                  </IonCol>
                </IonRow>
              </>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            {!!clinic?.id && <ClinicAccountantList clinicID={clinic?.id} />}
            {!!clinic?.id && <ClinicDoctorList clinicID={clinic?.id} history={history} />}
            {!!clinic?.id && <ClinicReceptionList clinicID={clinic?.id} />}
          </IonCol>
        </IonRow>
      </IonGrid>
    </ClinicAdminContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: AdminDesk,
})
