import { Patient } from '../../API'
import Booking from '../../pages/booking/Booking.model'

function isBooking(model: Booking | Patient): model is Booking {
  return (model as Booking).__typename === 'Booking'
}

function isPatient(model: Booking | Patient): model is Patient {
  return (model as Patient).__typename === 'Patient'
}

export { isBooking, isPatient }
