import { FC, useEffect, useState } from 'react'
import { IonButton, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { isDesktop } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import { Quiz } from '../helpers/Quiz.model'
import { QuizQuestion } from '../helpers/QuizQuestion.model'

interface OwnProps {
  quizQuestion: QuizQuestion
  swiper?: SwiperCore
  disabled: boolean
}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface Dass21QuestionPrps extends OwnProps, StateProps, DispatchProps {}
const Dass21Question: FC<Dass21QuestionPrps> = ({ quizQuestion, swiper, quiz, setQuiz, disabled }) => {
  const [value, setValue] = useState(quizQuestion.answer)

  const setModel = (answer: any) => {
    const q = quiz?.questions?.find((q: QuizQuestion) => q.id === quizQuestion.id)
    if (!!q) {
      q.answer = answer
      const idx = quiz?.questions?.findIndex((x) => x.id === quizQuestion.id)
      quiz.questions[idx] = q
      setQuiz(quiz)
    }
  }

  const isValidValue = (valueParam: string) => {
    return options?.find((opt) => opt.id === valueParam)
  }

  useEffect(() => {
    setModel(value)
  }, [value]) // eslint-disable-line

  return (
    <>
      <h1 style={{ display: 'inline-block' }} className='ion-padding-end'>
        {quizQuestion.label}
      </h1>
      {!!quizQuestion.hint && (
        <p style={{ margin: 0, fontSize: 'initial', display: 'inline-block' }}>{quizQuestion.hint}</p>
      )}
      <IonList>
        <IonRadioGroup
          value={value}
          onIonChange={(e) => {
            if (isValidValue(e.detail.value)) {
              setValue(e.detail.value!)
            }
          }}
        >
          <IonListHeader>
            <IonLabel>During the last week</IonLabel>
          </IonListHeader>
          {options.map((option) => (
            <IonItem key={option.id}>
              <IonLabel className='ion-text-wrap' dangerouslySetInnerHTML={{ __html: option.label }}></IonLabel>
              <IonRadio value={option.id} disabled={disabled} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonList>
      {!!swiper && (
        <div className={`ion-text-center ${isDesktop() ? 'ion-margin-vertical' : ''}`}>
          <IonButton
            fill='outline'
            size={isDesktop() ? 'large' : 'default'}
            style={{ minWidth: '100px' }}
            className='ion-padding-end'
            onClick={() => swiper?.slidePrev()}
          >
            Previous
          </IonButton>
          {!swiper?.isEnd && (
            <IonButton
              disabled={!value}
              size={isDesktop() ? 'large' : 'default'}
              style={{ minWidth: '100px' }}
              onClick={() => swiper?.slideNext()}
            >
              Next
            </IonButton>
          )}
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: Dass21Question,
})

const options = [
  { id: 'N', label: 'Did not apply to me at all - <b>NEVER</b>' },
  { id: 'S', label: 'Applied to me to some degree, or some of the time - <b>SOMETIMES</b>' },
  { id: 'O', label: 'Applied to me to a considerable degree, or a good part of time - <b>OFTEN</b>' },
  { id: 'AA', label: 'Applied to me very much, or most of the time - <b>ALMOST ALWAYS</b>' },
]
