import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Accountant, ModelAccountantFilterInput, UpdateAccountantInput } from '../../API'
import { createAccountant, updateAccountant } from '../../graphql/mutations'
import { findAccountantByUserId, listAccountants } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import hasNewChanges from '../util/hasNewChanges'
import { TbnResponse } from './TbnResponse'

export class AccountantService {
  private static accountantService: AccountantService | undefined

  public async list(filter?: ModelAccountantFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listAccountants,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return Promise.resolve({ data: res?.data?.listAccountants?.items, errorMessage: res?.errors?.[0]?.message })
    } catch (err) {
      warn('error fetching accountant: ', err)
      return Promise.reject(err)
    }
  }

  public async findAccountant(userID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findAccountantByUserId,
        variables: { userID },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForAccountantDuplication(res?.data?.findAccountantByUserId?.items)
      return { data: res?.data?.findAccountantByUserId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error fetching accountant: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async addOrEditAccountant(userID: string, accountant: Accountant): Promise<TbnResponse> {
    try {
      const res: TbnResponse = await this.findAccountant(userID)
      if (!!res.data) {
        return { data: this.editAccountant(res.data, accountant) }
      } else {
        return { data: this.addAccountant(userID, accountant) }
      }
    } catch (err) {
      log('error fetching accountant: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async editAccountant(previous: Accountant, accountant: Accountant) {
    const next: UpdateAccountantInput = {
      id: previous.id,
      name: accountant.name?.trim(),
      avatar: accountant.avatar,
      owner: previous.owner,
    }
    log('Accountant update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Accountant update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updateAccountant,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Accountant updated to', res?.data?.updateAccountant)
      return res?.data?.updateAccountant
    }
  }

  public async addAccountant(userID: string, accountant: Accountant) {
    try {
      const res: any = await API.graphql({
        query: createAccountant,
        variables: {
          input: {
            userID,
            name: accountant.name?.trim(),
            avatar: accountant.avatar,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create accountant res', res)
      return res.data.createAccountant
    } catch (err) {
      log('Error creating accountant! :', err)
      return err
    }
  }

  private checkForAccountantDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      warn('FOUND DUPLICATED ENTITY!!!', items)
    }
  }

  private includesNoNewChanges(cmd: UpdateAccountantInput, previous: Accountant) {
    return hasNewChanges(cmd, previous)
  }

  private async buildAccountantService(): Promise<AccountantService> {
    try {
      let service = new AccountantService()
      return Promise.resolve(service)
    } catch (err) {
      warn('Init AccountantService failure:', err)
      return this.buildAccountantService()
    }
  }

  public static get Instance(): AccountantService {
    if (!this.accountantService) {
      this.accountantService = new AccountantService()
      this.accountantService.buildAccountantService().then((service: AccountantService) => {
        this.accountantService = service
      })
    }
    return this.accountantService
  }
}
