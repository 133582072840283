import { ProductPresentation } from '../../API'
import { warn } from '../util/Log'

const productPresentationLookup = (text: string) => {
  if (!!text) {
    switch (text) {
      case 'Capsule':
      case 'Oral Capsule':
      case 'Inhalation':
      case 'Softgel Capsule':
      case 'Soft Gelatin Capsule':
      case 'Inhalation, pressurised':
      case 'Pastille':
      case 'Wafer':
        return ProductPresentation.CAPSULE
      case 'Oil':
      case 'Oral Liquid':
      case 'Oil Solution':
      case 'Oral Solution':
      case 'Oral solution':
      case 'Liquid':
      case 'Solution':
      case 'Vaginal Cream':
      case 'Cannabis Oil':
      case 'Topical':
      case 'Oral Tincture':
        return ProductPresentation.ORAL_LIQUID
      case 'Dried Herb':
      case 'Herb, dried (for vaporisation)':
      case 'Vaporisation':
      case 'Dried Flower':
      case 'Dried flower':
        return ProductPresentation.DRIED_HERB
      case 'Spray':
      case 'Spray, solution':
        return ProductPresentation.SPRAY_SOLUTION
    }
  }
  warn('NO PRESENTATION FOUND for', text)
  return null
}

export default productPresentationLookup
