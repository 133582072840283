import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ScriptReceiver } from '../../API'
import { CreateScriptReceiverInput } from '../../API'
import { UpdateScriptReceiverInput } from '../../API'
import { ModelSortDirection } from '../../API'
import { createScriptReceiver, deleteScriptReceiver, updateScriptReceiver } from '../../graphql/mutations'
import { findScriptReceiverByDoctorId, getScriptReceiver } from '../../graphql/queries'
import { error, log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ScriptReceiverService {
  private static service: ScriptReceiverService | undefined

  public async load(id: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getScriptReceiver,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getScriptReceiver, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByDoctorId(doctorID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findScriptReceiverByDoctorId,
        variables: {
          doctorID,
          sortDirection: ModelSortDirection.ASC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return {
        data: res?.data?.findScriptReceiverByDoctorId?.items,
        errorMessage: res?.errors?.[0]?.message,
      }
    } catch (err) {
      warn(err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(scriptReceiver: ScriptReceiver | CreateScriptReceiverInput): Promise<TbnResponse> {
    try {
      if (!!scriptReceiver.id) {
        const next: UpdateScriptReceiverInput = {
          id: scriptReceiver.id,
          name: scriptReceiver.name,
          email: scriptReceiver.email,
        }
        const res: any = await API.graphql({
          query: updateScriptReceiver,
          variables: { input: next },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity updated', res?.data?.updateScriptReceiver?.id)
        return { data: res?.data?.updateScriptReceiver }
      } else {
        const res: any = await API.graphql({
          query: createScriptReceiver,
          variables: { input: scriptReceiver },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity created', res?.data?.createScriptReceiver?.id)
        return { data: res?.data, errorMessage: res?.errors?.[0].message }
      }
    } catch (err) {
      error('error on persist item: ', err, scriptReceiver)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async delete(scriptReceiver: ScriptReceiver): Promise<TbnResponse> {
    try {
      const input: UpdateScriptReceiverInput = {
        id: scriptReceiver.id,
      }
      const res: any = await API.graphql({
        query: deleteScriptReceiver,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteScriptReceiver, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on delete item: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ScriptReceiverService {
    if (!this.service) {
      this.service = new ScriptReceiverService()
    }
    return this.service
  }
}
