import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { findUserByEmail, findUserByPhoneNumber, getUser } from '../../graphql/queries'
import { log } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class UserService {
  private static userService: UserService | undefined

  private async buildUserService() {
    const userService: UserService = new UserService()
    return userService
  }

  public async findByPhoneOrEmail(phone?: string | null, email?: string | null): Promise<TbnResponse> {
    if (!!phone) {
      return await this.findByPhone(phone)
    } else if (!!email) {
      return await this.findByEmail(email)
    } else {
      return { errorMessage: 'Phone or Email is required.' }
    }
  }

  private async findByEmail(email: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findUserByEmail,
        variables: {
          email,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findUserByEmail?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error finding user by email: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  private async findByPhone(phoneNumber: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findUserByPhoneNumber,
        variables: {
          phoneNumber,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      console.log('findByPhone', res?.data?.findUserByPhoneNumber?.items?.[0])
      return { data: res?.data?.findUserByPhoneNumber?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error finding user by phone: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findBySub(sub: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: getUser,
        variables: { id: sub },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.getUser, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error getting user by sub: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): UserService | undefined {
    if (!this.userService) {
      this.userService = new UserService()
      this.userService.buildUserService().then((service: UserService | undefined) => {
        this.userService = service
      })
    }
    return this.userService
  }
}
