import { FC, useEffect, useState } from 'react'
import { IonIcon, IonThumbnail } from '@ionic/react'
import { Storage } from 'aws-amplify'
import { business } from 'ionicons/icons'
import { Clinic, ClinicStatus } from '../../API'

interface ClinicStatusCompProps {
  clinic?: Clinic | null
  slot?: string
  activeOverride?: boolean | null
}
const ClinicStatusComp: FC<ClinicStatusCompProps> = ({ slot = 'start', clinic, activeOverride = false }) => {
  const [logo, setLogo] = useState<any>()
  const resolveColor = () => {
    if (activeOverride) {
      return 'medium'
    }
    switch (clinic?.status) {
      case ClinicStatus.ACTIVE:
        return 'success'
      case ClinicStatus.INACTIVE:
        return 'warning'
      case ClinicStatus.ARCHIVED:
        return 'medium'
      case ClinicStatus.DELETED:
        return 'danger'
    }
    return 'dark'
  }

  const fetchLogo = async () => {
    if (!!clinic?.logoImage) {
      const logoUrl = await Storage.get(clinic?.logoImage, { level: 'public', expires: 84600 })
      setLogo(logoUrl)
    }
  }

  useEffect(() => {
    fetchLogo()
  }, [clinic?.logoImage]) // eslint-disable-line

  return (
    <>
      {!!logo && (
        <IonThumbnail title={clinic?.name} slot={slot}>
          <img src={logo} alt='' />
        </IonThumbnail>
      )}
      {!logo && (
        <span className='ion-padding-end' title={clinic?.name}>
          <IonIcon size='large' icon={business} slot={slot} color={resolveColor()} />
        </span>
      )}
    </>
  )
}

export default ClinicStatusComp
