import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonLabel, isPlatform } from '@ionic/react'
import { PhoneNumberField, useAuthenticator } from '@aws-amplify/ui-react'
import { Swiper as SwiperCore } from 'swiper'
import { checkmark } from 'ionicons/icons'
import { connect } from '../../data'
import { setBooking } from '../../data/user/user.actions'
import Booking from './Booking.model'

interface OwnProps {
  model: Booking
  swiper?: SwiperCore
  paramElse: string | null | undefined
}
interface DispatchProps {
  setBooking: typeof setBooking
}
interface BookingSlideTwoProps extends OwnProps, DispatchProps {}
const BookingSlideTwo: FC<BookingSlideTwoProps> = ({ model, paramElse, setBooking, swiper }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const isKeyboardOpen = useDetectKeyboardOpen()
  const [hideLabel, setHideLabel] = useState(false)

  useEffect(() => {
    if (isKeyboardOpen && isPlatform('android')) {
      setHideLabel(true)
    } else {
      setHideLabel(false)
    }
  }, [isKeyboardOpen])

  useEffect(() => {
    if (!paramElse && !model?.phone && !!user?.attributes?.phone_number) {
      setTimeout(() => {
        setBooking({
          ...model,
          phone: user?.attributes?.phone_number,
          firstName: !paramElse ? user?.attributes?.given_name : model.firstName,
        })
      }, 300)
    }
  }, [user?.attributes?.phone_number]) // eslint-disable-line

  return (
    <>
      {!hideLabel && (
        <IonLabel className={`ion-text-wrap required`}>
          {!paramElse && (
            <span>
              Thank you {model.firstName}, please share your phone number so we could connect this booking to your
              account?
            </span>
          )}
          {!!paramElse && (
            <span>
              Thank you, please share {model.firstName}'s phone number so we could connect this booking to{' '}
              {model.firstName}'s account?
            </span>
          )}
        </IonLabel>
      )}
      <PhoneNumberField
        defaultCountryCode='+61'
        dialCodeList={['+61']}
        label='Phone number'
        placeholder='4xxxxxxxx'
        hasError={model?.phone?.length !== 12}
        errorMessage='Not a valid phone number!'
        value={!!model?.phone ? model?.phone?.substring(3) : ''}
        onChange={(e) => {
          setBooking({ ...model, phone: `+61${e.target?.value}` })
        }}
      />
      {!hideLabel && !!swiper && (
        <>
          <IonButton color='primary' disabled={model?.phone?.length !== 12} onClick={() => swiper?.slideNext()}>
            OK
            {model?.phone?.length === 12 && <IonIcon icon={checkmark} slot='end' />}
          </IonButton>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
  },
  component: BookingSlideTwo,
})
