import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { IonButton, IonChip, IonItem, IonLabel } from '@ionic/react'
import { IonList, IonListHeader, IonRadio, IonRadioGroup, IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { AppointmentDefinition, Clinic, ClinicStatus, Doctor, TypeFormEvent } from '../../API'
import statusColor from '../../components/models/ClinicStatusColor'
import TbnAvatar from '../../components/models/TbnAvatar'
import TbnThumbnail from '../../components/models/TbnThumbnail'
import { AppointmentDefinitionService } from '../../components/services/AppointmentDefinitionService'
import { BookingService } from '../../components/services/BookingService'
import { CurrentReceptionService } from '../../components/services/CurrentReceptionService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { AuthStatus } from '../../components/util/AuthStatus'
import { log } from '../../components/util/Log'
import TbnSpinner from '../../components/util/TbnSpinner'
import { isMobile } from '../../components/util/isMobile'
import { connect } from '../../data'
import { setBooking, setNet } from '../../data/user/user.actions'
import PageContainer from '../PageContainer'
import Booking from '../booking/Booking.model'
import { Question } from '../forms/helpers/Question.model'
import AvailableBookingsComponent from '../landing/AvailableBookingsComponent'
import OnePageBooking from './OnePageBooking'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
  booking: Booking
}
interface DispatchProps {
  setNet: typeof setNet
  setBooking: typeof setBooking
}
interface BookFastProps extends OwnProps, StateProps, DispatchProps {}
const BookFast: FC<BookFastProps> = ({ net, setNet, history, setBooking, booking }) => {
  const { appointmentId } = useParams<any>()
  const [clinic, setClinic] = useState<Clinic | null>()
  const [apptDefs, setApptDefs] = useState<AppointmentDefinition[]>([])
  const [error, setError] = useState<string>() // eslint-disable-line
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [doctorPublicId, setDoctorPublicId] = useState<string | null>()
  const [doctors, setDoctors] = useState<Doctor[]>([])
  const [appointmentDef, setAppointmentDef] = useState<AppointmentDefinition | null>()
  const [previousAppointment, setPreviousAppointment] = useState<TypeFormEvent>()

  const resolveReceptionClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentReceptionService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveReceptionClinic()
    }
  }

  const loadClinic = async () => {
    if (!!clinic) {
      return
    }
    setNet(true)
    const clinicR: Clinic = await resolveReceptionClinic()
    if (!!clinicR) {
      setClinic(clinicR)
      await loadApptDefs(clinicR.publicId)
    }
    setNet(false)
  }

  const loadApptDefs = async (clinicPublicId: string) => {
    if (apptDefs.length > 0) {
      return
    }
    setNet(true)
    const res: TbnResponse = await AppointmentDefinitionService.Instance.publicFindByClinic(
      clinicPublicId,
      authStatus as AuthStatus,
    )
    if (res.data) {
      setApptDefs(res.data?.filter((itm: AppointmentDefinition) => itm.active))
    }
    setNet(false)
  }

  const loadPreviousAppointment = async () => {
    if (appointmentId !== '0' && authStatus === AuthStatus.AUTHENTICATED) {
      const res: TbnResponse = await BookingService.Instance.findForReception(appointmentId)
      const previous: TypeFormEvent = res.data
      log('Previous appointment', res)
      setPreviousAppointment(previous)
      setDoctorPublicId(previous?.doctorPublicId || doctorPublicId)
      const questions = JSON.parse(previous?.questions || '[]')
      await setBooking({
        __typename: 'Booking',
        appointmentDefinitionID: appointmentDef?.id || 'not-set-yet',
        bookingUrl: undefined,
        bookerID: 'not-set-yet',
        firstName: previous?.firstName,
        phone: previous?.phone || undefined,
        psychosisHistory: questions.find((q: Question) => q.id === 'psychosisHistory')?.answer,
        bipolarDisorder: questions.find((q: Question) => q.id === 'bipolarDisorder')?.answer,
        anxietyDisorder: questions.find((q: Question) => q.id === 'anxietyDisorder')?.answer,
        severeCardio: questions.find((q: Question) => q.id === 'severeCardio')?.answer,
        pregnant: questions.find((q: Question) => q.id === 'pregnant')?.answer,
        pregnantPlanning: questions.find((q: Question) => q.id === 'pregnantPlanning')?.answer,
        chestPain: questions.find((q: Question) => q.id === 'chestPain')?.answer,
        bloodThinning: questions.find((q: Question) => q.id === 'bloodThinning')?.answer,
        bloodThinningMedication: questions.find((q: Question) => q.id === 'bloodThinningMedication')?.answer,
        angina: questions.find((q: Question) => q.id === 'angina')?.answer,
        suicidalThoughts: questions.find((q: Question) => q.id === 'suicidalThoughts')?.answer,
      })
    } else {
      setPreviousAppointment(undefined)
      setAppointmentDef(undefined)
      await setBooking({
        __typename: 'Booking',
        appointmentDefinitionID: appointmentDef?.id || 'not-set-yet',
        bookingUrl: undefined,
        bookerID: 'not-set-yet',
      })
    }
  }

  useEffect(() => {
    const res: any[] = []
    apptDefs.flatMap((itm: AppointmentDefinition) => {
      if (!res.find((d: Doctor) => d.drId === itm.drPublicId)) {
        res.push({
          drId: itm.drPublicId,
          drName: itm.drName,
          avatar: itm.drAvatar,
        })
      }
      return itm
    })
    if (!doctorPublicId) {
      setDoctorPublicId(res?.[0]?.drId)
    }
    setDoctors(res)
  }, [apptDefs]) // eslint-disable-line

  useEffect(() => {
    loadClinic()
  }, [authStatus]) // eslint-disable-line

  useEffect(() => {
    if (appointmentDef?.id)
      setBooking({
        ...booking,
        appointmentDefinitionID: appointmentDef.id,
      })
  }, [appointmentDef?.id]) // eslint-disable-line

  useEffect(() => {
    loadPreviousAppointment()
  }, [appointmentId, authStatus]) // eslint-disable-line

  return (
    <PageContainer
      id='book-fast'
      isPrivate={false}
      padding={true}
      title={
        <>
          {isMobile() ? 'Appt' : 'Appointment'} at <IonText color='tertiary'>{clinic?.name}</IonText> clinic
        </>
      }
      actionButtonsEnd={<>{!!clinic?.logoImage && <TbnThumbnail path={clinic?.logoImage} />}</>}
    >
      {net && <TbnSpinner />}
      {!!error && (
        <>
          <h6>Something went wrong, We apologize for any inconvenience.</h6>
          <IonButton className='ion-padding' onClick={() => window.location.reload()}>
            {'Refresh page'}
          </IonButton>
          <br />
          <br />
          <details style={{ whiteSpace: 'pre-wrap' }}>{error}</details>
        </>
      )}
      {!net && !error && clinic?.status !== ClinicStatus.ACTIVE && (
        <div className='ion-text-center'>
          <IonText color='tertiary'>{clinic?.name}</IonText> clinic
          <IonChip color={statusColor(clinic?.status)}>{clinic?.status}</IonChip>
        </div>
      )}
      {!net && !error && clinic?.status === ClinicStatus.ACTIVE && (
        <>
          {doctors?.length > 1 && (
            <IonList>
              <IonListHeader>Doctors:</IonListHeader>
              <IonRadioGroup value={doctorPublicId} onIonChange={(e) => setDoctorPublicId(e.detail.value)}>
                {doctors.map((d: Doctor) => (
                  <IonItem key={d.drId} mode='ios'>
                    <TbnAvatar avatar={d.avatar} />
                    <IonLabel>Dr {d.drName}</IonLabel>
                    <IonRadio slot='end' value={d.drId}></IonRadio>
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          )}
          <AvailableBookingsComponent
            previousAppointment={previousAppointment}
            apptDefs={apptDefs}
            clinicPublicId={clinic?.publicId}
            drPublicId={doctorPublicId}
            history={history}
            fastMode={true}
            onSelect={setAppointmentDef}
            onChange={setAppointmentDef}
          />
          {!!appointmentDef && (
            <OnePageBooking booking={booking} appointmentDefinition={appointmentDef} paramElse={'y'} bookFast={true} />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
    booking: state.user.booking,
  }),
  mapDispatchToProps: {
    setNet,
    setBooking,
  },
  component: BookFast,
})
