import { ProductCategory } from '../../API'

const productCategoryLabel = (productCategory?: ProductCategory | null) => {
  switch (productCategory) {
    case ProductCategory.CATEGORY_1:
      return 'Category 1'
    case ProductCategory.CATEGORY_2:
      return 'Category 2'
    case ProductCategory.CATEGORY_3:
      return 'Category 3'
    case ProductCategory.CATEGORY_4:
      return 'Category 4'
    case ProductCategory.CATEGORY_5:
      return 'Category 5'
  }
  return ''
}

export default productCategoryLabel
