import { parse } from 'papaparse'
import { FC, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaLeaf, FaCapsules, FaFlask, FaSprayCan } from 'react-icons/fa'
import { IonButton, IonButtons, IonChip, IonIcon, IonInput, IonSegment, IonSegmentButton } from '@ionic/react'
import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react'
import { IonSpinner, IonText, ItemReorderEventDetail, useIonAlert, useIonToast } from '@ionic/react'
import { archive, cloudUpload } from 'ionicons/icons'
import { CannabisProduct, CreateCannabisProductInput } from '../../../API'
import { ModelCannabisProductFilterInput, ProductCategory, ProductPresentation } from '../../../API'
import { DoctorMapCsvModel } from '../../../components/models/DoctorMapCsvModel'
import ProductCategoryBadge from '../../../components/models/ProductCategoryBadge'
import productCategoryExtractor from '../../../components/models/ProductCategoryExtractor'
import ProductPresentationIcon from '../../../components/models/ProductPresentationIcon'
import productPresentationLookup from '../../../components/models/ProductPresentationLookup'
import { CannabisProductService } from '../../../components/services/CannabisProductService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { log } from '../../../components/util/Log'
import SORT_CHAR from '../../../components/util/Sorter'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import CannabisProductAddButton from './CannabisProductAddButton'
import CannabisProductModalForm from './CannabisProductModalForm'

interface CannabisProductListProps {}
const CannabisProductList: FC<CannabisProductListProps> = () => {
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const fileInput = useRef<any>()
  const [csvContent, setCsvContent] = useState<any[]>([])
  const [items, setItems] = useState<CannabisProduct[]>([])
  const [present] = useIonToast()
  const [errors, setErrors] = useState<string[]>([])
  const [successfulImport, setSuccessfulImport] = useState(0)
  const [presentAlert] = useIonAlert()
  const [name, setName] = useState()
  const [category, setCategory] = useState<ProductCategory>()
  const [presentation, setPresentation] = useState<ProductPresentation>()
  const [showEditModal, setShowEditModal] = useState(false)
  const [activeProduct, setActiveProduct] = useState<CannabisProduct>()

  const fetchItems = async () => {
    setLoading(true)
    const filter: ModelCannabisProductFilterInput = {}
    if (!!name) filter.name = { contains: name }
    if (!!category) filter.category = { eq: category }
    if (!!presentation) filter.presentation = { eq: presentation }
    const res: TbnResponse = await CannabisProductService.Instance.listAll(filter)
    if (!!res?.data) {
      setItems(res.data)
    } else if (!!res?.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  const importCsvContent = async () => {
    setUploading(true)
    let idxOrder = 0
    const persistOperations = []
    const localErrors = []
    for (let rawRow of csvContent.slice(1)) {
      try {
        const row: DoctorMapCsvModel = {
          Product: rawRow[0],
          ProductPresentation: rawRow[1],
          CreatedDate: rawRow[2],
          ApplicationNumber: rawRow[3],
          Status: rawRow[4],
          DecisionDate: rawRow[5],
          ExpiryDate: rawRow[6],
        }
        const name =
          row.Product?.startsWith('Category') && row.Product?.includes('-')
            ? row.Product?.substring(row.Product?.indexOf('-') + 1)
            : row.Product
        const cmd: CreateCannabisProductInput = {
          name: name?.trim(),
          idxOrder: JSON.parse(JSON.stringify(idxOrder)),
          category: productCategoryExtractor(row.Product?.trim()),
          presentation: productPresentationLookup(row.ProductPresentation?.trim()),
          presentationText: row.ProductPresentation?.trim(),
          sorter: SORT_CHAR,
          archived: false,
        }
        if (!!name && !!cmd.presentation) {
          persistOperations.push(CannabisProductService.Instance.store(cmd))
          idxOrder++
        } else {
          localErrors.push(`Presentaion not found for ${cmd.presentationText}`)
        }
      } catch (err) {
        localErrors.push(JSON.stringify(err))
      }
    }
    const resArray: any[] = await Promise.allSettled(persistOperations)
    let successes = 0
    for (let res of resArray) {
      if (!!res?.value?.errorMessage) {
        localErrors.push(res?.value?.errorMessage)
      } else {
        successes++
      }
    }
    setCsvContent([])
    setErrors(localErrors)
    setSuccessfulImport(successes)
    setUploading(false)
  }

  const onProcessFile = async (e: any) => {
    setUploading(true)
    e.preventDefault()
    try {
      const file = e.target.files[0]
      parse(file, {
        delimiter: ',',
        header: false,
        encoding: 'UTF-8',
        complete: (result) => setCsvContent(result?.data as DoctorMapCsvModel[]),
      })
    } catch (err) {
      failure(JSON.stringify(err), present)
    }
    setUploading(false)
  }

  const onOpenFileDialog = () => {
    fileInput.current.click()
  }

  const deleteItem = async (itm: CannabisProduct) => {
    setDeleting(true)
    const res: TbnResponse = await CannabisProductService.Instance.archive(itm)
    if (!!res?.data) {
      await fetchItems()
    } else if (!!res?.errorMessage) {
      failure(res.errorMessage, present)
    }
    setDeleting(false)
  }

  const updateListOrder = async () => {
    const persistOperations = []
    let idxOrder = 1
    for (let itm of items) {
      persistOperations.push(
        CannabisProductService.Instance.store({ ...itm, idxOrder: JSON.parse(JSON.stringify(idxOrder)) }),
      )
      idxOrder++
    }
    await Promise.allSettled(persistOperations)
  }

  const handleReorder = async (evt: CustomEvent<ItemReorderEventDetail>) => {
    let movedItem = items.splice(evt.detail.from, 1)[0]
    log('Dragged from index', evt.detail.from, 'to', evt.detail.to, movedItem)
    items.splice(evt.detail.to, 0, movedItem)
    setItems(items)
    evt.detail.complete()
    await updateListOrder()
  }

  useEffect(() => {
    if (csvContent.length > 0) {
      importCsvContent()
    } else {
      fetchItems()
    }
  }, [csvContent]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
  }, [name, category, presentation]) // eslint-disable-line

  return (
    <IonList>
      <IonItem>
        <IonInput
          value={name}
          onIonChange={(e: any) => setName(e.detail.value)}
          placeholder={'Product name'}
          debounce={1000}
        />
        <IonButtons slot='end'>
          <input type='file' accept='.csv' onChange={onProcessFile} ref={fileInput} hidden={true}></input>
          {false && (
            <IonButton fill='clear' onClick={onOpenFileDialog} slot='end' disabled={uploading}>
              {uploading && <IonSpinner slot='icon-only' />}
              {!uploading && <IonIcon icon={cloudUpload} slot='icon-only' />}
            </IonButton>
          )}
          <CannabisProductAddButton
            onAddSuccess={(data: any) => {
              if (!!data) fetchItems()
            }}
          />
        </IonButtons>
      </IonItem>
      <IonItem>
        <IonSegment value={category} onIonChange={(e: any) => setCategory(e.detail.value)}>
          <IonSegmentButton value={ProductCategory.CATEGORY_1}>Cat 1</IonSegmentButton>
          <IonSegmentButton value={ProductCategory.CATEGORY_2}>Cat 2</IonSegmentButton>
          <IonSegmentButton value={ProductCategory.CATEGORY_3}>Cat 3</IonSegmentButton>
          <IonSegmentButton value={ProductCategory.CATEGORY_4}>Cat 4</IonSegmentButton>
          <IonSegmentButton value={ProductCategory.CATEGORY_5}>Cat 5</IonSegmentButton>
          <IonSegmentButton value={undefined}>All</IonSegmentButton>
        </IonSegment>
      </IonItem>
      <IonItem>
        <IonSegment
          value={presentation}
          onIonChange={(e: any) => {
            setPresentation(e.detail.value)
          }}
        >
          <IonSegmentButton value={ProductPresentation.CAPSULE} title='Capsule'>
            <FaCapsules />
          </IonSegmentButton>
          <IonSegmentButton value={ProductPresentation.DRIED_HERB} title='Dried Herb'>
            <FaLeaf />
          </IonSegmentButton>
          <IonSegmentButton value={ProductPresentation.ORAL_LIQUID} title='Oral Liquid'>
            <FaFlask />
          </IonSegmentButton>
          <IonSegmentButton value={ProductPresentation.SPRAY_SOLUTION} title='Spray Solution'>
            <FaSprayCan />
          </IonSegmentButton>
          <IonSegmentButton value={undefined} title='No form'>
            All
          </IonSegmentButton>
        </IonSegment>
      </IonItem>
      {loading && <TbnSpinner />}
      {!loading &&
        errors?.map((err: string) => (
          <CopyToClipboard key={err} text={err}>
            <IonChip color='danger'>{err}</IonChip>
          </CopyToClipboard>
        ))}
      {!loading && !!successfulImport && (
        <IonChip color='success'>{`${successfulImport} records imported or updated successfully.`}</IonChip>
      )}
      <IonList>
        <IonReorderGroup onIonItemReorder={handleReorder} disabled={false}>
          {!loading &&
            items?.map((itm: CannabisProduct) => (
              <IonItem key={itm.id} button={true}>
                <IonText slot='start'>
                  <ProductPresentationIcon pp={itm.presentation} />
                </IonText>
                <IonLabel
                  onClick={() => {
                    setActiveProduct(itm)
                    setTimeout(() => {
                      setShowEditModal(true)
                    }, 300)
                  }}
                >
                  <span className='pointer'>
                    {itm.name} ({itm.ingredients})
                  </span>
                  <p>
                    {itm.strength} | {itm.volume} | {itm.qtyPerDosageUnit}
                  </p>
                </IonLabel>
                <IonButtons slot='end'>
                  <ProductCategoryBadge item={itm} onUpdate={fetchItems} />
                  <IonButton
                    fill='clear'
                    color='danger'
                    disabled={deleting}
                    title='Archive'
                    onClick={() => {
                      presentAlert({
                        header: `Are you sure?`,
                        subHeader: 'After archive product will not be visiable to anyone.',
                        message: itm.name,
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {},
                          },
                          {
                            text: 'Archive',
                            role: 'destructive',
                            cssClass: 'alert-button-delete',
                            handler: () => {
                              deleteItem(itm)
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => {},
                      })
                    }}
                  >
                    <IonIcon icon={archive} slot='icon-only' />
                  </IonButton>
                  <IonReorder></IonReorder>
                </IonButtons>
              </IonItem>
            ))}
        </IonReorderGroup>
      </IonList>
      {!!activeProduct && (
        <div>
          <CannabisProductModalForm
            isOpen={showEditModal}
            onDidDismiss={(res) => {
              setShowEditModal(false)
              if (!!res?.detail?.data) fetchItems()
              setTimeout(() => {
                setActiveProduct(undefined)
              }, 0)
            }}
            product={activeProduct}
          />
        </div>
      )}
    </IonList>
  )
}

export default CannabisProductList
