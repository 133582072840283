import { API, Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { ModelPatientFilterInput, ModelSortDirection, Patient, UpdatePatientInput, User } from '../../API'
import { createPatient, updatePatient } from '../../graphql/mutations'
import { findPatientByUserId } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentPatientService {
  private static patientService: CurrentPatientService | undefined
  private patient?: Patient
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentPatient(): Promise<Patient> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentPatientService.Instance.patient) {
      return Promise.resolve(CurrentPatientService.Instance.patient)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentPatientService.Instance.patient) {
      return Promise.resolve(CurrentPatientService.Instance.patient)
    } else {
      return Promise.reject('Current patient resolve timeout!')
    }
  }

  public async updateCurrentPatientDetail(patient: Patient): Promise<TbnResponse> {
    const previous: Patient = await this.currentPatient()
    const next: UpdatePatientInput = {
      id: previous.id,
      givenName: patient.givenName,
      surName: patient.surName,
      medicareNumber: patient.medicareNumber,
      addressOneLine: patient.addressOneLine,
      dateOfBirth: !!patient.dateOfBirth ? patient.dateOfBirth : null,
      contactNumber: patient.contactNumber,
      nextOfKin: patient.nextOfKin,
      nextOfKinRelation: patient.nextOfKinRelation,
      nextOfKinContact: patient.nextOfKinContact,
      powerOfAttorney: patient.powerOfAttorney,
      powerOfAttorneyRelation: patient.powerOfAttorneyRelation,
      powerOfAttorneyContact: patient.powerOfAttorneyContact,
      carer: patient.carer,
      carerRelation: patient.carerRelation,
      carerContact: patient.carerContact,
      emergency: patient.emergency,
      emergencyRelation: patient.emergencyRelation,
      emergencyContact: patient.emergencyContact,
    }
    log('Patient detail update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Patient detail update skipped, no new changes')
      return { data: previous }
    } else {
      const res: any = await API.graphql({
        query: updatePatient,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Patient detail updated to', res?.data?.updatePatient)
      CurrentPatientService.Instance.patient = res?.data?.updatePatient
      return { data: res?.data?.updatePatient, errorMessage: res?.errors?.[0]?.message }
    }
  }

  public async updateCurrentPatientHealth(patient: Patient) {
    const previous: Patient = await this.currentPatient()
    const next: UpdatePatientInput = {
      id: previous.id,
      primaryDiagnosis: patient.primaryDiagnosis,
      otherTreatments: patient.otherTreatments,
      otherMedications: patient.otherMedications,
      selfMedicated: patient.selfMedicated,
      kidneyLiver: patient.kidneyLiver,
      cardiovascular: patient.cardiovascular,
      substanceAbuseDisorder: patient.substanceAbuseDisorder,
      allergies: patient.allergies,
      smoke: patient.smoke,
      drinkAlcohol: patient.drinkAlcohol,
      otherConditions: patient.otherConditions,
      owner: previous.owner,
    }
    log('Patient health update command', next)
    if (this.includesNoNewChanges(next, previous)) {
      log('Patient health update skipped, no new changes')
      return previous
    } else {
      const res: any = await API.graphql({
        query: updatePatient,
        variables: { input: next },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('Patient health updated to', res?.data?.updatePatient)
      CurrentPatientService.Instance.patient = res?.data?.updatePatient
      return res?.data?.updatePatient
    }
  }

  private async buildCurrentPatientService(givenName?: string): Promise<CurrentPatientService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let patientService = new CurrentPatientService(user)
      patientService = await patientService.checkIfPatientExists(patientService)
      return Promise.resolve(patientService)
    } catch (err) {
      warn('Init PaitentService failure:', err)
      return this.buildCurrentPatientService(givenName)
    }
  }

  private async checkIfPatientExists(patientService: CurrentPatientService) {
    try {
      const user = await Auth.currentUserInfo()
      const filter: ModelPatientFilterInput = {
        givenName: { eq: user?.attributes?.given_name },
        surName: { eq: user?.attributes?.family_name },
      }
      const res: any = await API.graphql({
        query: findPatientByUserId,
        variables: { userID: patientService.user?.id, filter, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      this.checkForPatientDuplication(res?.data?.findPatientByUserId?.items)
      const patient = res?.data?.findPatientByUserId?.items?.[0]
      if (!!patient) {
        patientService.patient = patient
        log('foundByUserId', patient)
        return patientService
      } else {
        const res: any = await this.createPatient(patientService)
        return res
      }
    } catch (err) {
      log('error fetching patient: ', err)
    }
    return patientService
  }

  private async createPatient(patientService: CurrentPatientService) {
    try {
      const user = await Auth.currentUserInfo()
      const res: any = await API.graphql({
        query: createPatient,
        variables: {
          input: {
            userID: user?.attributes?.sub,
            givenName: user?.attributes?.given_name,
            surName: user?.attributes?.family_name,
            email: user?.attributes?.email,
            phone: user?.attributes.phone_number,
          },
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      log('create patient res', res)
      patientService.patient = res.data.createPatient
    } catch (err) {
      log('Error creating patient! :', err)
    }
    return patientService
  }

  private checkForPatientDuplication(items?: any[]) {
    if (!!items && items.length > 1) {
      log('FOUND DUPLICATED PATIENT!!!', items?.length)
    }
  }

  private includesNoNewChanges(cmd: UpdatePatientInput, previous: Patient) {
    return Object.keys(cmd).every((k) => {
      //log((cmd as any)?.[k], '===' , (previous as any)?.[k], (cmd as any)?.[k] === (previous as any)?.[k])
      return (cmd as any)?.[k] === (previous as any)?.[k]
    })
  }

  public static get Instance(): CurrentPatientService {
    if (!this.patientService) {
      this.patientService = new CurrentPatientService()
      this.patientService.buildCurrentPatientService().then((service: CurrentPatientService) => {
        this.patientService = service
      })
    }
    return this.patientService
  }
}
