import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, useIonToast } from '@ionic/react'
import { add, trashBin } from 'ionicons/icons'
import { ModelProductMapFilterInput, ProductMap, CannabisProduct } from '../../../API'
import { MedicalAuthorisedPrescriber, CreateProductMapInput } from '../../../API'
import CannabisProductSelect from '../../../components/select/CannabisProductSelect'
import { MedicalAuthorisedPrescriberService } from '../../../components/services/MedicalAuthorisedPrescriberService'
import { ProductMapService } from '../../../components/services/ProductMapService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'

interface OwnProps {
  mapID: string
}
interface ProductMapListProps extends OwnProps {}
const ProductMapList: FC<ProductMapListProps> = ({ mapID }) => {
  const [items, setItems] = useState<ProductMap[]>([])
  const [loading, setLoading] = useState(false)
  const [presentToast] = useIonToast()
  const [product, setProduct] = useState<CannabisProduct>()
  const [adding, setAdding] = useState(false)
  const [map, setMap] = useState<MedicalAuthorisedPrescriber>()
  const [removing, setRemoving] = useState(false)

  const loadMap = async (mapID?: string) => {
    if (!!mapID && !map) {
      const res: TbnResponse = await MedicalAuthorisedPrescriberService.Instance.load(mapID)
      if (res.data) setMap(res.data)
    }
  }

  const fetchItems = async () => {
    setLoading(true)
    const filter: ModelProductMapFilterInput = {
      mapID: { eq: mapID },
    }
    const res: TbnResponse = await ProductMapService.Instance.list(filter)
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setLoading(false)
  }

  const addItem = async () => {
    setAdding(true)
    if (!!product && !!map) {
      const productMap: CreateProductMapInput = { mapID, productID: product.id, doctorID: map?.doctorID }
      const res: TbnResponse = await ProductMapService.Instance.store(productMap)
      if (res.data) fetchItems()
      else if (res.errorMessage) failure(res.errorMessage, presentToast)
    }
    setAdding(false)
  }

  const removeItem = async (itm: ProductMap) => {
    setRemoving(true)
    const res: TbnResponse = await ProductMapService.Instance.remove(itm)
    if (res.data) fetchItems()
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setRemoving(false)
  }

  useEffect(() => {
    fetchItems()
    loadMap(mapID)
  }, [mapID]) // eslint-disable-line

  return (
    <IonList>
      {loading && <TbnSpinner />}
      {!loading &&
        items.map((itm: ProductMap) => (
          <IonItem key={itm.id}>
            <IonLabel>
              {itm?.product?.name}
              <p>{itm?.product.ingredients}</p>
            </IonLabel>
            <IonButtons slot='end'>
              <IonButton color='danger' onClick={() => removeItem(itm)} fill='clear' disabled={removing}>
                <IonIcon icon={trashBin} />
              </IonButton>
            </IonButtons>
          </IonItem>
        ))}
      <div style={{ minHeight: '250px' }}>
        <CannabisProductSelect onChange={(itm: CannabisProduct) => setProduct(itm)} />
        <IonButton disabled={!product || adding} onClick={addItem}>
          <IonIcon icon={add} slot='icon-only' />
        </IonButton>
      </div>
    </IonList>
  )
}

export default ProductMapList
