import { FC, useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'

interface TbnImageProps {
  path?: string | null
  width?: string
  alt?: string
}
const TbnImage: FC<TbnImageProps> = ({ path, width = '150px', alt = '' }) => {
  const [url, setUrl] = useState<string>()

  const fetchUrl = async () => {
    if (!!path) {
      const url = await Storage.get(path, { level: 'public' })
      setUrl(url)
    }
  }

  useEffect(() => {
    fetchUrl()
  }, [path]) // eslint-disable-line

  return <img src={url} alt={alt} width={width}></img>
}

export default TbnImage
