import { FC } from 'react'
import { AlertInput, IonBadge, useIonAlert, useIonToast } from '@ionic/react'
import { CannabisProduct, ProductCategory } from '../../API'
import { CannabisProductService } from '../services/CannabisProductService'
import { failure } from '../util/Toast'
import productCategoryLookup from './ProductCategoryLookup'
import productCategoryToNumber from './ProductCategoryToNumber'

interface ProductCategoryBadgeProps {
  item?: CannabisProduct | null
  onUpdate?: Function
  editable?: boolean
}
const ProductCategoryBadge: FC<ProductCategoryBadgeProps> = ({ item, onUpdate, editable = false }) => {
  const [presentAlert] = useIonAlert()
  const [presentToast] = useIonToast()

  const editCategory = () => {
    if (!editable) return
    presentAlert({
      header: 'Edit Category',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: async () => {},
        },
        {
          text: 'Save',
          role: 'save',
          handler: () => {},
        },
      ],
      inputs: [
        {
          type: 'number',
          value: productCategoryToNumber(item?.category),
          placeholder: 'New category number',
          handler: (input: AlertInput) => {},
        },
      ],
      onDidDismiss: async (e: CustomEvent) => {
        if (e.detail?.role === 'save' && !!item) {
          try {
            const catNum = e.detail?.data?.values?.[0]
            const cat: ProductCategory | null = productCategoryLookup(catNum)
            await CannabisProductService.Instance.updateCategory(item, cat)
            if (!!onUpdate) onUpdate()
          } catch (err) {
            failure(JSON.stringify(err), presentToast)
          }
        }
      },
    })
  }

  return (
    <>
      {!item?.category && (
        <IonBadge onClick={editCategory} color='light' className='pointer' title='Edit'>
          cat ?
        </IonBadge>
      )}
      {!!item?.category && (
        <IonBadge onClick={editCategory} color='light' className='pointer' title='Edit'>
          cat {productCategoryToNumber(item?.category)}
        </IonBadge>
      )}
    </>
  )
}

export default ProductCategoryBadge
