import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonText } from '@ionic/react'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { calendar, refresh } from 'ionicons/icons'
import { ModelSortDirection, ModelTypeFormEventFilterInput, TypeFormEvent } from '../../API'
import AppointmentTypeIcon from '../../components/models/AppointmentTypeIcon'
import { defaultTimezone, utcToLocale } from '../../components/util/Date'
import SORT_CHAR from '../../components/util/Sorter'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'
import { findTypeFormEventsBySorter } from '../../graphql-custom/patient/queries'
import PageContainer from '../PageContainer'
import PageNavigate from '../doctor/PageNavigate'

interface OwnProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface MyAppointmentsProps extends OwnProps, StateProps, DispatchProps {}
const MyAppointments: FC<MyAppointmentsProps> = ({ setNet, net }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [items, setItems] = useState([])
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length

  const fetchItems = async () => {
    setNet(true)
    if (!!user?.attributes?.given_name && !!user?.attributes?.phone_number) {
      const filter: ModelTypeFormEventFilterInput = {}
      filter.firstName = { eq: user?.attributes?.given_name }
      filter.phone = { eq: user?.attributes?.phone_number }
      const variables = {
        nextToken,
        filter,
        sorter: SORT_CHAR,
        sortDirection: ModelSortDirection.DESC,
      }
      try {
        const res: any = await API.graphql({
          query: findTypeFormEventsBySorter,
          variables,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        setItems(res?.data?.findTypeFormEventsBySorter?.items || [])
        setNextNextToken(res?.data?.findTypeFormEventsBySorter?.nextToken)
      } catch (err: any) {
        console.log('err', err)
        setItems(err?.data?.findTypeFormEventsBySorter?.items || [])
        setNextNextToken(err?.data?.findTypeFormEventsBySorter?.nextToken)
      }
    }
    setNet(false)
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  // eslint-disable-next-line
  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  const resolveDate = (itm: any) => {
    return !!itm.startTime ? `${utcToLocale(itm.startTime)} (${defaultTimezone()})` : 'null'
  }

  useEffect(() => {
    fetchItems()
  }, [nextToken, user?.attributes?.given_name, user?.attributes?.phone_number]) // eslint-disable-line

  return (
    <PageContainer
      id='appointments'
      hideFooter={true}
      isPrivate={true}
      title={'My Appointments'}
      actionButtonsEnd={
        <>
          <IonButton onClick={fetchItems} disabled={net}>
            <IonIcon icon={refresh} slot='icon-only' />
          </IonButton>
        </>
      }
    >
      <IonGrid>
        {!user?.attributes?.given_name && (
          <IonText color='danger'>Please enter your given name at your profile.</IonText>
        )}
        {!user?.attributes?.phone_number && <IonText color='danger'>Phone number is required.</IonText>}
        {items.map((itm: TypeFormEvent) => (
          <IonItem key={itm.id}>
            <AppointmentTypeIcon item={itm} />
            <IonLabel>
              <span>
                {itm?.definitionDoctorName} - {itm?.definitionClinicName}
              </span>
              <p>
                <IonText color={itm.canceled ? 'danger' : ''}>{resolveDate(itm)}</IonText>
                {itm.rescheduled && (
                  <IonText color='warning' className='ion-margin-horizontal'>
                    Rescheduled
                  </IonText>
                )}
                {itm.canceled && (
                  <IonText color='danger' className='ion-margin-horizontal'>
                    Canceled: {itm.cancelReason}
                  </IonText>
                )}
              </p>
            </IonLabel>
            <IonButtons slot='end'>
              <IonButton
                fill='clear'
                onClick={() =>
                  window.open(itm?.bookingUrl?.replace('https://api.calendly.com', 'https://calendly.com'), '_blank')
                }
              >
                <IonIcon icon={calendar} slot='icon-only'></IonIcon>
              </IonButton>
            </IonButtons>
          </IonItem>
        ))}
        {false && (
          <IonRow>
            <IonCol>
              <PageNavigate {...{ hasNext, hasPrev, prev, next, loading: net }} />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: MyAppointments,
})
