const isTechAdmin = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('TechAdmins')
}

const isAdmin = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('Admins')
}

const isClinicAdmin = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('ClinicAdmins')
}

const isClinicAdminOrAdmin = (user: any) => {
  return isClinicAdmin(user) || isAdmin(user)
}

const isDoctor = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('Doctors')
}

const isReception = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('Receptionists')
}

const isAccountant = (user: any) => {
  const groups: string[] = user?.signInUserSession?.accessToken?.payload['cognito:groups']
  return groups?.includes('Accountants')
}

export { isTechAdmin, isAdmin, isClinicAdmin, isClinicAdminOrAdmin, isDoctor, isReception, isAccountant }
