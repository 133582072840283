import { FC, useState } from 'react'
import { IonButton, IonIcon, useIonToast } from '@ionic/react'
import { add } from 'ionicons/icons'
import { ClinicAccountant, Accountant } from '../../../API'
import AccountantSelect from '../../../components/select/AccountantSelect'
import { ClinicAccountantService } from '../../../components/services/ClinicAccountantService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure, success } from '../../../components/util/Toast'
import { isMobile } from '../../../components/util/isMobile'

interface ClinicAccountantAddButtonProps {
  clinicID: string
  onSuccess: Function
}
const ClinicAccountantAddButton: FC<ClinicAccountantAddButtonProps> = ({ clinicID, onSuccess }) => {
  const [selected, setSelected] = useState<Accountant>()
  const [model] = useState<ClinicAccountant>({
    clinicID,
  } as ClinicAccountant)
  const [adding, setAdding] = useState(false)
  const [presentToast] = useIonToast()

  const addItem = async () => {
    setAdding(true)
    if (!!selected) {
      const res: TbnResponse = await ClinicAccountantService.Instance.store({ ...model, accountantID: selected.id })
      if (res.data) {
        success('Success adding new accountant to the clinic', presentToast)
        onSuccess()
      } else if (res.errorMessage) {
        failure(res.errorMessage, presentToast)
      }
    }
    setAdding(false)
  }

  return (
    <div className='flex-container flex-space-between align-items-center'>
      <div style={{ width: '90%' }}>
        <AccountantSelect onChange={(itm: Accountant) => setSelected(itm)} />
      </div>
      <IonButton
        onClick={addItem}
        disabled={!selected || adding}
        className='ion-float-end'
        size={isMobile() ? 'small' : 'default'}
      >
        <IonIcon icon={add} slot='icon-only' />
      </IonButton>
    </div>
  )
}

export default ClinicAccountantAddButton
