import { FC, useEffect } from 'react'
import { IonText } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Clinic } from '../../API'
import { CurrentReceptionService } from '../../components/services/CurrentReceptionService'
import { isReception } from '../../components/util/Auth'
import PageContainer from '../PageContainer'

interface OwnProps {
  id: string
  title?: string
  padding?: boolean
  actionButtonsEnd?: any
  actionButtonsStart?: any
  onClinicLoaded: (clinic: Clinic) => void
}
interface ReceptionContainerProps extends OwnProps {}

const ReceptionContainer: FC<ReceptionContainerProps> = ({
  id,
  title,
  children,
  padding = true,
  actionButtonsEnd,
  actionButtonsStart,
  onClinicLoaded,
}) => {
  const { user } = useAuthenticator((context) => [context.user])

  const resolveReceptionClinic = async (): Promise<Clinic> => {
    try {
      const reception = await CurrentReceptionService.Instance.currentClinic()
      return reception
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return resolveReceptionClinic()
    }
  }

  const resolveClinic = async () => {
    const clinic: Clinic = await resolveReceptionClinic()
    if (!!clinic?.id) onClinicLoaded(clinic)
  }

  useEffect(() => {
    resolveClinic()
  }, []) // eslint-disable-line

  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      padding={padding}
      actionButtonsEnd={actionButtonsEnd}
      actionButtonsStart={actionButtonsStart}
    >
      {isReception(user) && <>{children}</>}
      {!isReception(user) && <IonText color='danger'>Access Denied (Reception role is required)</IonText>}
    </PageContainer>
  )
}

export default ReceptionContainer
