import { FC } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router'
import { IonRouterOutlet } from '@ionic/react'
import Home from './pages/Home'
import Root from './pages/Root'
import AccountantDesk from './pages/accountant/AccountantDesk'
import AdminAppointments from './pages/admin/AdminAppointments'
import CalendlyAppointmentList from './pages/admin/calendly/CalendlyAppointmentList'
import CannabisProductListPage from './pages/admin/cannabisProducts/CannabisProductListPage'
import ClinicEdit from './pages/admin/clinics/ClinicEdit'
import ClinicList from './pages/admin/clinics/ClinicList'
import AccountantEdit from './pages/admin/users/AccountantEdit'
import AdminEdit from './pages/admin/users/AdminEdit'
import DoctorEdit from './pages/admin/users/DoctorEdit'
import ReceptionEdit from './pages/admin/users/ReceptionEdit'
import UserManagement from './pages/admin/users/UserManagement'
import BookingPage from './pages/booking/BookingPage'
import BookingRoot from './pages/booking/BookingRoot'
import InitialConsultationLegacy from './pages/booking/InitialConsultationLegacy'
import ClinicAdminDesk from './pages/clinicadmin/ClinicAdminDesk'
import Menu from './pages/common/Menu'
import MyAppointments from './pages/customer/MyAppointments'
import AppointmentDetail from './pages/doctor/AppointmentDetail'
import Appointments from './pages/doctor/Appointments'
import MapNumbers from './pages/doctor/MapNumbers'
import PatientSearchPage from './pages/doctor/patient/PatientSearchPage'
import ConsentForm from './pages/forms/consent/ConsentForm'
import AboutUs from './pages/general/AboutUs'
import ClinicPublicPage from './pages/landing/ClinicPublicPage'
import DoctorPublicPage from './pages/landing/DoctorPublicPage'
import DrLanding from './pages/landing/DrLanding'
import Profile from './pages/profile/Profile'
import QuizPage from './pages/quiz/QuizPage'
import ChronicPain from './pages/quiz/chronic-pain/ChronicPain'
import Dass21 from './pages/quiz/dass-21/Dass21'
import BookFast from './pages/reception/BookFast'
import ReceptionAppointmentDetail from './pages/reception/ReceptionAppointmentDetail'
import ReceptionistBookings from './pages/reception/ReceptionBookings'
import ReceptionPatientSearchPage from './pages/reception/patient/PatientSearchPage'
import ReferAPatientPage from './pages/referring/ReferAPatientPage'
import ClinicReferralListPage from './pages/referring/clinic/ClinicReferralListPage'
import ReferralListPage from './pages/referring/doctor/ReferralListPage'
import TgaReportingTools from './pages/report/tga/TgaReportingTools'
import DosageCalculatorPage from './pages/tools/dose-calc/DosageCalculatorPage'
import CalendlyWebhooks from './pages/webhooks/CalendlyWebhooks'

const Routes: FC<RouteComponentProps> = ({ history, location, match, staticContext }) => {
  return (
    <>
      <Menu history={history} location={location} match={match} staticContext={staticContext} />
      <IonRouterOutlet id='main'>
        <Route exact path='/'>
          <Root />
        </Route>
        <Route exact path='/home'>
          <Home />
        </Route>
        <Route exact path='/landing'>
          <DrLanding />
        </Route>
        <Route exact path='/booking'>
          <BookingRoot />
        </Route>
        <Route exact path='/booking/:appointmentDefinitionID'>
          <BookingPage history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/initial-consultation'>
          <InitialConsultationLegacy />
        </Route>
        <Route exact path='/profile'>
          <Profile history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/appointments'>
          <AdminAppointments history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/doctor/appts'>
          <Appointments history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/appointment/:id'>
          <AppointmentDetail />
        </Route>
        <Route exact path='/r/appointment/:id'>
          <ReceptionAppointmentDetail />
        </Route>
        <Route exact path='/doctor/maps'>
          <MapNumbers history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/doctor/search'>
          <PatientSearchPage history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/my/appts'>
          <MyAppointments />
        </Route>
        <Route exact path='/webhooks/calendly'>
          <CalendlyWebhooks />
        </Route>
        <Route exact path='/forms/consent'>
          <ConsentForm />
        </Route>
        <Route exact path='/quiz'>
          <QuizPage />
        </Route>
        <Route exact path='/quiz/chronic-pain'>
          <ChronicPain history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/quiz/dass-21'>
          <Dass21 history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/admin/users'>
          <UserManagement history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/admin/doctors/:sub'>
          <DoctorEdit />
        </Route>
        <Route exact path='/admin/receptions/:sub'>
          <ReceptionEdit />
        </Route>
        <Route exact path='/admin/accountants/:sub'>
          <AccountantEdit />
        </Route>
        <Route exact path='/admin/admins/:sub'>
          <AdminEdit />
        </Route>
        <Route exact path='/calendly/appt'>
          <CalendlyAppointmentList history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/admin/clinics'>
          <ClinicList history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/admin/clinic/:clinicId'>
          <ClinicEdit history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/dr/:drPublicId'>
          <DoctorPublicPage history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/c/:clinicPublicId'>
          <ClinicPublicPage history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/c/:clinicPublicId/:drPublicId'>
          <ClinicPublicPage history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/book/fast/:appointmentId'>
          <BookFast history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/admin/products'>
          <CannabisProductListPage />
        </Route>
        <Route exact path='/reception/bookings'>
          <ReceptionistBookings history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/reception/search'>
          <ReceptionPatientSearchPage
            history={history}
            location={location}
            match={match}
            staticContext={staticContext}
          />
        </Route>
        <Route exact path='/accountant/desk'>
          <AccountantDesk history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/ca/desk'>
          <ClinicAdminDesk history={history} location={location} match={match} staticContext={staticContext} />
        </Route>
        <Route exact path='/about'>
          <AboutUs />
        </Route>
        <Route exact path='/tga'>
          <TgaReportingTools />
        </Route>
        <Route exact path='/dosage-calculator'>
          <DosageCalculatorPage />
        </Route>
        <Route exact path='/refer-a-patient'>
          <ReferAPatientPage />
        </Route>
        <Route exact path='/refer-a-patient/dr/:doctorPublicId'>
          <ReferAPatientPage />
        </Route>
        <Route exact path='/refer-a-patient/clinic/:clinicPublicId'>
          <ReferAPatientPage />
        </Route>
        <Route exact path='/doctor/referral-list'>
          <ReferralListPage />
        </Route>
        <Route exact path='/reception/referral-list'>
          <ClinicReferralListPage />
        </Route>
      </IonRouterOutlet>
    </>
  )
}

export default withRouter(Routes)
