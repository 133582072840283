import React from 'react'
import { IonButton } from '@ionic/react'

class ErrorBoundary extends React.Component<{}, { error: any; errorInfo: any }> {
  constructor(props: any) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className='ion-padding'>
          <h2>{'Error'}</h2>
          <IonButton className='ion-padding' onClick={() => window.location.reload()}>
            {'Refresh page'}
          </IonButton>
          <IonButton className='ion-padding' onClick={() => (window.location.href = '/')}>
            {'Back to home'}
          </IonButton>
          <details style={{ whiteSpace: 'pre-wrap', marginTop: '50px' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
