import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonGrid, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react'
import { lockClosed } from 'ionicons/icons'

interface DosageCalculatorProps {}
const DosageCalculator: FC<DosageCalculatorProps> = () => {
  const [productAmount, setProductAmount] = useState<any>()
  const [productAmountLock, setProductAmountLock] = useState<boolean>(false)
  const [thcPercentage, setThcPercentage] = useState<number>()
  const [cbdPercentage, setCbdPercentage] = useState<number>()
  const [thc, setThc] = useState<number>()
  const [cbd, setCbd] = useState<number>()

  const calculate = () => {
    if (!!thcPercentage && !!productAmount) {
      setThc(+((thcPercentage / 100) * 1000 * productAmount)?.toFixed(3))
    }
    if (!!cbdPercentage && !!productAmount) {
      setCbd(+((cbdPercentage / 100) * 1000 * productAmount)?.toFixed(3))
    }
  }

  const reverseCalculateThc = () => {
    if (!!thcPercentage && !!thc) {
      const origAmount = +thc / (thcPercentage / 100)
      setProductAmount(+(origAmount / 1000).toFixed(3))
    }
  }
  const reverseCalculateCbd = () => {
    if (!!cbdPercentage && !!cbd) {
      const origAmount = +cbd / (cbdPercentage / 100)
      setProductAmount(+(origAmount / 1000)?.toFixed(3))
    }
  }

  useEffect(() => {
    if (!productAmountLock) calculate()
  }, [productAmount, thcPercentage, productAmountLock, cbdPercentage]) // eslint-disable-line

  useEffect(() => {
    if (productAmountLock) reverseCalculateThc()
  }, [thc, productAmountLock, thcPercentage]) // eslint-disable-line

  useEffect(() => {
    if (productAmountLock) reverseCalculateCbd()
  }, [productAmountLock, cbd, cbdPercentage]) // eslint-disable-line

  return (
    <IonGrid>
      <IonItem>
        <IonLabel position='stacked'>Product amount in grams</IonLabel>
        <IonInput
          value={productAmount}
          onIonChange={(e) => setProductAmount(e.detail.value)}
          type='number'
          inputMode='decimal'
          disabled={productAmountLock}
        />
        <IonButtons slot='end'>
          {!productAmountLock && (
            <IonButton fill='clear' onClick={() => setProductAmountLock(!productAmountLock)}>
              <IonIcon slot='icon-only' icon={lockClosed} />
            </IonButton>
          )}
        </IonButtons>
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>THC %</IonLabel>
        <IonInput
          min='1'
          max='100'
          value={thcPercentage}
          onIonChange={(e: any) => setThcPercentage(e.detail.value as number)}
          type='number'
          inputMode='decimal'
        />
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>CBD %</IonLabel>
        <IonInput
          min='1'
          max='100'
          value={cbdPercentage}
          onIonChange={(e: any) => setCbdPercentage(e.detail.value as number)}
          type='number'
          inputMode='decimal'
        />
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>Total mg of THC</IonLabel>
        <IonInput
          value={thc}
          onIonChange={(e: any) => setThc(e.detail.value)}
          type='number'
          inputMode='decimal'
          disabled={!productAmountLock}
        />
        <IonButtons slot='end'>
          {productAmountLock && (
            <IonButton fill='clear' onClick={() => setProductAmountLock(!productAmountLock)}>
              <IonIcon slot='icon-only' icon={lockClosed} />
            </IonButton>
          )}
        </IonButtons>
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>Total mg of CBD</IonLabel>
        <IonInput
          value={cbd}
          onIonChange={(e: any) => setCbd(e.detail.value)}
          type='number'
          inputMode='decimal'
          disabled={!productAmountLock}
        />
        <IonButtons slot='end'>
          {productAmountLock && (
            <IonButton fill='clear' onClick={() => setProductAmountLock(!productAmountLock)}>
              <IonIcon slot='icon-only' icon={lockClosed} />
            </IonButton>
          )}
        </IonButtons>
      </IonItem>
    </IonGrid>
  )
}

export default DosageCalculator
