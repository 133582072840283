import { FC } from 'react'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { Authenticator, Button, CheckboxField, Heading, Image } from '@aws-amplify/ui-react'
import { Text, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react'
import { connect } from '../data'
import { setLoginMode } from '../data/user/user.actions'
import { isMainProd } from './util/AmplifyEnv'

declare var window: any

interface OwnProps {
  children: any
}
interface StateProps {
  darkMode: boolean
  loginMode: 'phone_number' | 'email' | 'username'
}
interface DispatchProps {
  setLoginMode: typeof setLoginMode
}
interface AuthenticatorContainerProps extends OwnProps, StateProps, DispatchProps {}
const AuthenticatorContainer: FC<AuthenticatorContainerProps> = ({ children, darkMode, loginMode, setLoginMode }) => {
  const onLoginMechanismsChange = async (changeTo: 'phone_number' | 'email') => {
    await setLoginMode(changeTo)

    window.location.reload()
  }

  const loginSwitcher = (
    <IonSegment value={loginMode} onIonChange={(e: any) => onLoginMechanismsChange(e.detail.value)} mode='md'>
      <IonSegmentButton value={'phone_number'}>Phone</IonSegmentButton>
      <IonSegmentButton value={'email'}>Email</IonSegmentButton>
    </IonSegment>
  )

  const formFields = {
    signIn: {
      username: {
        dialCode: '+61',
        dialCodeList: ['+61'],
      },
    },
    signUp: {
      phone_number: {
        dialCode: '+61',
        dialCodeList: ['+61'],
      },
    },
    resetPassword: {
      username: {
        dialCode: '+61',
        dialCodeList: ['+61'],
      },
    },
  }

  const components = {
    Header() {
      return (
        <View textAlign='center'>
          <Image
            alt='Telebinoid logo'
            src={`../../assets/img/logo-text-colorful-transparent${darkMode ? '-dark' : ''}.svg`}
            maxHeight={'150px'}
          />
        </View>
      )
    },

    Footer() {
      const { tokens } = useTheme()

      return (
        <View textAlign='center' padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>&copy; 2022, Telebinoid.com, v{process?.env?.REACT_APP_VERSION}</Text>
        </View>
      )
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme()

        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Sign in to your account
          </Heading>
        )
      },
      Footer() {
        const { toResetPassword } = useAuthenticator()

        return (
          <View textAlign='center'>
            <Button fontWeight='normal' onClick={toResetPassword} size='small' variation='link'>
              Reset Password
            </Button>
            <div className='ion-padding-vertical'>{isMainProd() ? <></> : loginSwitcher}</div>
          </View>
        )
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme()

        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Create a new account
          </Heading>
        )
      },
      FormFields() {
        const { validationErrors } = useAuthenticator()

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement as string}
              hasError={!!validationErrors.acknowledgement}
              name='acknowledgement'
              value='yes'
              label='I agree with the Terms & Conditions'
            />
          </>
        )
      },
      Footer() {
        const { toSignIn } = useAuthenticator()

        return (
          <View textAlign='center'>
            <Button fontWeight='normal' onClick={toSignIn} size='small' variation='link'>
              Back to Sign In
            </Button>
          </View>
        )
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme()
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        )
      },
      Footer() {
        return <></>
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme()
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        )
      },
      Footer() {
        return <Text></Text>
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme()
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        )
      },
      Footer() {
        return <Text></Text>
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme()
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        )
      },
      Footer() {
        return <></>
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme()
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        )
      },
      Footer() {
        return <></>
      },
    },
  }

  const services = {
    async validateCustomSignUp(formData: any) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions',
        }
      }
    },
  }

  return (
    <>
      {loginMode !== 'username' && (
        <Authenticator
          {...(isMainProd() || loginMode === 'phone_number' ? { formFields } : {})}
          components={components}
          loginMechanisms={[isMainProd() ? 'phone_number' : loginMode]}
          services={services}
          signUpAttributes={[]}
        >
          {children}
        </Authenticator>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    loginMode: state.user.loginMode,
  }),
  mapDispatchToProps: {
    setLoginMode,
  },
  component: AuthenticatorContainer,
})
