import { FC } from 'react'
import { Redirect } from 'react-router'
import { connect } from '../../data'

interface StateProps {}
interface DispatchProps {}
interface BookingRootProps extends StateProps, DispatchProps {}
const BookingRoot: FC<BookingRootProps> = () => {
  return <Redirect to={`/dr/nas`} />
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: BookingRoot,
})
