import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { CalendlyAppointment, DeleteCalendlyAppointmentInput } from '../../API'
import { deleteCalendlyAppointment } from '../../graphql/mutations'
import { listCalendlyAppointments } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class CalendlyAppointmentService {
  private static service: CalendlyAppointmentService | undefined

  public async listAll(): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listCalendlyAppointments,
        variables: {},
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listCalendlyAppointments?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching calendly appointments: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(calendlyAppointment: CalendlyAppointment): Promise<TbnResponse> {
    try {
      const input: DeleteCalendlyAppointmentInput = {
        id: calendlyAppointment.id,
      }
      const res: any = await API.graphql({
        query: deleteCalendlyAppointment,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteCalendlyAppointment, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on remove : ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async removeAll(): Promise<TbnResponse> {
    const list = await this.listAll()
    for (let itm of list?.data) {
      await this.remove(itm)
    }
    return { data: 'ok' }
  }

  public static get Instance(): CalendlyAppointmentService {
    if (!this.service) {
      this.service = new CalendlyAppointmentService()
    }
    return this.service
  }
}
