import { FC, useEffect, useState } from 'react'
import { IonGrid, IonText, useIonToast } from '@ionic/react'
import { Patient } from '../../API'
import Spinner from '../../components/helpers/Spinner'
import { PatientService } from '../../components/services/PatientService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data'
import questions from './healthQuestions.json'
import QuestionTypeView from './helpers/QuestionTypeView'

interface OwnProps {
  doubleCheck: boolean
  givenName?: string
  email?: string | null
  phone?: string | null
  patient?: Patient
}
interface StateProps {}
interface DispatchProps {}
interface PatientHealthViewProp extends OwnProps, StateProps, DispatchProps {}
const PatientHealthView: FC<PatientHealthViewProp> = ({ email, patient = null, phone, givenName, doubleCheck }) => {
  const [loading, setLoading] = useState(!patient)
  const [item, setItem] = useState<Patient | null>(patient)
  const [present] = useIonToast()
  const healthQuestions = questions.questions

  const loadPatientHealth = async () => {
    setLoading(true)
    const res: TbnResponse = await PatientService.Instance.findByPhoneOrEmail(
      doubleCheck ? givenName : undefined,
      phone,
      email,
    )
    if (!!res.data) {
      setItem(res.data)
    } else if (!!res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  useEffect(() => {
    if ((!!email && !patient) || (!!phone && !patient)) {
      loadPatientHealth()
    }
  }, [email, phone, doubleCheck]) // eslint-disable-line

  return (
    <>
      {!email && !phone && !patient && <IonText color='danger'>Phone or Email is required.</IonText>}
      {loading && <Spinner />}
      {!loading && !!item && (
        <IonGrid>
          {healthQuestions.map((q: any) => (
            <QuestionTypeView question={{ ...q, answer: (item as any)[q.id] }} key={q.id} />
          ))}
        </IonGrid>
      )}
      {!loading && !item && (
        <div className='ion-padding ion-margin ion-text-start'>
          <IonText>Patient health not found!</IonText>
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  component: PatientHealthView,
})
