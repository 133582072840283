import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Accountant, User, Clinic } from '../../API'
import { findAccountantByUserId } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { ClinicAccountantService } from './ClinicAccountantService'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentAccountantService {
  private static accountantService: CurrentAccountantService | undefined
  private accountant?: Accountant
  private clinic?: Clinic
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentAccountant(): Promise<Accountant> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentAccountantService.Instance.accountant) {
      return Promise.resolve(CurrentAccountantService.Instance.accountant)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentAccountantService.Instance.accountant) {
      return Promise.resolve(CurrentAccountantService.Instance.accountant)
    } else {
      return Promise.reject('Current accountant resolve timeout!')
    }
  }

  public async currentClinic(): Promise<Clinic> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentAccountantService.Instance.clinic) {
      return CurrentAccountantService.Instance.clinic
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentAccountantService.Instance.clinic) {
      return CurrentAccountantService.Instance.clinic
    } else {
      return Promise.reject('Current clinic resolve timeout!')
    }
  }

  private async checkIfAccountantExists(service: CurrentAccountantService) {
    try {
      const res: any = await API.graphql({
        query: findAccountantByUserId,
        variables: { userID: service.user?.id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      const accountant = res?.data?.findAccountantByUserId?.items?.[0]
      if (!!accountant) {
        service.accountant = accountant
        const res: TbnResponse = await ClinicAccountantService.Instance.find(accountant.id)
        if (!!res.data) service.clinic = res.data.clinic
        log('foundAccountantByUserId', accountant?.id, 'at', res.data?.clinicID)
        return service
      }
    } catch (err) {
      log('error fetching Accountant: ', err)
    }
    return service
  }

  private async buildCurrentAccountantService(): Promise<CurrentAccountantService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let service = new CurrentAccountantService(user)
      service = await service.checkIfAccountantExists(service)
      return Promise.resolve(service)
    } catch (err) {
      warn('Init CurrentAccountantService failure:', err)
      return this.buildCurrentAccountantService()
    }
  }

  public static get Instance(): CurrentAccountantService {
    if (!this.accountantService) {
      this.accountantService = new CurrentAccountantService()
      this.accountantService.buildCurrentAccountantService().then((service: CurrentAccountantService) => {
        this.accountantService = service
      })
    }
    return this.accountantService
  }
}
