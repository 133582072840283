import { FC } from 'react'
import { IonIcon } from '@ionic/react'
import { call } from 'ionicons/icons'
import { AppointmentType, TypeFormEvent } from '../../API'
import ClinicStatusDetails from './ClinicStatusDetails'

interface AppointmentTypeIconProps {
  item?: TypeFormEvent
}

const AppointmentTypeIcon: FC<AppointmentTypeIconProps> = ({ item }) => {
  return (
    <>
      {item?.definitionType === AppointmentType.TELEHEALTH && (
        <span title={item?.definitionClinicName || ''} className='ion-margin-start' style={{ width: '56px' }}>
          <IonIcon size='large' icon={call} color={!item?.canceled ? 'medium' : ''} />
        </span>
      )}
      {item?.definitionType === AppointmentType.IN_PERSON && (
        <ClinicStatusDetails name={item?.definitionClinicName} logoImage={item?.definitionClinicLogo} />
      )}
    </>
  )
}

export default AppointmentTypeIcon
