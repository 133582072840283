import { FC, useEffect, useState } from 'react'
import { IonIcon, IonThumbnail } from '@ionic/react'
import { Storage } from 'aws-amplify'
import { business } from 'ionicons/icons'
import { ClinicStatus } from '../../API'

interface ClinicStatusDetailsProps {
  status?: ClinicStatus | null
  name?: string | null
  logoImage?: string | null
  slot?: string
  activeOverride?: boolean | null
}
const ClinicStatusDetails: FC<ClinicStatusDetailsProps> = ({
  slot = 'start',
  status,
  name = '',
  logoImage,
  activeOverride = false,
}) => {
  const [logo, setLogo] = useState<any>()
  const resolveColor = () => {
    if (activeOverride) {
      return 'medium'
    }
    switch (status) {
      case ClinicStatus.ACTIVE:
        return 'success'
      case ClinicStatus.INACTIVE:
        return 'warning'
      case ClinicStatus.ARCHIVED:
        return 'medium'
      case ClinicStatus.DELETED:
        return 'danger'
    }
    return 'dark'
  }

  const fetchLogo = async () => {
    if (!!logoImage) {
      const logoUrl = await Storage.get(logoImage, { level: 'public', expires: 84600 })
      setLogo(logoUrl)
    }
  }

  useEffect(() => {
    fetchLogo()
  }, [logoImage]) // eslint-disable-line

  return (
    <>
      {!!logo && (
        <IonThumbnail title={name || ''} slot={slot}>
          <img src={logo} alt='' />
        </IonThumbnail>
      )}
      {!logo && (
        <span className='ion-margin-start' title={name || ''} style={{ width: '56px' }}>
          <IonIcon size='large' icon={business} slot={slot} color={resolveColor()} />
        </span>
      )}
    </>
  )
}

export default ClinicStatusDetails
