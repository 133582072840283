import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonText, isPlatform } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { checkmark } from 'ionicons/icons'
import { Patient } from '../../../API'
import { isBooking, isPatient } from '../../../components/models/Model'
import { isValidEmail } from '../../../components/util/Email'
import { connect } from '../../../data'
import { setBooking, setPatientHealth } from '../../../data/user/user.actions'
import Booking from '../../booking/Booking.model'

interface DispatchProps {
  setBooking: typeof setBooking
  setPatientHealth: typeof setPatientHealth
}
interface OwnProps {
  id: string
  label: string
  alter?: string
  model: Booking | Patient
  required?: boolean
  swiper: SwiperCore
  email?: boolean
  readonly?: boolean
  paramElse?: string | null
}
interface QuestionShortTextProps extends OwnProps, DispatchProps {}
const QuestionShortText: FC<QuestionShortTextProps> = ({
  id,
  label,
  alter,
  model,
  required = false,
  readonly = false,
  setBooking,
  setPatientHealth,
  swiper,
  email,
  paramElse,
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen()
  const [hideLabel, setHideLabel] = useState(false)

  useEffect(() => {
    if (isKeyboardOpen && isPlatform('android')) {
      setHideLabel(true)
    } else {
      setHideLabel(false)
    }
  }, [isKeyboardOpen])

  const onKeyUpEvent = (e: any) => {
    if (e?.key === 'Enter') {
      e?.target?.blur()
    }
  }

  const setModel = (id: string, value: string) => {
    if (!readonly) {
      if (isBooking(model)) {
        setBooking({ ...model, [id]: value })
      } else if (isPatient(model)) {
        setPatientHealth({ ...model, [id]: value })
      }
    }
  }

  return (
    <>
      {!hideLabel && (
        <IonLabel className={`ion-text-wrap ${required ? ' required' : ''}`}>
          {!paramElse &&
            label.split(' ').map((str) => {
              if (str.startsWith('{')) {
                return (model as any)?.[str.replace(/[{}]/g, '')]
              } else {
                return str + ' '
              }
            })}
          {!!paramElse &&
            alter?.split(' ').map((str) => {
              if (str.startsWith('{')) {
                return (model as any)?.[str.replace(/[{}]/g, '')]
              } else {
                return str + ' '
              }
            })}
        </IonLabel>
      )}
      <IonItem key={label + id}>
        <IonInput
          value={(model as any)?.[id]}
          onIonChange={(e: any) => setModel(id, e.detail.value)}
          placeholder={'Type your answer here...'}
          onIonFocus={() => {
            if (isPlatform('android')) {
              setHideLabel(true)
            }
          }}
          onIonBlur={() => {
            setHideLabel(false)
          }}
          onKeyUp={onKeyUpEvent}
          readonly={readonly}
        ></IonInput>
      </IonItem>
      {!hideLabel && (
        <>
          {email && (model as any)?.[id] && !isValidEmail((model as any)?.[id]) && (
            <IonText className='ion-padding-start' style={{ fontSize: '14px' }}>
              please enter a valid email address.
            </IonText>
          )}
          <IonButton
            color='primary'
            disabled={required ? (email ? !isValidEmail((model as any)?.[id]) : !(model as any)?.[id]) : false}
            onClick={() => swiper?.slideNext()}
          >
            OK
            {(email ? isValidEmail((model as any)?.[id]) : !!(model as any)?.[id]) && (
              <IonIcon icon={checkmark} slot='end' />
            )}
          </IonButton>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
    setPatientHealth,
  },
  component: QuestionShortText,
})
