import { FC, useEffect, useState } from 'react'
import { IonButton, IonGrid, IonIcon, IonItem, IonListHeader, useIonAlert } from '@ionic/react'
import { IonLabel, useIonToast } from '@ionic/react'
import { personRemove } from 'ionicons/icons'
import { ClinicReception } from '../../../API'
import { ClinicReceptionService } from '../../../components/services/ClinicReceptionService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { utcToLocaleDate } from '../../../components/util/Date'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import ClinicReceptionAddButton from './ClinicReceptionAddButton'

interface OwnProps {
  clinicID: string
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ClinicReceptionListProps extends OwnProps, StateProps, DispatchProps {}

const ClinicReceptionList: FC<ClinicReceptionListProps> = ({ clinicID, net, setNet }) => {
  const [items, setItems] = useState<ClinicReception[]>([])
  const [present] = useIonToast()
  const [presentAlert] = useIonAlert()

  const fetchItems = async () => {
    setNet(true)
    const res: TbnResponse = await ClinicReceptionService.Instance.findByClinicID(clinicID)
    if (res.data) {
      setItems(res.data)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setNet(false)
  }

  const deleteItem = async (itm: ClinicReception) => {
    setNet(true)
    setItems([])
    await ClinicReceptionService.Instance.remove(itm)
    await fetchItems()
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
  }, [clinicID]) // eslint-disable-line

  return (
    <IonGrid>
      <IonListHeader mode='ios'>Receptions</IonListHeader>
      <ClinicReceptionAddButton clinicID={clinicID} onSuccess={fetchItems} />
      {items.map((itm: ClinicReception) => (
        <IonItem key={itm.id}>
          <IonLabel>
            {itm?.reception.name}
            <p>{utcToLocaleDate(itm?.createdAt)}</p>
          </IonLabel>
          <IonButton
            fill='clear'
            color='danger'
            onClick={() => {
              presentAlert({
                header: 'Are you sure?',
                message: `${itm?.reception?.name}`,
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {},
                  },
                  {
                    text: 'Revoke access',
                    role: 'destructive',
                    cssClass: 'alert-button-delete',
                    handler: () => {
                      deleteItem(itm)
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) => {},
              })
            }}
          >
            <IonIcon icon={personRemove} slot='icon-only' />
          </IonButton>
        </IonItem>
      ))}
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: ClinicReceptionList,
})
