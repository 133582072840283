import { FC, useEffect, useState } from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonSpinner, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Patient } from '../../API'
import useDebounce from '../../components/hooks/useDebounce'
import { CurrentPatientService } from '../../components/services/CurrentPatientService'
import { log, warn } from '../../components/util/Log'
import { connect } from '../../data'
import { setNet, setPatientHealth } from '../../data/user/user.actions'
import questions from './healthQuestions.json'
import QuestionType from './helpers/QuestionType'

interface OwnProps {
  hideTitle?: boolean
}
interface StateProps {
  patientHealth: Patient
}
interface DispatchProps {
  setPatientHealth: typeof setPatientHealth
  setNet: typeof setNet
}
interface PatientHealthProp extends OwnProps, StateProps, DispatchProps {}
const PatientHealth: FC<PatientHealthProp> = ({ patientHealth, setPatientHealth, setNet, hideTitle = false }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [patientLoaded, setPatientLoaded] = useState(false)
  const debounced = useDebounce(patientHealth, 3000)
  const [present] = useIonToast()
  const healthQuestions = questions.questions

  const loadPatientHealth = async () => {
    if (!!user?.attributes?.given_name) {
      try {
        const patient: Patient = await CurrentPatientService.Instance.currentPatient()
        setPatientHealth({
          ...patientHealth,
          __typename: 'Patient',
          primaryDiagnosis: patient.primaryDiagnosis,
          otherTreatments: patient.otherTreatments,
          otherMedications: patient.otherMedications,
          selfMedicated: patient.selfMedicated,
          kidneyLiver: patient.kidneyLiver,
          cardiovascular: patient.cardiovascular,
          substanceAbuseDisorder: patient.substanceAbuseDisorder,
          allergies: patient.allergies,
          smoke: patient.smoke,
          drinkAlcohol: patient.drinkAlcohol,
          otherConditions: patient.otherConditions,
        })
        log('Patient loaded', patient)
        setTimeout(() => {
          setPatientLoaded(true)
        }, 300)
      } catch (err) {
        warn('Loading user retry...')
        if (!patientLoaded) {
          await loadPatientHealth()
        }
      }
    }
  }

  const updatePatientHealth = async () => {
    if (!patientLoaded) {
      return
    }
    try {
      setNet(true)
      await CurrentPatientService.Instance.updateCurrentPatientHealth(patientHealth)
      setNet(false)
    } catch (err: any) {
      warn('Patient update failure.', err)
      present({ message: `Error: ${err?.errors?.[0]?.message}`, color: 'danger', duration: 3000 })
      setNet(false)
    }
  }

  useEffect(() => {
    if (patientLoaded) {
      updatePatientHealth()
    }
  }, [debounced]) // eslint-disable-line

  useEffect(() => {
    loadPatientHealth()
  }, []) // eslint-disable-line

  return (
    <IonCard>
      {!hideTitle && (
        <IonCardHeader style={{ paddingBottom: 0 }}>
          <IonCardSubtitle>Paitent Health</IonCardSubtitle>
        </IonCardHeader>
      )}
      <IonCardContent>
        {!patientLoaded && (
          <div className='ion-text-center'>
            <IonSpinner />
          </div>
        )}
        {patientLoaded && (
          <>
            {healthQuestions.map((q: any) => (
              <QuestionType question={q} model={patientHealth} key={q.id} labelOutside={false} />
            ))}
          </>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    patientHealth: state.user.patientHealth,
  }),
  mapDispatchToProps: {
    setPatientHealth,
    setNet,
  },
  component: PatientHealth,
})
