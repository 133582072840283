import { FC, useRef } from 'react'
import { IonButton, IonButtons, IonContent, IonFooter, IonModal, IonToolbar } from '@ionic/react'
import DosageCalculator from './DosageCalculator'

interface DosageCalculatorModalProps {
  isOpen: boolean
  onDidDismiss: (event: any) => void
}
const DosageCalculatorModal: FC<DosageCalculatorModalProps> = ({ isOpen, onDidDismiss }) => {
  const modalRef = useRef<any>()

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss} ref={modalRef} id='dosage-calculator-modal'>
      <IonContent className='ion-padding'>
        <DosageCalculator />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => modalRef.current.dismiss(null)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default DosageCalculatorModal
