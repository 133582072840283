import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { CannabisProduct, CreateCannabisProductInput } from '../../API'
import { ModelCannabisProductFilterInput, ModelSortDirection, UpdateCannabisProductInput } from '../../API'
import { ModelMedicalAuthorisedPrescriberFilterInput, ProductCategory } from '../../API'
import { createCannabisProduct, updateCannabisProduct } from '../../graphql/mutations'
import { findCannabisProductBySorter, getCannabisProduct } from '../../graphql/queries'
import { error, log, warn } from '../util/Log'
import SORT_CHAR from '../util/Sorter'
import { TbnResponse } from './TbnResponse'

export class CannabisProductService {
  private static service: CannabisProductService | undefined

  public async load(id: string) {
    const res: any = await API.graphql({
      query: getCannabisProduct,
      variables: { id },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })
    return res?.data?.getCannabisProduct
  }

  public async listAll(filter?: ModelCannabisProductFilterInput): Promise<TbnResponse> {
    try {
      const defaultFilter: ModelCannabisProductFilterInput = {
        ...filter,
      }
      if (!defaultFilter.archived) defaultFilter.archived = { ne: true }
      const res: any = await API.graphql({
        query: findCannabisProductBySorter,
        variables: {
          sorter: SORT_CHAR,
          filter: defaultFilter,
          sortDirection: ModelSortDirection.ASC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findCannabisProductBySorter?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn(err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(
    cannabisProduct: CannabisProduct,
    filter?: ModelMedicalAuthorisedPrescriberFilterInput,
  ): Promise<TbnResponse> {
    try {
      const defaultFilter: ModelCannabisProductFilterInput = {
        archived: { attributeExists: true },
        ...filter,
      }
      if (!!cannabisProduct.name) defaultFilter.name = { eq: cannabisProduct.name }
      if (!!cannabisProduct.presentation) defaultFilter.presentation = { eq: cannabisProduct.presentation }
      if (!!cannabisProduct.ingredients) defaultFilter.ingredients = { eq: cannabisProduct.ingredients }
      if (!!cannabisProduct.category) defaultFilter.category = { eq: cannabisProduct.category }
      if (!!cannabisProduct.sponsor) defaultFilter.sponsor = { eq: cannabisProduct.sponsor }
      const list: TbnResponse = await this.listAll(defaultFilter)
      return { data: list?.data?.[0] }
    } catch (err) {
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(cannabisProduct: CannabisProduct | CreateCannabisProductInput): Promise<TbnResponse> {
    try {
      if (!!cannabisProduct.id) {
        const next: UpdateCannabisProductInput = {
          id: cannabisProduct.id,
          name: cannabisProduct.name?.trim(),
          ingredients: cannabisProduct?.ingredients?.trim(),
          strength: cannabisProduct?.strength?.trim(),
          volume: cannabisProduct?.volume?.trim(),
          qtyPerDosageUnit: cannabisProduct?.qtyPerDosageUnit?.trim(),
          sponsor: cannabisProduct?.sponsor?.trim(),
          idxOrder: cannabisProduct.idxOrder,
          presentation: cannabisProduct.presentation,
          presentationText: cannabisProduct.presentationText?.trim(),
          archived: false,
          sorter: SORT_CHAR,
        }
        if (!!cannabisProduct.category) {
          next.category = cannabisProduct.category
        }
        const res: any = await API.graphql({
          query: updateCannabisProduct,
          variables: { input: next },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity updated', res?.data?.updateCannabisProduct?.id)
        return { data: res?.data?.updateCannabisProduct }
      } else {
        const res: any = await API.graphql({
          query: createCannabisProduct,
          variables: { input: { ...cannabisProduct, sorter: SORT_CHAR } },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('entity created', res?.data?.createCannabisProduct?.id)
        return { data: res?.data, errorMessage: res?.errors?.[0].message }
      }
    } catch (err) {
      error('error on persist item: ', err, cannabisProduct)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async import(cannabisProduct: CreateCannabisProductInput): Promise<TbnResponse> {
    try {
      const existed: TbnResponse = await this.find(cannabisProduct as CannabisProduct)
      if (!!existed?.data?.id) {
        log('Cannabis product founded', existed?.data?.id)
        return { data: existed?.data }
      } else {
        const res: any = await API.graphql({
          query: createCannabisProduct,
          variables: { input: { ...cannabisProduct, sorter: SORT_CHAR } },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('Cannabis product created', res?.data?.createCannabisProduct?.id)
        return { data: res?.data, errorMessage: res?.errors?.[0].message }
      }
    } catch (err) {
      error('error on persist cannabis product: ', cannabisProduct, err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async archive(cannabisProduct: CannabisProduct): Promise<TbnResponse> {
    try {
      const input: UpdateCannabisProductInput = {
        id: cannabisProduct.id,
        archived: true,
      }
      const res: any = await API.graphql({
        query: updateCannabisProduct,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateCannabisProduct, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on archive item: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async updateCategory(
    cannabisProduct: CannabisProduct,
    newCategory: ProductCategory | null,
  ): Promise<TbnResponse> {
    try {
      const input: UpdateCannabisProductInput = {
        id: cannabisProduct.id,
        category: newCategory,
      }
      const res: any = await API.graphql({
        query: updateCannabisProduct,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.updateCannabisProduct, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      log('error on update category: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): CannabisProductService {
    if (!this.service) {
      this.service = new CannabisProductService()
    }
    return this.service
  }
}
