import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronBack, chevronForward } from 'ionicons/icons'
import { FC } from 'react'

interface OwnProps {
  loading: boolean
  hasNext: boolean
  hasPrev: boolean
  next: Function
  prev: Function
}

interface PageNavigateProps extends OwnProps {}

const PageNavigate: FC<PageNavigateProps> = ({ loading, hasNext, hasPrev, next, prev }) => {
  const disabledPrev = !hasPrev || loading
  const disabledNext = !hasNext || loading
  return (
    <IonRow>
      <IonCol>
        <IonButton disabled={disabledNext} onClick={() => next()}>
          <IonIcon icon={chevronBack} />
          <span>Previous</span>
        </IonButton>
      </IonCol>
      <IonCol className='ion-text-end'>
        <IonButton disabled={disabledPrev} onClick={() => prev()}>
          <span>Next</span>
          <IonIcon icon={chevronForward} />
        </IonButton>
      </IonCol>
    </IonRow>
  )
}

export default PageNavigate
