export const findTypeFormEventsBySorter = /* GraphQL */ `
  query FindTypeFormEventsBySorter(
    $sorter: String!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTypeFormEventsBySorter(
      sorter: $sorter
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorPublicId
        clinicPublicId
        definitionID
        definitionTitle
        definitionSlug
        definitionType
        definitionSubject
        definitionDoctorName
        definitionClinicName
        definitionClinicLogo
        who
        email
        phone
        firstName
        bookingUrl
        oldBookingUrl
        calendlyStatus
        rescheduled
        canceled
        cancelReason
        startTime
        endTime
      }
      nextToken
    }
  }
`
