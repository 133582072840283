import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonChip, IonCol, IonGrid, IonIcon } from '@ionic/react'
import { IonItemSliding, IonRow } from '@ionic/react'
import { IonItem, IonLabel, IonSpinner } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { add, refresh } from 'ionicons/icons'
import { Clinic } from '../../../API'
import ClinicStatusComp from '../../../components/models/ClinicStatus'
import statusColor from '../../../components/models/ClinicStatusColor'
import { ClinicService } from '../../../components/services/ClinicService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import PageNavigate from '../../doctor/PageNavigate'
import AdminContainer from '../AdminContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
}
interface ClinicListProps extends OwnProps, StateProps, DispatchProps {}
const ClinicList: FC<ClinicListProps> = ({ setNet, net, history }) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [present] = useIonToast()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length

  const fetchItems = async () => {
    setLoading(true)
    const res: TbnResponse = await ClinicService.Instance.getAll()
    if (res.data) {
      setItems(res?.data?.items || [])
      setNextNextToken(res?.data?.nextToken)
    } else if (res.errorMessage) {
      present({ message: res.errorMessage, color: 'danger', duration: 3000 })
    }
    setLoading(false)
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  // eslint-disable-next-line
  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }

  useEffect(() => {
    fetchItems()
  }, [nextToken]) // eslint-disable-line

  return (
    <AdminContainer
      id='clinic-list'
      title='Clinics'
      actionButtonsEnd={
        <IonButton onClick={fetchItems} disabled={loading}>
          <IonIcon icon={refresh} slot='icon-only' />
        </IonButton>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton onClick={() => history.push('/admin/clinic/new')}>
              <IonIcon icon={add} slot='start' />
              New Clinic
            </IonButton>
          </IonCol>
        </IonRow>
        {items.map((itm: Clinic) => (
          <IonItemSliding key={itm.id}>
            <IonItem button onClick={() => history.push(`/admin/clinic/${itm.id}`)}>
              <ClinicStatusComp clinic={itm} />
              <IonLabel>
                <span>{itm?.name}</span>
                <p>
                  {itm?.address} {itm?.phone ? `(${itm?.phone})` : ''}
                </p>
              </IonLabel>
              <IonChip slot='end' color={statusColor(itm.status)}>
                {itm?.status}
              </IonChip>
            </IonItem>
          </IonItemSliding>
        ))}
        {loading && (
          <div className='ion-text-center'>
            <IonSpinner style={{ position: 'fixed' }} />
          </div>
        )}
        {!loading && items.length === 0 && <div className='ion-text-center'>No clinic record</div>}
        <IonRow>
          <IonCol>
            <PageNavigate {...{ hasNext, hasPrev, prev, next, loading }} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </AdminContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: withRouter(ClinicList),
})
