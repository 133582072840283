import { FC, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IonBadge, IonButton, IonButtons, IonContent } from '@ionic/react'
import { IonHeader, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { IonModal, IonTextarea, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { clipboard, close } from 'ionicons/icons'
import { AppointmentNote } from '../../API'
import { AppointmentNoteService } from '../../components/services/AppointmentNoteService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { utcToLocaleDate } from '../../components/util/Date'
import { failure, info } from '../../components/util/Toast'
import { connect } from '../../data'
import { setNet } from '../../data/user/user.actions'

interface OwnProps {
  patientID: string
  doctorPublicId: string
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AppointmentNoteHistoryProps extends OwnProps, DispatchProps {}

const AppointmentNoteHistory: FC<AppointmentNoteHistoryProps> = ({ patientID, doctorPublicId, setNet }) => {
  const [items, setItems] = useState<AppointmentNote[]>([])
  const [presentToast] = useIonToast()
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState<AppointmentNote>()
  const modal = useRef<any>()

  const fetchItems = async () => {
    setNet(true)
    const res: TbnResponse = await AppointmentNoteService.Instance.findByPatientId(patientID)
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonList>
      {items.map((itm: AppointmentNote) => (
        <IonItem
          key={itm.id}
          button
          onClick={() => {
            setItem(itm)
            setShowModal(true)
          }}
        >
          <IonLabel className='ion-text-wrap'>
            {itm.note}
            <p>{utcToLocaleDate(itm.createdAt)}</p>
          </IonLabel>
          {doctorPublicId !== itm?.doctor?.drId && (
            <IonBadge slot='end' color={'medium'}>
              {itm?.doctor?.drId}
            </IonBadge>
          )}
        </IonItem>
      ))}
      <IonModal
        ref={modal}
        isOpen={showModal}
        onDidDismiss={() => {
          setShowModal(false)
          setItem(undefined)
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
                <IonIcon icon={close} slot='icon-only'></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>Appointment Note</IonTitle>
            <IonButtons slot='end'>
              <CopyToClipboard text={item?.note || ''} onCopy={() => info('Copied', presentToast)}>
                <IonButton>
                  <IonIcon icon={clipboard}></IonIcon>
                </IonButton>
              </CopyToClipboard>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonTextarea value={item?.note} readonly={true} autoGrow={true} />
          </IonItem>
        </IonContent>
      </IonModal>
    </IonList>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setNet,
  },
  component: AppointmentNoteHistory,
})
