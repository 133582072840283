import { ProductCategory } from '../../API'

const productCategoryExtractor = (text: string) => {
  if (text?.startsWith('Category')) {
    const catText = text.split('-')[0]
    switch (catText) {
      case 'Category 1':
        return ProductCategory.CATEGORY_1
      case 'Category 2':
        return ProductCategory.CATEGORY_2
      case 'Category 3':
        return ProductCategory.CATEGORY_3
      case 'Category 4':
        return ProductCategory.CATEGORY_4
      case 'Category 5':
        return ProductCategory.CATEGORY_5
    }
  }
  return null
}

export default productCategoryExtractor
