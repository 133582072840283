import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonText, useIonToast } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { Doctor, DoctorStatus } from '../../../API'
import TbnAvatar from '../../../components/models/TbnAvatar'
import { DoctorService } from '../../../components/services/DoctorService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure } from '../../../components/util/Toast'

interface DoctorListProps {}
const DoctorList: FC<DoctorListProps> = () => {
  const [items, setItems] = useState<Doctor[]>([])
  const [presentToast] = useIonToast()

  const fetchItems = async () => {
    const res: TbnResponse = await DoctorService.Instance.list()
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>Doctors</IonLabel>
        <IonButton fill='clear' onClick={fetchItems}>
          <IonIcon icon={refresh} />
        </IonButton>
      </IonListHeader>
      {items.map((itm: Doctor) => (
        <IonItem key={itm.id}>
          <TbnAvatar avatar={itm.avatar} slot='start' />
          <IonLabel>
            <IonText
              color={
                itm?.status === DoctorStatus.ACTIVE
                  ? 'success'
                  : itm?.status === DoctorStatus.INACTIVE
                  ? 'warning'
                  : itm?.status === DoctorStatus.DELETED
                  ? 'danger'
                  : 'medium'
              }
            >
              Dr
            </IonText>{' '}
            {itm.drName}
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  )
}

export default DoctorList
