import { FC, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { Doctor } from '../../../API'
import ScriptReceiverAddButton from './ScriptReceiverAddButton'
import ScriptReceiverList from './ScriptReceiverList'

interface OwnProps {
  doctor: Doctor
}
interface ScriptReceiverComponentProps extends OwnProps {}
const ScriptReceiverComponent: FC<ScriptReceiverComponentProps> = ({ doctor }) => {
  const [reload, setReload] = useState(false)

  return (
    <div>
      <IonButton fill='clear' onClick={() => setReload(!reload)} className='ion-float-end'>
        <IonIcon icon={refresh} slot='icon-only' />
      </IonButton>
      <ScriptReceiverAddButton
        onAddSuccess={(data: any) => {
          if (!!data) setReload(!reload)
        }}
      />
      <br />
      <ScriptReceiverList doctor={doctor} reload={reload} />
    </div>
  )
}

export default ScriptReceiverComponent
