import { useParams } from 'react-router'
import PageContainer from '../PageContainer'
import ReferAPatient from './ReferAPatient'

const ReferAPatientPage = () => {
  const { doctorPublicId, clinicPublicId } = useParams<any>()
  return (
    <PageContainer id='refer-a-patient-page' padding={true} title='Referral for Medical Cannabis Assessment '>
      <ReferAPatient defaultDoctorPublicId={doctorPublicId} defaultClinicPublicId={clinicPublicId} />
    </PageContainer>
  )
}

export default ReferAPatientPage
