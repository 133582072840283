import { arrowDown } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { IonChip, IonCol, IonIcon, IonRow, IonText } from '@ionic/react'
import { isMobile } from '../../util/isMobile'
import './selector.scss'

const lmap = {
  1: { tag: 'Faint', desc: 'My pain is barely noticeable', color: 'success' },
  2: { tag: 'Mild', desc: 'My pain is light and infrequent', color: 'success' },
  3: { tag: 'Moderate', desc: 'My pain is bothering but can be ignored', color: 'success' },
  4: { tag: 'Uncomfortable', desc: 'My pain is constant but not too limiting', color: 'warning' },
  5: { tag: 'Distracting', desc: 'My pain is interfering with my life', color: 'warning' },
  6: { tag: 'Distressing', desc: 'My pain controls my attention', color: 'warning' },
  7: { tag: 'Intense', desc: 'My pain is deteriorating my lifestyle', color: 'warning' },
  8: { tag: 'Unmanageable', desc: 'My pain is so bad I can barely move', color: 'danger' },
  9: { tag: 'Severe', desc: 'My pain is unbearable', color: 'danger' },
  10: { tag: 'Debilitating', desc: 'My pain requires emergency attention', color: 'danger' },
}

interface PainLevelSelectorProps {
  disabled?: boolean
  onChangeSelection: Function
  defaultSelection?: number
}
const PainLevelSelector: FC<PainLevelSelectorProps> = ({ disabled = false, onChangeSelection, defaultSelection }) => {
  const [selection, setSelection] = useState<number>(defaultSelection || 0)

  const isSelected = (level: number) => {
    return selection === level
  }

  useEffect(() => {
    onChangeSelection(selection)
  }, [selection]) // eslint-disable-line

  return (
    <>
      <div id='pain-level-selector-id'>
        <IonRow>
          <IonCol size='12' className='ion-text-center' style={{ minHeight: '105px' }}>
            {!!selection && (
              <IonChip id='selected' color={(lmap as any)?.[selection].color}>
                &nbsp;&nbsp;
                <span style={{ position: 'absolute', left: selection === 10 ? '8px' : '12px' }}>{selection}</span>
              </IonChip>
            )}
            {!selection && (
              <IonText>
                <br />
                Select the pain level <br /> you're feeling today <br />
                <IonIcon size='large' icon={arrowDown} />
              </IonText>
            )}
          </IonCol>
        </IonRow>
        {!!selection && (
          <IonRow>
            <IonCol size='12' className='ion-text-center'>
              <h2 className='ion-no-margin'>{(lmap as any)?.[selection]?.tag}&nbsp;</h2>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol size='12' className='ion-text-center'>
            <span style={{ fontSize: isMobile() ? '70%' : '75%' }} className='hint'>
              {(lmap as any)?.[selection]?.desc}&nbsp;
            </span>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonChip color='success' outline={!isSelected(1)} onClick={() => setSelection(1)} disabled={disabled}>
              1
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='success' outline={!isSelected(2)} onClick={() => setSelection(2)} disabled={disabled}>
              2
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='success' outline={!isSelected(3)} onClick={() => setSelection(3)} disabled={disabled}>
              3
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='warning' outline={!isSelected(4)} onClick={() => setSelection(4)} disabled={disabled}>
              4
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='warning' outline={!isSelected(5)} onClick={() => setSelection(5)} disabled={disabled}>
              5
            </IonChip>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: '5px' }}>
          <IonCol>
            <IonChip color='warning' outline={!isSelected(6)} onClick={() => setSelection(6)} disabled={disabled}>
              6
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='warning' outline={!isSelected(7)} onClick={() => setSelection(7)} disabled={disabled}>
              7
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='danger' outline={!isSelected(8)} onClick={() => setSelection(8)} disabled={disabled}>
              8
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='danger' outline={!isSelected(9)} onClick={() => setSelection(9)} disabled={disabled}>
              9
            </IonChip>
          </IonCol>
          <IonCol>
            <IonChip color='danger' outline={!isSelected(10)} onClick={() => setSelection(10)} disabled={disabled}>
              &nbsp;&nbsp;<span style={{ position: 'absolute', left: '8px' }}>10</span>
            </IonChip>
          </IonCol>
        </IonRow>
      </div>
    </>
  )
}

export default PainLevelSelector
