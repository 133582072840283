import { FC, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader } from '@ionic/react'
import { IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { clipboard, close } from 'ionicons/icons'
import { TypeFormEvent } from '../../API'

interface OwnProps {
  showModal: boolean
  setShowModal: Function
  model?: TypeFormEvent | undefined
}

interface AnswerModalProps extends OwnProps {}

const AnswerModal: FC<AnswerModalProps> = ({ showModal, setShowModal, model }) => {
  const [present] = useIonToast()
  const [item, setItem] = useState(model)
  const modal = useRef<HTMLIonModalElement>(null)

  const resolveAnswer = (question: any) => {
    if ('checkbox' === question?.type) {
      return question?.answer ? 'true' : 'false'
    } else {
      return question?.answer
    }
  }

  const resolveAnswersText = () => {
    let result = ''
    ;(item as any)?.qus?.map((q: any, idx: number) => {
      result += `${q.label}   ${resolveAnswer(q)} \n`
      return q
    })
    return result
  }

  const shouldBeFlagged = (q: any) => {
    if (q.type === 'yes_no') {
      return q.answer === 'yes'
    } else if (q.type === 'checkbox') {
      return q.answer
    }
    return false
  }

  useEffect(() => {
    const parsed: any = { ...item, qus: JSON.parse(item?.questions || '[]') }
    setItem(parsed)
  }, []) // eslint-disable-line

  return (
    <IonModal ref={modal} isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal?.current?.dismiss()} color='primary'>
              <IonIcon icon={close} slot='icon-only'></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <span>
                Answers - {item?.firstName} ({item?.email || item?.phone}) {item?.submittedAt}
              </span>
            </CopyToClipboard>
          </IonTitle>
          <IonButtons slot='end'>
            <CopyToClipboard
              text={resolveAnswersText()}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <IonButton color='primary'>
                <IonIcon icon={clipboard} slot='icon-only'></IonIcon>
              </IonButton>
            </CopyToClipboard>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          {(item as any)?.qus?.map((q: any, idx: number) => (
            <CopyToClipboard
              key={q.id + '-' + idx}
              text={q.label + ' ' + q.answer}
              onCopy={() => present({ message: 'copied!', color: 'tertiary', duration: 3000 })}
            >
              <IonRow>
                <IonCol className={`${shouldBeFlagged(q) ? 'text-bold' : ''}`}>{q.label}</IonCol>
                <IonCol size='4'>{resolveAnswer(q)}</IonCol>
              </IonRow>
            </CopyToClipboard>
          ))}
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default AnswerModal
