import { combineReducers } from './combineReducers'
import { userReducer } from './user/user.reducer'

export const initialState: AppState = {
  user: {
    net: false,
    loading: false,
    darkMode: false,
    loginMode: 'username',
    menuEnabled: true,
    booking: {
      __typename: 'Booking',
    },
    consent: {
      __typename: 'ConsentModel',
    },
    patient: {},
    referral: {},
    emailSent: false,
  },
}

export const reducers = combineReducers({
  user: userReducer,
})

export type AppState = ReturnType<typeof reducers>
