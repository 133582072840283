import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, useIonToast } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { Reception } from '../../../API'
import TbnAvatar from '../../../components/models/TbnAvatar'
import { ReceptionService } from '../../../components/services/ReceptionService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { failure } from '../../../components/util/Toast'

interface ReceptionListProps {}
const ReceptionList: FC<ReceptionListProps> = () => {
  const [items, setItems] = useState<Reception[]>([])
  const [presentToast] = useIonToast()

  const fetchItems = async () => {
    const res: TbnResponse = await ReceptionService.Instance.list()
    if (res.data) setItems(res.data)
    else if (res.errorMessage) failure(res.errorMessage, presentToast)
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>Receptions</IonLabel>
        <IonButton fill='clear' onClick={fetchItems}>
          <IonIcon icon={refresh} />
        </IonButton>
      </IonListHeader>
      {items.map((itm: Reception) => (
        <IonItem key={itm.id}>
          <TbnAvatar avatar={itm.avatar} slot='start' />
          <IonLabel>{itm.name}</IonLabel>
        </IonItem>
      ))}
    </IonList>
  )
}

export default ReceptionList
