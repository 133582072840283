import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { CannabisProduct } from '../../API'
import productCategoryToNumber from '../models/ProductCategoryToNumber'
import { CannabisProductService } from '../services/CannabisProductService'
import './select.scss'

interface CannabisProductSelectProps {
  defaultValue?: string | null
  onChange: Function
}
const CannabisProductSelect: FC<CannabisProductSelectProps> = ({ defaultValue, onChange }) => {
  const [options, setOptions] = useState()
  const [items, setItems] = useState<CannabisProduct[]>([])
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
    onChange(items.find((e: CannabisProduct) => e.id === itm.value))
  }

  const fetchOptions = async () => {
    const res: any = await CannabisProductService.Instance.listAll()
    if (res.data) {
      setItems(res.data)
      setOptions(
        res.data.flatMap((itm: CannabisProduct) => {
          return {
            value: itm.id,
            label: `${itm.name} (${itm.ingredients}) ${
              itm.category ? `Cat ${productCategoryToNumber(itm.category)}` : ''
            }`,
          }
        }),
      )
    }
  }

  useEffect(() => {
    fetchOptions()
  }, []) // eslint-disable-line

  return <Select value={selectedOption} options={options} onChange={handleChange} />
}

export default CannabisProductSelect
