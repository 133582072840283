import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { Reception, User, Clinic } from '../../API'
import { findReceptionByUserId } from '../../graphql/queries'
import { log, warn } from '../util/Log'
import { ClinicReceptionService } from './ClinicReceptionService'
import { CurrentUserService } from './CurrentUserService'
import { TbnResponse } from './TbnResponse'

export class CurrentReceptionService {
  private static receptionService: CurrentReceptionService | undefined
  private reception?: Reception
  private clinic?: Clinic
  private user?: User
  constructor(user?: User) {
    this.user = user
  }

  public async currentReception(): Promise<Reception> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentReceptionService.Instance.reception) {
      return Promise.resolve(CurrentReceptionService.Instance.reception)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentReceptionService.Instance.reception) {
      return Promise.resolve(CurrentReceptionService.Instance.reception)
    } else {
      return Promise.reject('Current reception resolve timeout!')
    }
  }

  public async currentClinic(): Promise<Clinic> {
    try {
      await CurrentUserService.Instance?.currentUser()
    } catch (ignore) {}
    if (!!CurrentReceptionService.Instance.clinic) {
      return CurrentReceptionService.Instance.clinic
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentReceptionService.Instance.clinic) {
      return CurrentReceptionService.Instance.clinic
    } else {
      return Promise.reject('Current clinic resolve timeout!')
    }
  }

  private async checkIfReceptionExists(service: CurrentReceptionService) {
    try {
      const res: any = await API.graphql({
        query: findReceptionByUserId,
        variables: { userID: service.user?.id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      const reception = res?.data?.findReceptionByUserId?.items?.[0]
      if (!!reception) {
        service.reception = reception
        const res: TbnResponse = await ClinicReceptionService.Instance.find(reception.id)
        if (!!res.data) service.clinic = res.data.clinic
        log('foundReceptionByUserId', reception?.id, 'at', res.data?.clinicID)
        return service
      }
    } catch (err) {
      log('error fetching reception: ', err)
    }
    return service
  }

  private async buildCurrentReceptionService(): Promise<CurrentReceptionService> {
    try {
      const user: User | undefined = await CurrentUserService.Instance?.currentUser()
      let service = new CurrentReceptionService(user)
      service = await service.checkIfReceptionExists(service)
      return Promise.resolve(service)
    } catch (err) {
      warn('Init CurrentReceptionService failure:', err)
      return this.buildCurrentReceptionService()
    }
  }

  public static get Instance(): CurrentReceptionService {
    if (!this.receptionService) {
      this.receptionService = new CurrentReceptionService()
      this.receptionService.buildCurrentReceptionService().then((service: CurrentReceptionService) => {
        this.receptionService = service
      })
    }
    return this.receptionService
  }
}
