import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, useIonToast } from '@ionic/react'
import { IonItem, IonLabel, IonRow, IonSpinner, IonText } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { Reception } from '../../../API'
import { ReceptionService } from '../../../components/services/ReceptionService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { UserService } from '../../../components/services/UserService'
import ImageUpload from '../../../components/util/ImageUpload'
import { failure, success } from '../../../components/util/Toast'
import AdminContainer from '../AdminContainer'

interface OwnProps {}
interface ReceptionEditProps extends OwnProps {}

const ReceptionEdit: FC<ReceptionEditProps> = () => {
  const { sub } = useParams<any>()
  const [user, setUser] = useState<any>()
  const [reception, setReception] = useState<Reception>()
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [present] = useIonToast()

  const addOrEdit = async () => {
    setEditing(true)
    if (!!reception) {
      const res: TbnResponse = await ReceptionService.Instance.addOrEditReception(user?.id, reception)
      if (res.data) success('Success updating reception', present)
      else if (res.errorMessage) failure(res.errorMessage, present)
    }
    setEditing(false)
  }

  const loadUser = async (sub: string) => {
    setLoading(true)
    setUser(undefined)
    setReception(undefined)
    const res: TbnResponse | any = await UserService.Instance?.findBySub(sub)
    if (res.data) {
      setUser(res.data)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  const load = async (userID: string) => {
    setLoading(true)
    const res: TbnResponse = await ReceptionService.Instance.findReception(userID)
    if (!!res.data) {
      setReception(res.data)
    } else if (res.errorMessage) {
      failure(res.errorMessage, present)
      setReception({ userID } as Reception)
    }
    setLoading(false)
  }

  const userDisplay = (user: any) => {
    return user?.email
  }

  const isFormInvalid = () => !reception?.name

  useEffect(() => {
    if (!!reception?.avatar) {
      addOrEdit()
    }
  }, [reception?.avatar]) // eslint-disable-line

  useEffect(() => {
    if (!!sub) {
      loadUser(sub)
    }
  }, [sub]) // eslint-disable-line

  useEffect(() => {
    if (!!user?.id) {
      load(user.id)
    }
  }, [user?.id]) // eslint-disable-line

  return (
    <AdminContainer
      id='receptionEdit'
      title={userDisplay(user)}
      actionButtonsEnd={
        <IonButton onClick={() => load(user?.id)}>
          <IonIcon icon={refresh} slot='icon-only' />
        </IonButton>
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            {loading && (
              <div className='ion-text-center'>
                <IonSpinner />
              </div>
            )}
            {!loading && !user?.id && (
              <IonText>
                Reception's user account doesn't exist, Reception need to login and update her/his given name and
                surname first.
              </IonText>
            )}
          </IonCol>
        </IonRow>
        {!!user && !loading && (
          <>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel className='required'>Name</IonLabel>
                  <IonInput
                    value={reception?.name}
                    onIonChange={(e: any) => setReception({ ...reception, name: e.detail.value } as Reception)}
                    disabled={editing}
                  />
                </IonItem>
                <ImageUpload
                  defaultPath='images/reception/avatar'
                  defaultImage={reception?.avatar}
                  label='Avatar'
                  avatar={true}
                  onTbnChange={(avatar: string) => setReception({ ...reception, avatar } as Reception)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-text-end'>
                <IonButton onClick={addOrEdit} disabled={editing || isFormInvalid()}>
                  Save
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
    </AdminContainer>
  )
}

export default ReceptionEdit
