export const log = (message?: any, ...optionalParams: any[]) => {
  if (process?.env?.REACT_APP_LOG_ENABLED) {
    console.log(message, optionalParams)
  }
}

export const warn = (message?: any, ...optionalParams: any[]) => {
  if (process?.env?.REACT_APP_WARN_ENABLED) {
    console.warn(message, optionalParams)
  }
}

export const error = (message?: any, ...optionalParams: any[]) => {
  if (process?.env?.REACT_APP_ERROR_ENABLED) {
    console.error(message, optionalParams)
  }
}
