import { create } from 'apisauce'
import { trash } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, useIonToast } from '@ionic/react'
import { log } from '../../components/util/Log'
import PageContainer from '../PageContainer'

interface CalendlyWebhook {
  callback_url: string
  creator: string
  events: string[]
  orginaization: string
  retry_started_at?: string
  scope: 'organization' | 'user'
  state: string
  uri: string
  user?: string
  created_at: string
  updated_at: string
}

const CalendlyWebhooks = () => {
  const [present] = useIonToast()
  const [webhooks, setWebhooks] = useState([])
  const orgUrl = `https://api.calendly.com/organizations/${process?.env?.REACT_APP_CALENDLY_ORGANIZATION_ID}`
  const usrUrl = 'https://api.calendly.com/users/4a75975e-6458-4452-9664-669d5cb0ea9c'
  const [callbackUrl, setCallbackUrl] = useState<any>()
  const [inprogress, setInprogress] = useState(false)
  const [deleteInprogress, setDeleteInprogress] = useState(false)
  // const [eventType, setEventType] = useState('invitee.canceled')
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const api = create({
    baseURL: 'https://api.calendly.com',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process?.env?.REACT_APP_CALENDLY_PERSONAL_TOKEN}`,
    },
  })

  const fetchWebhooks = async () => {
    const res: any = await api.get(`/webhook_subscriptions?organization=${orgUrl}&scope=organization`)
    if (res.ok) {
      setWebhooks(res.data.collection)
      log('Webhooks', res.data.collection)
    } else {
      present({
        message: `${res?.data?.message} : ${res?.data?.details?.[0]?.message}`,
        color: 'danger',
        duration: 3000,
      })
    }
  }

  const createWebhook = async () => {
    setInprogress(true)
    const body = {
      url: callbackUrl,
      events: ['invitee.created', 'invitee.canceled'],
      organization: orgUrl,
      user: usrUrl,
      scope: 'organization',
      signing_key: process?.env?.REACT_APP_CALENDLY_WEBHOOK_SIGNING_KEY,
    }
    const res: any = await api.post(`webhook_subscriptions`, body)
    if (res.ok) {
      setCallbackUrl(null)
      await fetchWebhooks()
    } else {
      log('Create webhook error: ', res)
      present({
        message: `${res?.data?.message} : ${res?.data?.details?.[0]?.message}`,
        color: 'danger',
        duration: 3000,
      })
    }
    setInprogress(false)
  }

  const deleteWebhook = async (webhook: CalendlyWebhook) => {
    setDeleteConfirm(true)
    setDeleteInprogress(true)
    if (!deleteConfirm) {
      present({ message: 'To confirm delete, click delete again', duration: 3000 })
      setDeleteInprogress(false)
      return
    }
    const res: any = await api.delete(webhook.uri.replace('https://api.calendly.com', ''))
    if (res.ok) {
      present({ message: 'Webhook deleted.', color: 'success', duration: 3000 })
      await fetchWebhooks()
    } else {
      present({
        message: `${res?.data?.message} : ${res?.data?.details?.[0]?.message}`,
        color: 'danger',
        duration: 3000,
      })
    }
    setDeleteInprogress(false)
  }

  useEffect(() => {
    fetchWebhooks()
  }, []) // eslint-disable-line

  return (
    <PageContainer id='calendly-webhooks' isPrivate={true} padding={true} title='Calendly Webhooks'>
      <IonItem>
        <IonLabel position='floating'>Callback URL</IonLabel>
        <IonInput value={callbackUrl} onIonChange={(e: any) => setCallbackUrl(e.detail.value)}></IonInput>
      </IonItem>
      {/* <IonItem>
        <IonSelect value={eventType} onIonChange={(e: any) => setEventType(e.detail.value)}>
          <IonSelectOption value='invitee.canceled'>Cancel Callback</IonSelectOption>
          <IonSelectOption value='invitee.created'>Create Callback</IonSelectOption>
        </IonSelect>
      </IonItem> */}
      <IonButton className='ion-margin' onClick={() => createWebhook()} disabled={!callbackUrl || inprogress}>
        Create new webhook
      </IonButton>
      <h3>Calendly webhooks</h3>
      {webhooks.map((w: CalendlyWebhook) => (
        <IonItem key={w.uri}>
          <IonLabel>
            {JSON.stringify(w?.events)}
            <p>{w?.callback_url}</p>
          </IonLabel>
          <IonButton
            fill='clear'
            color={'danger'}
            slot='end'
            onClick={() => deleteWebhook(w)}
            disabled={deleteInprogress}
          >
            <IonIcon icon={trash} slot='icon-only'></IonIcon>
          </IonButton>
        </IonItem>
      ))}
    </PageContainer>
  )
}

export default CalendlyWebhooks
