import { FC, useEffect, useState } from 'react'
import { IonThumbnail } from '@ionic/react'
import { Storage } from 'aws-amplify'

interface TbnThumbnailProps {
  path?: string | null
}
const TbnThumbnail: FC<TbnThumbnailProps> = ({ path }) => {
  const [url, setUrl] = useState<string>()

  const fetchUrl = async () => {
    if (!!path) {
      const url = await Storage.get(path, { level: 'public' })
      setUrl(url)
    }
  }

  useEffect(() => {
    fetchUrl()
  }, [path]) // eslint-disable-line

  return (
    <IonThumbnail>
      <img src={url} alt='' />
    </IonThumbnail>
  )
}

export default TbnThumbnail
