import { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonText, useIonToast } from '@ionic/react'
import { mailOpenOutline, mailOutline, refresh } from 'ionicons/icons'
import { Doctor, Referred } from '../../../API'
import { ReferredService } from '../../../components/services/ReferredService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setDoctorReferralCount } from '../../../data/user/user.actions'
import DoctorContainer from '../../doctor/DoctorContainer'
import Referral from '../Referral.model'
import ReferralAnswerModal from '../ReferralAnswerModal'

interface DispatchProps {
  setDoctorReferralCount: typeof setDoctorReferralCount
}
interface ReferralListPageProps extends DispatchProps {}
const ReferralListPage: FC<ReferralListPageProps> = ({ setDoctorReferralCount }) => {
  const [doctor, setDoctor] = useState<Doctor>()
  const [item, setItem] = useState<Referred>()
  const [items, setItems] = useState<Referred[]>([])
  const [presentToast] = useIonToast()
  const [intializing, setInitalizing] = useState(true)
  const [marking, setMarking] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const fetchItems = async () => {
    if (doctor?.drId) {
      const res: TbnResponse = await ReferredService.Instance.findByDoctorPublicId(doctor?.drId)
      if (res.data) setItems(res.data)
      else if (res.errorMessage) failure(res.errorMessage, presentToast)
    }
  }

  const markReadAsReverse = async (item: Referred) => {
    setMarking(true)
    const res: TbnResponse = await ReferredService.Instance.changeDoctorReadStatus(item.id, !item.readByDoctor)
    if (res.data) {
      await fetchItems()
      if (doctor?.drId) {
        const count: TbnResponse = await ReferredService.Instance.countUnreadByDoctorPublicId(doctor?.drId)
        await setDoctorReferralCount(count.data)
      }
    } else if (res.errorMessage) failure(res.errorMessage, presentToast)
    setMarking(false)
  }

  useEffect(() => {
    if (!!doctor) fetchItems()
  }, [doctor]) // eslint-disable-line

  return (
    <DoctorContainer
      id='referral-list-page'
      title='Referral list'
      doctorLoaded={(doctor: Doctor) => {
        setDoctor(doctor)
        setInitalizing(false)
      }}
      actionButtonsEnd={
        <IonButton onClick={fetchItems}>
          <IonIcon icon={refresh} />
        </IonButton>
      }
    >
      {intializing && <TbnSpinner />}
      {!intializing && items.length === 0 && <IonText>No record to display</IonText>}
      <IonList>
        {items.map((itm: Referred) => (
          <IonItem key={itm.id}>
            <IonLabel
              className='pointer'
              style={{ fontWeight: itm?.readByDoctor ? 'normal' : 'bold' }}
              onClick={() => {
                setItem(itm)
                setShowModal(true)
              }}
            >
              {itm.givenName} {itm.surName}
              <p>
                {itm.practitionerFirstName
                  ? `Referred by ${itm.practitionerFirstName} ${itm.practitionerLastName}`
                  : ''}
              </p>
            </IonLabel>
            <IonButtons>
              {!itm.readByDoctor && (
                <IonButton onClick={() => markReadAsReverse(itm)} disabled={marking}>
                  <IonIcon icon={mailOutline}></IonIcon>
                </IonButton>
              )}
              {!!itm.readByDoctor && (
                <IonButton onClick={() => markReadAsReverse(itm)} disabled={marking}>
                  <IonIcon icon={mailOpenOutline}></IonIcon>
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        ))}
      </IonList>
      {!!item && (
        <ReferralAnswerModal
          referral={item as Referral}
          isOpen={showModal}
          onDidDismiss={() => {
            setShowModal(false)
            setTimeout(() => {
              setItem(undefined)
            }, 0)
          }}
        />
      )}
    </DoctorContainer>
  )
}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {
    setDoctorReferralCount,
  },
  component: ReferralListPage,
})
