import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import {
  ClinicReception,
  DeleteClinicReceptionInput,
  ModelClinicReceptionFilterInput,
  ModelSortDirection,
} from '../../API'
import { createClinicReception, deleteClinicReception } from '../../graphql/mutations'
import {
  findClinicReceptionByClinicId,
  findClinicReceptionByReceptionId,
  listClinicReceptions,
} from '../../graphql/queries'
import { warn } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class ClinicReceptionService {
  private static service: ClinicReceptionService | undefined

  public async list(filter: ModelClinicReceptionFilterInput): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: listClinicReceptions,
        variables: { filter },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.listClinicReceptions?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error fetching ClinicReceptions: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async findByClinicID(clinicID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findClinicReceptionByClinicId,
        variables: { clinicID, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicReceptionByClinicId?.items, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async find(receptionID: string): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: findClinicReceptionByReceptionId,
        variables: { receptionID, sortDirection: ModelSortDirection.DESC },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.findClinicReceptionByReceptionId?.items?.[0], errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error finding: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async store(clinicReception: ClinicReception): Promise<TbnResponse> {
    try {
      const res: any = await API.graphql({
        query: createClinicReception,
        variables: { input: clinicReception },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.createClinicReception, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on persist: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public async remove(clinicReception: ClinicReception): Promise<TbnResponse> {
    try {
      const input: DeleteClinicReceptionInput = {
        id: clinicReception.id,
      }
      const res: any = await API.graphql({
        query: deleteClinicReception,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      return { data: res?.data?.deleteClinicReception, errorMessage: res?.errors?.[0]?.message }
    } catch (err) {
      warn('error on remove: ', err)
      return { errorMessage: JSON.stringify(err) }
    }
  }

  public static get Instance(): ClinicReceptionService {
    if (!this.service) {
      this.service = new ClinicReceptionService()
    }
    return this.service
  }
}
