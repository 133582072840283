import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, isPlatform } from '@ionic/react'
import { Swiper as SwiperCore } from 'swiper'
import { checkmark } from 'ionicons/icons'
import capitalizeFirstName from '../../components/util/CapitalizeFirstName'
import { connect } from '../../data'
import { setBooking } from '../../data/user/user.actions'
import Booking from './Booking.model'

interface OwnProps {
  model: Booking
  swiper?: SwiperCore
  paramElse: string | null | undefined
}
interface DispatchProps {
  setBooking: typeof setBooking
}
interface BookingSlideTwoProps extends OwnProps, DispatchProps {}
const BookingSlideTwo: FC<BookingSlideTwoProps> = ({ model, paramElse, setBooking, swiper }) => {
  const isKeyboardOpen = useDetectKeyboardOpen()
  const [hideLabel, setHideLabel] = useState(false)

  useEffect(() => {
    if (isKeyboardOpen && isPlatform('android')) {
      setHideLabel(true)
    } else {
      setHideLabel(false)
    }
  }, [isKeyboardOpen])

  const onKeyUpEvent = (e: any) => {
    if (e?.key === 'Enter') {
      e?.target?.blur()
    }
  }

  return (
    <>
      {!hideLabel && (
        <IonLabel className={`ion-text-wrap required`}>
          {!paramElse && <span>What is your first name?</span>}
          {!!paramElse && <span>Patient’s first name:</span>}
        </IonLabel>
      )}
      <IonItem key={'firstName'}>
        <IonInput
          value={(model as any)?.['firstName']}
          onIonChange={(e: any) => setBooking({ ...model, firstName: capitalizeFirstName(e.detail.value) })}
          placeholder={'Type your answer here...'}
          onIonFocus={() => {
            if (isPlatform('android')) {
              setHideLabel(true)
            }
          }}
          onIonBlur={() => {
            setHideLabel(false)
          }}
          onKeyUp={onKeyUpEvent}
        ></IonInput>
      </IonItem>
      {!hideLabel && !!swiper && (
        <>
          <IonButton color='primary' disabled={!(model as any)?.['firstName']} onClick={() => swiper?.slideNext()}>
            OK
            {!!(model as any)?.['firstName'] && <IonIcon icon={checkmark} slot='end' />}
          </IonButton>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setBooking,
  },
  component: BookingSlideTwo,
})
