import { FC } from 'react'
import { Patient } from '../../../API'
import Booking from '../../booking/Booking.model'
import { Question } from './Question.model'
import QuestionMultiCheck from './QuestionMultiCheck'
import QuestionShortText from './QuestionShortText'
import QuestionStatement from './QuestionStatement'
import QuestionText from './QuestionText'
import QuestionYesNo from './QuestionYesNo'

interface OwnProps {
  question: Question
  model: Booking | Patient
  readonly?: boolean
  swiper?: any
  labelOutside?: boolean
  paramElse?: string | null
}
interface QuestionTypeProps extends OwnProps {}

const QuestionType: FC<QuestionTypeProps> = ({
  question,
  model,
  swiper,
  labelOutside = true,
  readonly = false,
  paramElse,
}) => {
  return (
    <>
      {question?.type === 'statement' && (
        <QuestionStatement statement={question?.label} okayButtonText={question?.okText} swiper={swiper} />
      )}
      {question?.type === 'short_text' && (
        <QuestionShortText
          id={question.id}
          label={question?.label}
          alter={question?.alter}
          model={model}
          required={question?.required}
          swiper={swiper}
          email={question?.email}
          readonly={readonly}
          paramElse={paramElse}
        />
      )}
      {question?.type === 'text' && (
        <QuestionText
          id={question.id}
          label={question?.label}
          alter={question?.alter}
          model={model}
          required={question?.required}
          swiper={swiper}
          rows={question?.lines}
          readonly={readonly}
          paramElse={paramElse}
        />
      )}
      {question?.type === 'yes_no' && (
        <QuestionYesNo
          question={question}
          model={model}
          swiper={swiper}
          labelOutside={labelOutside}
          readonly={readonly}
          paramElse={paramElse}
        />
      )}
      {question?.type === 'multi_check' && (
        <QuestionMultiCheck
          id={question?.id}
          label={question?.label}
          alter={question?.alter}
          model={model}
          required={question?.required}
          swiper={swiper}
          options={question?.options || []}
          readonly={readonly}
          paramElse={paramElse}
        />
      )}
    </>
  )
}

export default QuestionType
