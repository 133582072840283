import { FC } from 'react'
import { IonIcon } from '@ionic/react'
import { business, call } from 'ionicons/icons'
import { AppointmentDefinition, AppointmentType, ClinicStatus } from '../../API'
import ClinicStatusDetails from './ClinicStatusDetails'

interface AppointmentDefintionIconProps {
  model: AppointmentDefinition
  slot?: string
}
const AppointmentDefintionIcon: FC<AppointmentDefintionIconProps> = ({ slot = 'start', model }) => {
  const resolveColor = () => {
    if (model.appointmentType === AppointmentType.IN_PERSON) {
      switch (model.clinicStatus) {
        case ClinicStatus.ACTIVE:
          return 'success'
        case ClinicStatus.INACTIVE:
          return 'warning'
        case ClinicStatus.ARCHIVED:
          return 'medium'
        case ClinicStatus.DELETED:
          return 'danger'
      }
    }
    return 'dark'
  }

  const resolveIcon = () => {
    switch (model.appointmentType) {
      case AppointmentType.IN_PERSON:
        return business
      case AppointmentType.TELEHEALTH:
        return call
    }
  }

  return (
    <>
      {model.appointmentType === AppointmentType.IN_PERSON && (
        <ClinicStatusDetails status={model.clinicStatus} name={model.clinicName} logoImage={model.clinicLogoImage} />
      )}
      {model.appointmentType === AppointmentType.TELEHEALTH && (
        <IonIcon icon={resolveIcon()} slot={slot} color={resolveColor()} />
      )}
    </>
  )
}

export default AppointmentDefintionIcon
