import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonSpinner, IonText, useIonToast, useIonViewDidEnter } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Swiper as SwiperCore } from 'swiper'
import { Keyboard, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { QuizType } from '../../../API'
import { QuizService } from '../../../components/services/QuizService'
import { log } from '../../../components/util/Log'
import { failure, success } from '../../../components/util/Toast'
import { isMobile } from '../../../components/util/isMobile'
import { connect } from '../../../data'
import { setQuiz } from '../../../data/user/user.actions'
import PageContainer from '../../PageContainer'
import '../Quiz.scss'
import { Quiz } from '../helpers/Quiz.model'
import { QuizQuestion } from '../helpers/QuizQuestion.model'
import QuizQuestionType from '../helpers/QuizQuestionType'
import quizJson from './ChronicPain.json'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  quiz: Quiz
}
interface DispatchProps {
  setQuiz: typeof setQuiz
}
interface ChronicPainProps extends OwnProps, StateProps, DispatchProps {}
const ChronicPain: FC<ChronicPainProps> = ({ quiz, setQuiz, history }) => {
  let [swiper, setSwiper] = useState<SwiperCore>()
  const questions = quizJson.questions
  const [slide, setSlide] = useState(0)
  const [inprogress, setInprogress] = useState(true)
  const [succeed, setSucceed] = useState(false)
  const [preset] = useIonToast()
  const { user } = useAuthenticator((context) => [context.user])

  const submitQuiz = async () => {
    setInprogress(true)
    try {
      await QuizService.Instance.createQuiz({
        quizType: QuizType.CHRONIC_PAIN,
        userID: user?.attributes?.sub || '0',
        questions: JSON.stringify(quiz?.questions),
      })
      success('Quiz sent successfully', preset)
      setSucceed(true)
    } catch (err: any) {
      failure(`Error: ${err?.errors?.[0]?.message}`, preset)
    }
    setInprogress(false)
  }

  const width = () => {
    if (window.innerWidth >= 650) {
      return '650px'
    } else {
      return `${window.innerWidth - 25}px`
    }
  }

  const handleSlideChangeStart = () => {
    setSlide(swiper?.realIndex || 0)
  }

  const goBack = () => {
    if (!!history.goBack) {
      history.goBack()
    } else {
      history.push('/quiz')
    }
  }

  useIonViewDidEnter(() => {
    log('init quiz data')
    setQuiz({
      __typename: 'Quiz',
      type: 'CHRONIC_PAIN',
      questions,
    })
  })

  useEffect(() => {
    log('innerWith', window.innerWidth)
    log('innerHeight', window.innerHeight)
  }, []) // eslint-disable-line

  useEffect(() => {
    log('slide', slide)
  }, [slide])

  useEffect(() => {
    if (swiper?.isEnd) {
      log('Quiz ended!')
      submitQuiz()
    }
  }, [swiper?.isEnd]) // eslint-disable-line

  return (
    <PageContainer
      id='quize-chronic-pain'
      isPrivate={true}
      hideBackButton={false}
      hideMenu={true}
      hideFooter={true}
      padding={false}
      fullscreen={true}
      title='Chronic pain assessment tool'
    >
      <Swiper
        onSwiper={setSwiper}
        onSlideChangeTransitionStart={handleSlideChangeStart}
        pagination={{
          type: 'progressbar',
          clickable: false,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Pagination, Keyboard]}
        shortSwipes={false}
      >
        {questions.map((q: any) => (
          <SwiperSlide id={q.id} key={q.id}>
            <div style={{ width: width(), margin: 'auto', marginTop: isMobile() ? '0' : 'auto', overflowY: 'auto' }}>
              <QuizQuestionType quizQuestion={q as QuizQuestion} swiper={swiper} />
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide id='end'>
          <div className='ion-text-center'>
            <h1>Thank you!</h1>
            <hr />
            {inprogress && <IonSpinner />}
            {!inprogress && succeed && (
              <>
                <IonText color='success'>You have completed the assessment.</IonText>
                <br />
                <IonButton onClick={goBack}>Back to home</IonButton>
              </>
            )}
            {!inprogress && !succeed && (
              <>
                <IonText color='danger'>Sorry, something went wrong, please submit again</IonText>
                <br />
                <IonButton onClick={submitQuiz} disabled={inprogress}>
                  Submit
                </IonButton>
              </>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    quiz: state.user.quiz,
  }),
  mapDispatchToProps: {
    setQuiz,
  },
  component: withRouter(ChronicPain),
})
