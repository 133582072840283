import { FC } from 'react'
import { IonCol, IonRow, IonText } from '@ionic/react'
import { Question } from './Question.model'

interface OwnProps {
  question: Question
}
interface QuestionTypeViewProps extends OwnProps {}

const QuestionTypeView: FC<QuestionTypeViewProps> = ({ question }) => {
  return (
    <>
      {question?.type === 'statement' && <IonText>{question?.label}</IonText>}
      {question?.type === 'short_text' && (
        <>
          <IonRow className='tr-header'>
            <IonCol>{question?.label}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{question?.answer}</IonCol>
          </IonRow>
        </>
      )}
      {question?.type === 'text' && (
        <>
          <IonRow className='tr-header'>
            <IonCol>{question?.label}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{question?.answer}</IonCol>
          </IonRow>
        </>
      )}
      {question?.type === 'yes_no' && (
        <>
          <IonRow className='tr-header'>
            <IonCol>{question?.label}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{question?.answer === 'yes' ? <b>yes</b> : question?.answer}</IonCol>
          </IonRow>
        </>
      )}
      {question?.type === 'multi_check' && (
        <>
          <IonRow className='tr-header'>
            <IonCol>{question?.label}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{question?.answer}</IonCol>
          </IonRow>
        </>
      )}
    </>
  )
}

export default QuestionTypeView
