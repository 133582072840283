import { FC, useEffect, useState } from 'react'
import { IonCol, IonGrid, IonRow, IonText, useIonToast } from '@ionic/react'
import { Patient } from '../../API'
import Spinner from '../../components/helpers/Spinner'
import { PatientService } from '../../components/services/PatientService'
import { TbnResponse } from '../../components/services/TbnResponse'
import { failure } from '../../components/util/Toast'

interface OwnProps {
  doubleCheck: boolean
  givenName: string
  email?: string | null
  phone?: string | null
  patient?: Patient
}
interface PatientDetailsViewProps extends OwnProps {}
const PatientDetailsView: FC<PatientDetailsViewProps> = ({ email, patient = null, phone, givenName, doubleCheck }) => {
  const [loading, setLoading] = useState(!patient)
  const [item, setItem] = useState<Patient | null>(patient)
  const [present] = useIonToast()

  const findItem = async () => {
    setLoading(true)
    const res: TbnResponse = await PatientService.Instance.findByPhoneOrEmail(
      doubleCheck ? givenName : undefined,
      phone,
      email,
    )
    if (!!res.data) {
      setItem(res.data)
    } else if (!!res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  useEffect(() => {
    if ((!!email && !patient) || (!!phone && !patient)) {
      findItem()
    }
  }, [email, phone, doubleCheck]) // eslint-disable-line

  return (
    <>
      {!email && !phone && !patient && <IonText color='danger'>Phone or Email is required.</IonText>}
      {loading && <Spinner />}
      {!loading && !!item && (
        <IonGrid>
          <IonRow>
            <IonCol>Given Name</IonCol>
            <IonCol>{item.givenName}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Surname</IonCol>
            <IonCol>{item.surName}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Contact number</IonCol>
            <IonCol>
              <a href={`tel:${item.contactNumber}`}>{item.contactNumber}</a>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Date of Birth</IonCol>
            <IonCol>{item.dateOfBirth}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Address</IonCol>
            <IonCol>{item.addressOneLine}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Medicare Number/ private health number</IonCol>
            <IonCol>{item.medicareNumber}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Next of Kin</IonCol>
            <IonCol>{item.nextOfKin}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Next of Kin Relationship</IonCol>
            <IonCol>{item.nextOfKinRelation}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Next of Kin Contact Number</IonCol>
            <IonCol>{item.nextOfKinContact}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Power of Attorney</IonCol>
            <IonCol>{item.powerOfAttorney}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Power of Attorney Relationship</IonCol>
            <IonCol>{item.powerOfAttorneyRelation}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Power of Attorney Contact Number</IonCol>
            <IonCol>{item.powerOfAttorneyContact}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Parent/guardian/carer</IonCol>
            <IonCol>{item.carer}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Parent/guardian/carer Relationship</IonCol>
            <IonCol>{item.carerRelation}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Parent/guardian/carer Contact Number</IonCol>
            <IonCol>{item.carerContact}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Emergency Contact</IonCol>
            <IonCol>{item.emergency}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Emergency Contact Relationship</IonCol>
            <IonCol>{item.emergencyRelation}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Emergency Contact Number</IonCol>
            <IonCol>{item.emergencyContact}</IonCol>
          </IonRow>
        </IonGrid>
      )}
      {!loading && !item && (
        <div className='ion-padding ion-margin ion-text-start'>
          <IonText>Patient details not found!</IonText>
        </div>
      )}
    </>
  )
}

export default PatientDetailsView
