import { FC, useEffect, useState } from 'react'
import { IonBadge } from '@ionic/react'
import { Doctor } from '../../../API'
import { CurrentDoctorService } from '../../../components/services/CurrentDoctorService'
import { ReferredService } from '../../../components/services/ReferredService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { connect } from '../../../data'
import { setDoctorReferralCount } from '../../../data/user/user.actions'

interface OwnProps {}
interface StateProps {
  doctorReferralCount: number
}
interface DispatchProps {
  setDoctorReferralCount: typeof setDoctorReferralCount
}
interface DoctorReferralCountBadgeProps extends OwnProps, StateProps, DispatchProps {}
const DoctorReferralCountBadge: FC<DoctorReferralCountBadgeProps> = ({
  doctorReferralCount,
  setDoctorReferralCount,
}) => {
  const [doctor, setDoctor] = useState<Doctor>()

  const loadDoctorLoop = async (): Promise<Doctor> => {
    try {
      return await CurrentDoctorService.Instance?.currentDoctor()
    } catch (ignore) {
      await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
      return loadDoctorLoop()
    }
  }

  const loadDoctor = async () => {
    const doctor: Doctor = await loadDoctorLoop()
    setDoctor(doctor)
  }

  const fetchCount = async () => {
    if (doctor?.drId) {
      const res: TbnResponse = await ReferredService.Instance.countUnreadByDoctorPublicId(doctor?.drId)
      if (res.data) await setDoctorReferralCount(res.data)
    }
  }

  useEffect(() => {
    if (!!doctor) fetchCount()
  }, [doctor]) // eslint-disable-line

  useEffect(() => {
    loadDoctor()
  }, []) // eslint-disable-line

  return (
    <>
      {!!doctorReferralCount && (
        <IonBadge color='danger' slot='end'>
          {doctorReferralCount}
        </IonBadge>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    doctorReferralCount: state.user.doctorReferralCount,
  }),
  mapDispatchToProps: {
    setDoctorReferralCount,
  },
  component: DoctorReferralCountBadge,
})
