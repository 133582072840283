import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { OverlayEventDetail } from '@ionic/core'
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon } from '@ionic/react'
import { IonItem, IonLabel, IonRow, IonSpinner, useIonActionSheet, useIonToast } from '@ionic/react'
import { API, Auth } from 'aws-amplify'
import { ellipsisVertical, refresh } from 'ionicons/icons'
import { error } from '../../../components/util/Log'
import { success } from '../../../components/util/Toast'
import { connect } from '../../../data'
import { setNet } from '../../../data/user/user.actions'
import PageNavigate from '../../doctor/PageNavigate'

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface DispatchProps {
  setNet: typeof setNet
}
interface ClinicAdminsProps extends OwnProps, StateProps, DispatchProps {}
const ClinicAdmins: FC<ClinicAdminsProps> = ({ setNet, history }) => {
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any>([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length
  const [actionSheet] = useIonActionSheet()
  const [actionResult, setActionResult] = useState<OverlayEventDetail>()
  const [present] = useIonToast()
  const ITEMS_PER_PAGE = 5

  async function listUsers() {
    setLoading(true)
    let apiName = 'AdminQueries'
    let path = '/listUsersInGroup'
    let myInit = {
      queryStringParameters: {
        groupname: 'ClinicAdmins',
        limit: ITEMS_PER_PAGE,
        token: nextToken,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    }
    const { NextToken, ...rest } = await API.get(apiName, path, myInit)
    setUsers(rest.Users)
    setNextNextToken(NextToken)
    setLoading(false)
    return rest
  }

  async function removeFromGroup(groupname: string, user: any) {
    setNet(true)
    let apiName = 'AdminQueries'
    let path = '/removeUserFromGroup'
    let myInit = {
      body: {
        groupname,
        username: user.Username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    }
    try {
      await API.post(apiName, path, myInit)
      await listUsers()
      success(`Success removing ${userDisplay(user)} from ${groupname} group`, present)
    } catch (err) {
      error(err)
    }
    setNet(false)
  }

  const next = () => {
    setPreviousTokens((prev: any) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const userDisplay = (user: any) => {
    return `${user?.Attributes?.find((a: any) => a.Name === 'given_name')?.Value || ''} ${
      user?.Attributes?.find((a: any) => a.Name === 'email')?.Value || ''
    }`
  }

  useEffect(() => {
    if (!!actionResult?.data?.groupname) {
      removeFromGroup(actionResult?.data?.groupname, actionResult?.data?.user)
    }
  }, [actionResult]) // eslint-disable-line

  useEffect(() => {
    listUsers()
  }, [nextToken]) // eslint-disable-line

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='12' className='ion-text-end'>
          <IonButton fill='clear' onClick={listUsers}>
            <IonIcon icon={refresh} slot='icon-only' />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12'>
          <>
            {users.map((u: any) => (
              <IonItem key={u.Username} button={true} onClick={() => history.push(`/admin/admins/${u.Username}`)}>
                <IonLabel>
                  <span>{u?.Attributes?.find((a: any) => a.Name === 'given_name')?.Value}</span>{' '}
                  <span>{u?.Attributes?.find((a: any) => a.Name === 'family_name')?.Value}</span>
                  <p>
                    {u?.Attributes?.find((a: any) => a.Name === 'phone_number')?.Value}{' '}
                    {u?.Attributes?.find((a: any) => a.Name === 'email')?.Value}
                  </p>
                </IonLabel>
                <IonButtons slot='end'>
                  <IonButton
                    fill='clear'
                    onClick={() =>
                      actionSheet({
                        header: `${u?.Attributes?.find((a: any) => a.Name === 'given_name')?.Value || ''} ${
                          u?.Attributes?.find((a: any) => a.Name === 'email')?.Value || ''
                        }`,
                        subHeader: `${u?.Attributes?.find((a: any) => a.Name === 'phone_number')?.Value || ''}`,
                        buttons: [
                          {
                            text: 'Remove from ClinicAdmin group',
                            role: 'destructive',
                            data: {
                              groupname: 'ClinicAdmins',
                              user: u,
                            },
                          },
                          {
                            text: 'Cancel',
                            role: 'cancel',
                            data: {
                              action: 'cancel',
                            },
                          },
                        ],
                        onDidDismiss: ({ detail }) => setActionResult(detail),
                      })
                    }
                  >
                    <IonIcon icon={ellipsisVertical} />
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
            {loading && (
              <div className='ion-text-center'>
                <IonSpinner />
              </div>
            )}
          </>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <PageNavigate {...{ hasNext, hasPrev, prev, next, loading }} />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setNet,
  },
  component: ClinicAdmins,
})
