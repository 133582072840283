import { FC, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { Doctor } from '../../../API'
import DoctorMapNumberImportButton from './DoctorMapNumberImportButton'
import DoctorMapNumberList from './DoctorMapNumberList'
import MapNumberAddButton from './MapNumberAddButton'

interface OwnProps {
  doctor: Doctor
}
interface MapNumbersComponentProps extends OwnProps {}
const MapNumbersComponent: FC<MapNumbersComponentProps> = ({ doctor }) => {
  const [reload, setReload] = useState(false)

  return (
    <div>
      <DoctorMapNumberImportButton doctor={doctor} onUploaded={() => setReload(!reload)} />
      <IonButton fill='clear' onClick={() => setReload(!reload)} className='ion-float-end'>
        <IonIcon icon={refresh} slot='icon-only' />
      </IonButton>
      <MapNumberAddButton
        onAddSuccess={(data: any) => {
          if (!!data) setReload(!reload)
        }}
      />
      <br />
      <DoctorMapNumberList doctor={doctor} reload={reload} />
    </div>
  )
}

export default MapNumbersComponent
