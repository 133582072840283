import { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText, useIonToast } from '@ionic/react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { chevronForward } from 'ionicons/icons'
import { ConsentEvent } from '../../../API'
import { CurrentConsentService } from '../../../components/services/CurrentConsentService'
import { TbnResponse } from '../../../components/services/TbnResponse'
import { utcToLocale } from '../../../components/util/Date'
import TbnSpinner from '../../../components/util/TbnSpinner'
import { failure } from '../../../components/util/Toast'
import { connect } from '../../../data'

interface OwnProps {
  history: any
  selectProfile: Function
}
interface StateProps {}
interface DispatchProps {}
interface ConsentListProps extends OwnProps, StateProps, DispatchProps {}

const ConsentList: FC<ConsentListProps> = ({ history, selectProfile }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<any>([])
  const [present] = useIonToast()
  const [error, setError] = useState<string>()

  const fetchItems = async () => {
    setLoading(true)
    const res: TbnResponse = await CurrentConsentService.Instance.listConsentPublicDTO(
      user?.attributes?.sub,
      user?.attributes?.given_name,
      user?.attributes?.family_name,
    )
    if (!!res.data) {
      setItems(res.data)
    } else if (!!res.errorMessage) {
      failure(res.errorMessage, present)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!!user?.attributes?.sub && !!user?.attributes?.given_name && !!user?.attributes?.family_name) {
      setError(undefined)
      fetchItems()
    } else {
      setError('Please enter your Given name and Surname first:')
    }
  }, [user?.attributes?.sub, user?.attributes?.given_name, user?.attributes?.family_name]) // eslint-disable-line

  return (
    <div className='ion-padding'>
      {!!error && (
        <div className='flex-container align-items-center'>
          <IonText color='danger'>{error}</IonText>
          <IonButton fill='clear' onClick={() => selectProfile()}>
            Account
          </IonButton>
        </div>
      )}
      {!error && (
        <>
          {!loading && items.length === 0 && (
            <IonButton onClick={() => history.push('/forms/consent')}>
              Submit consent form
              <IonIcon icon={chevronForward} slot='end'></IonIcon>
            </IonButton>
          )}
          <IonGrid>
            <IonRow className='tr-header'>
              <IonCol size='10'>Consent date</IonCol>
              <IonCol></IonCol>
            </IonRow>
            {!loading &&
              items.map((itm: ConsentEvent) => (
                <IonRow key={itm.id}>
                  <IonCol size='10' className='ion-no-padding flex-container flex-column flex-center'>
                    {utcToLocale(itm.createdAt)}
                  </IonCol>
                  <IonCol className='ion-no-padding'></IonCol>
                </IonRow>
              ))}
            <IonRow>
              <>
                {!loading && items.length === 0 && <IonCol>No record to display</IonCol>}
                {loading && (
                  <IonCol>
                    <TbnSpinner />
                  </IonCol>
                )}
              </>
            </IonRow>
          </IonGrid>
        </>
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  component: ConsentList,
})
