import { API, Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { CreateUserInput, UpdateUserInput, User } from '../../API'
import { createUser, updateUser } from '../../graphql/mutations'
import { getUser } from '../../graphql/queries'
import { log } from '../util/Log'
import { TbnResponse } from './TbnResponse'

export class CurrentUserService {
  private static userService: CurrentUserService | undefined
  private user?: User
  public username?: string
  public email?: string
  public phoneNumber?: string
  public sub?: string
  public givenName?: string
  public familyName?: string

  public async currentUser(): Promise<User> {
    if (!!CurrentUserService.Instance?.user) {
      return Promise.resolve(CurrentUserService.Instance.user)
    }
    await new Promise((r) => setTimeout(r, 2000)) // Delay 2 seconds
    if (!!CurrentUserService.Instance?.user) {
      return Promise.resolve(CurrentUserService.Instance.user)
    } else {
      return Promise.reject('Current user resolve timeout!')
    }
  }

  public updateCurrentGivenName = async (givenName?: string): Promise<TbnResponse> => {
    if (!!CurrentUserService.Instance?.sub) {
      try {
        const input: UpdateUserInput = {
          id: CurrentUserService.Instance?.sub,
          givenName,
        }
        const res: any = await API.graphql({
          query: updateUser,
          variables: {
            input,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        CurrentUserService.Instance.givenName =
          res?.data?.updateUser?.givenName || CurrentUserService.Instance.givenName
        return { data: res?.data?.updateUser, errorMessage: res?.errors?.[0]?.message }
      } catch (err) {
        return { errorMessage: JSON.stringify(err) }
      }
    }
    return { errorMessage: 'User id is null' }
  }

  public updateCurrentFamilyName = async (familyName?: string): Promise<TbnResponse> => {
    if (!!CurrentUserService.Instance?.sub) {
      try {
        const input: UpdateUserInput = {
          id: CurrentUserService.Instance?.sub,
          familyName,
        }
        const res: any = await API.graphql({
          query: updateUser,
          variables: {
            input,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        CurrentUserService.Instance.familyName =
          res?.data?.updateUser?.familyName || CurrentUserService.Instance.familyName
        return { data: res?.data?.updateUser, errorMessage: res?.errors?.[0]?.message }
      } catch (err) {
        return { errorMessage: JSON.stringify(err) }
      }
    }
    return { errorMessage: 'User id is null' }
  }

  public updateCurrentEmail = async (email?: string): Promise<TbnResponse> => {
    if (!!CurrentUserService.Instance?.sub) {
      try {
        const input: UpdateUserInput = {
          id: CurrentUserService.Instance?.sub,
          email,
        }
        const res: any = await API.graphql({
          query: updateUser,
          variables: {
            input,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        CurrentUserService.Instance.email = res?.data?.updateUser?.email || CurrentUserService.Instance.email
        return { data: res?.data?.updateUser, errorMessage: res?.errors?.[0]?.message }
      } catch (err) {
        return { errorMessage: JSON.stringify(err) }
      }
    }
    return { errorMessage: 'User id is null' }
  }

  private async buildCurrentUserService() {
    const userService: CurrentUserService = new CurrentUserService()
    try {
      const user = await Auth.currentUserInfo()
      userService.username = user.username
      userService.email = user.attributes?.email
      userService.phoneNumber = user.attributes?.phone_number
      userService.sub = user.attributes?.sub
      userService.givenName = user?.attributes?.given_name
      userService.familyName = user?.attributes?.faimly_name
    } catch (err) {
      log('error getting user data... ', err)
      return undefined
    }
    log('subject:', userService.sub)
    if (!!userService.sub) {
      const res: CurrentUserService = await this.checkIfUserExists(userService)
      return res
    }
    return undefined
  }

  private async checkIfUserExists(userService: CurrentUserService) {
    try {
      const res: any = await API.graphql({
        query: getUser,
        variables: { id: userService.sub },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
      const user = res?.data?.getUser
      if (!!user) {
        userService.user = user
        return userService
      } else {
        const res: any = await this.createUser(userService)
        return res
      }
    } catch (err) {
      log('error fetching user: ', err)
    }
    return userService
  }

  private async createUser(userService: CurrentUserService): Promise<CurrentUserService> {
    try {
      if (!!userService?.sub) {
        const input: CreateUserInput = {
          id: userService.sub,
          sub: userService.sub,
          username: userService.phoneNumber || userService.email || userService.sub,
          phoneNumber: userService.phoneNumber,
          email: userService.email,
          givenName: userService.givenName,
          familyName: userService.familyName,
        }
        const res: any = await API.graphql({
          query: createUser,
          variables: {
            input,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })
        log('create user res', res)
        userService.user = res.data.createUser
      }
    } catch (err) {
      log('Error creating user! :', err)
    }
    return userService
  }

  public static get Instance(): CurrentUserService | undefined {
    if (!this.userService) {
      this.userService = new CurrentUserService()
      this.userService.buildCurrentUserService().then((service: CurrentUserService | undefined) => {
        this.userService = service
      })
    }
    return this.userService
  }
}
