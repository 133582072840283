import { useEffect, useState } from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import './Home.css'
import PageContainer from './PageContainer'

const Home: React.FC = () => {
  const [user, setUser] = useState<any | undefined>({})

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState: AuthState, authData) => {
      setUser(authData)
    })
  }, [])

  return (
    <PageContainer id='home' isPrivate={false}>
      <div>
        <h2>Welcome {user?.attributes?.given_name}!</h2>
      </div>
    </PageContainer>
  )
}

export default Home
